import React, {useEffect, useMemo, useState} from "react";
import { useParams, useHistory, useLocation } from 'react-router-dom';
import {Card, CardBody, Col, Modal, Row} from "reactstrap"
import MetaTags from 'react-meta-tags'
import Pagination from 'components/Common/Pagination';
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {TableHeader, Search} from "../Datatable/index";
import {apiKey} from "helpers/url_helper"
import axios from 'axios';
import notification from "../../components/Common/Notification"
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import Moment from 'moment';
import toastr from "toastr"
import {Link} from "react-router-dom"
let api_url = process.env.REACT_APP_API_URL;
let api_key = apiKey()
let exportURL = api_url + "/get-user-csv"

const useFullPageLoader = () => {
    const [loading, setLoading] = useState(false);

    return [
        loading ? 'Loading' : null,
        () => setLoading(true), //Show loader
        () => setLoading(false) //Hide Loader
    ];
};
const headers = { "Content-Type": "application/json", "Authorization": "Bearer " + api_key };
let PageSize = 15;
function getStatus(status) {
//    0: New,1: Rejected, 2: Shortlisted, 3: Hired
    if (status === 0){
        return <span className="font-size-12 badge-warning badge bg-warning rounded-pill">Inactive</span>
    }else if (status === 1){
        return <span className="font-size-12 badge-success badge bg-success rounded-pill">Active</span>
    }else if (status === 2){
        return <span className="font-size-12 badge-dark badge bg-dark rounded-pill">Deleted</span>
    }else if (status === 3){
        return <span className="font-size-12 badge-danger badge bg-danger rounded-pill">BLocked</span>
    }
}
function socialMedia(social) {
    if (social){
        return (
            <ul className="social list-unstyled" style={{minWidth:'120px',marginBottom:0}}>
                {social.social_website && <li><a className="text-primary" href={social.social_website}><i className="mdi mdi-web"></i> Website</a></li>}
                {social.social_facebook && <li><a className="text-info" href={social.social_facebook}><i className="mdi mdi-facebook"></i> Facebook</a></li>}
                {social.social_youtube && <li><a className="text-danger" href={social.social_youtube}><i className="mdi mdi-youtube"></i> Youtube</a></li>}
                {social.social_instagram && <li><a className="text-warning" href={social.social_instagram}><i className="mdi mdi-instagram"></i> Instagram</a></li>}
            </ul>
        )
    }
}
function formatDate(date){
    return Moment(date).format('DD-MM-YYYY, h:mm:ss A')
}
// eslint-disable-next-line react/prop-types
const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    return (
        text.length < 50 ? <p className="read-text">{text}</p> : <p className="read-text">
            {isReadMore ? text.slice(0, 50) : text}
            <span onClick={toggleReadMore} className="read-or-hide">
        {isReadMore ? "...read more" : " show less"}
      </span>
        </p>
    );
};
const handleCopy = (value) => {
    let a = navigator.clipboard.writeText(value)
    navigator.clipboard.readText().then(function(data) {
        toastr.success(`Successfully Copied - ${data}`);
    });
}
const Users = () => {
    const history = useHistory();
    const location = useLocation();
    const { pId:pageno } = useParams();
    const [tableData, settableData] = useState([]);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(() => {
        const paramPage = parseInt(pageno);
        if (!isNaN(paramPage)) {
            return paramPage;
          } else {
            return 1;
          }
      });
      const { searchQuery } = useParams();
      const [search, setSearch] = useState(() => {
          if (searchQuery) {
              return searchQuery;
            } else {
              return '';
            }
        });
      const [getSearch, setGetSearch] = useState(search);
      const [getSearchPage, setGetSearchPage] = useState(currentPage)
      const [isFits, setisFits] = useState(false);
    const [main_img, setMainImg] = useState("");
    const [EditId, setEditId] = useState(0);
    const [status, setStatus] = useState(0);
    const [RequestStatus, setRequestStatus] = useState(0);
    const [StatusModal, setStatusModal] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [userType, setUserType] = useState([])
    const [selectUserType, setSelectedUserType]  = useState('');
    const fetchData = () => {
        fetch(`${api_url}/user-roles?status=1`, {headers})
            .then(response => response.json())
            .then(data =>setUserType(data.data))
            .catch(err => {
                console.log("error:", err);
            });
    }
    const CountryHeader = [
        {name: "#", field: "id", sortable: false},
        {name: "Type", field: "role", sortable: false},
        {name: "Username", field: "username", sortable: false},
        {name: "Profile photo", field: "photo", sortable: false},
        {name: "Name", field: "name", sortable: false},
        {name: "Email", field: "email", sortable: false},
        {name: "Country code", field: "country_code", sortable: false},
        {name: "Mobile", field: "mobile", sortable: false},
        {name: "Bio", field: "bio", sortable: false},
        {name: "Followers", field: "", sortable: false},
        {name: "Static Followers", field: "", sortable: false},
        {name: "Following", field: "", sortable: false},
        {name: "Website", field: "", sortable: false},
        {name: "Facebook", field: "", sortable: false},
        {name: "Youtube", field: "", sortable: false},
        {name: "Instagram", field: "", sortable: false},
        // {name: "Email Verified", field: "email_verified", sortable: false},
        // {name: "Mobile Verified", field: "mobile_verified", sortable: false},
        {name: "Designation", field: "designation", sortable: false},
        // {name: "Social Link", field: "", sortable: false},
        {name: "Created date", field: "created_date", sortable: false},
        // {name: "Updated date", field: "updated_date", sortable: false},
        // {name: "Last login", field: "last_login", sortable: false},
        {name: "Status", field: "status", sortable: false},
        {name: "Deep Link", field: "", sortable: false},
        {name: "Action", field: "", sortable: false}
    ];

    
    function handleSearch(){
        setSearch(getSearch);
        setCurrentPage(getSearchPage);
        if(getSearch){
            history.push(`/users/pageno/${getSearchPage}/${getSearch}`)
        }
    }
    // Handle any changes to the currentPage state
    const handlePageChange = () => {
        setCurrentPage(() => {
            const paramPage = parseInt(pageno);
            if (!isNaN(paramPage)) {
                return paramPage;
            } else {
                return 1;
            }
        })
    };
    useEffect(() => {
        handlePageChange();
    }, [pageno,location]);

    useEffect(() => {
        fetchData();
        showLoader()
        let query = ''
        search && ( query = `keyword=${search}&`)
        const url = `${api_url}/user-list?${query}limit=${PageSize}&pageNo=${currentPage}${selectUserType !== "" ? `&role_ids=${selectUserType}`:''}`
        axios.get(url,{
            headers: {
                "Authorization": 'Bearer '+api_key,
            }
        })
            .then(res => {
                hideLoader();
                if(res.status) {
                    settableData(res.data.data);
                    setTotalItems(res.data.totalRecords);
                }
            }).catch(
                err => {
                    notification(err, 'error', 'toast-bottom-right')
                    if(err.response.status===401 || err.response.status===400 ){
                        history.push('/logout')
                    }
                }
            );
    }, [currentPage,search,selectUserType])  // pass `value` as a dependency
    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }
    function tog_center() {
        removeBodyCss()
    }
    const setUserTypeFun = (roleId) =>{
        if(roleId==1){
            return "Superadmin"
        }
        if(roleId==2){
            return "Admin"
        }
        if(roleId==3){
            return "Creator"
        }
        if(roleId==4){
            return "Contributor"
        }
        if(roleId==5){
            return "Viewer"
        }
    }
    const submitData = (e) => {
        setSubmit(true)
        const headers = {'Content-Type': 'application/json', "Authorization": 'Bearer ' + api_key}
        var data = JSON.stringify({
            "id": EditId,
            "updatedId": 3,
            "request_status": RequestStatus
        });
        axios({
            method: "post", url: `${api_url}/update-request-status`, data: data, headers
        }).then(response => {
            if (response.status) {
                toastr.success('Request Status update successful.')
                setCurrentPage(0)
                setStatusModal(false)
                setSubmit(false)
            }
        }).catch(err => {
            setSubmit(false)
            toastr.error(err.message)
        })
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Manage Users</title>
                </MetaTags>
                <div className="container-fluid">
                    <Breadcrumbs title="Dashboard" path="/" breadcrumbItem="Users"/>
                    {isFits ? (
                        <Lightbox
                            mainSrc={main_img}
                            enableZoom={false}
                            onCloseRequest={() => {
                                setisFits(!isFits);
                            }}
                        />
                    ) : null}
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <div className="d-flex justify-content-between mb-3 gap-3">
                                        <div>
                                            <a href={exportURL}
                                                target="_blank" rel="noreferrer"
                                               className="btn btn-primary waves-effect waves-light"><i
                                                className="bx bx-export font-size-16 align-middle me-2"></i> Export</a>
                                        </div>
                                        <div>
                                            <a href="/users/add"
                                               className="btn btn-primary waves-effect waves-light"><i
                                                className="bx bx-plus font-size-16 align-middle me-2"></i> Add</a>
                                        </div>
                                    </div>
                                    <div className="row flex-row-reverse">
                                        <div className="col-12 col-md-6 d-flex flex-row-reverse mb-3">
                                            <div onClick={handleSearch}
                                               className="btn btn-primary waves-effect waves-light ms-2"> Search</div>
                                            <Search
                                                onSearch={value => {
                                                    setGetSearch(value)
                                                    setGetSearchPage(1)
                                                }}
                                            />
                                        </div>
                                        <div className="col-12 col-md-6 d-flex mb-3 justify-content-end justify-content-md-start ">
                                            <select style={{maxWidth:"190px"}} className="form-select"  value={selectUserType} onChange={(e) =>
                                            {setSelectedUserType(e.currentTarget.value)
                                                setCurrentPage(1)
                                            } }>
                                                <option value="" selected>User Type</option>
                                                {userType.map((item) => (
                                                    <option key={item.id} value={item.id}>
                                                        {item.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-striped">
                                            <TableHeader
                                                headers={CountryHeader}
                                            />
                                            <tbody className="">

                                            {tableData.map((value,index) => (
                                                <tr key={++index}>
                                                    <th scope="row">
                                                        {PageSize * (currentPage - 1) + (index + 1)}
                                                    </th>
                                                    <td>{setUserTypeFun(value.roleId)}</td>
                                                    <td>{value.username}</td>
                                                    <td>
                                                        { value.photo && <img onClick={() => { setisFits(true); setMainImg(value.photo)}} className="img-thumbnail avatar-sm" alt={value.name} src={value.photo} width="200"/>}
                                                    </td>
                                                    <td>{value.name}</td>
                                                    <td>{value.email}</td>
                                                    <td>{value.country_code}</td>
                                                    <td>{value.mobile}</td>
                                                    <td>{<ReadMore>{value.bio}</ReadMore>}</td>
                                                    <td>{value.followers}</td>
                                                    <td>{value.social_static_followers}</td>
                                                    <td>{value.following}</td>
                                                    <td>{value.social_website && <a className="text-primary" href={value.social_website}><i className="mdi mdi-web"></i> Website</a>}</td>
                                                    <td>{value.social_facebook && <a className="text-info" href={value.social_facebook}><i className="mdi mdi-facebook"></i> Facebook</a>}</td>
                                                    <td>{value.social_youtube && <a className="text-danger" href={value.social_youtube}><i className="mdi mdi-youtube"></i> Youtube</a>}</td>
                                                    <td>{value.social_instagram && <a className="text-warning" href={value.social_instagram}><i className="mdi mdi-instagram"></i> Instagram</a>}</td>
                                                    {/*<td>{value.email_verified === 1 ? <span className="badge bg-success"><i className="bx bx-check-circle"></i>Verified</span>:<span className="badge bg-danger font-size-12"><i className="bx bx-x"></i> No</span>}</td>*/}
                                                    {/*<td>{value.mobile_verified === 1 ? <span className="badge bg-success"><i className="bx bx-check-circle"></i>Verified</span>:<span className="badge bg-danger font-size-12"><i className="bx bx-x"></i> No</span> }</td>*/}
                                                    <td>{value.roleId === 3 && value.designation}</td>
                                                    {/*<td>{socialMedia({'social_youtube':value.social_youtube,'social_website':value.social_website,'social_facebook':value.social_facebook,'social_instagram':value.social_instagram})}</td>*/}
                                                    <td>{value.created_date && <div style={{minWidth:"100px"}}>{formatDate(value.created_date)}</div>}</td>
                                                    {/*<td>{value.updated_date && <div style={{minWidth:"100px"}}>{formatDate(value.updated_date)}</div>}</td>*/}
                                                    {/*<td>{value.last_login && <div style={{minWidth:"100px"}}>{formatDate(value.last_login)}</div>}</td>*/}
                                                    <td>{getStatus(value.status)}</td>
                                                    <td>
                                                        <button style={{backgroundColor:'green',padding:'1px 10px'}} className="btn btn-primary waves-effect waves-light copu_btn" onClick={() => {handleCopy(value.deeplink_url)}}>Copy</button>
                                                    </td>
                                                    <td><Link to={`/users/${currentPage}/edit/${value.id}`}><i className="bx bxs-edit-alt"></i> </Link></td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <Row className="justify-content-center">
                                        <Col className="col-auto">
                                            <Pagination
                                                className="pagination-bar"
                                                currentPage={currentPage}
                                                totalCount={totalItems}
                                                pageSize={PageSize}
                                                onPageChange={page => history.push(`/users/pageno/${page}/${search}`)}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
            <Modal size="sm" isOpen={StatusModal} toggle={() => {tog_center()}} centered>
                <div className="modal-header">
                    <h5 className="modal-title mt-0">Request Status</h5>
                    <button
                        type="button"
                        onClick={() => {
                            setStatusModal(false)
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <input type="hidden" id="edit_id" defaultValue={EditId}/>
                    <select className="form-control" defaultValue={status} onChange={event => setRequestStatus(event.target.value)}>
                        <option value="0">New</option>
                        <option value="1">Rejected</option>
                        <option value="2">Shortlisted</option>
                        <option value="3">Hired</option>
                    </select>
                    <button className="w-100 btn btn-primary mt-2" onClick={submitData} disabled={submit} type="button">{submit === true ? 'Please wait...' : 'Submit Data'}</button>
                </div>
            </Modal>
        </React.Fragment>
    )
}

export default Users
