import axios from 'axios'
import {apiKey} from "helpers/url_helper"
let api_key = apiKey()
class UploadFilesService {
    upload(file,id,type, onUploadProgress) {
        let formData = new FormData();
        console.log(file);

        formData.append("id", id);
        formData.append("media", file);
        if(file.type==='video/mp4')
        {
            formData.append("media_type", 'video');
        }
        formData.append('type', type)
        return axios.post(`${process.env.REACT_APP_API_URL}/upload-image-video`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": 'Bearer ' + api_key
            },
            onUploadProgress,
        });
    }

    getFiles(id,type) {
        const params = {
            type: type,
            id: id
        }
        return axios.get(`${process.env.REACT_APP_API_URL}/get-single-data`,{params,
            headers: {"Authorization": 'Bearer ' + api_key,"Access-Control-Allow-Origin": "*"}});
    }
}

export default new UploadFilesService();
