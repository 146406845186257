import React from "react"
import MetaTags from "react-meta-tags"
import {Button, Card, CardBody, Col, Container, Label, Row,} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {SketchPicker} from "react-color"
import "@vtaits/react-color-picker/dist/index.css"
import {apiKey} from "../../../helpers/url_helper"
import SimpleReactValidator from 'simple-react-validator'
import axios from "axios"
import toastr from "toastr"
import PropTypes from "prop-types"

let api_url = process.env.REACT_APP_API_URL
let api_key = apiKey()
const mystyle = {
    height: "110px",
    overflow: "hidden",
    width: "auto",
    objectFit:"cover"
}
const headers = {'Content-Type': 'application/json', "Authorization": 'Bearer ' + api_key}
class DealCategoryEdit extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            status: true,
            category: "",
            logo: "",
            font_color: "",
            bg_color: "",
            ordering: "",
            handleResponse: null,
            submit: false,
            simple_color1: false,
            simple_color2: false,
        }
        this.validator = new SimpleReactValidator({autoForceUpdate: this})
    }

    componentDidMount() {
        const id = this.props.match.params.id
        this.setState({edit_id: id})
        const params = {type: "deals_categories", id: id}
        axios.get(`${api_url}/get-single-data`, {
            params, headers
        })
            .then(res => {
                if (res.data.status) {
                    let edit = res.data.data
                    this.setState({
                        category: edit['name'],
                        font_color: edit['font_color'],
                        old_logo: edit['icon'],
                        bg_color: edit['bg_color'],
                        ordering: edit['ordering'],
                        status: edit['status']
                    })
                } else {
                    toastr.danger('Server not response')
                }
            })
    }

    handleInput = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }
    handleFileChange = (event) => {
        this.setState({
            icon: event.target.files[0]
        })
    }

    handleFormSubmit = (e) => {
        e.preventDefault()
        if (this.validator.allValid()) {
            const formData = new FormData()
            formData.append('name', this.state.category)
            var icon = document.getElementById("icon").files[0]
            if (icon) {
                formData.append('icon', this.state.icon)
            }
            formData.append('font_color', this.state.font_color)
            formData.append('bg_color', this.state.bg_color)
            formData.append('status', this.state.status)
            formData.append('ordering', this.state.ordering)
            formData.append('catDealId', this.state.edit_id)
            this.setState({submit: true})
            axios({
                method: "post", url: `${api_url}/update-deal-category`, data: formData, headers
            }).then(response => {
                if (response.status) {
                    this.setState({submit: false})
                    toastr.success('Deal Category update successful.')
                    // eslint-disable-next-line react/prop-types
                    const {history} = this.props
                    // eslint-disable-next-line react/prop-types
                    const pageno = this.props.match.params.pId; // Get the pageno param from the URL
                    history.goBack();
                }
            }).catch(err => {
                toastr.error(err.message)
                this.setState({submit: false})
                alert(err.message)
            })
        } else {
            this.validator.showMessages()
            this.forceUpdate()
        }
    }

    handleColorPicker = () => {
        if(this.state.simple_color1){
            this.setState({simple_color1:false})
        }
        if(this.state.simple_color2){
            this.setState({simple_color2:false})
        }
    }

    render() {
        const {category, ordering, font_color, bg_color, simple_color1, simple_color2, submit} = this.state
        const handleHor = color => {
            this.setState({font_color: color.hex})
        }
        const handleHor1 = color => {
            this.setState({bg_color: color.hex})
        }
        return (
            <React.Fragment>
                <div className="page-content" onClick={(e) => {this.handleColorPicker(e)}}>
                    <MetaTags>
                        <title>
                            Deal Categories | {process.env.REACT_APP_PROJECTNAME}
                        </title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Deal Categories" path="/deals/categories/pageno/1" breadcrumbItem="Edit Deal Categories"/>
                        <Row>
                            <Col xl="8">
                                <Card>
                                    <CardBody>
                                        <form onSubmit={this.handleFormSubmit}>
                                            <Row>
                                                <Col md="7" className="mb-3">
                                                    <Label htmlFor="category_name">Deal Categories</Label>
                                                    <input name="category" placeholder="Type Deal Category"
                                                           defaultValue={category} type="text"
                                                           className={"form-control"} onChange={this.handleInput}/>
                                                    {this.validator.message('category', this.state.category, 'required')}
                                                </Col>
                                                <Col md="5" className="mb-3">
                                                    <Label htmlFor="icon">Icon Image</Label>
                                                    <input
                                                        id="icon"
                                                        name="icon"
                                                        placeholder="Icon Image"
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={this.handleFileChange}
                                                        className={"form-control"}
                                                    />
                                                    {/*{this.validator.message('icon', this.state.icon, 'required')}*/}
                                                </Col>
                                                <Col md="3" className="mb-3">
                                                    <Label htmlFor="font_color">Font Color ({font_color})</Label>
                                                    <input
                                                        name="font_color"
                                                        type="color"
                                                        placeholder="Font Color"
                                                        className={"form-control"}
                                                        onChange={this.handleInput}
                                                        onClick={() => {
                                                            this.setState({simple_color2: !simple_color2})
                                                        }}
                                                        value={font_color}
                                                        defaultValue={font_color}
                                                        readOnly
                                                    />
                                                    {this.validator.message('font_color', this.state.font_color, 'required')}
                                                </Col>
                                                <Col md="3" className="mb-3">
                                                    <Label htmlFor="bg_color">Background Color ({bg_color})</Label>
                                                    <input
                                                        name="bg_color"
                                                        type="color"
                                                        placeholder="Background Color"
                                                        className={"form-control"}
                                                        onChange={this.handleInput}
                                                        onClick={() => {
                                                            this.setState({simple_color1: !simple_color1})
                                                        }}
                                                        defaultValue={bg_color}
                                                        value={bg_color}
                                                        readOnly
                                                    />
                                                    {this.validator.message('bg_color', this.state.bg_color, 'required')}
                                                </Col>
                                                <Col md="2" className="mb-3">
                                                    <Label htmlFor="validationCustom03">Order No.</Label>
                                                    <input
                                                        name="ordering"
                                                        type="number"
                                                        placeholder="Order No."
                                                        className={"form-control"}
                                                        onChange={this.handleInput}
                                                        defaultValue={ordering}
                                                    />
                                                    {this.validator.message('ordering', this.state.ordering, 'required|numeric')}
                                                </Col>
                                                <Col md="3" className="mb-3">
                                                    <Label htmlFor="">Status</Label>
                                                    <div className="square-switch mt-2">
                                                        <input
                                                            type="checkbox"
                                                            id="square-switch-status"
                                                            switch="none"
                                                            name="status"
                                                            checked={this.state.status}
                                                            onChange={() =>
                                                                this.setState({status: !this.state.status})
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="square-switch-status"
                                                            data-on-label="On"
                                                            data-off-label="Off"
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>

                                            <div className="d-flex flex-wrap gap-2 justify-content-end">
                                                <Button color="primary" type="submit" disabled={submit}>
                                                    {submit === true ? 'Please wait...' : 'Submit Data'}
                                                </Button>
                                            </div>
                                        </form>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xl="4">
                                <Card>
                                    <CardBody>
                                        <h6>Old Icon</h6>
                                        <img src={this.state.old_logo} className="img-fluid" style={mystyle} alt="cover image"/>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

DealCategoryEdit.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string
        })
    }),
    location: PropTypes.object
}

export default DealCategoryEdit
