import React from "react"
import MetaTags from "react-meta-tags"
import { Button, Card, CardBody, Col, Container, Label, Row, Spinner, Modal } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Select from "react-select"
import CreatableSelect from 'react-select/creatable';
import { apiKey } from "../../helpers/url_helper"
import SimpleReactValidator from 'simple-react-validator'
import axios from "axios"
import toastr from "toastr"
import "style.min.css"
import PropTypes from "prop-types"
import { Editor } from '@tinymce/tinymce-react'
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Moment from 'moment'
import LocationSearchInput from '../../components/LocationSearchInput'

let api_url = process.env.REACT_APP_API_URL
let api_key = apiKey()

function formatDate(date) {
    // 2022-10-20T07:17:20.272+00:00
    return Moment(date).format()
}
function getDataName(id, data) {
    if (id === undefined) {
        return []
    }
    let selected = [];
    for (var x = 0; x < id.length; x++) {
        const result = data.find((name) => name['_id'] === id[x]);
        if (result !== undefined) {
            selected.push({ label: result['name'], value: result['_id'] })
        }
    }
    return selected;
}
function createRowData(data) {
    if (data !== undefined) {
        const content = htmlToDraft(data);
        if (content) {
            const contentState = ContentState.createFromBlockArray(content.contentBlocks);
            return EditorState.createWithContent(contentState);
        }
    }
}
const headers = { 'Content-Type': 'application/json', "Authorization": 'Bearer ' + api_key }
class DealsEdit extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            partners: [],
            categories: [],
            cities: [],
            interests: [],
            tags: [],
            selectedPartners: "",
            selectedCategory: [],
            selectedInterests: [],
            selectedTags: [],
            selectedCity: [],
            recommended: false,
            notification_msg_within_city: "",
            notification_msg: "",
            status: true,
            deal_name: "",
            about: "",
            old_about: "",
            location: "",
            location_full_address: "",
            latitude: "",
            longitude: "",
            exclusive: false,
            contact_number: "",
            email_address: "",
            type: "",
            tnc: "",
            old_tnc: "",
            ordering: "",
            cover_image: "",
            partner: "",
            end_date: "",
            start_date: "",
            deals_category: "",
            interest: "",
            terms_conditions: "",
            handleResponse: null,
            submit: false,
            loaded: false,
            // custom tags for ------------
            tagModel: false,
            selected_interests: "",
            customTag: "",
            isLoading: false,
            // editorState: EditorState.createEmpty(),
            // editorState2: EditorState.createEmpty(),
            // editorState3: EditorState.createEmpty(),
        }
        this.validator = new SimpleReactValidator({ autoForceUpdate: this })
        this.handleAutoClose = this.handleAutoClose.bind(this)
    }

    componentDidMount() {
        const id = this.props.match.params.id
        this.setState({ edit_id: id })
        const params = {
            type: "deals",
            id: id
        }
        axios.get(`${api_url}/get-single-data`, {
            params, headers: {
                "Authorization": 'Bearer ' + api_key,
            }
        })
            .then(res => {
                if (res.data.status) {
                    let edit = res.data.data[0]
                    this.setState({
                        defaultCategory: [],
                        defaultInterests: [],
                        defaultTags: [],
                        tag_list: [],
                        notification_msg_within_city: edit['notification_msg_within_city'],
                        notification_msg: edit['notification_msg'],
                        about: edit['about'],
                        old_about: edit['about'],
                        location: edit['location'],
                        location_full_address: edit['location_full_address'],
                        latitude: edit['latitude'],
                        longitude: edit['longitude'],
                        exclusive: edit['exclusive'],
                        contact_number: edit['contact_number'],
                        email_address: edit['email_address'],
                        deal_name: edit['name'],
                        type: edit['type'],
                        tnc: edit['tnc'],
                        old_tnc: edit['tnc'],
                        old_cover_image: edit['cover_image'],
                        partner: (edit['partner'].length > 0) ? edit['partner'][0].id : '',
                        end_date: edit['end_date'],
                        start_date: edit['start_date'],
                        terms_conditions: edit['terms_conditions'],
                        ordering: edit['ordering'],
                        recommended: edit['recommended'],
                        show_on_main_page: edit['show_on_main_page'],
                        status: edit['status'],
                        selectedPartners: (edit['partner'].length > 0) ? edit['partner'][0].id : '',
                        edit_deals_category: edit['deals_category'],
                        edit_interest: edit['interest'],
                        edit_tags: edit['tags'],
                    })
                    this.fetchMaster()
                } else {
                    toastr.danger('Server not response')
                }
            })
    }

    async fetchMaster() {
        Promise.all([
            fetch(`${api_url}/get-deal-categories?&status=1`, { headers }).then(response => response.json()),
            fetch(`${api_url}/get-interests?&status=1`, { headers }).then(response => response.json()),
            fetch(`${api_url}/get-deal-partner?&status=1`, { headers }).then(response => response.json()),
            fetch(`${api_url}/get-deal-city?&status=1`, { headers }).then(response => response.json()),
        ]).then(([categories, interests, partner, cities]) => {
            this.setState({
                categories: categories.data
            })
            if (this.state.edit_deals_category !== undefined && this.state.edit_deals_category.length) {
                let edit_deals_category = []
                for (let i = 0; i < this.state.edit_deals_category.length; i++) {
                    edit_deals_category.push(this.state.edit_deals_category[i].id)
                }
                this.setState({ defaultCategory: getDataName(edit_deals_category, categories.data) })
            }
            this.setState({ selectedCategory: this.state.defaultCategory.map(({ value }) => value).join(',') })
            let edit_interest = [];
            if (this.state.edit_interest !== undefined && this.state.edit_interest.length) {
                for (let i = 0; i < this.state.edit_interest.length; i++) {
                    edit_interest.push(this.state.edit_interest[i].id)
                }
            }
            this.setState({ interests: interests.data, defaultInterests: getDataName(edit_interest, interests.data) })
            this.setState({ selectedInterests: this.state.defaultInterests.map(({ value }) => value).join(',') })
            this.handleInterestTags(false, this.state.edit_interest)

            this.setState({ partners: partner.data })
            if (cities.data.length > 0) {
                this.setState({ cities: this.selectData(cities.data) })
                let cities_data = cities.data;
                for (let i = 0; i < cities_data.length; i++) {
                    if (cities_data[i]._id === this.state.location) {
                        this.setState({ selectedCity: { value: cities_data[i]._id, label: cities_data[i].name } })
                    }
                }
            }
            this.setState({ loaded: true })
        });
    }
    handleInterestTags = (e = false, g = false) => {
        let formData = [];
        if (e) {
            for (let a = 0; a < e.length; a++) {
                formData.push(e[a]['value'])
            }
        } else {
            for (let a = 0; a < g.length; a++) {
                g[a]?.id && formData.push(g[a]?.id)
            }
        }

        axios({
            method: "post", url: `${api_url}/get-interest-wise-tags`, data: { "interest": formData }, headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + api_key
            }
        }).then(response => {
            if (response.data) {
                let b = [], a = response.data.data;
                for (var c = 0; c < a.length; c++) b.push({
                    label: a[c].name,
                    value: a[c]._id
                });
                this.setState({ tags: b })
                this.setState({ tag_list: response.data.data }, this.handelSelectedTags)
            }
        }).catch(err => {
            toastr.error(err.message);
        });
    }
    handelSelectedTags = () => {
        let edit_tags = []
        if (this.state.edit_tags !== undefined && this.state.edit_tags.length) {
            for (let i = 0; i < this.state.edit_tags.length; i++) {
                edit_tags.push(this.state.edit_tags[i].id)
            }
            this.setState({ defaultTags: getDataName(edit_tags, this.state.tag_list) })
        }
    }
    handleCategory = selectedCategory => {
        this.setState({ selectedCategory: selectedCategory.map(({ value }) => value).join(',') })
        this.setState({ defaultCategory: selectedCategory })
    }
    handleInterests = selectedInterests => {
        this.setState({ selectedInterests: selectedInterests.map(({ value }) => value).join(',') })
        this.setState({ defaultInterests: selectedInterests })
    }
    handleTags = selectedTags => {
        this.setState({ defaultTags: selectedTags })
    }
    handleFileChange = (event) => {
        this.setState({
            cover_image: event.target.files[0]
        })
    }
    handlePartner = (e) => {
        this.setState({ selectedPartners: e.target.value })
    }
    selectData = a => {
        let b = []
        for (var c = 0; c < a.length; c++) b.push({
            label: a[c].name, value: a[c]._id
        })
        return b
    }
    handleInput = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleAutoClose(date) {
        this.setState({ auto_close: date })
    }
    setFormLocation = (googleLocation) => {
        if (googleLocation.length) {
            let city;
            for (let i = 0; i < googleLocation[0]['address_components'].length; i++) {
                if (googleLocation[0]['address_components'][i]['types'][0] !== undefined && googleLocation[0]['address_components'][i]['types'][0] === 'locality') {
                    city = googleLocation[0]['address_components'][i]['long_name']
                }
            }

            this.setState({
                location: city,
                location_full_address: googleLocation[0]['formatted_address'],
                latitude: googleLocation[0].geometry.location.lat(),
                longitude: googleLocation[0].geometry.location.lng(),
            })
        }
    }
    handleFormSubmit = (e) => {
        e.preventDefault()
        if (this.validator.allValid()) {
            const formData = new FormData()
            formData.append('dealId', this.state.edit_id)
            formData.append('name', this.state.deal_name)
            formData.append('about', this.state.about)
            formData.append('location', this.state.selectedCity?.value)
            formData.append('location_full_address', this.state.location_full_address)
            formData.append('latitude', this.state.latitude)
            formData.append('longitude', this.state.longitude)
            formData.append('exclusive', this.state.exclusive)
            formData.append('contact_number', this.state.contact_number)
            formData.append('notification_msg', this.state.notification_msg)
            formData.append('notification_msg_within_city', this.state.notification_msg_within_city)
            formData.append('email_address', this.state.email_address)
            formData.append('type', this.state.type)
            formData.append('tnc', this.state.tnc)
            formData.append('end_date', this.state.end_date)
            formData.append('start_date', this.state.start_date)
            var cover_image = document.getElementById("cover_image").files[0];
            if (cover_image) {
                formData.append('cover_image', this.state.cover_image)
            }
            formData.append('status', this.state.status)
            formData.append('ordering', this.state.ordering)
            formData.append('recommended', this.state.recommended)
            formData.append('partner', this.state.partner)
            formData.append('deals_category', this.state.selectedCategory)
            formData.append('interest', this.state.selectedInterests)
            formData.append('tags', this.state.defaultTags.map(({ value }) => value).join(','))
            formData.append('terms_conditions', this.state.terms_conditions)
            this.setState({ submit: true })
            axios({
                method: "post", url: `${api_url}/update-deal`, data: formData, headers: {
                    'Content-Type': 'application/json', "Authorization": 'Bearer ' + api_key,
                }
            }).then(response => {
                if (response.status) {
                    this.setState({ submit: false })
                    toastr.success('Deal update successful.')
                    // eslint-disable-next-line react/prop-types
                    const {history} = this.props
                    // eslint-disable-next-line react/prop-types
                    const pageno = this.props.match.params.pId; // Get the pageno param from the URL
                    history.goBack();
                }
            }).catch(err => {
                toastr.error(err.message)
                this.setState({ submit: false })
                alert(err.message)
            })
        } else {
            this.validator.showMessages()
            this.forceUpdate()
        }
    }

    handleEditorChange = e => {
        this.setState({ about: e.target.getContent() })
    }

    handleEditorChange1 = e => {
        this.setState({ tnc: e.target.getContent() })
    }

    handleCreate = (inputValue) => {
        this.setState({customTag:inputValue})
        this.setState({isLoading: true,tagModal:true})
    };
    // Custom Tags ==================================
    handleCustomTag = (e) => {
        e.preventDefault();
        var data = JSON.stringify({
        "name": this.state.customTag,
        "interest": this.state.selected_interests.value,
        "ordering": 1,
        "status": true,
        "user_id": 0,
        "is_user_defined": false,
    });
      axios({
          method: "post", url: `${api_url}/add-tag`, data: data, headers: {
              'Content-Type': 'application/json',
              "Authorization": 'Bearer ' + api_key,
          }
      })
      .then(response=> {
          toastr.success('Tag added successful.')
          if (response.data.status && response.data?.data?.upserted[0]?._id !== undefined) {
            this.setState({tags: [...this.state.tags, {label:this.state.customTag, value:response.data?.data?.upserted[0]?._id}]});
            this.setState({defaultTags: [...this.state.defaultTags, {label:this.state.customTag, value:response.data?.data?.upserted[0]?._id}]});
            this.setState({selected_interests: "",customTag:"",isLoading:false,tagModal:false})
          }
      })
      .catch(response => {
          toastr.error(response)
      });
  }
// Custom Tags end ================================

    render() {
        const { categories, partners, interests, cities, tags, submit, selectedPartners, defaultCategory, defaultInterests, defaultTags, isLoading } = this.state
        return (<React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>
                        Manage Deals | {process.env.REACT_APP_PROJECTNAME}
                    </title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title="Deals" path="/deals/pageno/1" breadcrumbItem="Edit Deal" />
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    {this.state.loaded === false && <div className="text-center px-5"><Spinner className="ms-2" color="primary" /></div>}
                                    {this.state.loaded === true &&
                                        <form onSubmit={this.handleFormSubmit}>
                                            <Row>
                                                <Col md="12" className="mb-3">
                                                    <Label htmlFor="title">Deal</Label>
                                                    <input name="deal_name" placeholder="Type Deal Title" type="text"
                                                        className={"form-control"} defaultValue={this.state.deal_name}
                                                        onChange={this.handleInput} />
                                                    {this.validator.message('deal_name', this.state.deal_name, 'required|string')}
                                                </Col>
                                                <Col md="3" className="mb-3">
                                                    <Label htmlFor="cover_image">
                                                        Cover Image
                                                    </Label>
                                                    <input
                                                        name="cover_image"
                                                        placeholder="Cover Image"
                                                        type="file"
                                                        id="cover_image"
                                                        accept="image/*"
                                                        onChange={this.handleFileChange}
                                                        className={"form-control"}
                                                    />
                                                    {/*{this.validator.message('cover_image', this.state.cover_image, 'required')}*/}
                                                </Col>
                                                <Col md="3" className="mb-3">
                                                    <label className="control-label">
                                                        City
                                                    </label>
                                                    <Select
                                                        isMulti={false}
                                                        defaultValue={this.state.selectedCity}
                                                        name="city"
                                                        onChange={e => { this.setState({ selectedCity: e }) }}
                                                        options={cities}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                    {this.validator.message('selectedCity', this.state.selectedCity, 'required')}
                                                </Col>
                                                {this.state.location_full_address !== '' &&
                                                    <Col md="5" className="mb-3">
                                                        <Label htmlFor="location">
                                                            Geo Location Address
                                                        </Label>
                                                        <LocationSearchInput setFormLocation={this.setFormLocation}
                                                            address={this.state.location_full_address} />
                                                        {this.validator.message('location', this.state.location, 'required')}
                                                    </Col>
                                                }
                                                {this.state.location_full_address === '' &&
                                                    <Col md="5" className="mb-3">
                                                        <Label htmlFor="location">
                                                            Geo Location Address
                                                        </Label>
                                                        <LocationSearchInput setFormLocation={this.setFormLocation} address={this.state.location_full_address} />
                                                        {this.validator.message('location', this.state.location, 'required')}
                                                    </Col>
                                                }
                                                <Col md="auto" className="mb-3">
                                                    <Label htmlFor="exclusive">Exclusive</Label>
                                                    <div className="square-switch mt-2">
                                                        <input type="checkbox" id="square-exclusive" switch="none"
                                                            name="exclusive" checked={this.state.exclusive}
                                                            onChange={() => this.setState({ exclusive: !this.state.exclusive })}
                                                        />
                                                        <label
                                                            htmlFor="square-exclusive"
                                                            data-on-label="On"
                                                            data-off-label="Off"
                                                        />
                                                    </div>
                                                    {this.validator.message('exclusive', this.state.exclusive, 'boolean')}
                                                </Col>
                                                <Col md="auto" className="mb-3">
                                                    <Label htmlFor="contact_number">
                                                        Contact Number
                                                    </Label>
                                                    <input
                                                        name="contact_number"
                                                        placeholder="Type contact number"
                                                        type="text"
                                                        className={"form-control"}
                                                        onChange={this.handleInput}
                                                        defaultValue={this.state.contact_number}
                                                    />
                                                    {this.validator.message('contact_number', this.state.contact_number, 'required|phone')}
                                                </Col>
                                                <Col md="3" className="mb-3">
                                                    <Label htmlFor="email_address">
                                                        Email Address
                                                    </Label>
                                                    <input
                                                        name="email_address"
                                                        placeholder="Type email address"
                                                        type="email"
                                                        className={"form-control"}
                                                        onChange={this.handleInput}
                                                        defaultValue={this.state.email_address}
                                                    />
                                                    {this.validator.message('email_address', this.state.email_address, 'required|email')}
                                                </Col>
                                                <Col md="3" className="mb-3">
                                                    <Label htmlFor="type">
                                                        Type
                                                    </Label>
                                                    <select name="type" value={this.state.type} className={"form-select"} onChange={this.handleInput}>
                                                        <option>Select</option>
                                                        <option value="stay">Stay</option>
                                                        <option value="non-stay">Non Stay</option>
                                                    </select>
                                                    {this.validator.message('type', this.state.type, 'required')}
                                                </Col>
                                                <Col md="auto" className="form-group mb-4">
                                                    <label>Start Date</label>
                                                    <div className="input-group">
                                                        <Flatpickr
                                                            className="form-control d-block"
                                                            placeholder="Start Date time"
                                                            name="start_date"
                                                            onChange={date => {
                                                                this.setState({ start_date: formatDate(date[0]) })
                                                            }}
                                                            value={this.state.start_date}
                                                            options={{
                                                                enableTime: true,
                                                                altInput: true,
                                                                altFormat: "F j, Y H:m:i K",
                                                                dateFormat: "Y-m-d h:m:i"
                                                            }}
                                                        />
                                                    </div>
                                                    {this.validator.message('start_date', this.state.start_date, 'required')}
                                                </Col>
                                                <Col md="auto" className="form-group mb-4">
                                                    <label>End Date</label>
                                                    <div className="input-group">
                                                        <Flatpickr
                                                            className="form-control d-block"
                                                            placeholder="End Date time"
                                                            name="end_date"
                                                            onChange={date => {
                                                                this.setState({ end_date: formatDate(date[0]) })
                                                            }}
                                                            value={this.state.end_date}
                                                            options={{
                                                                enableTime: true,
                                                                altInput: true,
                                                                altFormat: "F j, Y H:m:i K",
                                                                dateFormat: "Z"
                                                            }}
                                                        />
                                                    </div>
                                                    {this.validator.message('end_date', this.state.end_date, 'required')}
                                                </Col>
                                                {/* <Col md="auto" className="mb-3">
                                                <Label htmlFor="">Recommended</Label>
                                                <div className="square-switch mt-2">
                                                    <input type="checkbox" id="square-recommended" switch="none"
                                                           name="recommended" checked={this.state.recommended}
                                                           onChange={() => this.setState({recommended: !this.state.recommended})}
                                                    />
                                                    <label
                                                        htmlFor="square-recommended"
                                                        data-on-label="On"
                                                        data-off-label="Off"
                                                    />
                                                </div>
                                                {this.validator.message('recommended', this.state.recommended, 'boolean')}
                                            </Col> */}
                                                <Col md="4" className="mb-3">
                                                    <label className="control-label">
                                                        Partners
                                                    </label>
                                                    <select name="partner" value={selectedPartners} className={"form-select"} onChange={e => { this.handleInput(e); this.handlePartner(e) }}>
                                                        <option>Select</option>
                                                        {this.selectData(partners).map((opt, key) => {
                                                            return <option key={key} value={opt.value} >{opt.label}</option>;
                                                        })}
                                                    </select>
                                                    {this.validator.message('partner', this.state.partner, 'required')}
                                                </Col>
                                                <Col md="4" className="mb-3">
                                                    <label className="control-label">
                                                        Deal Category
                                                    </label>
                                                    <Select
                                                        isMulti={true}
                                                        name="category"
                                                        value={defaultCategory}
                                                        onChange={this.handleCategory}
                                                        options={this.selectData(categories)}
                                                        classNamePrefix="select2-selection"
                                                        menuPortalTarget={document.body}
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 99999 }) }}
                                                    />
                                                    {this.validator.message('category', this.state.categories, 'required')}
                                                </Col>
                                                <Col md="4" className="mb-3">
                                                    <label className="control-label">
                                                        Interests
                                                    </label>
                                                    <Select
                                                        isMulti={true}
                                                        name="interests"
                                                        value={defaultInterests}
                                                        onChange={e => { this.handleInterests(e); this.handleInterestTags(e) }}
                                                        options={this.selectData(interests)}
                                                        classNamePrefix="select2-selection"
                                                        menuPortalTarget={document.body}
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 99999 }) }}
                                                    />
                                                    {this.validator.message('interests', this.state.interests, 'required')}
                                                </Col>
                                                <Col md="4" className="mb-3" style={{ zIndex: 99 }}>
                                                    <label className="control-label">Tags</label>
                                                    <CreatableSelect
                                                        isMulti={true}
                                                        value={defaultTags}
                                                        onChange={this.handleTags}
                                                        options={tags}
                                                        classNamePrefix="select2-selection"
                                                        isClearable
                                                        isDisabled={isLoading}
                                                        isLoading={isLoading}
                                                        onCreateOption={this.handleCreate}
                                                        menuPortalTarget={document.body}
                                                        styles={{ menuPortal: base => ({ ...base, zIndex: 99999 }) }}
                                                    />
                                                    {this.validator.message('tags', this.state.tags, 'required')}
                                                </Col>
                                                <Col md="auto" className="mb-3">
                                                    <Label htmlFor="validationCustom03">Order No.</Label>
                                                    <input
                                                        name="ordering"
                                                        type="number"
                                                        defaultValue={this.state.ordering}
                                                        placeholder="Order No."
                                                        className={"form-control"}
                                                        onChange={this.handleInput}
                                                    />
                                                    {this.validator.message('ordering', this.state.ordering, 'required|numeric')}
                                                </Col>
                                                <Col md="auto" className="mb-3">
                                                    <Label htmlFor="">Status</Label>
                                                    <div className="square-switch mt-2">
                                                        <input
                                                            type="checkbox"
                                                            id="square-switch-status"
                                                            switch="none"
                                                            name="status"
                                                            checked={this.state.status}
                                                            onChange={() => this.setState({ status: !this.state.status })}
                                                        />
                                                        <label
                                                            htmlFor="square-switch-status"
                                                            data-on-label="On"
                                                            data-off-label="Off"
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md="3" className="mb-3">
                                                    <Label htmlFor="notification_type">
                                                        On Addition to new deals
                                                    </Label>
                                                    <select name="notification_msg" defaultValue={this.state.notification_msg} className={"form-select"} onChange={this.handleInput}>
                                                        <option>Select</option>
                                                        <option value="Deal ya no deal? We say, CT DEALs. Check out now!">Deal ya no deal? We say, CT DEALs. Check out now!</option>
                                                        <option value="Deal le lo! Deal!">Deal le lo! Deal!</option>
                                                        <option value="Ek taaza, taaza deal add hui hai. Check it out!">Ek taaza, taaza deal add hui hai. Check it out!</option>
                                                        <option value="Fresh off the rack! Here’s a new CT deal, just for YOU!">Fresh off the rack! Here’s a new CT deal, just for YOU! </option>
                                                        <option value="We’ve got something special for you. Tap to know more!">We’ve got something special for you. Tap to know more! </option>
                                                        <option value="Deal leke dekho ji! We have a new CT exclusive deal, just for you!">Deal leke dekho ji! We have a new CT exclusive deal, just for you!</option>
                                                        <option value="It is raining DEALS! ">It is raining DEALS! </option>
                                                        <option value="Mere usool, iDEALs yeh kehte hai, check out this new CT Deal!">Mere usool, iDEALs yeh kehte hai, check out this new CT Deal!</option>
                                                        <option value="Can’t deal with it? CT Deal with it! Check out this new exclusive offer!">Can’t deal with it? CT Deal with it! Check out this new exclusive offer!</option>
                                                        <option value="We have an offer you can’t refuse! Tap to know more">We have an offer you can’t refuse! Tap to know more</option>
                                                    </select>

                                                </Col>
                                                <Col md="4" className="mb-3">
                                                    <Label htmlFor="title">On Addition of deals which are near to my location</Label>
                                                    <select name="notification_msg_within_city" defaultValue={this.state.notification_msg_within_city} className={"form-select"} onChange={this.handleInput}>
                                                        <option>Select</option>
                                                        <option value="Hop, skip, deal! You have a new deal!">Hop, skip, deal! You have a new deal!</option>
                                                        <option value="What’s new in (name of area)? Tap to check out this exclusive deal">What’s new in (name of area)? Tap to check out this exclusive deal</option>
                                                        <option value="Things To Do In ___? Here’s an exclusive CT Deal to help you plan">Things To Do In _? Here’s an exclusive CT Deal to help you plan</option>
                                                        <option value="Looking for a date night spot with bae? Check out this CT Deal">Looking for a date night spot with bae? Check out this CT Deal</option>
                                                        <option value="Deal for two! These are the HOT deals in your area">Deal for two! These are the HOT deals in your area</option>
                                                        <option value="Why go far, when you can go to CT App to check out exclusive deals in your area.">Why go far, when you can go to CT App to check out exclusive deals in your area.</option>
                                                        <option value="We have an exclusive deal just for you.">We have an exclusive deal just for you.</option>
                                                        <option value="Aao kabhi ___ pe! Check out this exclusive deal near you,">Aao kabhi _ pe! Check out this exclusive deal near you,</option>
                                                        <option value="We believe in staying your dil (deal) ke pass. Here’s an exclusive CT Deal for you!">We believe in staying your dil (deal) ke pass. Here’s an exclusive CT Deal for you!</option>
                                                        <option value="Aaj ka plan kya hai? Make now with this CT Deal!">Aaj ka plan kya hai? Make now with this CT Deal!</option>
                                                        <option value="Dil kya kare jab kisise, CT Deal se pyaar hojaye! Tap to know more!">Dil kya kare jab kisise, CT Deal se pyaar hojaye! Tap to know more!</option>
                                                        <option value="Aaj khaane mein kya hai? This CT Deal will help you decide!">Aaj khaane mein kya hai? This CT Deal will help you decide!</option>

                                                    </select>

                                                </Col>
                                                <Col md="12" className="mb-3">
                                                    <Label htmlFor="title">About</Label>
                                                    <Editor
                                                        apiKey={process.env.REACT_APP_TINY_EDITOR}
                                                        initialValue={this.state.old_about}
                                                        init={{
                                                            height: 600,
                                                            menubar: true,
                                                            config: {},
                                                            plugins: [
                                                                'advlist autolink lists link image preview anchor',
                                                                'searchreplace code fullscreen',
                                                                'media paste'
                                                            ],
                                                            toolbar:
                                                                `preview  fullscreen | undo redo| link image media mediaembed | formatselect | bold italic | \
                                                            alignleft aligncenter alignright alignjustify | \
                                                            bullist numlist outdent indent | removeformat | code`,
                                                            image_title: true,
                                                            extended_valid_elements: 'script[src|async|defer|type|charset]',
                                                            forced_root_block: 'p',
                                                            keep_styles: true,
                                                            inline_boundaries: true,
                                                            branding: false,
                                                            visual: true
                                                        }}
                                                        onChange={this.handleEditorChange}
                                                    />
                                                    {this.validator.message('about', this.state.about, 'required')}
                                                </Col>
                                                <Col md="12" className="mb-3">
                                                    <Label htmlFor="title">Tnc</Label>
                                                    <Editor
                                                        apiKey={process.env.REACT_APP_TINY_EDITOR}
                                                        initialValue={this.state.old_tnc}
                                                        init={{
                                                            height: 600,
                                                            menubar: true,
                                                            config: {},
                                                            plugins: [
                                                                'advlist autolink lists link image preview anchor',
                                                                'searchreplace code fullscreen',
                                                                'media paste'
                                                            ],
                                                            toolbar:
                                                                `preview  fullscreen | undo redo| link image media mediaembed | formatselect | bold italic | \
                                                            alignleft aligncenter alignright alignjustify | \
                                                            bullist numlist outdent indent | removeformat | code`,
                                                            image_title: true,
                                                            extended_valid_elements: 'script[src|async|defer|type|charset]',
                                                            forced_root_block: 'p',
                                                            keep_styles: true,
                                                            inline_boundaries: true,
                                                            branding: false,
                                                            visual: true
                                                        }}
                                                        onChange={this.handleEditorChange1}
                                                    />
                                                    {this.validator.message('tnc', this.state.tnc, 'required')}
                                                </Col>
                                            </Row>

                                            <div className="d-flex flex-wrap gap-2 justify-content-end">
                                                <Button color="primary" type="submit" disabled={submit}>
                                                    {submit === true ? 'Please wait...' : 'Submit Data'}
                                                </Button>
                                            </div>
                                        </form>}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Modal
                isOpen={this.state.tagModal}
                centered
                >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">Create Tag</h5>
                    <button
                    type="button"
                    onClick={() => {
                        this.setState({ tagModal: false, isLoading: false })
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    >
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <form onSubmit={this.handleCustomTag}>
                    <div className="row">
                        <div className="col-md-6 mb-3">
                        <label>Interests</label>
                        <Select isMulti={false} onChange={e => { this.setState({ selected_interests: e }) }} options={this.selectData(interests)} classNamePrefix="select2-selection" required />
                        {this.state.selected_interests == '' && <div className="srv-validation-message">The category field is required.</div>}
                        </div>
                        <div className="col-md-6 mb-3">
                        <label>Tag Name</label>
                        <input className="form-control" defaultValue={this.state.customTag} placeholder="Enter Tag Name" onInput={(e) => this.setState({ customTag: e.target.value })} required />
                        </div>
                    </div>
                    <button type="submit" className="btn w-100 btn-primary">Submit</button>
                    </form>
                </div>
            </Modal>
        </React.Fragment>)
    }
}

DealsEdit.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string
        })
    }),
    location: PropTypes.object
}

export default DealsEdit
