import React from "react"
import { Redirect } from "react-router-dom"
// User profile
import UserProfile from "../pages/Authentication/UserProfile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
// Masters
import Cities from "../pages/Cities/index";
import CityAdd from "../pages/Cities/add";
import CityEdit from "../pages/Cities/edit";

import Countries from "../pages/Countries/index";
import CountryAdd from "../pages/Countries/add";
import CountryEdit from "../pages/Countries/edit";

import Interests from "../pages/Interests/index";
import InterestsAdd from "../pages/Interests/add";
import InterestsEdit from "../pages/Interests/edit";

import Tags from "../pages/Tags/index";
import TagsAdd from "../pages/Tags/add";
import TagsEdit from "../pages/Tags/edit";

import Categories from "../pages/Categories/index";
import CategoriesAdd from "../pages/Categories/add";
import CategoriesEdit from "../pages/Categories/edit";

import Guides from "../pages/Guides/index";
import GuidesAdd from "../pages/Guides/add";
import GuidesEdit from "../pages/Guides/edit";
import GuidesMedia from "../pages/Guides/media";

import Blogs from "../pages/Blogs/index"
import BlogAdd from "../pages/Blogs/add"
import BlogEdit from "../pages/Blogs/edit"
import BlogsMedia from "../pages/Blogs/media";

import Originals from "../pages/Originals/index"
import OriginalsAdd from "../pages/Originals/add"
import OriginalsEdit from "../pages/Originals/edit"

import OriginalSeasons from "../pages/OriginalSeasons/index"
import OriginalSeasonsAdd from "../pages/OriginalSeasons/add"
import OriginalSeasonsEdit from "../pages/OriginalSeasons/edit"

import OriginalSeasonEpisodes from "../pages/OriginalSeasonEpisodes/index"
import OriginalSeasonEpisodeAdd from "../pages/OriginalSeasonEpisodes/add"
import OriginalSeasonsEpisodeEdit from "../pages/OriginalSeasonEpisodes/edit"

import DealCategories from "../pages/Deals/Categories/index"
import DealCategoriesAdd from "../pages/Deals/Categories/add"
import DealCategoryEdit from "../pages/Deals/Categories/edit"

import DealPartners from "../pages/Deals/Partners/index"
import DealPartnersAdd from "../pages/Deals/Partners/add"
import DealPartnersEdit from "../pages/Deals/Partners/edit"

import Deals from "../pages/Deals/index"
import DealsAdd from "../pages/Deals/add"
import DealsEdit from "../pages/Deals/edit"

import Contributor from "../pages/Contributor/index"
// User manage ------------
import Users from "../pages/Users/index"
import UsersEdit from "../pages/Users/edit"
import UsersRole from "../pages/Users/user_roles"
// CT Reels ---------------
import Reels from "../pages/Reels/index"
import ReelEdit from "../pages/Reels/edit"
import ReelAdd from "../pages/Reels/add"
import UploadVideo from "../pages/Reels/upload-video"
import UploadVideoDraf from "../pages/Reels/upload-video-draft"

// CT Social list -----------
import SocialList from "../pages/Social/social_list"
import SocialCommentList from "../pages/Social/social_comment_list"
// CT CMS Page -----------
import CMSPage from "../pages/CMS_Page/index"
import CMSPageAdd from "../pages/CMS_Page/add"
import CMSPageEdit from "../pages/CMS_Page/edit"
//  Coupons page -----------
import Coupons from "../pages/Coupons/index"
import CouponAdd from "../pages/Coupons/add"
import CouponEdit from "../pages/Coupons/edit"
import DealMedia from "../pages/Deals/media"
import {Variants} from "../pages/Deals/Variant"
import VariantMedia from "../pages/Deals/Variant/table/add-media"
import VariantStayAdd from "../pages/Deals/Variant/form/stay_add"
import VariantNonStayAdd from "../pages/Deals/Variant/form/non_stay_add"
import VariantNonStayEdit from "../pages/Deals/Variant/form/non_stay_edit"
import VariantStayEdit from "../pages/Deals/Variant/form/stay_edit"
// Orders -----------------
import Orders from "../pages/Orders"
// Locations --------------
import Locations from "../pages/Location"
import LocationAdd from "../pages/Location/add"
import LocationEdit from "../pages/Location/edit"
import UsersAdd from "../pages/Users/add"
import Faqs from "../pages/Faqs"
import FaqsAdd from "../pages/Faqs/add"
import FaqsEdit from "../pages/Faqs/edit"
import OrderCancelReason from "../pages/Order_cancel_reason"
import ContactUs from "../pages/ContactUs"
import Original_trending from "../pages/Trending_episodes"
import Original_trending_add from "../pages/Trending_episodes/add"
import Original_trending_edit from "../pages/Trending_episodes/edit"
import Popular_original_episode from "../pages/Popular_original_episode"
import Popular_original_episode_add from "../pages/Popular_original_episode/add"
import Popular_original_episode_edit from "../pages/Popular_original_episode/edit"
import OrderDetail from "../pages/Orders/order_detail"
import DealCities from "../pages/Deals/Cities"
import DealCitiesEdit from "../pages/Deals/Cities/edit"
import BlogCategoryAdd from "../pages/Blogs/Categories/add"
import BlogsCategories from "../pages/Blogs/Categories"
import BlogCategoryEdit from "../pages/Blogs/Categories/edit"
import DealCitiesAdd from "../pages/Deals/Cities/add";
import OTP from "../pages/Authentication/otp";
import ChangePassword from "../pages/Authentication/ChangePassword"

// Anas
import Country from "../pages/MainLocation/Country/Country"
import MainCountryAdd from "../pages/MainLocation/Country/MainCountryAdd"
import MainCountryUpdate from "../pages/MainLocation/Country/UpdateCountry"

import States from "../pages/MainLocation/States/States"
import StatesAdd from "../pages/MainLocation/States/add"
import StateUpdate from "../pages/MainLocation/States/edit"

import MainCity from "../pages/MainLocation/Cities/Cities"
import CitiesAdd from "../pages/MainLocation/Cities/add"
import CitiesUpdate from "../pages/MainLocation/Cities/edit"
import GuideReportSpams from "../pages/Guides/ReportSpams";
import BlogReportSpams from "../pages/Blogs/ReportSpams";
import OriginalsReportSpams from "../pages/Originals/ReportSpams";
import ReelsReportSpams from "../pages/Reels/ReportSpams";

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  //  Countries ----------------------
  { path: "/countries/pageno/:pId", component: Countries },
  { path: "/countries/pageno/:pId/:searchQuery", component: Countries },
  { path: "/countries/edit/:id", component: CountryEdit },
  { path: "/countries/:pId/edit/:id", component: CountryEdit },
  { path: "/countries/add", component: CountryAdd },
  //  Cities ---------------------------
  { path: "/cities/pageno/:pId", component: Cities },
  { path: "/cities/pageno/:pId/:searchQuery", component: Cities },
  { path: "/cities/edit/:id", component: CityEdit },
  { path: "/cities/:pId/edit/:id", component: CityEdit },
  { path: "/cities/add", component: CityAdd },
  //  Interests ---------------------------
  { path: "/interests/pageno/:pId", component: Interests },
  { path: "/interests/pageno/:pId/:searchQuery", component: Interests },
  { path: "/interests/edit/:id", component: InterestsEdit },
  { path: "/interests/add", component: InterestsAdd },
  //  Tags ---------------------------
  { path: "/tags/pageno/:pId", component: Tags },
  { path: "/tags/pageno/:pId/:searchQuery", component: Tags },
  { path: "/tags/edit/:id", component: TagsEdit },
  { path: "/tags/:pId/edit/:id", component: TagsEdit },
  { path: "/tags/add", component: TagsAdd },
  //  Categories ---------------------------
  { path: "/categories/pageno/:pId", component: Categories },
  { path: "/categories/pageno/:pId/:searchQuery", component: Categories },
  { path: "/categories/add", component: CategoriesAdd },
  { path: "/categories/edit/:id", component: CategoriesEdit },
  //  Guides ---------------------------
  { path: "/guides/pageno/:pId", component: Guides },
  { path: "/guides/pageno/:pId/:searchQuery", component: Guides },
  { path: "/guides/add", component: GuidesAdd },
  { path: "/guides/edit/:id", component: GuidesEdit },
  { path: "/guides/:pId/edit/:id", component: GuidesEdit },
  { path: "/guides/media/:id", component: GuidesMedia },
  
  // { path: "/guides/media/:id", component: UploadFiles },
  //  Blogs ---------------------------
  { path: "/blogs/pageno/:pId", component: Blogs },
  { path: "/blogs/pageno/:pId/:searchQuery", component: Blogs },
  { path: "/blogs/add", component: BlogAdd },
  { path: "/blogs/edit/:id", component: BlogEdit },
  { path: "/blogs/:pId/edit/:id", component: BlogEdit },
  { path: "/blogs/media/:id", component: BlogsMedia },
  { path: "/blogs/categories", component: BlogsCategories },
  { path: "/blogs/categories/add", component: BlogCategoryAdd },
  { path: "/blogs/categories/edit/:id", component: BlogCategoryEdit },
  //  Originals -----------------------
  { path: "/originals/pageno/:pId", component: Originals },
  { path: "/originals/pageno/:pId/:searchQuery", component: Originals },
  { path: "/originals/add", component: OriginalsAdd },
  { path: "/originals/edit/:id", component: OriginalsEdit },
  { path: "/originals/:pId/edit/:id", component: OriginalsEdit },
  //  Originals seasons ---------------
  { path: "/seasons/pageno/:pId", component: OriginalSeasons },
  { path: "/seasons/pageno/:pId/:searchQuery", component: OriginalSeasons },
  { path: "/seasons/add", component: OriginalSeasonsAdd },
  { path: "/seasons/edit/:id", component: OriginalSeasonsEdit },
  { path: "/seasons/:pId/edit/:id", component: OriginalSeasonsEdit },
  //  Originals episode ---------------
  { path: "/episodes/pageno/:pId", component: OriginalSeasonEpisodes },
  { path: "/episodes/pageno/:pId/:searchQuery", component: OriginalSeasonEpisodes },
  { path: "/episodes/add", component: OriginalSeasonEpisodeAdd },
  { path: "/episodes/edit/:id", component: OriginalSeasonsEpisodeEdit },
  { path: "/episodes/:pId/edit/:id", component: OriginalSeasonsEpisodeEdit },
  //  Deals ---------------------------
  { path: "/deals/categories/pageno/:pId", component: DealCategories },
  { path: "/deals/categories/pageno/:pId/:searchQuery", component: DealCategories },
  { path: "/deals/categories/add", component: DealCategoriesAdd },
  { path: "/deals/categories/edit/:id", component: DealCategoryEdit },
  { path: "/deals/categories/:pId/edit/:id", component: DealCategoryEdit },

  { path: "/deals/partners/pageno/:pId", component: DealPartners },
  { path: "/deals/partners/pageno/:pId/:searchQuery", component: DealPartners },
  { path: "/deals/partners/add", component: DealPartnersAdd },
  { path: "/deals/partners/edit/:id", component: DealPartnersEdit },
  { path: "/deals/partners/:pId/edit/:id", component: DealPartnersEdit },

  { path: "/deals/pageno/:pId", component: Deals },
  { path: "/deals/pageno/:pId/:searchQuery", component: Deals },
  { path: "/deals/add", component: DealsAdd },
  { path: "/deals/edit/:id", component: DealsEdit },
  { path: "/deals/:pId/edit/:id", component: DealsEdit },
  { path: "/deals/media/:id", component: DealMedia },

  { path: "/deals/variants/:id", component: Variants },
  { path: "/deals/variants/media/:deal/:id/:title", component: VariantMedia },
  { path: "/deals/variants/add/stay/:id", component: VariantStayAdd },
  { path: "/deals/variants/add/non-stay/:id", component: VariantNonStayAdd },
  { path: "/deals/variants/stay/edit/:id", component: VariantStayEdit },
  { path: "/deals/variants/non-stay/edit/:id", component: VariantNonStayEdit },
  { path: "/guides/spam_reports/:id", component: GuideReportSpams },
  { path: "/guides/spam_reports", component: GuideReportSpams },
  { path: "/blogs/spam_reports/:id", component: BlogReportSpams },
  { path: "/blogs/spam_reports", component: BlogReportSpams },
  { path: "/originals/spam_reports/:id", component: OriginalsReportSpams },
  { path: "/originals/spam_reports", component: OriginalsReportSpams },
  { path: "/reels/spam_reports/:id", component: ReelsReportSpams },
  { path: "/reels/spam_reports", component: ReelsReportSpams },
  //  Deal Cities --------------------
  { path: "/deals/cities/pageno/:pId", component: DealCities },
  { path: "/deals/cities/pageno/:pId/:searchQuery", component: DealCities },
  { path: "/deals/cities/edit/:id", component: DealCitiesEdit },
  { path: "/deals/cities/:pId/edit/:id", component: DealCitiesEdit },
  { path: "/deals/cities/add", component: DealCitiesAdd },
  // Contributor --------------------
  { path: "/contributor/pageno/:pId", component: Contributor },
  { path: "/contributor/", component: Contributor },
  //  Users -------------------------
  { path: "/users/pageno/:pId", component: Users },
  { path: "/users/pageno/:pId/:searchQuery", component: Users },
  { path: "/user-roles", component: UsersRole },
  { path: "/users/edit/:id", component: UsersEdit },
  { path: "/users/:pId/edit/:id", component: UsersEdit },
  { path: "/users/add", component: UsersAdd },
  //  CT Reels ----------------------
  { path: "/reels/pageno/:pId", component: Reels },
  { path: "/reels/pageno/:pId/:searchQuery", component: Reels },
  { path: "/reels/edit/:id", component: ReelEdit },
  { path: "/reels/:pId/edit/:id", component: ReelEdit },
  { path: "/reels/add", component: ReelAdd },
  { path: "/reels/upload-video/:id", component: UploadVideo },
  { path: "/reels/upload-video-draft", component: UploadVideoDraf },
  //  Social list -------------------
  { path: "/social-list/pageno/:pId", component: SocialList },
  { path: "/social-list/pageno/:pId/:searchQuery", component: SocialList },
  { path: "/social-list/social-comment-list/:id", component: SocialCommentList },
   //  Social list -------------------
   { path: "/cms-page", component: CMSPage },
   { path: "/cms-page/add", component: CMSPageAdd },
  { path: "/cms-page/edit/:id", component: CMSPageEdit },
  //  Coupons-------------------------
  { path: "/coupons/pageno/:pId", component: Coupons },
  { path: "/coupons/pageno/:pId/:searchQuery", component: Coupons },
  { path: "/coupons/add", component: CouponAdd },
  { path: "/coupons/edit/:id", component: CouponEdit },
  //  Orders -------------------------
  { path: "/orders/pageno/:pId", component: Orders },
  { path: "/orders/pageno/:pId/:searchQuery", component: Orders },
  { path: "/orders/:id", component: OrderDetail },
  //  location -----------------------
  { path: "/location/pageno/:pId", component: Locations },
  { path: "/location/pageno/:pId/:searchQuery", component: Locations },
  { path: "/location/add", component: LocationAdd },
  { path: "/location/edit/:id", component: LocationEdit },
  { path: "/location/:pId/edit/:id", component: LocationEdit },

  // Main Locations
  { path: "/country/pageno/:pId", component: Country },
  { path: "/country/pageno/:pId/:searchQuery", component: Country },
  { path: "/main_country/add", component: MainCountryAdd },
  { path: "/main_country/update/:id", component: MainCountryUpdate },
  { path: "/main_country/:pId/update/:id", component: MainCountryUpdate },

  { path: "/states/pageno/:pId", component: States },
  { path: "/states/pageno/:pId/:searchQuery", component: States },
  { path: "/states/add", component: StatesAdd },
  { path: "/states/update/:id", component: StateUpdate },
  { path: "/states/:pId/update/:id", component: StateUpdate },

  { path: "/main_cities/pageno/:pId", component: MainCity },
  { path: "/main_cities/pageno/:pId/:searchQuery", component: MainCity },
  { path: "/main_cities/add", component: CitiesAdd },
  { path: "/main_cities/update/:id", component: CitiesUpdate },
  { path: "/main_cities/:pId/update/:id", component: CitiesUpdate },
  //profile
  { path: "/profile", component: UserProfile },
  //  Original-trending
  { path: "/trending-episodes/pageno/:pId", component: Original_trending },
  { path: "/trending-episodes/pageno/:pId/:searchQuery", component: Original_trending },
  { path: "/trending-episodes/add", component: Original_trending_add },
  { path: "/trending-episodes/edit/:id", component: Original_trending_edit },
  { path: "/trending-episodes/:pId/edit/:id", component: Original_trending_edit },
  //  Popular Original Episode
  { path: "/popular-original-episode/pageno/:pId", component: Popular_original_episode},
  { path: "/popular-original-episode/pageno/:pId/:searchQuery", component: Popular_original_episode},
  { path: "/popular-original-episode/add", component: Popular_original_episode_add },
  { path: "/popular-original-episode/edit/:id", component: Popular_original_episode_edit },
  { path: "/popular-original-episode/:pId/edit/:id", component: Popular_original_episode_edit },
  // Faqs ----------------------------
  { path: "/faqs", component: Faqs },
  { path: "/faqs/pageno/:pId", component: Faqs },
  { path: "/faqs/pageno/:pId/:searchQuery", component: Faqs },
  { path: "/faqs", component: Faqs },
  { path: "/faqs/add", component: FaqsAdd },
  { path: "/faqs/edit/:id", component: FaqsEdit },
  { path: "/order-cancel-reason", component: OrderCancelReason },
  { path: "/contact-us", component: ContactUs },
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
   { path: "/otp", component: OTP },
  { path: "/change-password", component: ChangePassword },

]

export { authProtectedRoutes, publicRoutes }
