import React, {useEffect, useState} from "react";
import { useHistory } from 'react-router-dom';
import {Card, CardBody, Col, Row} from "reactstrap"
import MetaTags from 'react-meta-tags'
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { TableHeader, Pagination, Search } from "../Datatable/index";
import {apiKey} from "helpers/url_helper"
import axios from 'axios';
import notification from "../../components/Common/Notification";
import Moment from "moment/moment"
let api_url = process.env.REACT_APP_API_URL;
let api_key = apiKey()

const PageSize = 15;

const useFullPageLoader = () => {
    const [loading, setLoading] = useState(false);

    return [
        loading ? 'Loading' : null,
        () => setLoading(true), //Show loader
        () => setLoading(false) //Hide Loader
    ];
};

const ContactUs = (props) => {    
    const history = useHistory();
    const [TableData, setTableData] = useState([]);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const headers = [
        { name: "#", field: "id", sortable: false },
        { name: "Name", field: "name", sortable: false },
        { name: "Email", field: "email", sortable: false },
        { name: "Mobile", field: "mobile", sortable: false },
        { name: "Subject", field: "city_image", sortable: false },
        { name: "Comment", field: "season_title", sortable: false },
        { name: "Date", field: "created_date", sortable: false }
    ];

    useEffect(() => {
        showLoader()
        let query = '';
        search && ( query = `keyword=${search}&`)
        const url = `${api_url}/get-contact-us?${query}limit=${PageSize}&pageNo=${currentPage}`
        axios.get(url,{
            headers: {
                "Authorization": 'Bearer '+api_key,
            }
        })
            .then(res => {
                hideLoader();
                if(res.status) {
                    setTableData(res.data.data);
                    setTotalItems(res.data.totalRecords);
                }
            }).catch(
                err => {
                    notification(err, 'error', 'toast-bottom-right')
                    if(err.response.status===401 || err.response.status===400 ){
                        history.push('/logout')
                    }
                }
            );
    }, [currentPage,search])

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Contact Us</title>
                </MetaTags>
                <div className="container-fluid">
                    <Breadcrumbs title="Dashboard" path="/" breadcrumbItem="Contact Us"/>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <div className="row">
                                        <div className="col-md-6"></div>
                                        <div className="col-md-6 d-flex flex-row-reverse">
                                            <Search
                                                onSearch={value => {
                                                    setSearch(value);
                                                    setCurrentPage(1);
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <table className="table table-striped">
                                        <TableHeader
                                            headers={headers}
                                        />
                                        <tbody>
                                        {loader && <tr><th colSpan={headers.length}><h4 className="text-center">Loading...</h4></th></tr>}
                                        {(!loader && TableData.length === 0) && <tr><th colSpan={headers.length}><h4 className="text-center">No data found</h4></th></tr>}
                                        {TableData.length > 0 && TableData.map((value,index) => (
                                            <tr key={++index}>
                                                <th scope="row">
                                                    {PageSize * (currentPage - 1) + (index + 1)}
                                                </th>
                                                <td>{value.name}</td>
                                                <td>{value.email}</td>
                                                <td>{value.mobile}</td>
                                                <td>{value.subject}</td>
                                                <td><div style={{maxWidth:"220px",fontSize:"12px"}}>{value.comment}</div></td>
                                                <td>{Moment(value.created_date).format('DD/MM/YYYY')}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                    <Pagination
                                        total={totalItems}
                                        itemsPerPage={PageSize}
                                        currentPage={currentPage}
                                        onPageChange={page => setCurrentPage(page)}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ContactUs
