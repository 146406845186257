import React, {useEffect, useState} from "react";
import { useParams, useHistory, useLocation } from 'react-router-dom';
import Pagination from 'components/Common/Pagination';
import {Card, CardBody, Col, Row} from "reactstrap"
import MetaTags from 'react-meta-tags'
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {TableHeader, Search} from "../Datatable/index";
import notification from "../../components/Common/Notification"

import axios from 'axios';
import {Link} from "react-router-dom";
import {apiKey} from "helpers/url_helper"
let api_url = process.env.REACT_APP_API_URL;
let api_key = apiKey()

const useFullPageLoader = () => {
    const [loading, setLoading] = useState(false);
    return [
        loading ? 'Loading' : null,
        () => setLoading(true), //Show loader
        () => setLoading(false) //Hide Loader
    ];
};
const PageSize = 15;

export default function Original_trending() {
    const history = useHistory();
    const location = useLocation();
    const { pId:pageno } = useParams();
    const [tableData, settableData] = useState([]);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const { searchQuery } = useParams();
    const [search, setSearch] = useState(() => {
        if (searchQuery) {
            return searchQuery;
          } else {
            return '';
          }
      });
    const [getSearch, setGetSearch] = useState(search);
    const [getSearchPage, setGetSearchPage] = useState(currentPage)
    
    const TagsHeader = [
        {name: "#", field: "id", sortable: false},
        {name: "Original", field: "original", sortable: false},
        {name: "Season", field: "season", sortable: false},
        {name: "Episode", field: "episode", sortable: false},
        {name: "Trending Image", field: "trending_image", sortable: false},
        {name: "Ordering", field: "ordering", sortable: false},
        {name: "Status", field: "status", sortable: false},
        {name: "Action", field: "", sortable: false}
    ];
    function handleSearch(){
        setSearch(getSearch);
        setCurrentPage(getSearchPage);
        if(getSearch){
            history.push(`/trending-episodes/pageno/${getSearchPage}/${getSearch}`)
        }
    }
    // Handle any changes to the currentPage state
    const handlePageChange = () => {
        setCurrentPage(() => {
            const paramPage = parseInt(pageno);
            if (!isNaN(paramPage)) {
                return paramPage;
              } else {
                return 1;
              }
          })
    };
    useEffect(() => {
        handlePageChange();
    }, [pageno,location]);

    useEffect(() => {
        showLoader()
        let query = ''
        search && ( query = `keyword=${search}`)
        const url = `${api_url}/get-original-trending?${query}&limit=${PageSize}&pageNo=${currentPage}`
        axios.get(url,{
            headers: {
                "Authorization": 'Bearer '+api_key,
            }
        })
            .then(res => {
                hideLoader();
                if(res.status) {
                    settableData(res.data.data);
                    setTotalItems(res.data.totalRecords);
                }
            }).catch(
                err => {
                    notification(err, 'error', 'toast-bottom-right')
                    if(err.response.status===401 || err.response.status===400 ){
                        history.push('/logout')
                    }
                }
            );
    }, [currentPage,search])  // pass `value` as a dependency

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Original Trending</title>
                </MetaTags>
                <div className="container-fluid">
                    <Breadcrumbs title="Dashboard" path="/" breadcrumbItem="Original Trending"/>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <div className="row mb-2">
                                        <div className="col-md-6">

                                        </div>
                                        <div className="col-md-6 d-flex flex-row-reverse rr">
                                            <div className="ms-3">
                                                <a href="/trending-episodes/add"
                                                   className="btn btn-primary waves-effect waves-light"><i
                                                    className="bx bx-plus font-size-16 align-middle me-2"></i> Add</a>
                                            </div>
                                            <div onClick={handleSearch}
                                               className="btn btn-primary waves-effect waves-light ms-2"> Search</div>
                                            <Search
                                                onSearch={value => {
                                                    setGetSearch(value)
                                                    setGetSearchPage(1)
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <table className="table table-striped">
                                        <TableHeader
                                            headers={TagsHeader}
                                            // onSorting={(field, order) =>
                                            //     setSorting({field, order})
                                            // }
                                        />
                                        <tbody style={{verticalAlign:"middle"}}>{loader && <tr><th colSpan={TagsHeader.length}><h4 className="text-center">Loading...</h4></th></tr>}
                                        {(!loader && tableData.length === 0) && <tr><th colSpan={TagsHeader.length}><h4 className="text-center">No data found</h4></th></tr>}
                                        {!loader && tableData.map((value,index) => (
                                            <tr key={++index}>
                                                <th scope="row">
                                                    {PageSize * (currentPage - 1) + (index + 1)}
                                                </th>
                                                {typeof value?.originals[0] != 'undefined' ?
                                                    <td>{value.originals[0].name}</td>
                                                    :
                                                    <td>N/A</td>
                                                }
                                                {typeof value?.original_seasons[0] != 'undefined' ?
                                                    <td>{value.original_seasons[0].searson_name}</td>
                                                    :
                                                    <td>N/A</td>
                                                }
                                                {typeof value?.original_season_episodes != 'undefined'?
                                                    <td>{value.original_season_episodes[0]!=undefined?value.original_season_episodes[0].episode_name:'N/A'}</td>
                                                    :
                                                    <td>N/A</td>
                                                }
                                                <td>{value.trending_image !== null && <img style={{height:"80px",maxWidth:"120px"}} className="rounded img-fluid" src={value.trending_image}/>}</td>
                                                <td>{value.ordering}</td>
                                                <td>{value.status === true ? <span
                                                        className="badge badge-pill badge-soft-success font-size-13">Active</span> :
                                                    <span
                                                        className="badge badge-pill badge-soft-error font-size-13">Inactive</span>}</td>
                                                <td>
                                                    <Link to={`/trending-episodes/${currentPage}/edit/${value._id}`}><i className="bx bxs-edit-alt"></i> </Link>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                    <Row className="justify-content-center">
                                        <Col className="col-auto">
                                            <Pagination
                                                className="pagination-bar"
                                                currentPage={currentPage}
                                                totalCount={totalItems}
                                                pageSize={PageSize}
                                                onPageChange={page => history.push(`/trending-episodes/pageno/${page}/${search}`)}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}
