import React, { useEffect, useState } from "react"
import { useHistory } from 'react-router-dom';
import { Row, Container, Card, CardBody, Col, CardTitle, Progress } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from "axios"
import notification from "../../components/Common/Notification"
import TotalUsers from "../Dashboard/TotalUsers";
import Paichart from "../Dashboard/Paichart";
import Dountchart from "../Dashboard/Dountchart";
import { apiKey } from "../../helpers/url_helper"
let api_url = process.env.REACT_APP_API_URL
import { Link } from "react-router-dom"
import Deals from '../../assets/images/dashboard/Deals.png';
import Guide  from '../../assets/images/dashboard/Guide.png'; 
import Reels  from '../../assets/images/dashboard/Reels.png'; 
import Scoop from '../../assets/images/dashboard/Scoop.png'; 
let api_key = apiKey()





const Dashboard =  (props) => {
    const history = useHistory();
    const [userType, setUserType] = useState(null)
    const [load, setLoad] = useState(false)

    useEffect(() => {
        axios.get( `${api_url}/get-dashboard-count`, {
            headers: { "Authorization": 'Bearer ' + api_key }
        })
            .then(res => {
                // console.log(res.data);
                if (res.data?.status) {
                    setUserType(res.data?.result);
                }
                setLoad(true)
            }).catch(
                err => {
                    notification(err, 'error', 'toast-bottom-right')
                    if(err.response.status===401 || err.response.status===400 ){
                        history.push('/logout')
                    }
                }
            );
    }, [])


    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Dashboard | Curly Tales</title>
                </MetaTags>
                <Container fluid>
                    {load === true && <>
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-0 font-size-18">Dashboard</h4>
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="/dashboard">Dashboards</a></li>
                                            <li className="active breadcrumb-item" aria-current="page"><a href="/dashboard">Dashboard</a></li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Row>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mini-stats-wid card">
                                                <div className="card-body">
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1">
                                                            <p className="text-muted fw-medium">Reels</p>
                                                            <h4 className="mb-0">{userType?.reel_count}</h4>
                                                        </div>
                                                        <img  src={Reels} />
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mini-stats-wid card">
                                                <div className="card-body">
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1">
                                                            <p className="text-muted fw-medium">Deals</p>
                                                            <h4 className="mb-0">{userType?.deals_count}</h4>
                                                        </div>
                                                        <img  src={Deals} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mini-stats-wid card">
                                                <div className="card-body">
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1">
                                                            <p className="text-muted fw-medium">Guide</p>
                                                            <h4 className="mb-0">{userType?.guide_count}</h4>
                                                        </div>
                                                        <img  src={Guide} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mini-stats-wid card">
                                                <div className="card-body">
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1">
                                                            <p className="text-muted fw-medium">Scoop</p>
                                                            <h4 className="mb-0">{userType?.blog_count}</h4>
                                                        </div>
                                                        <img  src={Scoop} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <hr /> */}
                                    <div className="row">

                                    <div className="col-md-4">
                                            <div className="mini-stats-wid card">
                                                <div className="card-body">
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1">
                                                            <p className="text-muted fw-medium">Originals</p>
                                                            <h4 className="mb-0">{userType?.original_count}</h4>
                                                        </div>
                                                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                            <span className="avatar-title rounded-circle bg-primary">
                                                                <i className="fas fa-tags font-size-24"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mini-stats-wid card">
                                                <div className="card-body">
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1">
                                                            <p className="text-muted fw-medium">Seasons</p>
                                                            <h4 className="mb-0">{userType?.count_season}</h4>
                                                        </div>
                                                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                            <span className="avatar-title rounded-circle bg-primary">
                                                                <i className="bx bx bx-collection font-size-24"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mini-stats-wid card">
                                                <div className="card-body">
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1">
                                                            <p className="text-muted fw-medium">Episode</p>
                                                            <h4 className="mb-0">{userType?.count_episodes}</h4>
                                                        </div>
                                                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                            <span className="avatar-title rounded-circle bg-primary">
                                                                <i className="bx dripicons-monitor font-size-24"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <Card style={{ paddingBottom: "4.5%" }}>
                                            <CardBody>
                                                <Paichart chartdata={[userType?.reel_count, userType?.deals_count, userType?.guide_count, userType?.blog_count]} />

                                            </CardBody>

                                        </Card>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="row">
                                        <Card style={{ paddingBottom: "0%" }}>

                                            <CardBody>
                                                <CardTitle className="mb-4">Total Orders</CardTitle>
                                                <Dountchart chartdata={[userType?.canceled_order, userType?.completed_order, userType?.upcoming_order]} />
                                            </CardBody>
                                        </Card>
                                    </div>
                                </div>
                                <div className="col-md-6">

                                    <div className="row">
                                        <CardTitle className="mb-4">Total Orders</CardTitle>
                                        <div className="col-md-4">
                                            <div className="mini-stats-wid card">
                                                <div className="card-body">
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1">
                                                            <p className="text-muted fw-medium">Upcoming Order</p>
                                                            <h4 className="mb-0">{userType?.upcoming_order}</h4>
                                                        </div>
                                                        {/* <Link className="text-decoration-underline text-reset" to={`/reels`}>More<i className="mdi mdi-arrow-right"></i></Link> */}
                                                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                            <span className="avatar-title rounded-circle bg-primary">
                                                                <i className="bx bx-archive-in font-size-24"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mini-stats-wid card">
                                                <div className="card-body">
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1">
                                                            <p className="text-muted fw-medium">Completed order</p>
                                                            <h4 className="mb-0">{userType?.completed_order}</h4>
                                                        </div>
                                                        {/* <Link className="text-decoration-underline text-reset" to={`/reels`}>More<i className="mdi mdi-arrow-right"></i></Link> */}
                                                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                            <span className="avatar-title rounded-circle bg-primary">
                                                                <i className="bx bx-task font-size-24"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mini-stats-wid card">
                                                <div className="card-body">
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1">
                                                            <p className="text-muted fw-medium">Cancelled order </p>
                                                            <h4 className="mb-0">{userType?.canceled_order}</h4>
                                                        </div>
                                                        {/* <Link className="text-decoration-underline text-reset" to={`/reels`}>More<i className="mdi mdi-arrow-right"></i></Link> */}
                                                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                            <span className="avatar-title rounded-circle bg-primary">
                                                                <i className="bx bx-window-close font-size-24"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <CardTitle className="mb-4">Current Order</CardTitle>
                                        <div className="col-md-4">
                                            <div className="mini-stats-wid card">
                                                <div className="card-body">
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1">
                                                            <p className="text-muted fw-medium">Month</p>
                                                            <h4 className="mb-0">{userType?.month_order}</h4>
                                                        </div>
                                                        {/* <Link className="text-decoration-underline text-reset" to={`/reels`}>More<i className="mdi mdi-arrow-right"></i></Link> */}
                                                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                            <span className="avatar-title rounded-circle bg-primary">
                                                                <i className="bx bx-calendar font-size-24"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mini-stats-wid card">
                                                <div className="card-body">
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1">
                                                            <p className="text-muted fw-medium">Week</p>
                                                            <h4 className="mb-0">{userType?.week_order}</h4>
                                                        </div>
                                                        {/* <Link className="text-decoration-underline text-reset" to={`/reels`}>More<i className="mdi mdi-arrow-right"></i></Link> */}
                                                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                            <span className="avatar-title rounded-circle bg-primary">
                                                                <i className="bx bx-calendar-week font-size-24"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mini-stats-wid card">
                                                <div className="card-body">
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1">
                                                            <p className="text-muted fw-medium">Today`s</p>
                                                            <h4 className="mb-0">{userType?.todays_order}</h4>
                                                        </div>
                                                        {/* <Link className="text-decoration-underline text-reset" to={`/reels`}>More<i className="mdi mdi-arrow-right"></i></Link> */}
                                                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                            <span className="avatar-title rounded-circle bg-primary">
                                                                <i className="bx bx-calendar-event font-size-24"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row">
                                        <TotalUsers usercount={userType?.user_count} creatorscount={userType?.creators_count} contributorcount={userType?.contributor_count} />
                                    </div>
                                </div>
                                {/* <div className="col-md-6">
                                <CardTitle className="mb-4">OverAll / Pending</CardTitle>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="mini-stats-wid card">
                                                <div className="card-body" style={{paddingTop: "4%"}} >
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1">
                                                            <p className="text-muted fw-medium">OverAll Order</p>
                                                            <h4 className="mb-0">{userType?.overall_order}</h4>
                                                        </div>
                                                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                            <span className="avatar-title rounded-circle bg-primary">
                                                                <i className="bx bx-copy-alt font-size-24"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                       
                                        <div className="col-md-12">
                                            <div className="mini-stats-wid card">
                                                <div className="card-body" style={{paddingTop: "4%"}} >
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1">
                                                            <p className="text-muted fw-medium">Pending Order</p>
                                                            <h4 className="mb-0">{userType?.pending_order}</h4>
                                                        </div>
                                                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                            <span className="avatar-title rounded-circle bg-primary">
                                                                <i className="bx bx-coin-stack font-size-24"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>


                        </Row>


                    </>}

                </Container>
            </div>
        </React.Fragment>
    )


}


export default Dashboard
