import React, {useEffect, useState} from "react";
import {Card, CardBody, Col, Row} from "reactstrap"
import MetaTags from 'react-meta-tags'
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {TableHeader, Search} from "../Datatable/index";
import {apiKey} from "helpers/url_helper"
import axios from 'axios';
import notification from "../../components/Common/Notification"
let api_url = process.env.REACT_APP_API_URL;
let api_key = apiKey()

const useFullPageLoader = () => {
    const [loading, setLoading] = useState(false);

    return [
        loading ? 'Loading' : null,
        () => setLoading(true), //Show loader
        () => setLoading(false) //Hide Loader
    ];
};
let PageSize = 15;
function getStatus(status) {
    if (status === 0){
        return <span className="font-size-12 badge-warning badge bg-warning rounded-pill">Inactive</span>
    }else if (status === 1){
        return <span className="font-size-12 badge-success badge bg-success rounded-pill">Active</span>
    }
}
const UsersRole = () => {
    const [tableData, settableData] = useState([]);
    const [loader, showLoader, hideLoader] = useFullPageLoader();

    const TablesHeader = [
        {name: "#", field: "id", sortable: false},
        {name: "Role Name", field: "name", sortable: false},
        {name: "Status", field: "status", sortable: false}
    ];

    useEffect(() => {
        showLoader()
        const url = `${api_url}/user-roles`
        axios.get(url,{
            headers: {
                "Authorization": 'Bearer '+api_key,
            }
        })
            .then(res => {
                hideLoader();
                if(res.status) {
                    settableData(res.data.data);
                }
            }).catch(err => notification(err,'error','toast-bottom-right'));
    }, [])  // pass `value` as a dependency

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>User Roles</title>
                </MetaTags>
                <div className="container-fluid">
                    <Breadcrumbs title="Dashboard" path="/" breadcrumbItem="User Roles"/>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <div className="table-responsive">
                                        <table className="table table-striped">
                                            <TableHeader
                                                headers={TablesHeader}
                                            />
                                            <tbody className="">

                                            {tableData.map((value,index) => (
                                                <tr key={index}>
                                                    <th scope="row">
                                                        {++index}
                                                    </th>
                                                    <td>{value.name}</td>
                                                    <td>{getStatus(value.status)}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

export default UsersRole
