import React, {useEffect, useMemo, useState} from "react";
import { useParams, useHistory, useLocation } from 'react-router-dom';
import {Card, CardBody, Col, Row} from "reactstrap"
import MetaTags from 'react-meta-tags'
import Pagination from 'components/Common/Pagination';
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {TableHeader, Search} from "../../Datatable/index";
import {apiKey} from "helpers/url_helper"
import {Link} from "react-router-dom";
import axios from 'axios';
import notification from "../../../components/Common/Notification"
import "react-image-lightbox/style.css";
let api_url = process.env.REACT_APP_API_URL;
let api_key = apiKey()

const useFullPageLoader = () => {
    const [loading, setLoading] = useState(false);

    return [
        loading ? 'Loading' : null,
        () => setLoading(true), //Show loader
        () => setLoading(false) //Hide Loader
    ];
};
let PageSize = 15;
const States = (props) => {
    const history = useHistory();
    const location = useLocation();
    const { pId:pageno } = useParams();
    const [tableData, settableData] = useState([]);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(() => {
        const paramPage = parseInt(pageno);
        if (!isNaN(paramPage)) {
            return paramPage;
          } else {
            return 1;
          }
      });
      const { searchQuery } = useParams();
      const [search, setSearch] = useState(() => {
          if (searchQuery) {
              return searchQuery;
            } else {
              return '';
            }
        });
      const [getSearch, setGetSearch] = useState(search);
      const [getSearchPage, setGetSearchPage] = useState(currentPage)
      
    const TableTopHeader = [
        {name: "#", field: "id", sortable: false},
        {name: "Country", field: "country", sortable: false},
        {name: "State", field: "name", sortable: false},
        {name: "Ordering", field: "ordering", sortable: false},
        {name: "Status", field: "status", sortable: false},
        {name: "Action", field: "", sortable: false}
    ];

    
    function handleSearch(){
        setSearch(getSearch);
        setCurrentPage(getSearchPage);
        if(getSearch){
            history.push(`/states/pageno/${getSearchPage}/${getSearch}`)
        }
    }
    // Handle any changes to the currentPage state
    const handlePageChange = () => {
        setCurrentPage(() => {
            const paramPage = parseInt(pageno);
            if (!isNaN(paramPage)) {
                return paramPage;
            } else {
                return 1;
            }
        })
    };
    useEffect(() => {
        handlePageChange();
    }, [pageno,location]);

    useEffect(() => {
        showLoader()
        let query = ''
        search && ( query = `keyword=${search}`)
        const url = `${api_url}/get-location-state?${query}&limit=${PageSize}&pageNo=${currentPage}`
        axios.get(url,{
            headers: {
                "Authorization": 'Bearer '+api_key,
            }
        })
            .then(res => {
                hideLoader();
                if(res.status) {
                    settableData(res.data.data);
                    setTotalItems(res.data.totalRecords);
                }
            }).catch(
                err => {
                    notification(err, 'error', 'toast-bottom-right')
                    if(err.response.status===401 || err.response.status===400 ){
                        history.push('/logout')
                    }
                }
            );
    }, [currentPage,search])  // pass `value` as a dependency

    function colorBox(param) {
        let colorBoxStyle = {height:'40px',width:'100px',background:param,border:'1px solid #eee',boxShadow:'rgb(158 158 158 / 44%) 0px 0px 10px',lineHeight:'40px',textAlign:'center',color:'#000'}
        return (
            <div style={colorBoxStyle}>{param}</div>
        )
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Manage States</title>
                </MetaTags>
                <div className="container-fluid">
                    <Breadcrumbs title="Dashboard" path="/" breadcrumbItem="States"/>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <div className="row">
                                        <div className="col-md-6">

                                        </div>
                                        <div className="col-md-6 d-flex flex-row-reverse">
                                            <div className="ms-2">
                                                <a href="/states/add"
                                                   className="btn btn-primary waves-effect waves-light"><i
                                                    className="bx bx-plus font-size-16 align-middle me-2"></i> Add</a>
                                            </div>
                                            <div onClick={handleSearch}
                                               className="btn btn-primary waves-effect waves-light ms-2"> Search</div>
                                            <Search
                                                onSearch={value => {
                                                    setGetSearch(value)
                                                    setGetSearchPage(1)
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <table className="table table-striped">
                                        <TableHeader
                                            headers={TableTopHeader}
                                        />
                                        <tbody className="">

                                        {tableData.map((value,index) => (
                                            <tr key={++index}>
                                                <th scope="row">
                                                    {PageSize * (currentPage - 1) + (index + 1)}
                                                </th>

                                                <td>{(value.location_country[0]!==undefined)?value.location_country[0].name:''}</td>
                                                <td>{value.name}</td>
                                                {/* <td>{value.image !== '' && <img className="img-thumbnail avatar-md" alt={value.image} src={value.image} width="300"/>}</td> */}
                                                <td>{value.ordering}</td>
                                                <td>{value.status?<span className="badge badge-pill badge-soft-success font-size-13">Active</span>: <span
                                                    className="badge badge-pill badge-soft-error font-size-13">Inactive</span>}</td>
                                                <td><Link to={`/states/${currentPage}/update/${value._id}`}><i className="bx bxs-edit-alt"></i> </Link></td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                    <Row className="justify-content-center">
                                        <Col className="col-auto">
                                            <Pagination
                                                className="pagination-bar"
                                                currentPage={currentPage}
                                                totalCount={totalItems}
                                                pageSize={PageSize}
                                                onPageChange={page => history.push(`/states/pageno/${page}/${search}`)}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

export default States
