import React, {useEffect, useMemo, useState} from "react";
import { useParams, useHistory, useLocation } from 'react-router-dom';
import {Card, CardBody, Col, Modal, Row} from "reactstrap"
import MetaTags from 'react-meta-tags'
import Pagination from 'components/Common/Pagination';
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {TableHeader, Search} from "../Datatable/index";
import {apiKey} from "helpers/url_helper"
import axios from 'axios';
import notification from "../../components/Common/Notification"
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import Moment from 'moment';
import toastr from "toastr"
let api_url = process.env.REACT_APP_API_URL;
let api_key = apiKey()

const useFullPageLoader = () => {
    const [loading, setLoading] = useState(false);

    return [
        loading ? 'Loading' : null,
        () => setLoading(true), //Show loader
        () => setLoading(false) //Hide Loader
    ];
};
let PageSize = 15;
function setData(data) {
    let arr = [];
    if (data.length){
        for (var i = 0; i < data.length;i++){
            let user = data[i]
            let update_by = "";
            if (user['updated'] !== null){
                update_by = Object.keys(user['updated']).length && user['updated']['name']
            }
            arr.push({
                id:user['id'],
                name:user['user'].name,
                email:user['user']['email'],
                mobile:user['user']['mobile'],
                photo:user['user']['photo'],
                social: {social_youtube:user['social_youtube'],
                    social_website:user['social_website'],
                    social_facebook:user['social_facebook'],
                    social_instagram:user['social_instagram']},
                updated_date:user['updated_date'],
                created_date:user['created_date'],
                submission_count:user['submission_count'],
                request_status:user['request_status'],
                updated_by:update_by,
            })
        }
    }
    return arr;
}
function getStatus(status) {
//    0: New,1: Rejected, 2: Shortlisted, 3: Hired
    if (status === 0){
        return <span className="font-size-12 badge-info badge bg-info rounded-pill">New</span>
    }else if (status === 1){
        return <span className="font-size-12 badge-danger badge bg-danger rounded-pill">Rejected</span>
    }else if (status === 2){
        return <span className="font-size-12 badge-warning badge bg-warning rounded-pill">Shortlisted</span>
    }else if (status === 3){
        return <span className="font-size-12 badge-success badge bg-success rounded-pill">Hired</span>
    }
}
function socialMedia(social) {
    if (social){
        return (
            <ul className="social list-unstyled" style={{minWidth:'120px',marginBottom:0}}>
                {social.social_website && <li><a className="text-primary" href={social.social_website}><i className="mdi mdi-web"></i> Website</a></li>}
                {social.social_facebook && <li><a className="text-info" href={social.social_facebook}><i className="mdi mdi-facebook"></i> Facebook</a></li>}
                {social.social_youtube && <li><a className="text-danger" href={social.social_youtube}><i className="mdi mdi-youtube"></i> Youtube</a></li>}
                {social.social_instagram && <li><a className="text-warning" href={social.social_instagram}><i className="mdi mdi-instagram"></i> Instagram</a></li>}
            </ul>
        )
    }
}
function formatDate(date){
    return Moment(date).format('DD-MM-YYYY, h:mm:ss A')
}
const Contributor = (props) => {
    const history = useHistory();
    const location = useLocation();
    const { pId:pageno } = useParams();
    const [tableData, settableData] = useState([]);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(() => {
        const paramPage = parseInt(pageno);
        if (!isNaN(paramPage)) {
            return paramPage;
          } else {
            return 1;
          }
      });
    const [search, setSearch] = useState("");
    const [isFits, setisFits] = useState(false);
    const [main_img, setMainImg] = useState("");
    const [EditId, setEditId] = useState(0);
    const [status, setStatus] = useState(0);
    const [RequestStatus, setRequestStatus] = useState(0);
    const [StatusModal, setStatusModal] = useState(false);
    const [submit, setSubmit] = useState(false);

    const CountryHeader = [
        {name: "#", field: "id", sortable: false},
        {name: "Name", field: "name", sortable: false},
        {name: "Email", field: "email", sortable: false},
        {name: "Mobile", field: "mobile", sortable: false},
        {name: "Photo", field: "photo", sortable: false},
        {name: "Social Link", field: "", sortable: false},
        {name: "Created date", field: "created_date", sortable: false},
        {name: "Updated date", field: "updated_date", sortable: false},
        {name: "Submission Count", field: "submission_count", sortable: false},
        {name: "Updated by", field: "updated_by", sortable: false},
        {name: "Status", field: "request_status", sortable: false},
        {name: "Action", field: "", sortable: false}
    ];

    const handleChange = (value) => {
        history.push(`/contributor/pageno/${value}`)
    };
    // Handle any changes to the currentPage state
    const handlePageChange = () => {
        setCurrentPage(() => {
            const paramPage = parseInt(pageno);
            if (!isNaN(paramPage)) {
                return paramPage;
            } else {
                return 1;
            }
        })
    };
    useEffect(() => {
        handlePageChange();
    }, [pageno,location]);

    useEffect(() => {
        showLoader()
        let query = ''
        search && ( query = `keyword=${search}`)
        const url = `${api_url}/user-request-list?${query}&limit=${PageSize}&pageNo=${currentPage}`
        axios.get(url,{
            headers: {
                "Authorization": 'Bearer '+api_key,
            }
        })
            .then(res => {
                hideLoader();
                if(res.status) {
                    let tableData = setData(res.data.data);
                    settableData(tableData);
                    setTotalItems(res.data.totalRecords);
                }
            }).catch(
                err => {
                    notification(err, 'error', 'toast-bottom-right')
                    if(err.response.status===401 || err.response.status===400 ){
                        history.push('/logout')
                    }
                }
            );
    }, [currentPage,search])  // pass `value` as a dependency
    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }
    function tog_center() {
        removeBodyCss()
    }
    const submitData = (e) => {
        setSubmit(true)
        const headers = {'Content-Type': 'application/json', "Authorization": 'Bearer ' + api_key}
        var data = JSON.stringify({
            "id": EditId,
            "updatedId": 3,
            "request_status": RequestStatus
        });
        axios({
            method: "post", url: `${api_url}/update-request-status`, data: data, headers
        }).then(response => {
            if (response.status) {
                toastr.success('Request Status update successful.')
                setCurrentPage(0)
                setStatusModal(false)
                setSubmit(false)
            }
        }).catch(err => {
            setSubmit(false)
            toastr.error(err.message)
        })
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Manage Contributor</title>
                </MetaTags>
                <div className="container-fluid">
                    <Breadcrumbs title="Dashboard" path="/" breadcrumbItem="Contributor"/>
                    {isFits ? (
                        <Lightbox
                            mainSrc={main_img}
                            enableZoom={false}
                            onCloseRequest={() => {
                                setisFits(!isFits);
                            }}
                        />
                    ) : null}
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <div className="row">
                                        <div className="col-md-6">

                                        </div>
                                        <div className="col-md-6 d-flex flex-row-reverse">
                                            <Search
                                                onSearch={value => {
                                                    setSearch(value);
                                                    setCurrentPage(1);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-striped">
                                            <TableHeader
                                                headers={CountryHeader}
                                            />
                                            <tbody className="">

                                            {tableData.map((value,index) => (
                                                <tr key={++index}>
                                                    <th scope="row">
                                                        {PageSize * (currentPage - 1) + (index + 1)}
                                                    </th>
                                                    <td>{value.name}</td>
                                                    <td>{value.email}</td>
                                                    <td>{value.mobile}</td>
                                                    <td>
                                                        { value.photo && <img onClick={() => { setisFits(true); setMainImg(value.photo)}} className="img-thumbnail avatar-sm" alt={value.name} src={value.photo} width="200"/>}
                                                    </td>
                                                    <td>{socialMedia(value.social)}</td>
                                                    <td>{value.created_date && <div style={{minWidth:"100px"}}>{formatDate(value.created_date)}</div>}</td>
                                                    <td>{value.updated_date && <div style={{minWidth:"100px"}}>{formatDate(value.updated_date)}</div>}</td>
                                                    <td>{value.submission_count}</td>
                                                    <td>{value.updated_by}</td>
                                                    <td>{getStatus(value.request_status)}</td>
                                                    <td>{value.request_status === 3 ? <button className="btn btn-sm btn-dark"><i className="bx bx-block"></i></button>: <a className="btn btn-sm btn-primary" onClick={() => {setEditId(value.id);setRequestStatus(value.request_status);setStatus(value.request_status);setStatusModal(true)}}><i className="bx bxs-edit-alt"></i> </a>}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <Row className="justify-content-center">
                                        <Col className="col-auto">
                                            <Pagination
                                                className="pagination-bar"
                                                currentPage={currentPage}
                                                totalCount={totalItems}
                                                pageSize={PageSize}
                                                onPageChange={page => handleChange(page)}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
            <Modal size="sm" isOpen={StatusModal} toggle={() => {tog_center()}} centered>
                <div className="modal-header">
                    <h5 className="modal-title mt-0">Request Status</h5>
                    <button
                        type="button"
                        onClick={() => {
                            setStatusModal(false)
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <input type="hidden" id="edit_id" defaultValue={EditId}/>
                    <select className="form-control" defaultValue={status} onChange={event => setRequestStatus(event.target.value)}>
                        <option value="0">New</option>
                        <option value="1">Rejected</option>
                        <option value="2">Shortlisted</option>
                        <option value="3">Hired</option>
                    </select>
                    <button className="w-100 btn btn-primary mt-2" onClick={submitData} disabled={submit} type="button">{submit === true ? 'Please wait...' : 'Submit Data'}</button>
                </div>
            </Modal>
        </React.Fragment>
    )
}

export default Contributor
