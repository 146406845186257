import React from "react";
import MetaTags from "react-meta-tags";
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from "react-select";
import { apiKey } from "../../helpers/url_helper";
import SimpleReactValidator from "simple-react-validator";
import axios from "axios";
import toastr from "toastr";
import PropTypes from "prop-types";

let api_url = process.env.REACT_APP_API_URL;
let api_key = apiKey();
const headers = { "Content-Type": "application/json", "Authorization": "Bearer " + api_key };
function default_value(id,data){
    let selected = [];
    for (var x = 0; x < id.length; x++){
        const result = data.find( (name) => name['_id'] === id[x]);
        if (result !== undefined){
            selected.push({label:result['name'],value:result['_id']})
        }
    }
    return selected;
}
function selected_city(id,data){
    let selected = [];
    for (var x = 0; x < id.length; x++){
        const result = data.find( (name) => name['_id'] === id[x]);
        if (result !== undefined){
            selected.push({label:result['city_name'],value:result['_id']})
        }
    }
    return selected;
}
function selected_userrole(id,data){
    let selected = [];
    for (var x = 0; x < data.length; x++){
        const result = data.find( (name) => name['id'] === id);
        if (result !== undefined){
            selected.push({label:result['name'],value:result['id']})
        }
    }
    return selected;
}
class CouponEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deals_data: [],
            partner_data: [],
            deals_category_data: [],
            interest_data: [],
            // city_data: [],
            user_type_data: [],
            deals: "",
            default_deals: [],
            partners: [],
            default_partners: [],
            deals_category: "",
            default_deals_category: [],
            interests: "",
            default_interests: [],
            // cities: "",
            // default_cities: [],
            coupon_title: "",
            coupon_code: "",
            user_type: "",
            default_userrole: [],
            status: true,
            order_amount: "",
            discount_type: "",
            max_discount: "",
            discount_value: "",
            handleResponse: null,
            submit: false
        };
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    }

    componentDidMount() {
        let edit_data = [];
        const id = this.props.match.params.id
        this.setState({edit_id: id})
        const params = {
            type: "coupons",
            id: id
        }
        axios.get(`${api_url}/get-single-data`, {params, headers })
            .then(res => {
                if (res.data.status) {
                    let edit = res.data.data
                    this.setState({
                        status: edit['status'],
                        deals: edit['deals'],
                        partners: edit['partner'],
                        deals_category: edit['deals_category'],
                        interests: edit['interest'],
                        // cities: edit['city'],
                        coupon_title: edit['coupon_title'],
                        coupon_code: edit['coupon_code'],
                        user_type: edit['user_type'],
                        order_amount: edit['order_amount'],
                        discount_type: edit['discount_type'],
                        max_discount: edit['max_discount'],
                        discount_value: edit['discount_value'],
                    })
                    edit_data = edit;
                } else {
                    toastr.danger('Server not response')
                }
            })

        // fetch(`${api_url}/get-cities`, { headers })
        //     .then(response => response.json())
        //     .then(data => this.setState({ city_data: data.data,default_cities:selected_city(this.state.cities,data.data) }))
        //     .catch(error => { console.error('Data loading:', error);});

        fetch(`${api_url}/get-active-deals?status=1`, { headers })
            .then(response => response.json())
            .then(data => {
                this.setState({deals_data: data.data,default_deals:default_value(this.state.deals,data.data)})
            })
            .catch(error => { console.error('Data loading:', error);});

        fetch(`${api_url}/get-deal-partner`, { headers })
            .then(response => response.json())
            .then(data => {
                this.setState({partner_data: data.data,default_partners:default_value(this.state.partners,data.data)})
            })
            .catch(error => { console.error('Data loading:', error);});

        fetch(`${api_url}/get-deal-categories`, { headers })
            .then(response => response.json())
            .then(data => this.setState({ deals_category_data: data.data,default_deals_category:default_value(this.state.deals_category,data.data)}))
            .catch(error => { console.error('Data loading:', error);});

        fetch(`${api_url}/get-interests`, { headers })
            .then(response => response.json())
            .then(data => this.setState({ interest_data: data.data,default_interests:default_value(this.state.interests,data.data) }))
            .catch(error => { console.error('Data loading:', error);});

        fetch(`${api_url}/user-roles`, { headers })
            .then(response => response.json())
            .then(data => this.setState({ user_type_data: data.data,default_userrole:selected_userrole(this.state.user_type,data.data) }))
            .catch(error => { console.error('Data loading:', error);});
    }


    handleCity = selectedCity => {
        this.setState({ default_cities: selectedCity });
    };
    handleDeals = selectedDeals => {
        this.setState({ default_deals: selectedDeals });
    };
    handlePartner = selectedPartner => {
        this.setState({ default_partners: selectedPartner });
    };
    handleInterest = selectedInterest => {
        this.setState({ default_interests: selectedInterest });
    };
    handleDealCategory = selectedDealCategory => {
        this.setState({ default_deals_category: selectedDealCategory });
    };
    handleUserType = selectedUser => {
        this.setState({ default_userrole: selectedUser });
        this.setState({ user_type: selectedUser['value'] });
    };

    citiesData = a => {
        let b = [];
        for (var c = 0; c < a.length; c++) b.push({
            label: a[c].city_name,
            value: a[c]._id
        });
        return b;
    };
    selectData = a => {
        let b = [];
        for (var c = 0; c < a.length; c++) b.push({
            label: a[c].name,
            value: a[c]._id
        });
        return b;
    };
    selectDataDash = a => {
        let b = [];
        for (var c = 0; c < a.length; c++) b.push({
            label: a[c].name,
            value: a[c].id
        });
        return b;
    };
    handleInput = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };
    handleFormSubmit = (e) => {
        e.preventDefault();
        if (this.validator.allValid()) {
            var data = JSON.stringify({
                "coupon_title": this.state.coupon_title,
                "coupon_code": this.state.coupon_code,
                // "user_type": this.state.user_type,
                "order_amount": this.state.order_amount,
                "discount_value": this.state.discount_value,
                "max_discount": this.state.max_discount,
                "discount_type": this.state.discount_type,
                "deals": this.state.default_deals.map(({ value }) => value).join(","),
                // "partner": this.state.default_partners.map(({ value }) => value).join(","),
                // "deals_category": this.state.default_deals_category.map(({ value }) => value).join(","),
                // "interest": this.state.default_interests.map(({ value }) => value).join(","),
                // "city": this.state.default_cities.map(({ value }) => value).join(","),
                "status": this.state.status,
                "cId": this.state.edit_id,
            });
            this.setState({ submit: true });
            axios({
                method: "post", url: `${api_url}/update-coupon`, data: data, headers
            }).then(response => {
                if (response.status) {
                    this.setState({ submit: false });
                    toastr.success("Coupon update successful.");
                    // eslint-disable-next-line react/prop-types
                    const { history } = this.props;
                    // eslint-disable-next-line react/prop-types
                    history.goBack();
                }
            }).catch(err => {
                toastr.error(err.message);
                this.setState({ submit: false });
                alert(err.message);
            });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };

    render() {
        const { deals_data,submit } = this.state;
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>
                            Manage Coupons | {process.env.REACT_APP_PROJECTNAME}
                        </title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Coupons" path="/coupons/pageno/1" breadcrumbItem="Edit Coupon" />
                        <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                        <form onSubmit={this.handleFormSubmit}>
                                            <Row>
                                                <Col md="4" className="mb-3">
                                                    <Label htmlFor="coupon_title">Coupon Title</Label>
                                                    <input name="coupon_title" placeholder="Type Coupon Title" type="text" className={"form-control"} defaultValue={this.state.coupon_title} onChange={this.handleInput} />
                                                    {this.validator.message("coupon_title", this.state.coupon_title, "required|string")}
                                                </Col>
                                                <Col md="4" className="mb-3">
                                                    <Label htmlFor="coupon_code">Coupon Code</Label>
                                                    <input name="coupon_code" placeholder="Type Coupon Code" type="text" className={"form-control"} defaultValue={this.state.coupon_code} onChange={this.handleInput} />
                                                    {this.validator.message("coupon_code", this.state.coupon_code, "required")}
                                                </Col>
                                                <Col md="2" className="mb-3">
                                                    <label htmlFor="discount_type">Discount Type</label>
                                                    <select className="form-control" name="discount_type" value={this.state.discount_type} onChange={this.handleInput}>
                                                        <option value="">Select a type</option>
                                                        <option value="amount">Amount</option>
                                                        <option value="percentage">Percentage</option>
                                                    </select>
                                                    {this.validator.message("discount_type", this.state.discount_type, "required")}
                                                </Col>
                                                <Col md="2" className="mb-3">
                                                    <Label htmlFor="max_discount">Max Discount</Label>
                                                    <input name="max_discount" placeholder="Type Max Discount" type="text" className={"form-control"} value={this.state.max_discount} onChange={this.handleInput} />
                                                    {this.validator.message("max_discount", this.state.max_discount, "required|currency")}
                                                </Col>
                                                <Col md="2" className="mb-3">
                                                    <Label htmlFor="order_amount">Discount Value</Label>
                                                    <input name="discount_value" placeholder="Type Discount Value" type="text" className={"form-control"} value={this.state.discount_value} onChange={this.handleInput} />
                                                    {this.validator.message("discount_value", this.state.discount_value, "required|currency")}
                                                </Col>
                                                <Col md="2" className="mb-3">
                                                    <Label htmlFor="order_amount">Minimum Order Amount</Label>
                                                    <input name="order_amount" placeholder="Type Minimum Order Amount" type="text" className={"form-control"} defaultValue={this.state.order_amount} onChange={this.handleInput} />
                                                    {this.validator.message("order_amount", this.state.order_amount, "required|currency")}
                                                </Col>
                                                {/*<Col md="3" className="mb-3">*/}
                                                {/*    <label className="control-label">User Type</label>*/}
                                                {/*    <Select*/}
                                                {/*        value={this.state.default_userrole}*/}
                                                {/*        isMulti={false}*/}
                                                {/*        name="deals"*/}
                                                {/*        onChange={this.handleUserType}*/}
                                                {/*        options={this.selectDataDash(user_type_data)}*/}
                                                {/*        classNamePrefix="select2-selection"*/}
                                                {/*    />*/}
                                                {/*    {this.validator.message("user_type", this.state.user_type, "required")}*/}
                                                {/*</Col>*/}
                                                <Col md="5" className="mb-3">
                                                    <label className="control-label">Deals</label>
                                                    <Select
                                                        value={this.state.default_deals}
                                                        isMulti={true}
                                                        name="deals"
                                                        onChange={this.handleDeals}
                                                        options={this.selectData(deals_data)}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                    {this.validator.message("deals", this.state.deals, "required")}
                                                </Col>
                                                {/*<Col md="4" className="mb-3">*/}
                                                {/*    <label className="control-label">Interests</label>*/}
                                                {/*    <Select*/}
                                                {/*        value={this.state.default_interests}*/}
                                                {/*        isMulti={true}*/}
                                                {/*        name="interests"*/}
                                                {/*        onChange={this.handleInterest}*/}
                                                {/*        options={this.selectData(interest_data)}*/}
                                                {/*        classNamePrefix="select2-selection"*/}
                                                {/*    />*/}
                                                {/*    {this.validator.message("interests", this.state.interests, "required")}*/}
                                                {/*</Col>*/}
                                                {/*<Col md="4" className="mb-3">*/}
                                                {/*    <label className="control-label">Partner</label>*/}
                                                {/*    <Select*/}
                                                {/*        isMulti={true}*/}
                                                {/*        value={this.state.default_partners}*/}
                                                {/*        onChange={this.handlePartner}*/}
                                                {/*        options={this.selectData(partner_data)}*/}
                                                {/*        classNamePrefix="select2-selection"*/}
                                                {/*    />*/}
                                                {/*    {this.validator.message("partners", this.state.partners, "required")}*/}
                                                {/*</Col>*/}
                                                {/*<Col md="4" className="mb-3">*/}
                                                {/*    <label className="control-label">Deals Category</label>*/}
                                                {/*    <Select*/}
                                                {/*        value={this.state.default_deals_category}*/}
                                                {/*        isMulti={true}*/}
                                                {/*        onChange={this.handleDealCategory}*/}
                                                {/*        options={this.selectData(deals_category_data)}*/}
                                                {/*        classNamePrefix="select2-selection"*/}
                                                {/*    />*/}
                                                {/*    {this.validator.message("deals_category", this.state.deals_category, "required")}*/}
                                                {/*</Col>*/}
                                                {/* <Col md="3" className="mb-3">
                                                    <label className="control-label">City</label>
                                                    <Select
                                                        value={this.state.default_cities}
                                                        isMulti={true}
                                                        onChange={this.handleCity}
                                                        options={this.citiesData(city_data)}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                    {this.validator.message("cities", this.state.cities, "required")}
                                                </Col> */}
                                                <Col md="auto" className="mb-3">
                                                    <Label htmlFor="">Status</Label>
                                                    <div className="square-switch mt-2">
                                                        <input
                                                            type="checkbox"
                                                            id="square-switch-status"
                                                            switch="none"
                                                            name="status"
                                                            checked={this.state.status}
                                                            onChange={() =>
                                                                this.setState({ status: !this.state.status })
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="square-switch-status"
                                                            data-on-label="On"
                                                            data-off-label="Off"
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>

                                            <div className="d-flex flex-wrap gap-2 justify-content-end">
                                                <Button color="primary" type="submit" disabled={submit}>
                                                    {submit === true ? "Please wait..." : "Submit Data"}
                                                </Button>
                                            </div>
                                        </form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

CouponEdit.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string
        })
    }),
    location: PropTypes.object
};

export default CouponEdit;
