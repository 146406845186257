import React from "react"
import MetaTags from "react-meta-tags"
import {Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Row,} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Select from "react-select"
import {apiKey} from "../../helpers/url_helper"
import {get} from "../../helpers/api_helper"
import SimpleReactValidator from 'simple-react-validator'
import axios from "axios"
import toastr from "toastr"
import "style.min.css"
import PropTypes from "prop-types"
import DualListBox from 'react-dual-listbox'
import 'react-dual-listbox/lib/react-dual-listbox.css'
import AsyncSelect from "react-select/async"

let api_url = process.env.REACT_APP_API_URL
let api_key = apiKey()
const headers = {'Content-Type': 'application/json', "Authorization": 'Bearer ' + api_key}
function getDataName(id, data) {
    let selected = []
    if (data.length) {
        for (var x = 0; x < id.length; x++) {
            const result = data.find((name) => name['_id'] === id[x])
            if (result !== undefined) {
                selected.push({label: result['name'], value: result['_id']})
            }
        }
        // console.log(id,data)
    }
    return selected
}

class ReelAdd extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            users: [],
            tagslist: [],
            Tags: "",
            defaultTags: [],
            interestslist: [],
            active_deals: [],
            associateMaster: [],
            Interest: "",
            defaultInterest: [],
            dealslist: [],
            Deals: "",
            defaultDeals: [],
            is_trending: false,
            status: true,
            ct_reel_title: "",
            is_associated: false,
            associate_deals_url: "",
            ct_reel_thumbnail: [],
            reel_thumbnail: "",
            ordering: "",
            handleResponse: null,
            submit: false,
            associate_type: "",
            is_approved: false,
            is_load_form: false,
            values:"",
            deal_id:"",
            user_id:"",
            _like_count:0
        }
        this.validator = new SimpleReactValidator({autoForceUpdate: this})
    }

    componentDidMount() {
        get(`${api_url}/get-interests?&status=1`, {headers})
            .then(data => this.setState({interestslist: data.data}))
        get(`${api_url}/user-list?pageNo=1&limit=90000&role_ids=3,4`, {headers})
            .then(data => this.setState({users: data.data}))

        fetch(`${api_url}/get-active-deals?&status=1`, {headers})
          .then(response => response.json())
          .then(data => this.setState({active_deals: data.data}))
          .catch(err => {
              console.log("error:", err);
          });
    }

    getOptions = () => {
        return axios.get(`${api_url}/get-location?status=1&keyword=${this.state.values}`,{ headers })
            .then((res) => {
                return res.data.data;
            })
            .catch(
                err => console.log(err)
            );
    }

    handleInterestTags = e => {
        axios({
            method: "post", url: `${api_url}/get-interest-wise-tags`, data: {"interest": [e.target.value]}, headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + api_key
            }
        }).then(response => {
            if (response.data){
                let b = [],a = response.data.data;
                for (var c = 0; c < a.length; c++) b.push({
                    label: a[c].name,
                    value: a[c]._id
                });
                this.setState({tagslist: b})
            }
        }).catch(err => {
            toastr.error(err.message);
        });
    }

    handleTags = defaultTags => {
        this.setState({Tags: defaultTags})
        this.setState({defaultTags})
    }

    handleInterests = defaultInterest => {
        this.setState({Interest: defaultInterest})
        this.setState({defaultInterest})
    }

    handleFileChange = (event) => {
        this.setState({
            ct_reel_thumbnail: event.target.files[0]
        })
    }

    selectData = a => {
        let b = []
        for (var c = 0; c < a.length; c++) b.push({
            label: a[c].name, value: a[c]._id
        })
        return b
    }
    handleDeals = e => {
        const [option] = e.target.selectedOptions
        this.setState({deal_id: e.target.value,associate_deals_url:option.dataset.link})
    }
    handleInputChange = value => {
        this.setState({values:value})
    };
    selectLocation = value => {
        this.setState({location:value._id})
    }
    handleInput = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }
    handleAssociateType = (event) => {
        this.setState({associate_type:event.target.value})
        //    guide,blog,original, deal
        let type = event.target.value
        let url = "";
        if (type === "guide"){
            url = `${api_url}/get-guides?status=1`
        }else if (type === "blog"){
            url = `${api_url}/get-blog?status=1`
        }else if (type === "deal"){
            url = `${api_url}/get-deals?status=1`
        }else if (type === "original"){
            url = `${api_url}/get-shows?status=1`
        }
        axios({
            method: "get", url: `${url}`, headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + api_key
            }
        }).then(response => {
            if (response.data){
                let b = [],a = response.data.data;
                for (var c = 0; c < a.length; c++) {
                    let deep_link;
                    if (type === "guide" || type === "deal"){
                        deep_link = a[c].deep_link
                    }
                    let title;
                    if (type === "deal" || type === "original"){
                        title = a[c].name
                    }else {
                        title = a[c].title
                    }
                    b.push({
                        label: title,
                        deep_link: deep_link,
                        value: a[c]._id
                    });
                }
                this.setState({associateMaster: b})
            }
        }).catch(err => {
            toastr.error(err.message);
        });
    }
    handleFormSubmit = (e) => {
        e.preventDefault()
        if (this.validator.allValid()) {
            const formData = new FormData()
            formData.append('ct_reel_title', this.state.ct_reel_title)
            var ct_reel_thumbnail = document.getElementById("ct_reel_thumbnail").files[0]
            if (ct_reel_thumbnail) {
                formData.append('ct_reel_thumbnail', ct_reel_thumbnail)
            }
            let deep_link = ""
            if (this.state.associate_deals_url !== undefined){
                deep_link = this.state.associate_deals_url
            }
            this.state.is_associated  === true && formData.append('associate_deals_url', deep_link)
            this.state.is_associated  === true && formData.append('associate_type', this.state.associate_type)
            formData.append('status', this.state.status)
            formData.append('is_trending', this.state.is_trending)
            // formData.append('is_approved', this.state.is_approved)
            formData.append('ordering', 0)
            formData.append('tags', this.state.Tags)
            formData.append('interest', this.state.Interest)
            formData.append('user_id', this.state.user_id)
            formData.append('_like_count', this.state._like_count)
            formData.append('is_deal_associated', this.state.is_associated)
            this.state.location  !== '' && formData.append('location', this.state.location)
            this.state.is_associated  === true && formData.append('deal_id', this.state.deal_id)
            this.setState({submit: true})
            axios({
                method: "post", url: `${api_url}/add-ctreels`, data: formData, headers: {
                    "Authorization": 'Bearer ' + api_key,
                    "Content-Type": "multipart/form-data",
                }
            }).then(response => {
                if (response.status) {
                    this.setState({submit: false})
                    toastr.success('Reel add successful.')
                    // eslint-disable-next-line react/prop-types
                    const {history} = this.props
                    // eslint-disable-next-line react/prop-types
                    history.push('/reels/pageno/1');

                }
            }).catch(err => {
                toastr.error(err.message)
                this.setState({submit: false})
                alert(err.message)
            })
        } else {
            this.validator.showMessages()
            this.forceUpdate()
        }
    }

    render() {
        const {
            ct_reel_title,
            tagslist,
            interestslist,
            ordering,
            defaultTags,
            submit
        } = this.state

        return (<React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>
                        Manage Reels | {process.env.REACT_APP_PROJECTNAME}
                    </title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title="Reels" path="/reels/pageno/1" breadcrumbItem="Add Reel"/>
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <form onSubmit={this.handleFormSubmit}>
                                        <Row>
                                            <Col md="9" className="mb-3">
                                                <Label htmlFor="originals_name">Reel Title</Label>
                                                <input name="ct_reel_title" placeholder="Type Reel Title"
                                                       defaultValue={ct_reel_title} type="text"
                                                       className={"form-control"}
                                                       onChange={this.handleInput}/>
                                                {this.validator.message('ct_reel_title', this.state.ct_reel_title, 'required|string')}
                                            </Col>
                                            <Col md={3} className="mb-3">
                                                <label className="control-label">Location</label>
                                                <AsyncSelect defaultOptions name="form-field-name" loadOptions={this.getOptions} getOptionLabel={e => e.name} getOptionValue={e => e._id} onInputChange={this.handleInputChange} onChange={this.selectLocation}/>
                                                {this.validator.message('location', this.state.location, 'required')}
                                            </Col>
                                            <Col md="4" className="mb-3">
                                                <Label htmlFor="ct_reel_thumbnail">
                                                    Reel thumbnail
                                                </Label>
                                                <input
                                                    id="ct_reel_thumbnail"
                                                    name="ct_reel_thumbnail"
                                                    placeholder="Reel Thumbnail"
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={this.handleFileChange}
                                                    className={"form-control"}
                                                />
                                                {/* {this.validator.message('ct_reel_thumbnail', this.state.ct_reel_thumbnail, 'required')} */}
                                            </Col>
                                            {/*<Col md="2" className="mb-3">*/}
                                            {/*    <Label htmlFor="validationCustom03">Order No.</Label>*/}
                                            {/*    <input*/}
                                            {/*        name="ordering"*/}
                                            {/*        type="number"*/}
                                            {/*        placeholder="Order No."*/}
                                            {/*        className={"form-control"}*/}
                                            {/*        onChange={this.handleInput}*/}
                                            {/*        defaultValue={ordering}*/}
                                            {/*    />*/}
                                            {/*    {this.validator.message('ordering', this.state.ordering, 'required|numeric')}*/}
                                            {/*</Col>*/}
                                            <Col md="auto" className="mb-3">
                                                <Label htmlFor="">Status</Label>
                                                <div className="square-switch mt-2">
                                                    <input
                                                        type="checkbox"
                                                        id="square-switch-status"
                                                        switch="none"
                                                        name="status"
                                                        checked={this.state.status}
                                                        onChange={() => this.setState({status: !this.state.status})}
                                                    />
                                                    <label
                                                        htmlFor="square-switch-status"
                                                        data-on-label="On"
                                                        data-off-label="Off"
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="2" className="mb-3">
                                                <Label htmlFor="">Is Associated</Label>
                                                <div className="square-switch mt-2">
                                                    <input
                                                        type="checkbox"
                                                        id="square-switch-associated"
                                                        switch="none"
                                                        name="is_associated"
                                                        checked={this.state.is_associated}
                                                        onChange={() => this.setState({is_associated: !this.state.is_associated})}
                                                    />
                                                    <label
                                                        htmlFor="square-switch-associated"
                                                        data-on-label="On"
                                                        data-off-label="Off"
                                                    />
                                                </div>
                                            </Col>
                                            {this.state.is_associated === true &&
                                                <Col md={3}>
                                                    <label className="control-label">Associate Type</label>
                                                    <select name="associate_type" className={"form-control"}
                                                            onChange={event => {
                                                                this.handleAssociateType(event)
                                                            }}>
                                                        <option value="" selected={true} disabled={true}>Select a
                                                            option
                                                        </option>
                                                        <option value="guide">Guide</option>
                                                        <option value="blog">Blogs</option>
                                                        <option value="original">Original</option>
                                                        <option value="deal">Deal</option>
                                                    </select>
                                                </Col>
                                            }
                                            {this.state.is_associated === true && this.state.associate_type !== '' &&
                                                <Col md={5}>
                                                    <label className="control-label">Associate {this.state.associate_type}</label>
                                                    <select name="deal_id" value={this.state.deal_id} className={"form-control deeplink"} onChange={e => {
                                                        this.handleInput(e);
                                                        this.handleDeals(e)
                                                    }}>
                                                        <option value="" selected disabled>Select a option</option>
                                                        {this.state.associateMaster.map((val, key) => {
                                                            return <option key={key} data-link={val.deep_link}
                                                                           value={val.value}>{val.label}</option>
                                                        })}
                                                    </select>
                                                    {/*{this.validator.message('Deals', this.state.deal_id, 'required')}*/}
                                                </Col>
                                            }
                                            <Col md="3" className="mb-4">
                                                <label className="control-label">Creator/Contributor</label>
                                                <select name="user_id" className={"form-control deeplink"} onChange={ e => {this.handleInput(e)}}>
                                                    <option value="" selected disabled>Select a user</option>
                                                    {this.state.users.map((val,key) =>{
                                                        return <option key={key} value={val.id}>{val.name}</option>
                                                    })}
                                                </select>
                                            </Col>
                                            <Col md="auto" className="mb-4">
                                                <label className="control-label">Interest</label>
                                                <select name="Interest" className={"form-control deeplink"} onChange={ e => {this.handleInput(e);this.handleInterestTags(e)}}>
                                                    <option value="" selected disabled>Select an interest</option>
                                                    {interestslist.map((val,key) =>{
                                                        return <option key={key} data-link={val.deep_link} value={val._id}>{val.name}</option>
                                                    })}
                                                </select>
                                                {this.validator.message('Interest', this.state.Interest, 'required')}
                                            </Col>
                                            <Col md="6" className="mb-3">
                                                <label className="control-label">Tags</label>
                                                <DualListBox
                                                    options={tagslist}
                                                    selected={defaultTags}
                                                    onChange={this.handleTags}
                                                />
                                                {/* {this.validator.message('Tags', this.state.Tags, 'required')} */}
                                            </Col>
                                            {/*<Col md="auto" className="mb-3">*/}
                                            {/*    <Label htmlFor="">Approved</Label>*/}
                                            {/*    <div className="square-switch mt-2">*/}
                                            {/*        <input type="checkbox" id="square-switch-is_approved" switch="none" name="status" checked={this.state.is_approved} onChange={() => this.setState({is_approved: !this.state.is_approved})}/>*/}
                                            {/*        <label htmlFor="square-switch-is_approved" data-on-label="On" data-off-label="Off"/>*/}
                                            {/*    </div>*/}
                                            {/*</Col>*/}
                                            {/* <Col md="auto" className="mb-3">
                                                <Label htmlFor="">Is Trending</Label>
                                                <div className="square-switch mt-2">
                                                    <input type="checkbox" id="square-switch-is_trending" switch="none" name="status" checked={this.state.is_trending} onChange={() => this.setState({is_trending: !this.state.is_trending})}/>
                                                    <label htmlFor="square-switch-is_trending" data-on-label="Yes" data-off-label="No"/>
                                                </div>
                                            </Col> */}
                                            <Col md="3" className="mb-3">
                                                <Label htmlFor="title">Static likes</Label>
                                                <input name="_like_count" placeholder="Type Static Likes" type="number" className={"form-control"}
                                                    value={this.state._like_count}
                                                    onChange={this.handleInput} />
                                                {this.validator.message("title", this.state._like_count, "required|number")}
                                                </Col>
                                        </Row>

                                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                                            <Button color="primary" type="submit" disabled={submit}>
                                                {submit === true ? 'Please wait...' : 'Submit Data'}
                                            </Button>
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>)
    }
}

ReelAdd.propTypes = {
    location: PropTypes.object
}

export default ReelAdd
