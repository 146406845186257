import React, {Component, useState,useEffect} from "react";
import MetaTags from "react-meta-tags";
import {Button, Card, CardBody, CardTitle, Col, Container, FormGroup, Input, Label, Row,} from "reactstrap";
import {Formik, Field, Form, ErrorMessage} from "formik";
import PropTypes from 'prop-types';
import * as Yup from "yup";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from "axios";
import toastr from "toastr";
import {apiKey} from "../../helpers/url_helper";

let api_url = process.env.REACT_APP_API_URL;
let api_key = apiKey()
const userSession = JSON.parse(localStorage.getItem("authUser"));

class TagsEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            validation: "", InterestsSample: [], interests: '', edit_id: 0,status:true
        };
        this.handleInterest = this.handleInterest.bind(this);
    }

    componentDidMount() {
        axios.get(`${api_url}/get-interests`, {headers: {
                "Authorization": 'Bearer ' + api_key,
            }})
            .then(res => {
                if (res.status) {
                    let arr = []
                    for (var i = 0; i < res.data.data.length; i++) {
                        arr.push({label: res.data.data[i].name, value: res.data.data[i]._id});
                    }
                    this.setState({InterestsSample: arr})
                }

            })
    }

    handleInterest(event) {
        this.setState({interests: event.target.value});
    }

    render() {
        // eslint-disable-next-line react/prop-types
        const pageno = this.props.match.params.pId;
        const {history} = this.props;
        const {edit_id} = this.state;
        const {InterestsSample} = this.state;
        const {interests} = this.state;
        const {status} = this.state;
        const initialValues = {
            tag: "",
            interests: "",
            is_user_defined: "",
            ordering: "",
            status: "",
        }
        const Interests = InterestsSample.map((Interest, key) => (
            <option value={Interest.value} key={key} selected={interests === Interest.value}>{Interest.label}</option>)
        );

        const onSubmit = async (values, {setSubmitting, setErrors, setStatus, resetForm}) => {
            try {
                let formData = JSON.parse(JSON.stringify(values));
                var data = JSON.stringify({
                    "tagId": edit_id,
                    "name": formData['tag'],
                    "interest": formData['interests'],
                    "ordering": formData['ordering'],
                    "user_id": userSession.id,
                    "is_user_defined": formData['is_user_defined'],
                    "status": status
                });
                axios({
                    method: "post", url: `${api_url}/add-tag`, data: data, headers: {
                        'Content-Type': 'application/json',
                        "Authorization": 'Bearer ' + api_key,
                    }
                })
                    .then(function (response) {
                        toastr.success('Tag update successful.')
                        if (response.data.status) {
                            setSubmitting(false);
                            history.goBack(); // Use pageno in the history.push statement
                        }
                    })
                    .catch(function (response) {
                        console.log(response);
                        toastr.error(response)
                    });
                resetForm({})
                setStatus({success: true})
            } catch (error) {
                setStatus({success: false})
                setSubmitting(false)
                setErrors({submit: error.message})
            }
        }

        return (<React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>
                        Manage Tags | {process.env.REACT_APP_PROJECTNAME}
                    </title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title="Tags" path="/tags/pageno/1" breadcrumbItem="Edit Tags"/>
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <Formik
                                        enableReinitialize={true}
                                        initialValues={initialValues}
                                        validationSchema={Yup.object().shape({
                                            tag: Yup.string().required("Please Enter Tags Name"),
                                            interests: Yup.string().required("Please Select Interests"),
                                            ordering: Yup.string().required("Please Enter Ordering Number"),
                                        })}
                                        onSubmit={onSubmit}
                                    >
                                        {({errors, isSubmitting, touched, isValid, status, setFieldValue}) => {
                                            useEffect(() => {
                                                const id = this.props.match.params.id;
                                                this.setState({edit_id: id})
                                                const params = {
                                                    type: "tags",
                                                    id: id,
                                                };
                                                axios.get(`${api_url}/get-single-data`, {params,headers: {
                                                        "Authorization": 'Bearer ' + api_key,
                                                    }},)
                                                    .then(res => {
                                                        if (res.data.status) {
                                                            let edit = res.data.data;
                                                            this.setState({status: edit.status})
                                                            setFieldValue("tag", edit.name, true)
                                                            setFieldValue("interests", edit.interest, true)
                                                            setFieldValue("is_user_defined", edit.is_user_defined, true)
                                                            setFieldValue("ordering", edit.ordering, true)
                                                            setFieldValue("status", edit.status, true)
                                                        } else {
                                                            toastr.danger('Server not response')
                                                        }
                                                    })
                                            }, []);
                                        return (
                                            <Form className="needs-validation">
                                                <Row>
                                                    <Col md="3">
                                                        <FormGroup className="mb-3">
                                                            <Label htmlFor="tag">
                                                                Tags name
                                                            </Label>
                                                            <Field
                                                                name="tag"
                                                                placeholder="Tag name"
                                                                type="text"
                                                                className={"form-control" + (errors.tag && touched.tag ? " is-invalid" : "")}
                                                            />
                                                            <ErrorMessage
                                                                name="tag"
                                                                component="div"
                                                                className="invalid-feedback"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={3}>
                                                        <FormGroup className="mb-3">
                                                            <label className="label" htmlFor="interests">
                                                                Interests
                                                            </label>
                                                            <div className="control">
                                                                <Field name="interests" as="select"
                                                                       className={"form-control is-fullwidth" + (errors.interests && touched.interests ? " is-invalid" : "")}>
                                                                    <option value={""}>Select an option</option>
                                                                    {Interests}
                                                                </Field>
                                                                <ErrorMessage
                                                                    name="interests"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={3}>
                                                        <FormGroup className="mb-3">
                                                            <label className="label" htmlFor="is_user_defined">Defined by ( CT/ User)</label>
                                                            <div className="control">
                                                                <Field name="is_user_defined" as="select"
                                                                       className={"form-control is-fullwidth" + (errors.is_user_defined && touched.is_user_defined ? " is-invalid" : "")}>
                                                                    <option value={""}>Select an option</option>
                                                                    <option value="true">User</option>
                                                                    <option value="false">CT</option>
                                                                </Field>
                                                                <ErrorMessage
                                                                    name="is_user_defined"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="3">
                                                        <FormGroup className="mb-3">
                                                            <Label htmlFor="ordering">Ordering.</Label>
                                                            <Field
                                                                name="ordering"
                                                                type="number"
                                                                placeholder="Ordering"
                                                                className={"form-control" + (errors.ordering && touched.ordering ? " is-invalid" : "")}
                                                            />
                                                            <ErrorMessage
                                                                name="ordering"
                                                                component="div"
                                                                className="invalid-feedback"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="3">
                                                        <Label htmlFor="">Status</Label>
                                                        <div className="square-switch mt-2">
                                                            <input
                                                                type="checkbox"
                                                                id="square-switch1"
                                                                switch="none"
                                                                name="status"
                                                                value="true"
                                                                checked={this.state.status}
                                                                onChange={() => this.setState({status: !this.state.status})}
                                                            />
                                                            <label
                                                                htmlFor="square-switch1"
                                                                data-on-label="On"
                                                                data-off-label="Off"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <div className="d-flex flex-wrap gap-2 justify-content-end">
                                                    <Button color="primary" type="submit">
                                                        Submit Data
                                                    </Button>
                                                </div>
                                            </Form>
                                        )

                                        }}
                                    </Formik>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>);
    }
}

TagsEdit.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string
        })
    }),
    location: PropTypes.object
};
export default TagsEdit;
