import React, { useState } from "react";
import { useParams, useHistory, useLocation } from 'react-router-dom';

// eslint-disable-next-line react/prop-types
const Search = ({ onSearch,placeHolder }) => {
    const history = useHistory();
    const params = useParams();
    const location = useLocation();
    const { searchQuery } = useParams();
    const [search, setSearch] = useState(() => {
        if (searchQuery) {
            return searchQuery;
          } else {
            return '';
          }
      });
    const onInputChange = value => {
        setSearch(value);
        onSearch(value);
    };
    const resetSearch = ()=>{
        setSearch('')
        const { pathname } = location;

        // Get the URL query parameters as an object
        const hasSearchQuery = params.hasOwnProperty('searchQuery');
        if(hasSearchQuery){
            const segments = pathname.split('/');
            // Remove the last segment (parameter)
            const updatedPathname = segments.slice(0, -1).join('/');
            // console.log(updatedPathname);
            history.push(updatedPathname);
        }
        
    }
    
    return (
        <div className="responsive-search" style={{position:'relative'}}>
            {
                search===''?<></>:<i onClick={resetSearch} className="bx bx-x-circle font-size-16 align-middle me-2" style={{position: 'absolute',right: '0px',top:'50%',cursor: 'pointer',transform: 'translateY(-50%)'}}></i>
            }
        <input
            type="text"
            className="form-control"
            style={{ height: '100%',}}
            placeholder={placeHolder?placeHolder:'Search'}
            value={search}
            onChange={e => onInputChange(e.target.value)}
            />
        </div>
    );
};

export default Search;
