import React, {useEffect, useState} from "react"
import {Link} from "react-router-dom"
import PropTypes from "prop-types"
const NonStayTable = (props) => {
    const [data, setData] = useState([])
    const [currentPage, setCurrentPage] = useState("")
    const [PageSize, setPageSize] = useState("")
    useEffect(() => {
        setData(props.tableData)
        setCurrentPage(props.currentPage)
        setPageSize(props.PageSize)
    }, [props.tableData]);
    return (
        data.map((value, index) => (
            <tr key={++index}>
                <th scope="row">
                    {PageSize * (currentPage - 1) + (index + 1)}
                </th>
                <td>{value.name}</td>
                <td>
                    <div className="btn-group btn-group-sm" role="group">
                        {value.media.length > 0 &&
                            <button
                                className="btn-sm btn-rounded btn btn-primary"
                                onClick={() => {
                                    props.media(value.media,value._id);
                                }}>Media</button>}
                        <Link
                            className="btn-sm btn-rounded btn btn-secondary"
                            to={`/deals/variants/media/${value.deal}/${value._id}/${encodeURIComponent(value.name)}`}>Add
                            Media</Link>
                    </div>
                </td>
                <td>{value.price_meta.normal_price !== undefined && value.price_meta.normal_price}</td>
                <td>{value.inventory_meta.total !== undefined && value.inventory_meta.total}</td>
                {/* <td>{value.inventory_meta.total !== undefined && value.inventory_meta.sold}</td> */}
                <td>{value.status === true ? <span
                        className="badge badge-pill badge-soft-success font-size-13">Active</span> :
                    <span
                        className="badge badge-pill badge-soft-error font-size-13">Inactive</span>}</td>
                <td>
                    <Link to={{
                        pathname: `/deals/variants/non-stay/edit/${value._id}`,
                        state: value
                    }}><i
                        className="bx bxs-edit-alt"></i> </Link>
                </td>
            </tr>
        ))
    )
}
NonStayTable.propTypes = {
    mediaData: PropTypes.any
}
export default NonStayTable