import React from "react"
import MetaTags from "react-meta-tags"
import {Button, Card, CardBody, Col, Container, Label, Row,} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Select from "react-select"
import {apiKey} from "../../helpers/url_helper"
import SimpleReactValidator from 'simple-react-validator'
import axios from "axios"
import toastr from "toastr"
import "style.min.css"
import PropTypes from "prop-types"

let api_url = process.env.REACT_APP_API_URL
let api_key = apiKey()
const mystyle = {
    height: "110px",
    overflow: "hidden",
    width: "auto",
    objectFit:"cover"
}
class OriginalSeasonsEpisodeEdit extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            originalData: [],
            status: true,
            highlight_episode: true,
            episode_name: "",
            cover_image: "",
            vertical_image: "",
            old_cover_image: "",
            about: "",
            video_url: "",
            episode_no: "",
            original_season: "",
            ordering: "",
            handleResponse: null,
            submit: false
        }
        this.validator = new SimpleReactValidator({autoForceUpdate: this})
    }

    componentDidMount() {
        const headers = {'Content-Type': 'application/json', "Authorization": 'Bearer ' + api_key}

        fetch(`${api_url}/get-season?status=1`, {headers})
            .then(response => response.json())
            .then(data => this.setState({originalData: data.data}))

        const id = this.props.match.params.id
        this.setState({edit_id: id})
        const params = {
            type: "season_episodes",
            id: id
        }
        axios.get(`${api_url}/get-single-data`, {
            params, headers: {
                "Authorization": 'Bearer ' + api_key,
            }
        }).then(res => {
            if (res.data.status) {
                let edit = res.data.data
                this.setState({
                    episode_name: edit['episode_name'],
                    about: edit['about'],
                    old_cover_image: edit['cover_image'],
                    video_url: edit['video_url'],
                    episode_no: edit['episode_no'],
                    ordering: edit['ordering'],
                    status: edit['status'],
                    highlight_episode: edit['home_episode'],
                    original_season: edit['original_season'],
                })
            } else {
                toastr.danger('Server not response')
            }
        })
    }

    handleFileChange = (event) => {
        this.setState({
            cover_image: event.target.files[0]
        })
    }

    selectData = a => {
        let b = []
        for (var c = 0; c < a.length; c++) b.push({
            label: a[c].searson_name,
            original: a[c].original,
            value: a[c]._id
        })
        return b
    }
    handleInput = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }
    handleFormSubmit = (e) => {
        e.preventDefault()
        if (this.validator.allValid()) {
            const formData = new FormData()
            formData.append('episode_name', this.state.episode_name)
            var cover_image = document.getElementById("cover_image").files[0];
            if (cover_image){
                formData.append('cover_image', this.state.cover_image)
            }
            if (this.state.vertical_image){
                formData.append('vertical_image', this.state.vertical_image)
            }
            formData.append('video_url', this.state.video_url)
            formData.append('about', this.state.about)
            formData.append('episode_no', this.state.episode_no)
            formData.append('original_season', this.state.original_season)
            formData.append('status', this.state.status)
            formData.append('home_episode', this.state.highlight_episode)
            formData.append('ordering', this.state.ordering)
            formData.append('episodeId', this.state.edit_id)
            this.setState({submit: true})
            axios({
                method: "post", url: `${api_url}/update-episode`, data: formData, headers: {
                    'Content-Type': 'application/json',
                    "Authorization": 'Bearer ' + api_key,
                }
            }).then(response => {
                if (response.status) {
                    this.setState({submit: false})
                    toastr.success('Original season episode update successful.')
                    // eslint-disable-next-line react/prop-types
                    const {history} = this.props
                    // eslint-disable-next-line react/prop-types
                    const pageno = this.props.match.params.pId; // Get the pageno param from the URL
                    history.goBack();
                }
            }).catch(err => {
                toastr.error(err.message)
                this.setState({submit: false})
                alert(err.message)
            })
        } else {
            this.validator.showMessages()
            this.forceUpdate()
        }
    }

    render() {

        const {originalData, ordering,episode_no, submit, original_season} = this.state

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>
                            Manage Original Episode | {process.env.REACT_APP_PROJECTNAME}
                        </title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Original Episode" path="/episodes/pageno/1" breadcrumbItem="Edit Original Episode"/>
                        <Row>
                            <Col xl="8">
                                <Card>
                                    <CardBody>
                                        <form onSubmit={this.handleFormSubmit}>
                                            <Row>
                                                <Col md="2" className="mb-3">
                                                    <Label htmlFor="episode_no">Episode No.</Label>
                                                    <input name="episode_no" type="text" placeholder="Episode No." className={"form-control"} onChange={this.handleInput} defaultValue={episode_no}/>
                                                    {this.validator.message('episode_no', this.state.episode_no, 'required')}
                                                </Col>
                                                <Col md="6" className="mb-3">
                                                    <Label htmlFor="episode_name">Episode Name</Label>
                                                    <input name="episode_name" placeholder="Type Episode Name" defaultValue={this.state.episode_name} type="text" className={"form-control"}
                                                           onChange={this.handleInput}/>
                                                    {this.validator.message('episode_name', this.state.episode_name, 'required|string')}
                                                </Col>
                                                <Col md="4" className="mb-3">
                                                    <label className="control-label">Seasons</label>
                                                    <select name="original_season" className={"form-select"} defaultValue={original_season} onChange={(e) => this.setState({original_season: e.target.value})}>
                                                        <option>Select a season</option>
                                                        {this.selectData(originalData).map((opt, key) => {
                                                            let q = original_season === opt.value ? 'selected' :''
                                                            return <option key={key} value={opt.value} selected={q}>{(opt.original[0])?opt.original[0].name:''} - {opt.label}</option>;
                                                        })}
                                                    </select>
                                                    {this.validator.message('original_season', this.state.original_season, 'required')}
                                                </Col>
                                                <Col md="12" className="mb-3">
                                                    <Label htmlFor="about">About</Label>
                                                    <textarea className="form-control" name="about" defaultValue={this.state.about} id="textarea" rows="5" placeholder="Enter about" onChange={this.handleInput}></textarea>
                                                    {/*{this.validator.message('about', this.state.about, 'required')}*/}
                                                </Col>
                                                <Col md="6" className="mb-3">
                                                    <Label htmlFor="cover_image">
                                                        Cover Image
                                                    </Label>
                                                    <input
                                                        id="cover_image"
                                                        name="cover_image"
                                                        placeholder="Cover Image"
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={this.handleFileChange}
                                                        className={"form-control"}
                                                    />
                                                    {/*{this.validator.message('cover_image', this.state.cover_image, 'required')}*/}
                                                </Col>
                                                <Col md="4" className="mb-3">
                                                    <Label htmlFor="vertical_image">Vertical Image</Label>
                                                    <input id="vertical_image" name="vertical_image" placeholder="Cover Image" type="file" accept="image/*" onChange={event => {this.setState({vertical_image: event.target.files[0]})}} className={"form-control"}
                                                    />
                                                    {/*{this.validator.message('vertical_image', this.state.vertical_image, 'required')}*/}
                                                </Col>
                                                <Col md="6" className="mb-3">
                                                    <Label htmlFor="trailer_url">Video URL</Label>
                                                    <input name="video_url" placeholder="Type Video URL" defaultValue={this.state.video_url}  type="url" className={"form-control"}
                                                           onChange={this.handleInput}/>
                                                    {this.validator.message('video_url', this.state.video_url, 'required')}
                                                </Col>
                                                <Col md="3" className="mb-3">
                                                    <Label htmlFor="validationCustom03">Order No.</Label>
                                                    <input
                                                        name="ordering"
                                                        type="number"
                                                        placeholder="Order No."
                                                        className={"form-control"}
                                                        onChange={this.handleInput}
                                                        defaultValue={ordering}
                                                    />
                                                    {this.validator.message('ordering', this.state.ordering, 'required|numeric')}
                                                </Col>
                                                {/* <Col md="auto" className="mb-3">
                                                    <Label htmlFor="">Season highlight episode</Label>
                                                    <div className="square-switch mt-2">
                                                        <input type="checkbox" id="square-switch-highlight_episode" switch="none" name="highlight_episode" checked={this.state.highlight_episode} onChange={() => this.setState({highlight_episode: !this.state.highlight_episode})}
                                                        />
                                                        <label htmlFor="square-switch-highlight_episode" data-on-label="Yes" data-off-label="No"/>
                                                    </div>
                                                </Col> */}
                                                <Col md="2" className="mb-3">
                                                    <Label htmlFor="">Status</Label>
                                                    <div className="square-switch mt-2">
                                                        <input
                                                            type="checkbox"
                                                            id="square-switch-status"
                                                            switch="none"
                                                            name="status"
                                                            checked={this.state.status}
                                                            onChange={() =>
                                                                this.setState({status: !this.state.status})
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="square-switch-status"
                                                            data-on-label="On"
                                                            data-off-label="Off"
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>

                                            <div className="d-flex flex-wrap gap-2 justify-content-end">
                                                <Button color="primary" type="submit" disabled={submit}>
                                                    {submit === true ? 'Please wait...' : 'Submit Data'}
                                                </Button>
                                            </div>
                                        </form>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xl="4">
                                <Card>
                                    <CardBody>
                                        <h6>Old Cover Image</h6>
                                        {this.state.old_cover_image &&
                                            <img src={this.state.old_cover_image} className="img-fluid" style={mystyle}
                                                 alt="cover image"/>
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

OriginalSeasonsEpisodeEdit.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string
        })
    }),
    location: PropTypes.object
}

export default OriginalSeasonsEpisodeEdit
