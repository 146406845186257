import React, { Component } from "react";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import TagsEdit from "../../pages/Tags/edit";

function notification(msg,type,position) {
    console.log(msg,type,position)
    toastr.options = {
        positionClass: position,
    }
    if (type == 'success'){
        toastr.success(msg)
    }
    if (type == 'error'){
        toastr.error(msg)
    }
    if (type == 'warning'){
        toastr.warning(msg)
    }
}

export default notification;