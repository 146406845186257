import React from "react"
import {Card, CardBody, Modal, Col, Row, Label, Button, Container, Table} from "reactstrap"
import MetaTags from 'react-meta-tags'
import Breadcrumbs from "../../components/Common/Breadcrumb"
import PropTypes from "prop-types"
import {apiKey} from "../../helpers/url_helper"
import axios from "axios"
import Moment from "moment/moment"
import {upperCase} from "lodash"
import toastr from "toastr"

let api_url = process.env.REACT_APP_API_URL
let api_key = apiKey()
const headers = {'Content-Type': 'application/json', "Authorization": 'Bearer ' + api_key}

class OrderDetail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            order_data: [],
            user_detail: [],
            cancelReason: [],
            manageOrder: false,
            data_loaded: false,
            submit: false,
            sub_total: 0,
            ReasonId: "",
            remark: "",
            OrderId: "",
            error_msg: "",
        }

    }

    componentDidMount() {
        const id = this.props.match.params.id
        this.setState({edit_id: id})
        const params = {
            type: "user_orders",
            id: id
        }
        axios.get(`${api_url}/get-single-data`, {
            params, headers: headers
        }).then(res => {
            if (res.status && res.data.data[0] !== undefined) {
                this.setState({
                    order_data: res.data.data[0],
                    data_loaded: true,
                    user_id: res.data.data[0].user_id
                },() => (this.get_user()))
                this.HandleSubTotal()
            }
        })
        this.get_reasons()
    }

    get_reasons = () => {
        fetch(`${api_url}/get-order-cancel-reason?pageNo=1&limit=100&status=1`, { headers })
            .then(response => response.json())
            .then(data => this.setState({cancelReason:data.data}));
    }

    get_user = () => {
        let params = {user_id: this.state.user_id}
        axios.get(`${api_url}/get-user`, {
            params, headers: headers
        }).then(res => {
            if (res.status) {
                this.setState({
                    user_detail: res.data.data
                })
            }else{
                toastr.error(res.message)
            }
        }).catch(err => {
            toastr.error('User not found!')
            this.setState({submit: false})
        })
    }

    FormSubmit = (e) => {
        e.preventDefault()
        if (!this.state.ReasonId) {
            this.setState({error_msg: "Select Cancellation Reason"})
            return false
        }
        let formData = {
            "order_cancel_id": this.state.ReasonId,
            "remark": this.state.remark,
            "orderId": this.state.OrderId
        }
        this.setState({submit: true})
        axios({
            method: "post", url: `${api_url}/update-order_status`, data: formData, headers: headers
        }).then(response => {
            if (response.status) {
                this.setState({submit: false})
                toastr.success("Order has been canceled")
                this.setState({manageOrder: false})
                window.location.reload()
            }
        }).catch(err => {
            toastr.error(err.message)
            this.setState({submit: false})
        })
    }

    handleAction = (id) => {
        this.setState({manageOrder:true,OrderId:id})
    }

    Status = (status) => {
        if (status === 'Upcoming'){
            return (<span className="badge bg-info font-size-16">Upcoming</span>)
        }else if (status === 'Completed'){
            return (<span className="badge bg-success font-size-16">Completed</span>)
        }else{
            return (<span className="badge bg-warning font-size-16">{status}</span>)
        }
    }

    HandleSubTotal = () => {
        var subtotal = 0
        this.state.order_data?.order_details.map((v,k) => {
            subtotal += v?.meta?.price !== undefined ? v?.meta?.price : 0
        })
        this.setState({sub_total:subtotal})
    }

    render() {
        const {order_data, user_detail, cancelReason, manageOrder, submit, sub_total, error_msg} = this.state
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>
                            Order Detail | {process.env.REACT_APP_PROJECTNAME}
                        </title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Manage Orders" path="/orders/pageno/1" breadcrumbItem="Order Detail"/>
                        <Card>
                            <CardBody>
                                {this.state.data_loaded === true && <Row>
                                    <Col lg="12">
                                        <Card>
                                            <CardBody>
                                                <div className="invoice-title">
                                                    <h4 className="font-size-16">
                                                        Order
                                                        # {order_data?.order_id} {order_data?.order_id === undefined && 'Not Available'}
                                                        <span className="float-end font-size-16 text-info fw-bolder">{order_data?.status}</span>
                                                    </h4>
                                                </div>
                                                <hr/>
                                                <Row>
                                                    <Col sm="6">
                                                        <address>
                                                            <h6 className="fw-bolder mb-2">Booked By:</h6>
                                                            <br/>
                                                            {user_detail !== "" &&
                                                                <>
                                                                    <p className="mb-1">User Id: {user_detail.id}</p>
                                                                    <p className="mb-1">{user_detail.name}</p>
                                                                    <p className="mb-1">{user_detail.email}</p>
                                                                    <p className="mb-1">{user_detail.mobile}</p>
                                                                </>
                                                            }
                                                        </address>
                                                    </Col>
                                                    <Col sm="6" className="text-sm-end">
                                                        <address className="mt-2 mt-sm-0">
                                                            <h6 className="fw-bolder mb-2">Booked For:</h6>
                                                            <br/>
                                                            {order_data.is_other == true ?
                                                            <>
                                                            <p className="mb-1">{order_data.other_name}</p>
                                                            <p className="mb-1">{order_data.other_email}</p>
                                                            <p className="mb-1">{order_data.other_phone}</p>
                                                            </>
                                                            :
                                                            <>
                                                            <p className="mb-1">{order_data.name}</p>
                                                            <p className="mb-1">{order_data.email}</p>
                                                            <p className="mb-1">{order_data.phone}</p>
                                                            </>
                                                            }
                                                        </address>
                                                    </Col>
                                                </Row>
                                                <div className="py-2 mt-3">
                                                    {order_data?.deal_details.map((val, key) => {
                                                        return (
                                                            <div key={key}>
                                                                <Table className="table-bordered">
                                                                    <thead className="table-light">
                                                                    <tr>
                                                                        <th colSpan={2}>
                                                                            <h3 className="font-size-15 font-weight-bold mb-0">
                                                                                Deal details:
                                                                            </h3>
                                                                        </th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    <tr>
                                                                        <th>Deal</th>
                                                                        <td>{val?.name}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Type</th>
                                                                        <td>{upperCase(val?.type)}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Category</th>
                                                                        <td>
                                                                            {(val.deal_category_details !== undefined && typeof val.deal_category_details !== "string") ? val.deal_category_details.map((item, k) => {
                                                                                return (
                                                                                    <span key={item._id} className="badge font-size-14 bg-primary me-2">{item.name}</span>
                                                                                )
                                                                            }) : '-'}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>City</th>
                                                                        <td>{val.location_full_address}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Partner</th>
                                                                        <td>
                                                                            {(val.partner !== undefined && typeof val.Authorizationpartner !== "string") ? val.partner.map((item, k) => {
                                                                                return(
                                                                                    <span key={item._id} className="badge font-size-14 bg-primary me-2">{item.name} </span>
                                                                                )
                                                                            }) : '-'}
                                                                        </td>
                                                                    </tr>
                                                                    { val.type === "stay" &&
                                                                        <>
                                                                            <tr>
                                                                                <th>Start Date</th>
                                                                                <td>{Moment(order_data?.order_details[0]?.meta?.start_date).format('DD/MM/YYYY')}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>End Date</th>
                                                                                <td>{Moment(order_data?.order_details[0]?.meta?.end_date).format('DD/MM/YYYY')}</td>
                                                                            </tr>
                                                                        </>
                                                                    }
                                                                    { val.type !== "stay" &&
                                                                        <tr>
                                                                            <th>Validity</th>
                                                                            <td>{(order_data?.last_date !== undefined && order_data?.last_date !== null) && Moment(order_data?.last_date).format('DD/MM/YYYY')}</td>
                                                                        </tr>
                                                                    }
                                                                    <tr>
                                                                        <th>Coupon name</th>
                                                                        <td>{order_data.coupon == null ? 'No coupon code' : order_data?.coupon_details[0]?.coupon_code}</td>
                                                                    </tr>
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        )
                                                    })}

                                                </div>
                                                <div className="table-responsive">
                                                    <Table className="table-nowrap">
                                                        <thead className="table-light">
                                                        <tr>
                                                            <th colSpan={3}>
                                                                <h3 className="font-size-15 font-weight-bold mb-0">
                                                                    Booking details
                                                                </h3>
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td>Variant</td>
                                                            <td>Qty</td>
                                                            <td>Amount</td>
                                                        </tr>
                                                        {order_data?.order_details.map((value,k) => {
                                                            return(
                                                                value?.deal_variant.map((val, key) => {
                                                                    return (
                                                                        <tr key={key}>
                                                                            <td>{val.name}</td>
                                                                            <td>{value?.qty}</td>
                                                                            <td>{value?.meta?.price}</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            )
                                                        })}

                                                        </tbody>
                                                    </Table>
                                                </div>
                                                <div className="py-2 text-end mt-3">
                                                    <h3 className="font-size-15 font-weight-bold">
                                                        Billing Details
                                                    </h3>
                                                </div>
                                                <div className="table-responsive">
                                                    <Table className="table-nowrap">
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan="2" className="border-0 text-end">
                                                                    <strong>Transaction id:</strong>
                                                                </td>
                                                                <td className="border-0 text-end">
                                                                    {order_data?.transaction_id !== undefined && order_data.transaction_id}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan="2" className="border-0 text-end">
                                                                    <strong>Subtotal</strong>
                                                                </td>
                                                                <td className="border-0 text-end">
                                                                    {sub_total}
                                                                </td>
                                                            </tr>
                                                            {order_data.discount !== 0 &&
                                                                <tr>
                                                                    <td colSpan={2} className="text-end">
                                                                        <b>Discount ({order_data.coupon == null ? 'No coupon code' : order_data?.coupon_details[0]?.coupon_code}):</b>
                                                                    </td>
                                                                    <td className="text-end">
                                                                        {order_data?.discount}
                                                                    </td>
                                                                </tr>
                                                            }
                                                            <tr>
                                                                <td colSpan="2" className="border-0 text-end">
                                                                    <strong>Total</strong>
                                                                </td>
                                                                <td className="border-0 text-end">
                                                                    <h4 className="m-0">
                                                                        {order_data?.totalAmount}
                                                                    </h4>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </div>
                                                <div className="d-print-none">
                                                    {(order_data?.status === "Upcoming" || order_data?.status === "Pending") &&
                                                        <div className="float-end">
                                                            <button className="btn btn-primary w-md" onClick={() => {this.handleAction(order_data._id)}}>
                                                                Cancel Order
                                                            </button>
                                                        </div>
                                                    }
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                }
                            </CardBody>
                        </Card>
                    </Container>
                </div>
                <Modal
                    isOpen={manageOrder}
                    centered
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">Cancel Order</h5>
                        <button
                            type="button"
                            onClick={() => {
                                this.setState({manageOrder: !manageOrder, OrderId: ""})
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={this.FormSubmit}>
                            <Row>
                                <Col md="12" className="mb-3">
                                    <Label htmlFor="remark">Remark</Label>
                                    <textarea name="remark" placeholder="Type Remark" onInput={event => {
                                        this.setState({remark: event.target.value})
                                    }} className={"form-control"}></textarea>
                                </Col>
                                <Col md={12} className="mb-3">
                                    <Label htmlFor="reason">Cancellation Reason</Label>
                                    <select name="reason" className={"form-control"} onChange={event => {
                                        this.setState({ReasonId: event.target.value})
                                    }}>
                                        <option value="" disabled selected={true}>Select a reason</option>
                                        {cancelReason.map((value, key) => {
                                            return (<option key={key} value={value._id}>{value.name}</option>)
                                        })}
                                    </select>
                                </Col>
                            </Row>
                            {error_msg !== '' && <p className="text-danger">{error_msg}</p>}
                            <div className="d-grid">
                                <Button color="primary" className="btn-block" type="submit" disabled={submit}>
                                    {submit === true ? "Please wait..." : "Submit Data"}
                                </Button>
                            </div>
                        </form>
                    </div>
                </Modal>
            </React.Fragment>
        )
    }
}

OrderDetail.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string
        })
    }),
    location: PropTypes.object
}

export default OrderDetail
