import React, {useEffect, useMemo, useState} from "react";
import { useParams, useHistory, useLocation } from 'react-router-dom';
import {Card, CardBody, CardTitle, Col, Row} from "reactstrap";
import MetaTags from "react-meta-tags";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {TableHeader, Pagination, Search} from "../Datatable/index";
import {apiKey} from "helpers/url_helper"

import axios from "axios";
import {Link} from "react-router-dom";
import notification from "components/Common/Notification";

const obj = JSON.parse(localStorage.getItem("authUser"));
let api_url = process.env.REACT_APP_API_URL;
let api_key = apiKey()
const PageSize = 15;

const useFullPageLoader = () => {
    const [loading, setLoading] = useState(false);

    return [
        loading ? "Loading" : null,
        () => setLoading(true), //Show loader
        () => setLoading(false) //Hide Loader
    ];
};

const Countries = (props) => {
    const history = useHistory();
    const location = useLocation();
    const { pId:pageno } = useParams();
    const [tableData, settableData] = useState([]);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(() => {
        const paramPage = parseInt(pageno);
        if (!isNaN(paramPage)) {
            return paramPage;
          } else {
            return 1;
          }
      });
      const { searchQuery } = useParams();
      const [search, setSearch] = useState(() => {
          if (searchQuery) {
              return searchQuery;
            } else {
              return '';
            }
        });
      const [getSearch, setGetSearch] = useState(search);
      const [getSearchPage, setGetSearchPage] = useState(currentPage)
      const [sorting, setSorting] = useState({field: "", order: ""});
    const CountryHeader = [
        {name: "#", field: "id", sortable: false},
        {name: "Country Name", field: "name", sortable: true},
        {name: "Country Image", field: "image", sortable: true},
        {name: "Status", field: "status", sortable: true},
        // { name: "Action", field: "action", sortable: true }
    ];
    const handleChange = (value) => {
        history.push(`/countries/pageno/${value}/${search}`)
    };
    // Handle any changes to the currentPage state
    const handlePageChange = () => {
        setCurrentPage(() => {
            const paramPage = parseInt(pageno);
            if (!isNaN(paramPage)) {
                return paramPage;
            } else {
                return 1;
            }
        })
    };
    function handleSearch(){
        setSearch(getSearch);
        setCurrentPage(getSearchPage);
        if(getSearch){
            history.push(`/countries/pageno/${getSearchPage}/${getSearch}`)
        }
    }useEffect(() => {
        handlePageChange();
        // console.log('currenpage',currentPage)
    }, [pageno,location]);

    useEffect(() => {
        let query = "";
        search && (query = `keyword=${search}&`);
        const url = `${api_url}/get-country?${query}pageNo=${currentPage}`;
        axios.get(url, {
            headers: {
                "Authorization": 'Bearer ' + api_key,
            }
        })
            .then(res => {
                hideLoader();
                settableData(res.data.data);
                setCurrentPage(res.data.pageNo?res.data.pageNo:1);
                setTotalItems(res.data.total_results);
            }).catch(
                err => {
                    notification(err, 'error', 'toast-bottom-right')
                    if(err.response.status===401 || err.response.status===400 ){
                        history.push('/logout')
                    }
                }
            );
    }, [search, currentPage])

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Manage Countries</title>
                </MetaTags>
                <div className="container-fluid">
                    <Breadcrumbs title="Dashboard" path="/" breadcrumbItem="Countries"/>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>

                                    <div className="row">
                                        <div className="col-md-6">

                                        </div>
                                        <div className="col-md-6 d-flex flex-row-reverse">
                                        <div onClick={handleSearch}
                                               className="btn btn-primary waves-effect waves-light ms-2"> Search</div>
                                            <Search
                                                onSearch={value => {
                                                    setGetSearch(value)
                                                    setGetSearchPage(1)
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <table className="table table-striped">
                                        <TableHeader
                                            headers={CountryHeader}
                                            onSorting={(field, order) =>
                                                setSorting({field, order})
                                            }
                                        />

                                        <tbody className="">{loader && <tr><th colSpan={CountryHeader.length}><h4 className="text-center">Loading...</h4></th></tr>}
                                        {(!loader && tableData.length === 0) && <tr><th colSpan={CountryHeader.length}><h4 className="text-center">No data found</h4></th></tr>}
                                        {tableData.map((value, index) => (
                                            <tr key={++index}>
                                                <th scope="row">
                                                {PageSize * (currentPage - 1) + (index + 1)}
                                                </th>
                                                <td>{value.name}</td>
                                                <td>{value.image !== "" ?
                                                    <img src={value.image} className="img-thumbnail avatar-md"
                                                         width="200" alt={value.name}/> : '-'}</td>
                                                <td>{value.status === true ? <span
                                                        className="badge badge-pill badge-soft-success font-size-13">Active</span> :
                                                    <span
                                                        className="badge badge-pill badge-soft-error font-size-13">Inactive</span>}</td>
                                                {/*<td><Link to={`/countries/${currentPage}/edit/${value._id}`}><i className="bx bxs-edit-alt"></i> </Link></td>*/}
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                    <Pagination
                                        total={totalItems}
                                        itemsPerPage={20}
                                        currentPage={currentPage}
                                        onPageChange={page => handleChange(page)}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Countries;
