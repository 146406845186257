import React from "react"
import MetaTags from "react-meta-tags"
import {Button, Card, CardBody, CardTitle, Col, Container, InputGroup, Label, Row,} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import {apiKey} from "../../../../helpers/url_helper"
import SimpleReactValidator from 'simple-react-validator'
import axios from "axios"
import toastr from "toastr"
import PropTypes from "prop-types"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import SpecialDate from "./Special_date"
import Moment from "moment"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import {CKEditor} from "@ckeditor/ckeditor5-react"

let api_url = process.env.REACT_APP_API_URL
let api_key = apiKey()
function formatDate(date){
    return Moment(date).format('YYYY-MM-DD')
}
class VariantStayAdd extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            status: true,
            variant_name: "",
            weekday_price: "",
            weekend_price: "",
            ordering: "",
            special_price: [],
            special_dates: [],
            black_out_dates: [],
            variant_inclusion: "",
            no_of_rooms_available: "",
            handleResponse: null,
            submit: false,
            Special_date: [],
            specific: [],
            Special_date_total: 0,
        }
        this.validator = new SimpleReactValidator({autoForceUpdate: this})
    }

    componentDidMount() {
        this.addSpecialDate(0)
    }

    addSpecialDate = (e) => {
        this.setState({Special_date_total:e})
        this.setState({
            // eslint-disable-next-line react/jsx-key
            Special_date: [...this.state.Special_date, <SpecialDate idx={e} key={e} onChange={this.handleSpecialDateData}/>]
        })
    }
    handleInput = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }
    handleBlackOutDate = (e) => {
        let black_out_dates = [];
        for (let i = 0; i < e.length; i++){
            black_out_dates.push(formatDate(e[i]))
        }
        this.setState({black_out_dates})
    }
    handleSpecialDateData = (e) => {
        let special_dates = document.getElementsByName('special_dates[]');
        let special_price = document.getElementsByName('special_price[]');
        let dates = [];let prices = [];
        for (let a = 0; a <special_dates.length; a++) {
            if (special_dates[a].value !== ''){
                let x = special_dates[a].value.replace(/ /g,'').split(',')
                dates.push(x)
            }
        }
        for (let a = 0; a <special_price.length; a++) {
            prices.push(parseInt(special_price[a].value))
        }
        this.setState({special_dates:dates,special_price:prices},this.convertData)
    }

    convertData = () => {
        let special_price = this.state.special_price,special_dates = this.state.special_dates
        let specific = [];
        for(let i=0; i<special_dates.length; i++) {
            specific.push({
                "price":parseInt(special_price[i]),
                "dates":special_dates[i]
            });
        }
        this.setState({specific})
    }


    handleFormSubmit = (e) => {
        e.preventDefault()
        if (this.validator.allValid()) {
            let price_meta = {
                "normal_price":this.state.weekday_price !== "" ? parseInt(this.state.weekday_price):"",
                "weekday_price": this.state.weekday_price !== "" ? parseInt(this.state.weekday_price):"",
                "weekend_price":this.state.weekend_price !== "" ? parseInt(this.state.weekend_price):"",
                "specific":this.state.specific
            }, inventory_meta = {
                "total":this.state.no_of_rooms_available
            }
            const formData = JSON.stringify({
                "name": this.state.variant_name,
                "about": this.state.variant_inclusion,
                "ordering": this.state.ordering,
                "status": this.state.status,
                "price_meta": price_meta,
                "black_out_dates": this.state.black_out_dates,
                "inventory_meta": inventory_meta,
                "deal": this.props.match.params.id,
                "deal_type": "stay"
            })
            this.setState({submit: true})
            axios({
                method: "post", url: `${api_url}/add-deal-variant`, data: formData, headers: {
                    'Content-Type': 'application/json',
                    "Authorization": 'Bearer ' + api_key,
                }
            }).then(response => {
                if (response.status) {
                    this.setState({submit: false})
                    toastr.success('Deal Variant added successful.')
                    // eslint-disable-next-line react/prop-types
                    const {history} = this.props
                    // eslint-disable-next-line react/prop-types
                    history.push(`/deals/variants/${this.props.match.params.id}`)
                }
            }).catch(err => {
                toastr.error(err.message)
                this.setState({submit: false})
                alert(err.message)
            })
        } else {
            this.validator.showMessages()
            this.forceUpdate()
        }
    }

    render() {
        const {submit} = this.state
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>
                            Add Variant | {process.env.REACT_APP_PROJECTNAME}
                        </title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Variants" path={`/deals/variants/${this.props.match.params.id}`}
                                     breadcrumbItem="Add Variant"/>
                        <form onSubmit={this.handleFormSubmit}>
                            <Row>
                                <Col xl="7">
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col md="6" className="mb-3">
                                                    <Label htmlFor="variant_name">Variant Name</Label>
                                                    <input name="variant_name" placeholder="Type Variant Name"
                                                           type="text" className={"form-control"}
                                                           onChange={this.handleInput}/>
                                                    {this.validator.message('variant_name', this.state.variant_name, 'required')}
                                                </Col>
                                                <Col md="3" className="mb-3">
                                                    <Label htmlFor="weekday_price">Weekday Price</Label>
                                                    <input name="weekday_price" type="text" placeholder="Weekday Price"
                                                           className={"form-control"} onChange={this.handleInput}/>
                                                    {this.validator.message('weekday_price', this.state.weekday_price, 'required')}
                                                </Col>
                                                <Col md="3" className="mb-3">
                                                    <Label htmlFor="weekend_price">Weekend Price</Label>
                                                    <input name="weekend_price" type="text" placeholder="Weekday Price"
                                                           className={"form-control"} onChange={this.handleInput}/>
                                                    {this.validator.message('weekend_price', this.state.weekend_price, 'required')}
                                                </Col>
                                                <Col md="5" className="mb-3">
                                                    <Label htmlFor="black_out_dates">Black Out Dates</Label>
                                                    <InputGroup>
                                                        <Flatpickr
                                                            name="black_out_dates"
                                                            className="form-control d-block"
                                                            placeholder="Black Out Dates"
                                                            options={{
                                                                enableTime: false,
                                                                mode: "multiple",
                                                                minDate: "today",
                                                                dateFormat: "Y-m-d"
                                                            }}
                                                            onChange={e => {
                                                                this.handleBlackOutDate(e)
                                                            }}
                                                        />
                                                        <div className="input-group-append">
                                                          <span className="input-group-text">
                                                            <i className="mdi mdi-clock-outline"/>
                                                          </span>
                                                        </div>
                                                    </InputGroup>
                                                    {/*{this.validator.message('black_out_dates', this.state.black_out_dates, 'required')}*/}
                                                </Col>
                                                <Col md="auto" className="mb-3">
                                                    <Label htmlFor="no_of_rooms_available">No. of Rooms available (per
                                                        day)</Label>
                                                    <input name="no_of_rooms_available" type="number"
                                                           placeholder="No. of Rooms available"
                                                           className={"form-control"} onChange={this.handleInput}/>
                                                    {this.validator.message('no_of_rooms_available', this.state.no_of_rooms_available, 'required|numeric')}
                                                </Col>
                                                <Col md="auto" className="mb-3">
                                                    <Label htmlFor="validationCustom03">Order No.</Label>
                                                    <input
                                                        name="ordering"
                                                        type="number"
                                                        placeholder="Order No."
                                                        className={"form-control"}
                                                        onChange={this.handleInput}
                                                    />
                                                    {this.validator.message("ordering", this.state.ordering, "required|numeric")}
                                                </Col>
                                                <Col md="auto" className="mb-3">
                                                    <Label htmlFor="">Status</Label>
                                                    <div className="square-switch mt-2">
                                                        <input
                                                            type="checkbox"
                                                            id="square-switch-status"
                                                            switch="none"
                                                            name="status"
                                                            checked={this.state.status}
                                                            onChange={() =>
                                                                this.setState({status: !this.state.status})
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="square-switch-status"
                                                            data-on-label="On"
                                                            data-off-label="Off"
                                                        />
                                                    </div>
                                                </Col>
                                                {this.state.black_out_dates.length !== 0 &&
                                                    <Col md={12}>
                                                        <hr/>
                                                        <h6>Black Out Date</h6>
                                                        { this.state.black_out_dates.map((val,key) => {
                                                            return (
                                                                <Button type="button" outline color="secondary" className="me-2 btn-sm mb-2" key={key}>{val}</Button>
                                                            )
                                                        })}
                                                        <hr/>
                                                    </Col>
                                                }
                                                <Col md="12" className="mb-3">
                                                    <Label htmlFor="variant_inclusion">Variant inclusion</Label>
                                                    <CKEditor
                                                        editor={ ClassicEditor }
                                                        data={this.state.variant_inclusion}
                                                        onChange={ ( event, editor ) => {
                                                            const data = editor.getData();
                                                            this.setState({variant_inclusion:data})
                                                        } }
                                                        onReady={(editor) => {
                                                            editor.editing.view.change((writer) => {
                                                                writer.setStyle(
                                                                    "min-height",
                                                                    "200px",
                                                                    editor.editing.view.document.getRoot()
                                                                );
                                                            });
                                                        }}
                                                    />
                                                    {/*{this.validator.message('variant_inclusion', this.state.variant_inclusion, 'required')}*/}
                                                </Col>
                                            </Row>
                                            <div className="d-flex flex-wrap gap-2 justify-content-end">
                                                <Button color="primary" type="submit" disabled={submit}>
                                                    {submit === true ? 'Please wait...' : 'Submit Data'}
                                                </Button>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col xl="5">
                                    <Card>
                                        <CardBody>
                                            <div className="d-flex justify-content-end">
                                                <a className="btn btn-rounded btn-sm btn-primary"
                                                   onClick={e => {this.addSpecialDate(this.state.Special_date_total+1)}}><i className="bx bx-plus"></i></a>
                                            </div>
                                            {this.state.Special_date}
                                            {this.state.special_price.length !== 0 &&
                                                this.state.special_price.map((val,key) => {
                                                        return (
                                                            <div key={key}>
                                                                <hr/>
                                                                <h6>Special Price #{val !== '' && val || key+1}</h6>
                                                                { this.state.special_dates[key] !== undefined && this.state.special_dates[key].map((val,key) => {
                                                                    return (
                                                                        <Button type="button" outline color="secondary" className="me-2 mb-2 btn-sm" key={key}>{val}</Button>
                                                                    )
                                                                })}
                                                            </div>
                                                        )
                                                    })
                                            }
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </form>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

VariantStayAdd.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string
        })
    }),
    location: PropTypes.object
}

export default VariantStayAdd
