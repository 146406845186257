import PropTypes from "prop-types";
import React, { Component } from "react";
import { Alert, Button, Card, CardBody, Col, Container, Row, Label } from "reactstrap";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SimpleReactValidator from 'simple-react-validator'

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/avatar-1.jpg";
// actions
import { editProfile,editPassword, resetProfileFlag } from "../../store/actions";
import {apiKey} from "../../helpers/url_helper"

let api_url = process.env.REACT_APP_API_URL;
let api_key = apiKey()
import axios from "axios";
import toastr from "toastr";

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = { email: "", name: "", idx: 1,mobile:"",password:"",confirm_password:"",photo:"",profile_photo:"",submit:false };
    this.validator = new SimpleReactValidator({autoForceUpdate: this})
  }

  handleInput = (e) => {
    this.setState({[e.target.name]: e.target.value})
  }
  handleFileChange = (event) => {
    this.setState({
      profile_photo: event.target.files[0]
    });
  };

  componentDidMount() {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        this.setState({ name: obj.username, email: obj.email,mobile: obj.mobile, idx: obj.id,photo:obj.photo });
      }
    }
  }
  handleFormSubmit = (e) => {
    e.preventDefault()
    if (this.validator.allValid()) {
      const formData = new FormData()
      var photo = document.getElementById("photo").files[0];
      if (photo){
        formData.append('photo', this.state.profile_photo)
      }
      formData.append('name', this.state.name)
      formData.append('mobile', this.state.mobile)
      formData.append('id', this.state.idx)
      this.setState({submit: true})
      axios({
        method: "post", url: `${api_url}/update-user`, data: formData, headers: {
          "Authorization": 'Bearer ' + api_key,
        }
      }).then(response => {
        if (response.data.status) {
          toastr.success('Profile successfully Updated.')
          var user={ token:response.data.data.token,username:response.data.data.name, email: response.data.data.email, id:response.data.data.id,mobile: response.data.data.mobile,photo: response.data.data.photo }
          localStorage.setItem("authUser", JSON.stringify(user));
          this.setState({submit: false})
          window.location.reload()
        }
      }).catch(err => {
        toastr.error(err.message)
        this.setState({submit: false})
        alert(err.message)
      })
    } else {
      this.validator.showMessages()
      this.forceUpdate()
    }
  }
  render() {
    const { name,email, mobile,photo,submit} = this.state
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumb title="Curly Tales" breadcrumbItem="Profile" />

            <Row>
              <Col lg="12">
                {this.props.error && this.props.error ? (
                  <Alert color="danger">{this.props.error}</Alert>
                ) : null}
                {this.props.success && this.props.success ? (
                  <Alert color="success">{this.props.success}</Alert>
                ) : null}

                <Card>
                  <CardBody>
                    <div className="d-flex">
                      <div className="me-3">
                        <img
                          src={this.state.photo}
                          alt=""
                          className="avatar-md rounded-circle img-thumbnail"
                        />
                      </div>
                      <div className="align-self-center flex-1">
                        <div className="text-muted">
                          <h5>{this.state.name}</h5>
                          <p className="mb-1">{this.state.email}</p>
                          <p className="mb-0">Mobile no: {this.state.mobile ? (this.state.mobile):'No Mobile Available'}</p>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <h4 className="card-title mb-4">Change Profile Detail</h4>

            <Card>
              <CardBody>

                <form onSubmit={this.handleFormSubmit}>
                      <Row>
                        <Col md={4} className="mb-3">
                          <Label for="name" className="form-label">
                            Name
                          </Label>

                          <input name="name" placeholder="Enter Name"
                                 defaultValue={name} type="text" className={"form-control"}
                                 onChange={this.handleInput}/>
                          {this.validator.message('name', this.state.name, 'required|string')}
                        </Col>
                        <Col md={4} className="mb-3">
                          <Label for="mobile" className="form-label">
                            Mobile
                          </Label>
                          <input name="mobile" placeholder="Enter Mobile"
                                 defaultValue={mobile} type="tel" className={"form-control"}
                                 onChange={this.handleInput}/>
                          {this.validator.message('mobile', this.state.mobile, 'required|string')}
                        </Col>
                        <Col md={4} className="mb-3">
                          <Label for="photo"  className="form-label">
                            Profile Pic
                          </Label>
                          <input
                            id="photo"
                            name="photo"
                            placeholder="Profile Pic"
                            type="file"
                            accept="image/*"
                            onChange={this.handleFileChange}
                            className={"form-control"}
                          />
                        </Col>
                      </Row>
                      <div className="text-end mt-4">
                        <Button type="submit" disabled={submit} color="success">
                          {submit === false ? 'Update Profile' : 'Please wait...'}
                        </Button>
                      </div>
                    </form>

              </CardBody>
            </Card>
            <h4 className="card-title mb-4">Change Profile Password</h4>

            <Card>
              <CardBody>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                      password: (this.state && this.state.password) || "",
                      confirm_password: (this.state && this.state.confirm_password) || "",
                      idx: (this.state && this.state.idx) || "",
                    }}
                    validationSchema={Yup.object().shape({
                      password: Yup.string().required(
                          "Please Enter Your  Password"
                      ),

                      confirm_password: Yup.string().required('No password provided.')
                          .min(8, 'Password is too short - should be 8 chars minimum.')
                          .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
                    })}
                    onSubmit={values => {
                      this.props.editPassword(values);
                    }}
                >
                  {({ errors, status, touched }) => (
                      <Form className="form-horizontal">
                        <Row>
                          <Col md={4} className="mb-3">
                            <Label for="password" className="form-label">
                              Password
                            </Label>
                            <Field
                                name="password"
                                type="password"
                                className={
                                    "form-control" +
                                    (errors.password && touched.password
                                        ? " is-invalid"
                                        : "")
                                }
                            />
                            <ErrorMessage
                                name="password"
                                component="div"
                                className="invalid-feedback"
                            />
                            <Field
                                name="idx"
                                type="hidden"
                                className={
                                    "form-control" +
                                    (errors.idx && touched.idx
                                        ? " is-invalid"
                                        : "")
                                }
                            />
                          </Col>
                          <Col md={4} className="mb-3">
                            <Label for="confirm_password" className="form-label">
                              Confirm  Password
                            </Label>
                            <Field
                                name="confirm_password"
                                type="password"
                                className={
                                    "form-control" +
                                    (errors.confirm_password && touched.confirm_password
                                        ? " is-invalid"
                                        : "")
                                }
                            />
                            <ErrorMessage
                                name="confirm_password"
                                component="div"
                                className="invalid-feedback"
                            />
                          </Col>
                          <Col md={4} className="align-self-center">
                            <div className="text-end">
                              <Button type="submit" color="danger">
                                Update Password
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

UserProfile.propTypes = {
  editProfile: PropTypes.func,
  editPassword: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
  resetProfileFlag: PropTypes.func,
};

const mapStateToProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStateToProps, {editProfile, editPassword, resetProfileFlag })(UserProfile)
);
