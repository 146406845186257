import { PROFILE_ERROR, PROFILE_SUCCESS, EDIT_PROFILE, RESET_PROFILE_FLAG } from "./actionTypes";
import toastr from "toastr";
import { apiKey } from "../../../helpers/url_helper";

let api_url = process.env.REACT_APP_API_URL;
let api_key = apiKey()
export const editProfile = async user => {

  const obj = JSON.parse(localStorage.getItem("authUser"));

  const formData = new FormData();
  formData.append("id", user['idx']);
  formData.append("name", user['username']);
  formData.append("mobile",  user['mobile']);
  if( user['photo'])
      formData.append("photo", user['photo'])
  const req2 = await fetch(`${api_url}/update-user`, {
    method: "POST",
    headers: {
      "Authorization": 'Bearer '+api_key,
    //  "Content-Type": "application/json",
    },
    body: formData
  });
  const data2 = await req2.json();
  if (data2.status) {

    toastr.success("Profile successfully Updated.");
    let object = data2.data;
    console.log(object);

    //object.accessToken=object.token;
    console.log('updated data'+object);
     localStorage.setItem('authUser',JSON.stringify(object));
    const {history} = this.props

    location.reload("/profile");
    toastr.success("Profile successfully Updated.");
  //  history.push("/profile");
  } else {
    toastr.error(data2.message);
  }
  // return {
  //   type: EDIT_PROFILE,
  //   payload: { user },
  // }
};
export const editPassword = async user => {
  const obj = JSON.parse(localStorage.getItem("authUser"));
  console.log(user);

  const req2 = await fetch(`${api_url}/update-password`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Authorization": 'Bearer '+api_key,
    },
    body: JSON.stringify({
      "password": user["password"],
      "id": user['idx']
    })
  });
  const data2 = await req2.json();
  if (data2.status) {
    toastr.success("Password successfully Updated.");
    let object = data2.data;


    // localStorage.setItem('authUser',JSON.stringify(object));

  } else {
    toastr.error(data2.message);
  }
  // return {
  //   type: EDIT_PROFILE,
  //   payload: { user },
  // }
};

export const profileSuccess = msg => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg
  };
};

export const profileError = error => {
  return {
    type: PROFILE_ERROR,
    payload: error
  };
};

export const resetProfileFlag = error => {
  return {
    type: RESET_PROFILE_FLAG
  };
};
