import React from "react"
import MetaTags from "react-meta-tags"
import {Button, Card, CardBody, CardTitle, Col, Container, Label, Row,} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import {apiKey} from "../../../../helpers/url_helper"
import SimpleReactValidator from 'simple-react-validator'
import axios from "axios"
import toastr from "toastr"
import PropTypes from "prop-types"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import {CKEditor} from "@ckeditor/ckeditor5-react"

let api_url = process.env.REACT_APP_API_URL
let api_key = apiKey()
class VariantNonStayAdd extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            status: true,
            variant_name: "",
            price: "",
            variant_inclusion: "",
            no_of_tickets_available: "",
            handleResponse: null,
            submit: false,
        }
        this.validator = new SimpleReactValidator({autoForceUpdate: this})
    }
    handleInput = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    handleFormSubmit = (e) => {
        e.preventDefault()
        if (this.validator.allValid()) {
            let price_meta = {
                "normal_price":parseInt(this.state.price)
            },inventory_meta = {
                "total":this.state.no_of_tickets_available
            };
            const formData = JSON.stringify({
                "name": this.state.variant_name,
                "about": this.state.variant_inclusion,
                "ordering": this.state.ordering,
                "status": this.state.status,
                "price_meta": price_meta,
                "inventory_meta": inventory_meta,
                "deal": this.props.match.params.id,
                "deal_type": "non-stay"
            })
            this.setState({submit: true})
            axios({
                method: "post", url: `${api_url}/add-deal-variant`, data: formData, headers: {
                    'Content-Type': 'application/json',
                    "Authorization": 'Bearer ' + api_key,
                }
            }).then(response => {
                if (response.status) {
                    this.setState({submit: false})
                    toastr.success('Deal Variant added successful.')
                    // eslint-disable-next-line react/prop-types
                    const {history} = this.props
                    // eslint-disable-next-line react/prop-types
                    history.push(`/deals/variants/${this.props.match.params.id}`)
                }
            }).catch(err => {
                toastr.error(err.message)
                this.setState({submit: false})
                alert(err.message)
            })
        } else {
            this.validator.showMessages()
            this.forceUpdate()
        }
    }

    render() {
        const {submit} = this.state
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>
                            Add Variant | {process.env.REACT_APP_PROJECTNAME}
                        </title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Variants" path={`/deals/variants/${this.props.match.params.id}`} breadcrumbItem="Add Variant"/>
                        <form onSubmit={this.handleFormSubmit}>
                            <Row>
                                <Col xl="12">
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col md="6" className="mb-3">
                                                    <Label htmlFor="variant_name">Variant Name</Label>
                                                    <input name="variant_name" placeholder="Type Variant Name"
                                                           type="text" className={"form-control"}
                                                           onChange={this.handleInput}/>
                                                    {this.validator.message('variant_name', this.state.variant_name, 'required')}
                                                </Col>
                                                <Col md="2" className="mb-3">
                                                    <Label htmlFor="price">Price</Label>
                                                    <input name="price" type="number" placeholder="Enter Price" className={"form-control"} onChange={this.handleInput}/>
                                                    {this.validator.message('price', this.state.price, 'required|numeric')}
                                                </Col>
                                                <Col md="auto" className="mb-3">
                                                    <Label htmlFor="no_of_rooms_available">No. of tickets available</Label>
                                                    <input name="no_of_tickets_available" type="number" placeholder="No. of tickets available" className={"form-control"} onChange={this.handleInput}/>
                                                    {this.validator.message('no_of_tickets_available', this.state.no_of_tickets_available, 'required|numeric')}
                                                </Col>
                                                <Col md="auto" className="mb-3">
                                                    <Label htmlFor="">Status</Label>
                                                    <div className="square-switch mt-2">
                                                        <input
                                                            type="checkbox"
                                                            id="square-switch-status"
                                                            switch="none"
                                                            name="status"
                                                            checked={this.state.status}
                                                            onChange={() =>
                                                                this.setState({status: !this.state.status})
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="square-switch-status"
                                                            data-on-label="On"
                                                            data-off-label="Off"
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md="12" className="mb-3">
                                                    <Label htmlFor="variant_inclusion">Variant inclusion</Label>
                                                    <CKEditor
                                                        editor={ ClassicEditor }
                                                        data={this.state.variant_inclusion}
                                                        onChange={ ( event, editor ) => {
                                                            const data = editor.getData();
                                                            this.setState({variant_inclusion:data})
                                                        } }
                                                        onReady={(editor) => {
                                                            editor.editing.view.change((writer) => {
                                                                writer.setStyle(
                                                                    "min-height",
                                                                    "200px",
                                                                    editor.editing.view.document.getRoot()
                                                                );
                                                            });
                                                        }}
                                                    />
                                                    {/*{this.validator.message('variant_inclusion', this.state.variant_inclusion, 'required')}*/}
                                                </Col>
                                            </Row>
                                            <div className="d-flex flex-wrap gap-2 justify-content-end">
                                                <Button color="primary" type="submit" disabled={submit}>
                                                    {submit === true ? 'Please wait...' : 'Submit Data'}
                                                </Button>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </form>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

VariantNonStayAdd.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string
        })
    }),
    location: PropTypes.object
}

export default VariantNonStayAdd
