import PropTypes from "prop-types"
import React, {Component} from "react"

//Simple bar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import {withRouter} from "react-router-dom"
import {Link} from "react-router-dom"

//i18n
import {withTranslation} from "react-i18next"
import Interests from "../../pages/Interests"

class SidebarContent extends Component {
    constructor(props) {
        super(props)
        this.refDiv = React.createRef()
    }

    componentDidMount() {
        this.initMenu()
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, ss) {
        if (this.props.type !== prevProps.type) {
            this.initMenu()
        }
    }

    initMenu() {
        new MetisMenu("#side-menu")

        let matchingMenuItem = null
        const ul = document.getElementById("side-menu")
        const items = ul.getElementsByTagName("a")
        for (let i = 0; i < items.length; ++i) {
            if (this.props.location.pathname === items[i].pathname) {
                matchingMenuItem = items[i]
                break
            }
        }
        if (matchingMenuItem) {
            this.activateParentDropdown(matchingMenuItem)
        }
    }

    // componentDidUpdate() {}

    scrollElement = item => {
        setTimeout(() => {
            if (this.refDiv.current !== null) {
                if (item) {
                    const currentPosition = item.offsetTop
                    if (currentPosition > window.innerHeight) {
                        if (this.refDiv.current)
                            this.refDiv.current.getScrollElement().scrollTop =
                                currentPosition - 300
                    }
                }
            }
        }, 300)
    }

    activateParentDropdown = item => {
        item.classList.add("active")
        const parent = item.parentElement
        const parent2El = parent.childNodes[1]
        if (parent2El && parent2El.id !== "side-menu") {
            parent2El.classList.add("mm-show")
        }

        if (parent) {
            parent.classList.add("mm-active")
            const parent2 = parent.parentElement

            if (parent2) {
                parent2.classList.add("mm-show") // ul tag

                const parent3 = parent2.parentElement // li tag

                if (parent3) {
                    parent3.classList.add("mm-active") // li
                    parent3.childNodes[0].classList.add("mm-active") //a
                    const parent4 = parent3.parentElement // ul
                    if (parent4) {
                        parent4.classList.add("mm-show") // ul
                        const parent5 = parent4.parentElement
                        if (parent5) {
                            parent5.classList.add("mm-show") // li
                            parent5.childNodes[0].classList.add("mm-active") // a tag
                        }
                    }
                }
            }
            this.scrollElement(item)
            return false
        }
        this.scrollElement(item)
        return false
    }

    render() {
        return (
            <React.Fragment>
                <SimpleBar className="h-100" ref={this.refDiv}>
                    <div id="sidebar-menu">
                        <ul className="metismenu list-unstyled" id="side-menu">
                            <li className="menu-title">{this.props.t("Menu")}</li>
                            <li>
                                <Link to="/dashboard">
                                    <i className="bx bx-home-circle"/>
                                    <span>{this.props.t("Dashboards")}</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/orders/pageno/1" className="">
                                    <i className="bx bx-package"/>
                                    <span>{this.props.t("Orders")}</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/interests/pageno/1" className="">
                                    <i className="bx bx-heart"/>
                                    <span>{this.props.t("Interests")}</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/tags/pageno/1" className="">
                                    <i className="bx bx-tag"/>
                                    <span>{this.props.t("Tags")}</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/#" className="has-arrow">
                                    <i className="bx bx-layer"/>
                                    <span>{this.props.t("Manage Guides")}</span>
                                </Link>
                                <ul className="sub-menu" aria-expanded="false">
                                    <li>
                                        <Link to="/guides/pageno/1">
                                            {this.props.t("Guides")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/guides/spam_reports">
                                            {this.props.t("Guide Spam Comment Report")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/categories/pageno/1">
                                            {this.props.t("Categories")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/countries/pageno/1">
                                            {this.props.t("Countries")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/cities/pageno/1">
                                            {this.props.t("Cities")}
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <Link  to="/#"  className="has-arrow">
                                    <i className="bx bx-news"/>
                                    <span>{this.props.t("Manage Scoops")}</span>
                                </Link>
                                <ul className="sub-menu" aria-expanded="false">
                                    <li>
                                        <Link to="/blogs/pageno/1">
                                            {this.props.t("Scoops")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/blogs/spam_reports">
                                            {this.props.t("Scoops Spam Comment Report")}
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <Link to="/blogs/categories" className="">
                                    <i className="bx bx-list-ul"/>
                                    <span>{this.props.t("Scoop Categories")}</span>
                                </Link>
                            </li>
                            {/*<li>*/}
                            {/*    <Link to="/contributor" className="">*/}
                            {/*        <i className="bx bxs-user-plus"/>*/}
                            {/*        <span>{this.props.t("Contributor Request List")}</span>*/}
                            {/*    </Link>*/}
                            {/*</li>*/}
                            <li>
                                <Link to="/cms-page" className="">
                                    <i className="bx bx-first-page"/>
                                    <span>{this.props.t("CMS Page")}</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/coupons/pageno/1" className="">
                                    <i className="bx bxs-coupon"/>
                                    <span>{this.props.t("Coupons")}</span>
                                </Link>
                            </li>

                            <li>
                                <Link to="/#" className="has-arrow">
                                    <i className="bx bx-store"/>
                                    <span>{this.props.t("CT Originals")}</span>
                                </Link>
                                <ul className="sub-menu" aria-expanded="false">
                                    <li>
                                        <Link to="/originals/pageno/1">
                                            {this.props.t("Originals")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/originals/spam_reports">
                                            {this.props.t("Originals Spam Comment Report")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/seasons/pageno/1">
                                            {this.props.t("Seasons")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/episodes/pageno/1">
                                            {this.props.t("Episodes")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/trending-episodes/pageno/1">
                                            {this.props.t("Trending Episodes")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/popular-original-episode/pageno/1">
                                            {this.props.t("Popular Original Episode")}
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <Link to="/#" className="has-arrow">
                                    <i className="bx bx-list-ul"/>
                                    <span>{this.props.t("CT Deals")}</span>
                                </Link>
                                <ul className="sub-menu" aria-expanded="false">
                                    <li>
                                        <Link to="/deals/categories/pageno/1">
                                            {this.props.t("Deal Categories")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/deals/partners/pageno/1">
                                            {this.props.t("Deal Partners")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/deals/pageno/1">
                                            {this.props.t("Deals")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/deals/cities/pageno/1">
                                            {this.props.t("Cities")}
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <Link  to="/#"  className="has-arrow">
                                    <i className="bx bx-news"/>
                                    <span>{this.props.t("Manage CT Reels")}</span>
                                </Link>
                                <ul className="sub-menu" aria-expanded="false">
                                    <li>
                                        <Link to="/reels/upload-video-draft">
                                            {this.props.t("Upload Video Draft")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/reels/pageno/1">
                                            {this.props.t("CT Reels")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/reels/spam_reports">
                                            {this.props.t("Reels Spam Comment Report")}
                                        </Link>
                                    </li>
                                </ul>

                            </li>
                            <li>
                                <Link to="/social-list/pageno/1">
                                    <i className="bx bxs-message-square"/>
                                    <span>{this.props.t("CT Social")}</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/location/pageno/1">
                                    <i className="bx bxs-map"/>
                                    <span>{this.props.t("Location")}</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/#" className="has-arrow">
                                    <i className="bx bxs-map"/>
                                    <span>{this.props.t("Main Location")}</span>
                                </Link>
                                <ul className="sub-menu" aria-expanded="false">
                                    <li>
                                        <Link to="/country/pageno/1">
                                            {this.props.t("Countries")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/states/pageno/1">
                                            {this.props.t("States")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/main_cities/pageno/1">
                                            {this.props.t("Cities")}
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <Link to="/#" className="has-arrow">
                                    <i className="bx bxs-user-rectangle"/>
                                    <span>{this.props.t("Manage Users")}</span>
                                </Link>
                                <ul className="sub-menu" aria-expanded="false">
                                    <li>
                                        <Link to="/users/pageno/1">
                                            {this.props.t("Users")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/user-roles">
                                            {this.props.t("User roles")}
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <Link to="/faqs">
                                    <i className="bx bx-location-plus"/>
                                    <span>{this.props.t("Faqs")}</span>
                                </Link>
                            </li>
                            <li className="menu-title">{this.props.t("Others")}</li>
                            <li>
                                <Link to="/order-cancel-reason">
                                    <i className="bx bx-list-plus"/>
                                    <span>{this.props.t("Order Cancel Reason")}</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/contact-us">
                                    <i className="bx bx-message"/>
                                    <span>{this.props.t("Contact Us")}</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </SimpleBar>
            </React.Fragment>
        )
    }
}

SidebarContent.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    type: PropTypes.string,
}

export default withRouter(withTranslation()(SidebarContent))
