import React from "react"
import MetaTags from "react-meta-tags"
import {Button, Card, CardBody, Col, Container, Label, Row,} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {apiKey} from "../../../helpers/url_helper"
import SimpleReactValidator from 'simple-react-validator'
import axios from "axios"
import toastr from "toastr"
import PropTypes from "prop-types"
import Select from "react-select";

let api_url = process.env.REACT_APP_API_URL
let api_key = apiKey()
const mystyle = {
    height: "110px",
    overflow: "hidden",
    width: "auto",
    objectFit:"cover"
}
const headers = {'Content-Type': 'application/json', "Authorization": 'Bearer ' + api_key}
class DealCitiesEdit extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            status: true,
            city_name: "",
            image: "",
            old_image: "",
            ordering: "",
            handleResponse: null,
            submit: false,
            selectedCity:[],
            selectedCityText:[],
            cities: [],
            city:"",
        }
        this.validator = new SimpleReactValidator({autoForceUpdate: this})
    }

    componentDidMount() {
        fetch(`${api_url}/get-active-city?&status=1`, {headers})
            .then(response => response.json())
            .then(data => this.setState({cities: this.selectData(data.data)}))
            .catch(err => {
                console.log("error:", err); 
            });
        const id = this.props.match.params.id
        this.setState({edit_id: id})
        const params = {type: "deal_city", id: id}
        axios.get(`${api_url}/get-single-data`, {
            params, headers
        })
            .then(res => {
                if (res.data.status) {
                    let edit = res.data.data
                    this.setState({
                        city_name: edit['name'],
                        old_image: edit['image'],
                        ordering: edit['ordering'],
                        status: edit['status'],
                        selectedCity:{label:edit['name'],value:edit['cityId']},
                        city:edit['cityId']
                    })
                } else {
                    toastr.danger('Server not response')
                }
            })
    }
    selectData = a => {
        let b = []
        for (var c = 0; c < a.length; c++) b.push({
            label: a[c].name, value: a[c]._id
        })
        return b
    }
    handleInput = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }
    handleFileChange = (event) => {
        this.setState({
            image: event.target.files[0]
        })
    }
    handleFileLogoChange = (event) => {
        this.setState({
            logo: event.target.files[0]
        })
    }

    handleFormSubmit = (e) => {
        e.preventDefault()
        if (this.validator.allValid()) {
            const formData = new FormData()
            if(this.state.selectedCityText != '' && this.state.selectedCityText != undefined){
                formData.append('name',this.state.selectedCity.label)
            }
            formData.append('cityId', this.state.selectedCity.value)
            var image = document.getElementById("image").files[0];
            if (image){
                formData.append('image', this.state.image)
            }
            formData.append('status', this.state.status)
            formData.append('ordering', this.state.ordering)
            formData.append('dealCityId',  this.state.edit_id)
            this.setState({submit: true})
            axios({
                method: "post", url: `${api_url}/update-deal-city`, data: formData, headers: {
                    'Content-Type': 'application/json',
                    "Authorization": 'Bearer ' + api_key,
                }
            }).then(response => {
                if (response.status) {
                    this.setState({submit: false})
                    this.setState({submit: false})
                    toastr.success('Deal City update successful.')
                    // eslint-disable-next-line react/prop-types
                    const {history} = this.props
                    // eslint-disable-next-line react/prop-types
                    const pageno = this.props.match.params.pId; // Get the pageno param from the URL
                    history.goBack();
                }
            }).catch(err => {
                toastr.error(err.message)
                this.setState({submit: false})
                alert(err.message)
            })
        } else {
            this.validator.showMessages()
            this.forceUpdate()
        }
    }
    handleCity = (e) => {
//         var index = e.target.selectedIndex;
//         var optionElement = e.target.childNodes[index]
// console.log(optionElement.getAttribute('data-id'));

//        this.setState({selectedCity:e.target.value})
//         this.setState({selectedCityText:optionElement.getAttribute('data-id')})
    }
    render() {

        const {city_name,ordering,submit,cities,selectedCity} = this.state
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>
                            Deal Cities | {process.env.REACT_APP_PROJECTNAME}
                        </title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Deal Cities" path="/deals/cities/pageno/1" breadcrumbItem="Edit City"/>
                        <Row>
                            <Col xl="10">
                                <Card>
                                    <CardBody>
                                        <form onSubmit={this.handleFormSubmit}>
                                            <Row>
                                                <Col md="3" className="mb-3">
                                                    <label className="control-label">
                                                        City
                                                    </label>
                                                    <Select
                                                        value={this.state.selectedCity}
                                                        isMulti={false}
                                                        name="city"
                                                        onChange={e => {this.setState({selectedCity: e}); this.handleCity(e)}}
                                                        options={cities}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                    {this.validator.message('selectedCity', this.state.selectedCity, 'required')}
                                                </Col>
                                                <Col md="4" className="mb-3">
                                                    <Label htmlFor="image">Image</Label>
                                                    <input
                                                        id="image"
                                                        name="image"
                                                        placeholder="Image"
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={this.handleFileChange}
                                                        className={"form-control"}
                                                    />
                                                    {/*{this.validator.message('image', this.state.image, 'required')}*/}
                                                </Col>
                                                <Col md="2" className="mb-3">
                                                    <Label htmlFor="ordering">Order No.</Label>
                                                    <input
                                                        name="ordering"
                                                        type="number"
                                                        placeholder="Order No."
                                                        className={"form-control"}
                                                        onChange={this.handleInput}
                                                        defaultValue={ordering}
                                                    />
                                                    {this.validator.message('ordering', this.state.ordering, 'required|numeric')}
                                                </Col>
                                                <Col md="auto" className="mb-3">
                                                    <Label htmlFor="">Status</Label>
                                                    <div className="square-switch mt-2">
                                                        <input
                                                            type="checkbox"
                                                            id="square-switch-status"
                                                            switch="none"
                                                            name="status"
                                                            checked={this.state.status}
                                                            onChange={() =>
                                                                this.setState({status: !this.state.status})
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="square-switch-status"
                                                            data-on-label="On"
                                                            data-off-label="Off"
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>

                                            <div className="d-flex flex-wrap gap-2 justify-content-end">
                                                <Button color="primary" type="submit" disabled={submit}>
                                                    {submit === true ? 'Please wait...' : 'Submit Data'}
                                                </Button>
                                            </div>
                                        </form>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xl="2">
                                <Card>
                                    <CardBody>
                                        <h6>Old Image</h6>
                                        {!this.state.old_image && <h5 className="text-danger">No image</h5>}
                                        {this.state.old_image && <img src={this.state.old_image} className="img-fluid" style={mystyle} alt="image"/>}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

DealCitiesEdit.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string
        })
    }),
    location: PropTypes.object
}

export default DealCitiesEdit
