import React, {useEffect, useState} from "react"
import { useParams, useHistory, useLocation } from 'react-router-dom';
import {Card, CardBody, Modal, Col, Row} from "reactstrap"
import MetaTags from 'react-meta-tags'
import Pagination from 'components/Common/Pagination'
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {TableHeader, Search} from "../Datatable/index"
import ReactPlayer from 'react-player'
// import axios from 'axios';
import {Link} from "react-router-dom"
import axios from "axios"
import notification from "../../components/Common/Notification"
import {apiKey} from "../../helpers/url_helper"
import {Image} from "react-bootstrap"
import Moment from 'moment';
import Leaflet from "leaflet"
import {
    Circle,
    FeatureGroup,
    LayerGroup,
    Map,
    Popup,
    Rectangle,
    TileLayer,
} from "react-leaflet"
import "leaflet/dist/leaflet.css"
import toastr from "toastr"
import {
    GridContextProvider,
    GridDropZone,
    GridItem,
    swap,
  } from "react-grid-dnd";


Leaflet.Icon.Default.imagePath = "../node_modules/leaflet"
delete Leaflet.Icon.Default.prototype._getIconUrl
Leaflet.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
})

const rectangle = [
    [51.49, -0.08],
    [51.5, -0.06],
]

let api_url = process.env.REACT_APP_API_URL
let api_key = apiKey()
let exportURL = api_url + "/get-deal-csv"

let PageSize = 15
const useFullPageLoader = () => {
    const [loading, setLoading] = useState(false)
    return [
        loading ? 'Loading' : null,
        () => setLoading(true), //Show loader
        () => setLoading(false) //Hide Loader
    ]
}

function LoadMap(prop) {
    const position = [prop.lat, prop.lng]
    return (
        <Map
            center={position}
            zoom={13}
            style={{ height: "300px" }}
        >
            <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <LayerGroup>
                <Circle center={position} fillColor="blue" radius={200} />
                <Circle
                    center={position}
                    fillColor="red"
                    radius={100}
                    stroke={false}
                />
                <LayerGroup>
                    <Circle
                        center={[51.51, -0.08]}
                        color="green"
                        fillColor="green"
                        radius={100}
                    />
                </LayerGroup>
            </LayerGroup>
            <FeatureGroup color="purple">
                <Popup>Deal Location</Popup>
                <Circle center={[51.51, -0.06]} radius={200} />
                <Rectangle bounds={rectangle} />
            </FeatureGroup>
        </Map>
    )
}
const Deals = (props) => {
    const history = useHistory();
    const location = useLocation();
    const { pId:pageno } = useParams();

    const [tableData, settableData] = useState([])
    const [loader, showLoader, hideLoader] = useFullPageLoader()
    const [totalItems, setTotalItems] = useState(0)
    const [currentPage, setCurrentPage] = useState(() => {
        const paramPage = parseInt(pageno);
        if (!isNaN(paramPage)) {
            return paramPage;
          } else {
            return 1;
          }
      });
      const { searchQuery } = useParams();
      const [search, setSearch] = useState(() => {
          if (searchQuery) {
              return searchQuery;
            } else {
              return '';
            }
        });
      const [getSearch, setGetSearch] = useState(search);
      const [getSearchPage, setGetSearchPage] = useState(currentPage)
      const [mediaModal, setMediaModal] = useState(false)
    const [mediaData, setMediaData] = useState([])
    const [mainId, setMainId] = useState("")
    const [is_delete, setDelete] = useState(false);
    const [bodyModal, setbodyModal] = useState(false)
    const [GuideBodyData, setGuideBodyData] = useState("")
    const [CategoryModal, setCategoryModal] = useState(false)
    const [CategoryData, setCategoryData] = useState([])
    const [isMap, setIsMap] = useState(false)
    const [lat, setLat] = useState("")
    const [lng, setLng] = useState("")
    const [address, setAddress] = useState("")
    const [status, setStatus] = useState(1)

    const Header = [
        {name: "#", field: "id", sortable: false},
        {name: "Partner's", field: "", sortable: false},
        {name: "Deal Name", field: "name", sortable: false},
        {name: "Cover Image", field: "cover_image", sortable: false},
        {name: "Media", field: "media", sortable: false},
        {name: "Deals Category", field: "deals_category", sortable: false},
        {name: "Type", field: "type", sortable: false},
        {name: "Variant", field: "_id", sortable: false},
        {name: "City", field: "location", sortable: false},
        {name: "Geo Location Address", field: "location_full_address", sortable: false},
        // {name: "Latitude", field: "latitude", sortable: false},
        // {name: "Longitude", field: "longitude", sortable: false},
        {name: "Exclusive", field: "exclusive", sortable: false},
        {name: "Contact Number", field: "contact_number", sortable: false},
        {name: "Email Address", field: "email_address", sortable: false},
        {name: "Interests", field: "interests", sortable: false},
        {name: "Tags", field: "tags", sortable: false},
        {name: "Recommended", field: "recommended", sortable: false},
        {name: "Start Date", field: "start_date", sortable: false},
        {name: "End Date", field: "end_date", sortable: false},
        {name: "Order No.", field: "ordering", sortable: false},
        {name: "Status", field: "status", sortable: false},
        {name: "Deep Link", field: "deep_link", sortable: false},
        {name: "Notification  to All", field: "notification_all", sortable: false},
        {name: "Notification By Location", field: "notification_by_location", sortable: false},
        {name: "Action", field: "", sortable: false}
    ]

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }
    function media(prop,id) {
        setMediaModal(true)
        setMediaData(prop)
        setMainId(id)
    }

    
    function handleSearch(){
        setSearch(getSearch);
        setCurrentPage(getSearchPage);
        if(getSearch){
            history.push(`/deals/pageno/${getSearchPage}/${getSearch}`)
        }
    }
    // Handle any changes to the currentPage state
    const handlePageChange = () => {
        setCurrentPage(() => {
            const paramPage = parseInt(pageno);
            if (!isNaN(paramPage)) {
                return paramPage;
            } else {
                return 1;
            }
        })
    };
    useEffect(() => {
        handlePageChange();
    }, [pageno,location]);


    useEffect(() => {
        showLoader()
        let query = ''
        search && (query = `keyword=${search}&`)
        const url = `${api_url}/get-deals?${query}limit=${PageSize}&pageNo=${currentPage}&status=${status}`
        axios.get(url, {
            headers: {
                "Authorization": 'Bearer ' + api_key,
            }
        })
            .then(res => {
                if (res.status) {
                    settableData(res.data.data)
                    res.data.totalRecords !== undefined && setTotalItems(res.data.totalRecords)
                }
                hideLoader()
            }).catch(
                err => {
                    notification(err, 'error', 'toast-bottom-right')
                    if(err.response.status===401 || err.response.status===400 ){
                        history.push('/logout')
                    }
                }
            );
    }, [currentPage, search, is_delete, status])  // pass `value` as a dependency

    function tog_center() {
        removeBodyCss()
    }
    function mapload(a,b,address) {
        setIsMap(true)
        setLat(a)
        setLng(b)
        setAddress(address)
    }
    function category(prop) {
        setCategoryModal(true)
        setCategoryData(prop)
    }
    function formatDate(date){
        return Moment(date).format('DD/MM/YYYY')
    }
    const deleteMedia = (data,index) => {
        let formData = {
            "id": mainId,
            "media_id": data._id,
            "type": "deals"
        }
        axios({
            method: "post", url: `${api_url}/delete-media-image`, data: formData, headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + api_key
            }
        }).then(response => {
            if (response.status){
                removeMedia(index)
                setCurrentPage(1)
                setDelete(!is_delete)
                notification('Delete success','success','toast-bottom-right')
            }
        }).catch(err => {
            toastr.error(err.message);
        });
    }
    const removeMedia = (index) => {
        let newMedia = []
        mediaData.map((val,key) => {
            if (key !== index){
                newMedia.push(val)
            }
        })
        setMediaData(newMedia)
    }

    const handleCopy = (value) => {
        let a = navigator.clipboard.writeText(value)
        navigator.clipboard.readText().then(function(data) {
            notification(`Successfully Copied - ${data}`,'success','toast-bottom-right')
        });
    }
    const  handleSendNotification=async (nofitificationMsg,dealId,isSendPushLocationWise) =>{
        const req2 = await fetch(`${api_url}/send-push`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Authorization": 'Bearer '+api_key,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "type": "deal",
                "notification_message": nofitificationMsg,
                "id": dealId,
                "isSendPushLocationWise":isSendPushLocationWise
            })
        });
        const data2 = await req2.json();
        console.log(data2);
        if (data2.status) {
            notification('Notification Successfully Sent','success','toast-bottom-right')
         //   toastr.success('Notification Successfully Sent.')
        } else {
            toastr.error(data2.message);
            this.setState({ submit: false });
        }
    }

     // save Rearranged List to DB
  const saveReorderedList=(reorderedList)=>{
    // Api call to save reordersList to the data base
    const url = `${api_url}/update-deal-media-image`;
    let payload = {
        id: mainId,
        media : reorderedList
    }
    axios({
            method: "post", url, data: payload, headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + api_key
            }
        })
        .then(res => {
            if(res.status) {
                notification('Saved','success','toast-bottom-right')
            }
        }).catch(err => notification(err,'error','toast-bottom-right'));
  }
  // Function to handle drag and drop events    
   function onChange(sourceId,sourceIndex,targetIndex) {
    const nextState = swap(mediaData, sourceIndex, targetIndex);
    setMediaData(nextState);
    console.log(nextState)
    saveReorderedList(nextState);
  }
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Manage Deals</title>
                </MetaTags>
                <div className="container-fluid">
                    <Breadcrumbs title="Dashboard" path="/" breadcrumbItem="Deals"/>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <div className="d-flex justify-content-between mb-3 gap-3">
                                        <div>
                                            <a href={exportURL}
                                                target="_blank" rel="noreferrer"
                                               className="btn btn-primary waves-effect waves-light"><i
                                                className="bx bx-export font-size-16 align-middle me-2"></i> Export</a>
                                        </div>
                                        <div>
                                            <a href="/deals/add"
                                               className="btn btn-primary waves-effect waves-light"><i
                                                className="bx bx-plus font-size-16 align-middle me-2"></i> Add</a>
                                        </div>
                                    </div>

                                    <div className="row flex-row-reverse">
                                        <div className="col-12 col-md-6 d-flex flex-row-reverse mb-3">
                                            <div onClick={handleSearch}
                                               className="btn btn-primary waves-effect waves-light ms-2"> Search</div>
                                            <Search
                                                onSearch={value => {
                                                    setGetSearch(value)
                                                    setGetSearchPage(1)
                                                }}
                                            />
                                        </div>
                                        <div className="col-12 col-md-6 d-flex mb-3 justify-content-end justify-content-md-start">
                                            <div className="d-flex">
                                                <select className="form-select" value={status} onChange={e => setStatus(e.target.value)}>
                                                    <option value={1}>Active Deals</option>
                                                    <option value={""}>Inactive Deals</option>
                                                </select>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-striped">
                                            <TableHeader
                                                headers={Header}
                                            />
                                            <tbody className="">{loader && <tr><th colSpan={Header.length}><h4 className="text-center">Loading...</h4></th></tr>}
                                            {(!loader && tableData.length === 0) && <tr><th colSpan={Header.length}><h4 className="text-center">No data found</h4></th></tr>}
                                            {!loader && tableData.map((value, index) => (
                                                <tr key={++index}>
                                                    <th scope="row">
                                                        {PageSize * (currentPage - 1) + (index + 1)}
                                                    </th>
                                                    <td>
                                                        {value.partner.map((val, key) => (
                                                            <span className="font-size-12 badge-dark badge bg-light rounded-pill" key={key}>{val.name}</span>
                                                        ))}</td>
                                                    <td><div style={{maxWidth:"100px",maxHeight:"36px",overflow:"hidden",textOverflow:"ellipsis"}}>{value.name}...</div></td>
                                                    <td>{value.cover_image && <img src={value.cover_image} className="img-thumbnail avatar-sm"
                                                             width="200" alt={value.cover_image}/>}</td>
                                                    <td>
                                                        <div
                                                            className="btn-group btn-group-sm"
                                                            role="group"
                                                            aria-label="Media"
                                                        >
                                                            {value.media.length > 0 &&
                                                                <button className="btn-sm btn-rounded btn btn-primary"
                                                                        onClick={() => media(value.media,value._id)}>Media</button>}
                                                            <Link className="btn-sm btn-rounded btn btn-secondary"
                                                                  to={`/deals/media/${value._id}`}>Add Media</Link>
                                                        </div>
                                                    </td>
                                                    <td>{value.deals_category.map((val, key) => (
                                                        <a onClick={() => category(value.deals_category[key])}
                                                           className="font-size-12 badge bg-secondary rounded-pill"
                                                           key={key}>{val['name']}</a>
                                                    ))}</td>
                                                    <td>{value.type}</td>
                                                    <td><a href={`/deals/variants/${value._id}`} className="btn-sm btn-rounded btn btn-secondary waves-effect waves-light">Variant</a></td>
                                                    <td>{(typeof value.deal_cities === 'object' && value.deal_cities.length > 0 ) && value.deal_cities?.[0]?.name }{value.deal_cities.length === 0 && value.location}</td>
                                                    <td><a style={{display:"-webkit-box",maxWidth:"100px","-webkit-line-clamp":"3","-webkit-box-orient":"vertical",overflow:"hidden"}} onClick={() => mapload(value.latitude,value.longitude,value.location_full_address)} key={index}>{value.location_full_address}</a></td>
                                                    <td>{value.exclusive === true ? 'Yes' : 'No'}</td>
                                                    <td>{value.contact_number}</td>
                                                    <td>{value.email_address}</td>
                                                    <td>{value.interest.map((value, key) => (
                                                        <span className="font-size-12 badge bg-info rounded-pill"
                                                              key={key}>{value['name']}</span>
                                                    ))}</td>
                                                    <td>{value.tags.map((value, key) => (
                                                        <span className="font-size-12 badge bg-warning rounded-pill"
                                                              key={key}>{value['name']}</span>
                                                    ))}</td>
                                                    <th>{value.recommended === true ? 'Yes':'No'}</th>
                                                    <th><div style={{minWidth:"100px"}}>{formatDate(value.start_date)}</div></th>
                                                    <th><div style={{minWidth:"100px"}}>{formatDate(value.end_date)}</div></th>
                                                    <th>{value.ordering}</th>
                                                    <td>{value.status === true ? <span
                                                            className="badge badge-pill badge-soft-success font-size-13">Active</span> :
                                                        <span
                                                            className="badge badge-pill badge-soft-error font-size-13">Inactive</span>}</td>
                                                    <td>
                                                       <button style={{backgroundColor:'green',padding:'1px 10px'}} className="btn btn-primary waves-effect waves-light copu_btn" onClick={() => {handleCopy(value.deep_link)}}>Copy</button>
                                                    </td>
                                                    <td>

                                                        { value.status === true && value.notification_msg?<button style={{backgroundColor:'green',padding:'1px 10px'}} className="btn btn-primary waves-effect waves-light copu_btn" onClick={() => {handleSendNotification(value.notification_msg,value._id,false)}}>Send</button>:'-' }

                                                    </td>
                                                    <td>
                                                        { value.status === true &&  value.notification_msg_within_city? <button style={{backgroundColor:'green',padding:'1px 10px'}} className="btn btn-primary waves-effect waves-light copu_btn" onClick={() => {handleSendNotification(value.notification_msg_within_city,value._id,true)}}>Send</button>:'-'}
                                                    </td>
                                                    <td>
                                                        <Link to={`/deals/${currentPage}/edit/${value._id}`}><i
                                                            className="bx bxs-edit-alt"></i> </Link>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <Row className="justify-content-center">
                                        <Col className="col-auto">
                                            <Pagination
                                                className="pagination-bar"
                                                currentPage={currentPage}
                                                totalCount={totalItems}
                                                pageSize={PageSize}
                                                onPageChange={page => history.push(`/deals/pageno/${page}/${search}`)}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <Modal
                    size="lg"
                    isOpen={mediaModal}
                    toggle={() => {
                        tog_center()
                    }}
                    centered
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">Media</h5>
                        <button
                            type="button"
                            onClick={() => {
                                setMediaModal(false)
                                setMediaData([])
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Row className="g-2">
                        <GridContextProvider onChange={onChange}>
                        <GridDropZone
                        id="items"
                        boxesPerRow={3}
                        rowHeight={220}
                        style={{ height: 220 * Math.ceil(mediaData.length / 3) }}
                        >
                        {mediaData.map((value, key) => (
                            value['url'].split(".").pop() === 'mp4' || value['url'].split(".").pop() === 'm3u8' ?
                            <GridItem key={value['_id']}>
                                    <div className="position-relative">
                                        <span style={{"cursor":"pointer","position":"absolute","top":"10px","right":"10px","zIndex":"5","background":"#fff","height":"25px","width":"25px","textAlign":"center","lineHeight":"25px","borderRadius":"10px","color":"#e91e63"}} onClick={() => deleteMedia(value,key)}><i className="mdi mdi-trash-can d-block font-size-16"></i></span>
                                        <ReactPlayer playing={false} controls={true} width="100%" height="100%" url={value['url']} />
                                    </div>
                            </GridItem>
                            :
                            <GridItem key={value['_id']}>
                                    <div className="img-box" style={{margin:'10px'}}>
                                        <span style={{"cursor":"pointer","position":"absolute","top":"10px","right":"10px","zIndex":"5","background":"#fff","height":"25px","width":"25px","textAlign":"center","lineHeight":"25px","borderRadius":"10px","color":"#e91e63"}} onClick={() => deleteMedia(value,key)}><i className="mdi mdi-trash-can d-block font-size-16"></i></span>
                                        <img className="img-main" src={value['url']} alt={value['_id']} draggable="false"/>
                                        <img className="img-back" src={value['url']} alt={value['_id']} draggable="false"/>
                                    </div>
                            </GridItem>
                        ))}
                        </GridDropZone>
                    </GridContextProvider>
                            {mediaData.length === 0 && <h4 className="text-center">No Media</h4>}
                        </Row>
                    </div>
                </Modal>
                <Modal
                    isOpen={bodyModal}
                    toggle={() => {
                        tog_center()
                    }}
                    centered
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">Guide Description</h5>
                        <button
                            type="button"
                            onClick={() => {
                                setbodyModal(false)
                                setGuideBodyData("")
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <p>{GuideBodyData}</p>
                    </div>
                </Modal>
                <Modal
                    size="lg"
                    isOpen={CategoryModal}
                    toggle={() => {
                        tog_center()
                    }}
                    centered
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">Category</h5>
                        <button
                            type="button"
                            onClick={() => {
                                setCategoryModal(false)
                                setCategoryData("")
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Row>
                            <Col md={8}>
                                <dl className="row mb-0">
                                    <dt className="col-sm-4">Category</dt>
                                    <dd className="col-sm-8">{CategoryData['name']}</dd>
                                    <dt className="col-sm-4">Font Color</dt>
                                    <dd className="col-sm-8">{CategoryData['font_color']}</dd>
                                    <dt className="col-sm-4">Background Color</dt>
                                    <dd className="col-sm-8">{CategoryData['bg_color']}</dd>
                                </dl>
                            </Col>
                            <Col md={4}>
                                <h6>Icon</h6>
                                <Image className="img-thumbnail avatar-md" src={CategoryData['icon']}/>
                            </Col>
                        </Row>
                    </div>
                </Modal>
                <Modal
                    size="lg"
                    isOpen={isMap}
                    toggle={() => {
                        tog_center()
                    }}
                    centered
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">Location</h5>
                        <button
                            type="button"
                            onClick={() => {
                                setIsMap(false)
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {address !== "" && <><Row><Col md="auto"><strong>Address:</strong></Col><Col md="8">{address}</Col></Row><hr/> </>}
                        { isMap && <LoadMap lat={lat} lng={lng}/>}
                    </div>
                </Modal>
            </div>
        </React.Fragment>
    )
}

export default Deals
