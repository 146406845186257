import React from "react"
import MetaTags from "react-meta-tags"
import { Button, Card, CardBody, Col, Container, Label, Row, Modal} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Select from "react-select"
import CreatableSelect from 'react-select/creatable';
import { apiKey } from "../../helpers/url_helper"
import SimpleReactValidator from 'simple-react-validator'
import axios from "axios"
import toastr from "toastr"
import "style.min.css"
import PropTypes from "prop-types"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Moment from 'moment';
import { Editor } from '@tinymce/tinymce-react'
import LocationSearchInput from '../../components/LocationSearchInput'
let api_url = process.env.REACT_APP_API_URL
let api_key = apiKey()

function formatDate(date) {
    // 2022-10-20T07:17:20.272+00:00
    return Moment(date).format()
}

class DealsAdd extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            partners: [],
            categories: [],
            interests: [],
            tags: [],
            cities: [],
            selectedPartners: "",
            selectedCategory: [],
            selectedInterests: [],
            selectedTags: [],
            selectedCity: [],
            recommended: false,
            status: true,
            deal_name: "",
            about: "",
            location: "",
            location_full_address: "",
            notification_msg_within_city: "",
            notification_msg: '',
            latitude: "",
            longitude: "",
            exclusive: false,
            contact_number: "",
            email_address: "",
            type: "",
            tnc: "",
            ordering: "",
            cover_image: "",
            partner: "",
            end_date: "",
            start_date: "",
            deals_category: "",
            interest: "",
            terms_conditions: "",
            handleResponse: null,
            submit: false,
            autoCompleteRef: null,
            // custom tags for ------------
            tagModel: false,
            selected_interests: "",
            customTag: "",
            isLoading: false,
            // editorState: EditorState.createEmpty(),
            // editorState2: EditorState.createEmpty(),
            // editorState3: EditorState.createEmpty(),
        }
        this.validator = new SimpleReactValidator({ autoForceUpdate: this })
        this.handleAutoClose = this.handleAutoClose.bind(this)
    }

    componentDidMount() {
        const headers = { 'Content-Type': 'application/json', "Authorization": 'Bearer ' + api_key }
        fetch(`${api_url}/get-deal-categories?&status=1`, { headers })
            .then(response => response.json())
            .then(data => this.setState({ categories: data.data }))
            .catch(err => {
                console.log("error:", err);
            });
        fetch(`${api_url}/get-interests?&status=1`, { headers })
            .then(response => response.json())
            .then(data => this.setState({ interests: data.data }))
            .catch(err => {
                console.log("error:", err);
            });
        fetch(`${api_url}/get-deal-partner?&status=1`, { headers })
            .then(response => response.json())
            .then(data => this.setState({ partners: data.data }))
            .catch(err => {
                console.log("error:", err);
            });
        fetch(`${api_url}/get-deal-city?&status=1`, { headers })
            .then(response => response.json())
            .then(data => this.setState({ cities: this.selectData(data.data) }))
            .catch(err => {
                console.log("error:", err);
            });
    }

    handleInterestTags = e => {
        // this.selectRef.select.clearValue();
        let formData = [];
        for (var a = 0; a < e.length; a++) {
            formData.push(e[a]['value'])
        }
        axios({
            method: "post", url: `${api_url}/get-interest-wise-tags`, data: { "interest": formData }, headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + api_key
            }
        }).then(response => {
            if (response.data) {
                let b = [], a = response.data.data;
                for (var c = 0; c < a.length; c++) b.push({
                    label: a[c].name,
                    value: a[c]._id
                });
                this.setState({ tags: b })
            }
        }).catch(err => {
            toastr.error(err.message);
        });
    }


    handlePartners = selectedPartner => {
        this.setState({ selectedPartners: selectedPartner.value })
    }
    handleCategory = selectedCategory => {
        this.setState({ selectedCategory: selectedCategory.map(({ value }) => value).join(',') })
    }
    handleInterests = selectedInterests => {
        this.setState({ selectedInterests: selectedInterests.map(({ value }) => value).join(',') })
    }
    handleTags = selectedTags => {
        this.setState({ selectedTags: selectedTags })
    }
    handleFileChange = (event) => {
        this.setState({
            cover_image: event.target.files[0]
        })
    }
    selectData = a => {
        let b = []
        for (var c = 0; c < a.length; c++) b.push({
            label: a[c].name, value: a[c]._id
        })
        return b
    }
    handleInput = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleAutoClose(date) {
        this.setState({ auto_close: date })
    }

    setFormLocation = (googleLocation) => {
        if (googleLocation.length) {
            this.setState({
                location_full_address: googleLocation[0]['formatted_address'],
                latitude: googleLocation[0].geometry.location.lat(),
                longitude: googleLocation[0].geometry.location.lng(),
            })
        }
    }
    handleFormSubmit = (e) => {
        e.preventDefault()
        if (this.validator.allValid()) {
            const formData = new FormData()
            formData.append('name', this.state.deal_name)
            formData.append('about', this.state.about)
            formData.append('location', this.state.selectedCity?.value)
            formData.append('location_full_address', this.state.location_full_address)
            formData.append('latitude', this.state.latitude)
            formData.append('longitude', this.state.longitude)
            formData.append('exclusive', this.state.exclusive)
            formData.append('contact_number', this.state.contact_number)
            formData.append('email_address', this.state.email_address)
            formData.append('type', this.state.type)
            formData.append('tnc', this.state.tnc)
            formData.append('end_date', this.state.end_date)
            formData.append('notification_msg', this.state.notification_msg)
            formData.append('notification_msg_within_city', this.state.notification_msg_within_city)
            formData.append('start_date', this.state.start_date)
            formData.append('cover_image', this.state.cover_image)
            formData.append('status', this.state.status)
            formData.append('ordering', this.state.ordering)
            formData.append('recommended', this.state.recommended)
            formData.append('partner', this.state.selectedPartners)
            formData.append('deals_category', this.state.selectedCategory)
            formData.append('interest', this.state.selectedInterests)
            formData.append('tags', this.state.selectedTags ? this.state.selectedTags.map(({ value }) => value).join(',') : '')
            formData.append('terms_conditions', this.state.terms_conditions)
            this.setState({ submit: true })
            axios({
                method: "post", url: `${api_url}/add-deal`, data: formData, headers: {
                    'Content-Type': 'application/json', "Authorization": 'Bearer ' + api_key,
                }
            }).then(response => {
                if (response.status) {
                    this.setState({ submit: false })
                    toastr.success('Deal added successful.')
                    // eslint-disable-next-line react/prop-types
                    const { history } = this.props
                    // eslint-disable-next-line react/prop-types
                    history.push('/deals/pageno/1');

                }
            }).catch(err => {
                toastr.error(err.message)
                this.setState({ submit: false })
                alert(err.message)
            })
        } else {
            this.validator.showMessages()
            this.forceUpdate()
        }
    }

    handleEditorChange = e => {
        this.setState({ about: e.target.getContent() })
    }

    handleEditorChange1 = e => {
        this.setState({ tnc: e.target.getContent() })
    }

    handleCreate = (inputValue) => {
        this.setState({ customTag: inputValue })
        this.setState({ isLoading: true, tagModal: true })
    };
    // Custom Tags ==================================
    handleCustomTag = (e) => {
        e.preventDefault();
        var data = JSON.stringify({
            "name": this.state.customTag,
            "interest": this.state.selected_interests.value,
            "ordering": 1,
            "status": true,
            "user_id": 0,
            "is_user_defined": false,
        });
        axios({
            method: "post", url: `${api_url}/add-tag`, data: data, headers: {
                'Content-Type': 'application/json',
                "Authorization": 'Bearer ' + api_key,
            }
        })
            .then(response => {
                toastr.success('Tag added successful.')
                if (response.data.status && response.data?.data?.upserted[0]?._id !== undefined) {
                    this.setState({ tags: [...this.state.tags, { label: this.state.customTag, value: response.data?.data?.upserted[0]?._id }] });
                    this.setState({ selectedTags: [...this.state.selectedTags, { label: this.state.customTag, value: response.data?.data?.upserted[0]?._id }] });
                    this.setState({ selected_interests: "", customTag: "", isLoading: false, tagModal: false })
                }
            })
            .catch(response => {
                toastr.error(response)
            });
    }
    // Custom Tags end ================================

    render() {
        const { categories, partners, interests, tags, cities, submit, isLoading } = this.state
        return (<React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>
                        Manage Deals | {process.env.REACT_APP_PROJECTNAME}
                    </title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title="Deals" path="/deals/pageno/1" breadcrumbItem="Add Deal" />
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <form onSubmit={this.handleFormSubmit}>
                                        <Row>
                                            <Col md="12" className="mb-3">
                                                <Label htmlFor="title">Deal</Label>
                                                <input name="deal_name" placeholder="Type Deal Title" type="text"
                                                    className={"form-control"} value={this.state.deal_name}
                                                    onChange={this.handleInput} />
                                                {this.validator.message('deal_name', this.state.deal_name, 'required|string')}
                                            </Col>
                                            <Col md="3" className="mb-3">
                                                <Label htmlFor="cover_image">
                                                    Cover Image
                                                </Label>
                                                <input
                                                    name="cover_image"
                                                    placeholder="Cover Image"
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={this.handleFileChange}
                                                    className={"form-control"}
                                                />
                                                {this.validator.message('cover_image', this.state.cover_image, 'required')}
                                            </Col>
                                            <input id="city" type="hidden" />
                                            <input id="latitude" type="hidden" />
                                            <input id="longitude" type="hidden" />
                                            <Col md="6" className="mb-3">
                                                <Label htmlFor="location_full_address">
                                                    Geo Location Address
                                                </Label>
                                                <LocationSearchInput setFormLocation={this.setFormLocation} address={this.state.location_full_address} />
                                                {this.validator.message('location_full_address', this.state.location_full_address, 'required')}
                                            </Col>
                                            <Col md="3" className="mb-3">
                                                <label className="control-label">
                                                    City
                                                </label>
                                                <Select
                                                    isMulti={false}
                                                    name="city"
                                                    onChange={e => { this.setState({ selectedCity: e }) }}
                                                    options={cities}
                                                    classNamePrefix="select2-selection"
                                                />
                                                {this.validator.message('selectedCity', this.state.selectedCity, 'required')}
                                            </Col>
                                            <Col md="auto" className="mb-3">
                                                <Label htmlFor="exclusive">Exclusive</Label>
                                                <div className="square-switch mt-2">
                                                    <input type="checkbox" id="square-exclusive" switch="none"
                                                        name="exclusive" checked={this.state.exclusive}
                                                        onChange={() => this.setState({ exclusive: !this.state.exclusive })}
                                                    />
                                                    <label
                                                        htmlFor="square-exclusive"
                                                        data-on-label="On"
                                                        data-off-label="Off"
                                                    />
                                                </div>
                                                {this.validator.message('exclusive', this.state.exclusive, 'boolean')}
                                            </Col>
                                            <Col md="auto" className="mb-3">
                                                <Label htmlFor="contact_number">
                                                    Contact Number
                                                </Label>
                                                <input
                                                    name="contact_number"
                                                    placeholder="Type contact number"
                                                    type="text"
                                                    className={"form-control"}
                                                    onChange={this.handleInput}
                                                />
                                                {this.validator.message('contact_number', this.state.contact_number, 'required|phone')}
                                            </Col>
                                            <Col md="3" className="mb-3">
                                                <Label htmlFor="email_address">
                                                    Email Address
                                                </Label>
                                                <input
                                                    name="email_address"
                                                    placeholder="Type email address"
                                                    type="email"
                                                    className={"form-control"}
                                                    onChange={this.handleInput}
                                                />
                                                {this.validator.message('email_address', this.state.email_address, 'required|email')}
                                            </Col>
                                            <Col md="3" className="mb-3">
                                                <Label htmlFor="type">
                                                    Type
                                                </Label>
                                                <select name="type" className={"form-select"} onChange={this.handleInput}>
                                                    <option>Select</option>
                                                    <option value="stay">Stay</option>
                                                    <option value="non-stay">Non Stay</option>
                                                </select>
                                                {this.validator.message('type', this.state.type, 'required')}
                                            </Col>
                                            <Col md="auto" className="form-group mb-4">
                                                <label>Start Date</label>
                                                <div className="input-group">
                                                    <Flatpickr
                                                        className="form-control d-block"
                                                        placeholder="Start Date time"
                                                        name="start_date"
                                                        onChange={date => {
                                                            this.setState({ start_date: formatDate(date[0]) })
                                                        }}
                                                        options={{
                                                            enableTime: true,
                                                            altInput: true,
                                                            altFormat: "F j, Y H:m:i K",
                                                            dateFormat: "Y-m-d h:m:i"
                                                        }}
                                                    />
                                                </div>
                                                {this.validator.message('start_date', this.state.start_date, 'required')}
                                            </Col>
                                            <Col md="auto" className="form-group mb-4">
                                                <label>End Date</label>
                                                <div className="input-group">
                                                    <Flatpickr
                                                        className="form-control d-block"
                                                        placeholder="End Date time"
                                                        name="end_date"
                                                        onChange={date => {
                                                            this.setState({ end_date: formatDate(date[0]) })
                                                        }}
                                                        options={{
                                                            enableTime: true,
                                                            altInput: true,
                                                            altFormat: "F j, Y H:m:i K",
                                                            dateFormat: "Z"
                                                        }}
                                                    />
                                                </div>
                                                {this.validator.message('end_date', this.state.end_date, 'required')}
                                            </Col>
                                            {/* <Col md="auto" className="mb-3">
                                                <Label htmlFor="">Recommended</Label>
                                                <div className="square-switch mt-2">
                                                    <input type="checkbox" id="square-recommended" switch="none"
                                                           name="recommended" checked={this.state.recommended}
                                                           onChange={() => this.setState({recommended: !this.state.recommended})}
                                                    />
                                                    <label
                                                        htmlFor="square-recommended"
                                                        data-on-label="On"
                                                        data-off-label="Off"
                                                    />
                                                </div>
                                                {this.validator.message('recommended', this.state.recommended, 'boolean')}
                                            </Col> */}
                                            <Col md="4" className="mb-3">
                                                <label className="control-label">
                                                    Partners
                                                </label>
                                                <Select
                                                    isMulti={false}
                                                    name="partner"
                                                    onChange={this.handlePartners}
                                                    options={this.selectData(partners)}
                                                    classNamePrefix="select2-selection"
                                                    menuPortalTarget={document.body}
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 99999 }) }}
                                                />
                                                {this.validator.message('partner', this.state.selectedCategory, 'required')}
                                            </Col>
                                            <Col md="4" className="mb-3">
                                                <label className="control-label">
                                                    Deal Category
                                                </label>
                                                <Select
                                                    isMulti={true}
                                                    name="category"
                                                    onChange={this.handleCategory}
                                                    options={this.selectData(categories)}
                                                    classNamePrefix="select2-selection"
                                                    menuPortalTarget={document.body}
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 99999 }) }}
                                                />
                                                {this.validator.message('category', this.state.selectedCategory, 'required')}
                                            </Col>
                                            <Col md="4" className="mb-3">
                                                <label className="control-label">
                                                    Interests
                                                </label>
                                                <Select
                                                    isMulti={true}
                                                    name="interests"
                                                    onChange={e => { this.handleInterests(e); this.handleInterestTags(e) }}
                                                    options={this.selectData(interests)}
                                                    classNamePrefix="select2-selection"
                                                    menuPortalTarget={document.body}
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 99999 }) }}
                                                />
                                                {this.validator.message('interests', this.state.selectedInterests, 'required')}
                                            </Col>
                                            <Col md="4" className="mb-3">
                                                <label className="control-label">Tags</label>
                                                <CreatableSelect
                                                    isMulti={true}
                                                    value={this.state.selectedTags}
                                                    onChange={this.handleTags}
                                                    options={tags}
                                                    classNamePrefix="select2-selection"
                                                    isClearable
                                                    isDisabled={isLoading}
                                                    isLoading={isLoading}
                                                    onCreateOption={this.handleCreate}
                                                    menuPortalTarget={document.body}
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 99999 }) }}
                                                />
                                                {this.validator.message('tags', this.state.selectedTags, 'required')}
                                            </Col>
                                            <Col md="auto" className="mb-3">
                                                <Label htmlFor="validationCustom03">Order No.</Label>
                                                <input
                                                    name="ordering"
                                                    type="number"
                                                    placeholder="Order No."
                                                    className={"form-control"}
                                                    onChange={this.handleInput}
                                                />
                                                {this.validator.message('ordering', this.state.ordering, 'required|numeric')}
                                            </Col>
                                            <Col md="auto" className="mb-3">
                                                <Label htmlFor="">Status</Label>
                                                <div className="square-switch mt-2">
                                                    <input
                                                        type="checkbox"
                                                        id="square-switch-status"
                                                        switch="none"
                                                        name="status"
                                                        checked={this.state.status}
                                                        onChange={() => this.setState({ status: !this.state.status })}
                                                    />
                                                    <label
                                                        htmlFor="square-switch-status"
                                                        data-on-label="On"
                                                        data-off-label="Off"
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3" className="mb-3">
                                                <Label htmlFor="notification_type">
                                                    On Addition to new deals
                                                </Label>
                                                <select name="notification_msg" defaultValue={this.state.notification_msg} className={"form-select"} onChange={this.handleInput}>
                                                    <option>Select</option>
                                                    <option value="Deal ya no deal? We say, CT DEALs. Check out now!">Deal ya no deal? We say, CT DEALs. Check out now!</option>
                                                    <option value="Deal le lo! Deal!">Deal le lo! Deal!</option>
                                                    <option value="Ek taaza, taaza deal add hui hai. Check it out!">Ek taaza, taaza deal add hui hai. Check it out!</option>
                                                    <option value="Fresh off the rack! Here’s a new CT deal, just for YOU!">Fresh off the rack! Here’s a new CT deal, just for YOU! </option>
                                                    <option value="We’ve got something special for you. Tap to know more!">We’ve got something special for you. Tap to know more! </option>
                                                    <option value="Deal leke dekho ji! We have a new CT exclusive deal, just for you!">Deal leke dekho ji! We have a new CT exclusive deal, just for you!</option>
                                                    <option value="It is raining DEALS! ">It is raining DEALS! </option>
                                                    <option value="Mere usool, iDEALs yeh kehte hai, check out this new CT Deal!">Mere usool, iDEALs yeh kehte hai, check out this new CT Deal!</option>
                                                    <option value="Can’t deal with it? CT Deal with it! Check out this new exclusive offer!">Can’t deal with it? CT Deal with it! Check out this new exclusive offer!</option>
                                                    <option value="We have an offer you can’t refuse! Tap to know more">We have an offer you can’t refuse! Tap to know more</option>
                                                </select>

                                            </Col>
                                            <Col md="4" className="mb-3">
                                                <Label htmlFor="title">On Addition of deals which are near to my location</Label>
                                                <select name="notification_msg_within_city" defaultValue={this.state.notification_msg_within_city} className={"form-select"} onChange={this.handleInput}>
                                                    <option>Select</option>
                                                    <option value="Hop, skip, deal! You have a new deal!">Hop, skip, deal! You have a new deal!</option>
                                                    <option value="What’s new in (name of area)? Tap to check out this exclusive deal">What’s new in (name of area)? Tap to check out this exclusive deal</option>
                                                    <option value="Things To Do In ___? Here’s an exclusive CT Deal to help you plan">Things To Do In _? Here’s an exclusive CT Deal to help you plan</option>
                                                    <option value="Looking for a date night spot with bae? Check out this CT Deal">Looking for a date night spot with bae? Check out this CT Deal</option>
                                                    <option value="Deal for two! These are the HOT deals in your area">Deal for two! These are the HOT deals in your area</option>
                                                    <option value="Why go far, when you can go to CT App to check out exclusive deals in your area.">Why go far, when you can go to CT App to check out exclusive deals in your area.</option>
                                                    <option value="We have an exclusive deal just for you.">We have an exclusive deal just for you.</option>
                                                    <option value="Aao kabhi ___ pe! Check out this exclusive deal near you,">Aao kabhi _ pe! Check out this exclusive deal near you,</option>
                                                    <option value="We believe in staying your dil (deal) ke pass. Here’s an exclusive CT Deal for you!">We believe in staying your dil (deal) ke pass. Here’s an exclusive CT Deal for you!</option>
                                                    <option value="Aaj ka plan kya hai? Make now with this CT Deal!">Aaj ka plan kya hai? Make now with this CT Deal!</option>
                                                    <option value="Dil kya kare jab kisise, CT Deal se pyaar hojaye! Tap to know more!">Dil kya kare jab kisise, CT Deal se pyaar hojaye! Tap to know more!</option>
                                                    <option value="Aaj khaane mein kya hai? This CT Deal will help you decide!">Aaj khaane mein kya hai? This CT Deal will help you decide!</option>

                                                </select>

                                            </Col>
                                            <Col md="12" className="mb-3">
                                                <Label htmlFor="title">About</Label>
                                                <Editor
                                                    apiKey={process.env.REACT_APP_TINY_EDITOR}
                                                    initialValue={''}
                                                    init={{
                                                        height: 600,
                                                        menubar: true,
                                                        config: {},
                                                        plugins: [
                                                            'advlist autolink lists link image preview anchor',
                                                            'searchreplace code fullscreen',
                                                            'media paste'
                                                        ],
                                                        toolbar:
                                                            `preview  fullscreen | undo redo| link image media mediaembed | formatselect | bold italic | \
                                                            alignleft aligncenter alignright alignjustify | \
                                                            bullist numlist outdent indent | removeformat | code`,
                                                        image_title: true,
                                                        extended_valid_elements: 'script[src|async|defer|type|charset]',
                                                        forced_root_block: 'p',
                                                        keep_styles: true,
                                                        inline_boundaries: true,
                                                        branding: false,
                                                        visual: true
                                                    }}
                                                    onChange={this.handleEditorChange}
                                                />
                                                {this.validator.message('about', this.state.about, 'required')}
                                            </Col>
                                            <Col md="12" className="mb-3">
                                                <Label htmlFor="title">Tnc</Label>
                                                <Editor
                                                    apiKey={process.env.REACT_APP_TINY_EDITOR}
                                                    initialValue={''}
                                                    init={{
                                                        height: 600,
                                                        menubar: true,
                                                        config: {},
                                                        plugins: [
                                                            'advlist autolink lists link image preview anchor',
                                                            'searchreplace code fullscreen',
                                                            'media paste'
                                                        ],
                                                        toolbar:
                                                            `preview  fullscreen | undo redo| link image media mediaembed | formatselect | bold italic | \
                                                            alignleft aligncenter alignright alignjustify | \
                                                            bullist numlist outdent indent | removeformat | code`,
                                                        image_title: true,
                                                        extended_valid_elements: 'script[src|async|defer|type|charset]',
                                                        forced_root_block: 'p',
                                                        keep_styles: true,
                                                        inline_boundaries: true,
                                                        branding: false,
                                                        visual: true
                                                    }}
                                                    onChange={this.handleEditorChange1}
                                                />
                                                {this.validator.message('tnc', this.state.tnc, 'required')}
                                            </Col>
                                        </Row>

                                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                                            <Button color="primary" type="submit" disabled={submit}>
                                                {submit === true ? 'Please wait...' : 'Submit Data'}
                                            </Button>
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Modal
                isOpen={this.state.tagModal}
                centered
                >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">Create Tag</h5>
                    <button
                    type="button"
                    onClick={() => {
                        this.setState({ tagModal: false, isLoading: false })
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    >
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <form onSubmit={this.handleCustomTag}>
                    <div className="row">
                        <div className="col-md-6 mb-3">
                        <label>Interests</label>
                        <Select isMulti={false} onChange={e => { this.setState({ selected_interests: e }) }} options={this.selectData(interests)} classNamePrefix="select2-selection" required />
                        {this.state.selected_interests == '' && <div className="srv-validation-message">The category field is required.</div>}
                        </div>
                        <div className="col-md-6 mb-3">
                        <label>Tag Name</label>
                        <input className="form-control" defaultValue={this.state.customTag} placeholder="Enter Tag Name" onInput={(e) => this.setState({ customTag: e.target.value })} required />
                        </div>
                    </div>
                    <button type="submit" className="btn w-100 btn-primary">Submit</button>
                    </form>
                </div>
            </Modal>
        </React.Fragment>)
    }
}

DealsAdd.propTypes = {
    location: PropTypes.object,
    setReportLocation: PropTypes.any
}

export default DealsAdd
