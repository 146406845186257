import React from "react"
import MetaTags from "react-meta-tags"
import {Button, Card, CardBody, Col, Container, Label, Row,} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {apiKey} from "../../../helpers/url_helper"
import SimpleReactValidator from 'simple-react-validator'
import axios from "axios"
import toastr from "toastr"
import PropTypes from "prop-types"
import Select from "react-select";

let api_url = process.env.REACT_APP_API_URL
let api_key = apiKey()
let headers = {
    'Content-Type': 'application/json',
    "Authorization": 'Bearer ' + api_key,
}
const mystyle = {
    height: "110px",
    overflow: "hidden",
    width: "auto",
    objectFit:"cover"
}

const userSession = JSON.parse(localStorage.getItem("authUser"));
class StateEdit extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            status: true,
            name: "",
            selectedCountry:[],
            countries: [],
            country:"",
            ordering: "",
            handleResponse: null,
            submit: false,
            dataLoaded: false
        }
        this.validator = new SimpleReactValidator({autoForceUpdate: this})
    }
    handleInput = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    handleFileChange = (event) => {
        this.setState({
            image: event.target.files[0]
        })
    }


    componentDidMount() {
        fetch(`${api_url}/get-active-country?&status=1`, {headers})
            .then(response => response.json())
            .then(data => this.setState({countries: data.data}))
            .catch(err => {
                console.log("error:", err);
            });
        if (this.state.dataLoaded === false){
            const params = {
                type: "location_state",
                id: this.props.match.params.id
            }
            axios.get(`${api_url}/get-single-data`, {
                params, headers: headers
            })
                .then(res => {
                    if (res.data.status) {
                        let edit = res.data.data
                        this.setState({
                            status: edit.status,
                            name: edit.name,
                            ordering: edit.ordering,
                            selectedCountry: edit.countryId,
                            country: edit.countryId,
                            dataLoaded:true
                        })
                    } else {
                        toastr.danger('Server not response')
                    }
                })
        }
    }
    handleFormSubmit = (e) => {
        e.preventDefault()
        if (this.validator.allValid()) {
            const formData = {'id': this.props.match.params.id,
                'name': this.state.name,
                'status':this.state.status,
                "countryId": this.state.country,
                'ordering':this.state.ordering
            }
            axios({
                method: "post", url: `${api_url}/update-location-state`, data: formData, headers: headers
            }).then(response => {
                if (response.status) {
                    this.setState({submit: false})
                    toastr.success('State update successful.')
                    // eslint-disable-next-line react/prop-types
                    const {history} = this.props
                    // eslint-disable-next-line react/prop-types
                    const pageno = this.props.match.params.pId; // Get the pageno param from the URL
                    history.goBack();
                }
            }).catch(err => {
                toastr.error(err.message)
                this.setState({submit: false})
                alert(err.message)
            })
        } else {
            this.validator.showMessages()
            this.forceUpdate()
        }
    }
    selectData = a => {
        let b = []
        for (var c = 0; c < a.length; c++) b.push({
            label: a[c].name, value: a[c]._id
        })
        return b
    }
    handleCountry = (e) => {
        this.setState({selectedCountry:e.target.value})
    }
    render() {
        const {submit,dataLoaded,countries,country,selectedCountry} = this.state
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>
                            Country | {process.env.REACT_APP_PROJECTNAME}
                        </title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="State" path="/states/pageno/1" breadcrumbItem="Edit State"/>
                        {dataLoaded === false && <Card><CardBody><div className="text-center px-4 font-size-18 text-primary">Please wait form initialize ...</div></CardBody></Card> }
                        {dataLoaded === true &&
                            <Row>
                                <Col xl="12">
                                    <Card>
                                        <CardBody>
                                            <form onSubmit={this.handleFormSubmit}>
                                                <Row>
                                                    <Col md="3" className="mb-3">
                                                        <label className="control-label">
                                                            Country
                                                        </label>
                                                      <select name="country" value={selectedCountry} className={"form-select"} onChange={e => {this.handleInput(e);this.handleCountry(e)}}>
                                                            <option>Select</option>
                                                            {this.selectData(countries).map((opt, key) => {
                                                                return <option key={key} value={opt.value} >{opt.label}</option>;
                                                            })}
                                                        </select>
                                                        {this.validator.message('country', this.state.country, 'required')}
                                                    </Col>
                                                    <Col md="3" className="mb-3">
                                                        <Label htmlFor="name">State Name</Label>
                                                        <input name="name" placeholder="Type State Name"
                                                               defaultValue={this.state.name} type="text"
                                                               className={"form-control"} onChange={this.handleInput}/>
                                                        {this.validator.message('name', this.state.name, 'required')}
                                                    </Col>

                                                    <Col md={3} className="mb-3">
                                                        <label htmlFor="ordering">Order No.</label>
                                                        <input name="ordering" placeholder="Type Ordering"
                                                               defaultValue={this.state.ordering} type="text"
                                                               className={"form-control"} onChange={this.handleInput}/>
                                                        {this.validator.message('ordering', this.state.ordering, 'required|numeric')}
                                                    </Col>
                                                    <Col md="auto" className="mb-3">
                                                        <Label htmlFor="">Status</Label>
                                                        <div className="square-switch mt-2">
                                                            <input type="checkbox" id="square-switch-status"
                                                                   switch="none" name="status"
                                                                   checked={this.state.status}
                                                                   onChange={() => this.setState({status: !this.state.status})}/>
                                                            <label
                                                                htmlFor="square-switch-status"
                                                                data-on-label="On"
                                                                data-off-label="Off"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <div className="d-flex flex-wrap gap-2 justify-content-end">
                                                    <Button color="primary" type="submit" disabled={submit}>
                                                        {submit === true ? 'Please wait...' : 'Submit Data'}
                                                    </Button>
                                                </div>
                                            </form>
                                        </CardBody>
                                    </Card>
                                </Col>

                            </Row>
                        }
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

StateEdit.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string
        })
    }),
    name: PropTypes.object
}

export default StateEdit
