import React, {useEffect, useMemo, useState} from "react";
import { useParams, useHistory, useLocation } from 'react-router-dom';
import {Card, CardBody, CardTitle, Col, Row} from "reactstrap"
import MetaTags from 'react-meta-tags'
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { TableHeader, Search } from "../Datatable/index";
import {apiKey} from "helpers/url_helper"
import Pagination from 'components/Common/Pagination'



import axios from 'axios';
import {Link} from "react-router-dom";
import notification from "../../components/Common/Notification";
let api_url = process.env.REACT_APP_API_URL;
let api_key = apiKey()

const PageSize = 15;

const useFullPageLoader = () => {
    const [loading, setLoading] = useState(false);

    return [
        loading ? 'Loading' : null,
        () => setLoading(true), //Show loader
        () => setLoading(false) //Hide Loader
    ];
};

const Cities = (props) => {
    const history = useHistory();
    const location = useLocation();
    const { pId:pageno } = useParams();
    const [comments, setComments] = useState([]);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(() => {
        const paramPage = parseInt(pageno);
        if (!isNaN(paramPage)) {
            return paramPage;
          } else {
            return 1;
          }
      });
      const { searchQuery } = useParams();
      const [search, setSearch] = useState(() => {
          if (searchQuery) {
              return searchQuery;
            } else {
              return '';
            }
        });
      const [getSearch, setGetSearch] = useState(search);
      const [getSearchPage, setGetSearchPage] = useState(currentPage)
      const [sorting, setSorting] = useState({ field: "", order: "" });

    const ITEMS_PER_PAGE = 15;

    const headers = [
        { name: "#", field: "id", sortable: false },
        { name: "City Name", field: "city_name", sortable: false },
        { name: "Country", field: "", sortable: false },
        { name: "Category", field: "", sortable: false },
        { name: "City Image", field: "city_image", sortable: false },
        { name: "Season title", field: "season_title", sortable: false },
        { name: "Seasonal category", field: "seasonal_category_details", sortable: false },
        { name: "Order No.", field: "ordering", sortable: false },
        { name: "Status", field: "status", sortable: false },
       // { name: "Created By", field: "created_by", sortable: false },
        { name: "Action", field: "", sortable: false }
    ];
    const sample_data = [
        {'id':1,city_name:'Mumbai',city_image:'https://images.unsplash.com/photo-1562979314-bee7453e911c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8bXVtYmFpfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=200&q=60',ordering:1,status:'Active',created_by:'Admin'},
        {'id':2,city_name:'Mumbai',city_image:'https://images.unsplash.com/photo-1562979314-bee7453e911c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8bXVtYmFpfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=200&q=60',ordering:1,status:'Active',created_by:'Admin'},
        {'id':3,city_name:'Mumbai',city_image:'https://images.unsplash.com/photo-1562979314-bee7453e911c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8bXVtYmFpfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=200&q=60',ordering:1,status:'Active',created_by:'Admin'}
    ];
    function handleSearch(){
        setSearch(getSearch);
        setCurrentPage(getSearchPage);
        if(getSearch){
            history.push(`/cities/pageno/${getSearchPage}/${getSearch}`)
        }
    }
    const handleChange = (value) => {
        history.push(`/cities/pageno/${value}/${search}`)
    };
    // Handle any changes to the currentPage state
    const handlePageChange = () => {
        setCurrentPage(() => {
            const paramPage = parseInt(pageno);
            if (!isNaN(paramPage)) {
                return paramPage;
            } else {
                return 1;
            }
        })
    };
    useEffect(() => {
        handlePageChange();
    }, [pageno,location]);
    
    useEffect(() => {
        showLoader()
        let query = 'query=all&'
        search && ( query = `keyword=${search}`)
        const url = `${api_url}/get-cities?${query}&limit=${PageSize}&pageNo=${currentPage}`
        axios.get(url,{
            headers: {
                "Authorization": 'Bearer '+api_key,
            }
        })
          .then(res => {
              hideLoader();
              if(res.status) {
                  setComments(res.data.data);
                  setTotalItems(res.data.totalRecords);
                  console.log(res.data);
                }
          }).catch(
            err => {
                notification(err, 'error', 'toast-bottom-right')
                if(err.response.status===401 || err.response.status===400 ){
                    history.push('/logout')
                }
            }
        );
    }, [currentPage,search])
    // const getData = () => {
    //     let query = 'query=all&'
    //     search && ( query = `query=${search}&`)
    //     const url = `${api_url}/get-cities?${query}pageNo=${currentPage}`
    //     axios.get(url,{
    //         headers: {
    //             "Authorization": 'Bearer '+api_key,
    //         }
    //     })
    //         .then(res => {
    //             hideLoader();
    //             console.log(res.data);
    //             setComments(res.data.data);
    //            // setCurrentPage(res.data.page);
    //             setTotalItems(res.data.total_results);
    //         }).catch(err => notification(err,'error','toast-bottom-right'));
    //     // console.log(sample_data)
    //     // setComments(sample_data);
    //     // setCurrentPage(1);
    //     // setTotalItems(sample_data.length);
    // }, [currentPage,search])
    //
    // useEffect(getData, [currentPage]);

    const commentsData = useMemo(() => {
        let computedComments = comments;

        if (search) {
            computedComments = computedComments.filter(
                comment =>
                    comment.original_title.toLowerCase().includes(search.toLowerCase()) ||
                    comment.overview.toLowerCase().includes(search.toLowerCase())
            );
        }
        setTotalItems(computedComments.length);

        //Sorting comments
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort(
                (a, b) =>
                    reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        //Current Page slice
        return computedComments.slice(
            (currentPage - 1) * ITEMS_PER_PAGE,
            (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE,
        );
    }, []);
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Manage Cities</title>
                </MetaTags>
                <div className="container-fluid">
                    <Breadcrumbs title="Dashboard" path="/" breadcrumbItem="Cities"/>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <div className="d-flex justify-content-end mb-3">
                                        <div>
                                            <a href="/cities/add" className="btn btn-primary waves-effect waves-light"><i className="bx bx-plus font-size-16 align-middle me-2"></i> Add</a>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">

                                        </div>
                                        <div className="col-md-6 d-flex flex-row-reverse">
                                        <div onClick={handleSearch}
                                               className="btn btn-primary waves-effect waves-light ms-2">Search</div>
                                            <Search
                                                onSearch={value => {
                                                    setGetSearch(value)
                                                    setGetSearchPage(1)
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <table className="table table-striped">
                                        <TableHeader
                                            headers={headers}
                                            onSorting={(field, order) =>
                                                setSorting({ field, order })
                                            }
                                        />
                                        <tbody>
                                        {comments.map((value,index) => (
                                            <tr key={++index}>
                                                <th scope="row">
                                                    {PageSize * (currentPage - 1) + (index + 1)}
                                                    
                                                </th>
                                                <td>{value.city_name}</td>
                                                <td>{(value.country.length>0)?value.country[0].name:''}</td>
                                                <td>{value.categories.map((val, key) => (
                                                  <a onClick={() => category(value.categories[key])}
                                                     className="font-size-12 badge bg-secondary rounded-pill"
                                                     key={key}>{val['name']}</a>
                                                ))}</td>
                                                <td><img src={value.city_image} className="img-thumbnail avatar-md" width="200" alt={value.city_name}/></td>
                                                <td>{value.is_seasonal_active === true && value.season_title}</td>
                                                <td>{value.is_seasonal_active === true && value.seasonal_category_details.map((val,key) => {
                                                    return ( <span key={key}>{val.name}</span>)
                                                })}</td>
                                                <td>{value.ordering}</td>
                                                <td>{value.status === true ? <span
                                                    className="badge badge-pill badge-soft-success font-size-13">Active</span> :
                                                  <span
                                                    className="badge badge-pill badge-soft-error font-size-13">Inactive</span>}</td>
                                                <td><Link to={`/cities/${currentPage}/edit/${value._id}`} ><i className="bx bxs-edit-alt"></i></Link></td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                    <Row className="justify-content-center">
                                        <Col className="col-auto">
                                            <Pagination
                                                className="pagination-bar"
                                                currentPage={currentPage}
                                                totalCount={totalItems}
                                                pageSize={PageSize}
                                                onPageChange={page => history.push(`/cities/pageno/${page}/${search}`)}
                                            />
                                            
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Cities
