import React from "react"
import MetaTags from "react-meta-tags"
import {Button, Card, CardBody, Col, Container, Label, Row,} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {apiKey} from "../../helpers/url_helper"
import SimpleReactValidator from 'simple-react-validator'
import axios from "axios"
import toastr from "toastr"
import PropTypes from "prop-types"
import Select from "react-select";

let api_url = process.env.REACT_APP_API_URL
let api_key = apiKey()
const userSession = JSON.parse(localStorage.getItem("authUser"));
class LocationAdd extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            status: true,
            location: "",
            selectedCity:[],
            city: [],
            image: "",
            ordering: "",
            is_user_defined: false,
            handleResponse: null,
            show_on_main_page: false,
            submit: false,
        }
        this.validator = new SimpleReactValidator({autoForceUpdate: this})
    }
    componentDidMount() {
        const headers = {'Content-Type': 'application/json', "Authorization": 'Bearer ' + api_key}

        fetch(`${api_url}/get-active-city?&status=1`, {headers})
            .then(response => response.json())
            .then(data => this.setState({city: this.selectData(data.data)}))
            .catch(err => {
                console.log("error:", err);
            });
    }
    selectData = a => {
        let b = []
        for (var c = 0; c < a.length; c++) b.push({
            label: a[c].name, value: a[c]._id
        })
        return b
    }
    handleInput = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    handleFileChange = (event) => {
        this.setState({
            image: event.target.files[0]
        })
    }

    handleFormSubmit = (e) => {
        e.preventDefault()
        if (this.validator.allValid()) {
            const formData = new FormData()
            // formData.append('name', this.state.location)
            formData.append('name',this.state.selectedCity.label)
            formData.append('cityId', this.state.selectedCity.value)
            var image = document.getElementById("image").files[0];
            if (image){
                formData.append('image', this.state.image)
            }
            formData.append('user_id', userSession.id)
            formData.append("show_on_main_page", this.state.show_on_main_page);
            formData.append('is_user_defined', this.state.is_user_defined)
            formData.append('status', this.state.status)
            formData.append('ordering', this.state.ordering)
            this.setState({submit: true})
            axios({
                method: "post", url: `${api_url}/add-location`, data: formData, headers: {
                    'Content-Type': 'application/json',
                    "Authorization": 'Bearer ' + api_key,
                }
            }).then(response => {
                if (response.status) {
                    this.setState({submit: false})
                    toastr.success('Location added successful.')
                    // eslint-disable-next-line react/prop-types
                    const {history} = this.props
                    // eslint-disable-next-line react/prop-types
                    history.push('/location/pageno/1');

                }
            }).catch(err => {
                toastr.error(err.message)
                this.setState({submit: false})
                alert(err.message)
            })
        } else {
            this.validator.showMessages()
            this.forceUpdate()
        }
    }
    handleCity = (e) => {
        console.log(e);
        //  this.setState({selectedCity:e.value})
    }
    render() {

        const {submit,city} = this.state
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>
                            Location | {process.env.REACT_APP_PROJECTNAME}
                        </title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Location" path="/location/pageno/1" breadcrumbItem="Add Location"/>
                        <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                        <form onSubmit={this.handleFormSubmit}>
                                            <Row>
                                                <Col md="3" className="mb-3">
                                                    <label className="control-label">
                                                        City
                                                    </label>
                                                    <Select
                                                        isMulti={false}
                                                        name="city"
                                                        onChange={e => {this.setState({selectedCity: e}); this.handleCity(e)}}
                                                        options={city}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                    {this.validator.message('selectedCity', this.state.selectedCity, 'required')}
                                                </Col>
                                                {/*<Col md="3" className="mb-3">*/}
                                                {/*    <Label htmlFor="location">Location Name</Label>*/}
                                                {/*    <input name="location" placeholder="Type Location Name" type="text" className={"form-control"} onChange={this.handleInput}/>*/}
                                                {/*    {this.validator.message('location', this.state.location, 'required')}*/}
                                                {/*</Col>*/}
                                                <Col md="3" className="mb-3">
                                                    <Label htmlFor="image">Image</Label>
                                                    <input
                                                        id="image"
                                                        name="image"
                                                        placeholder="Image"
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={this.handleFileChange}
                                                        className={"form-control"}
                                                    />
                                                    {/* {this.validator.message('image', this.state.image, 'required')} */}
                                                </Col>
                                                <Col md={3} className="mb-3">
                                                    <label htmlFor="is_user_defined">Defined by ( CT/ User)</label>
                                                    <select className="form-control" defaultValue={this.state.is_user_defined} name="is_user_defined" onChange={this.handleInput}>
                                                        <option value="" selected disabled>Select a option</option>
                                                        <option value="true">User</option>
                                                        <option value="false">CT</option>
                                                    </select>
                                                    {this.validator.message('ordering', this.state.is_user_defined, 'required')}
                                                </Col>
                                                <Col md={3} className="mb-3">
                                                    <label htmlFor="ordering">Order No.</label>
                                                    <input name="ordering" placeholder="Type Ordering" type="text" className={"form-control"} onChange={this.handleInput}/>
                                                    {this.validator.message('ordering', this.state.ordering, 'required|numeric')}
                                                </Col>
                                                <Col md="auto" className="mb-3">
                                                    <Label htmlFor="">Show on main page</Label>
                                                    <div className="square-switch mt-2">
                                                        <input type="checkbox" id="square-show_on_main_page" switch="none" name="show_on_main_page"
                                                               checked={this.state.show_on_main_page} onChange={() =>
                                                            this.setState({ show_on_main_page: !this.state.show_on_main_page })
                                                        }
                                                        />
                                                        <label
                                                            htmlFor="square-show_on_main_page"
                                                            data-on-label="On"
                                                            data-off-label="Off"
                                                        />
                                                    </div>
                                                    {this.validator.message("show_on_main_page", this.state.show_on_main_page, "boolean")}
                                                </Col>
                                                <Col md="auto" className="mb-3">
                                                    <Label htmlFor="">Status</Label>
                                                    <div className="square-switch mt-2">
                                                        <input
                                                            type="checkbox"
                                                            id="square-switch-status"
                                                            switch="none"
                                                            name="status"
                                                            checked={this.state.status}
                                                            onChange={() =>
                                                                this.setState({status: !this.state.status})
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="square-switch-status"
                                                            data-on-label="On"
                                                            data-off-label="Off"
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>

                                            <div className="d-flex flex-wrap gap-2 justify-content-end">
                                                <Button color="primary" type="submit" disabled={submit}>
                                                    {submit === true ? 'Please wait...' : 'Submit Data'}
                                                </Button>
                                            </div>
                                        </form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

LocationAdd.propTypes = {
    location: PropTypes.object
}

export default LocationAdd
