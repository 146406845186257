import React from "react"
import ReactApexChart from "react-apexcharts"

const PieChart = ({ chartdata }) => {

    const series = chartdata
    const options = {
        chart: {
            height: 320,
            type: 'pie',
        },
        series: series,
        labels: ["Reels", "Deals", "Guide", "Scoop"],
        legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'center',
            verticalAlign: 'middle',
            floating: false,
            fontSize: '14px',
            offsetX: 0,
        },
        responsive: [{
            breakpoint: 600,
            options: {
                chart: {
                    height: 240
                },
                legend: {
                    show: false
                },
            }
        }]
    }

    return (
        <ReactApexChart options={options} series={series} type="pie" height="320" />
    )
}

export default PieChart
