import React, {useEffect, useState} from "react"
import { useParams, useHistory, useLocation } from 'react-router-dom';
import {Card, CardBody, Modal, Col, Row} from "reactstrap"
import MetaTags from 'react-meta-tags'
import Pagination from 'components/Common/Pagination'
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {TableHeader, Search} from "../Datatable/index"
import ReactPlayer from 'react-player'
import axios from "axios"
import notification from "../../components/Common/Notification"
import {apiKey} from "../../helpers/url_helper"
import Moment from 'moment';
import {Link} from "react-router-dom"
let api_url = process.env.REACT_APP_API_URL
let api_key = apiKey()
let PageSize = 15
const useFullPageLoader = () => {
    const [loading, setLoading] = useState(false)
    return [
        loading ? 'Loading' : null,
        () => setLoading(true), //Show loader
        () => setLoading(false) //Hide Loader
    ]
}
const mystyle = {
    width: "200px",
    overflow: "hidden",
    maxHeight: "40px",
    textOverflow: "ellipsis",
    whiteSpace: "pre-line",
}
const SocialList = (props) => {
    const history = useHistory();
    const location = useLocation();
    const { pagpId:pageno } = useParams();

    const [tableData, settableData] = useState([])
    const [loader, showLoader, hideLoader] = useFullPageLoader()
    const [totalItems, setTotalItems] = useState(0)
    const [currentPage, setCurrentPage] = useState(() => {
        const paramPage = parseInt(pageno);
        if (!isNaN(paramPage)) {
            return paramPage;
          } else {
            return 1;
          }
      });
      const { searchQuery } = useParams();
      const [search, setSearch] = useState(() => {
          if (searchQuery) {
              return searchQuery;
            } else {
              return '';
            }
        });
      const [getSearch, setGetSearch] = useState(search);
      const [getSearchPage, setGetSearchPage] = useState(currentPage)
      const [mediaModal, setMediaModal] = useState(false)
    const [mediaData, setMediaData] = useState("")
    const [AboutModal, setAboutModal] = useState(false)
    const [AboutData, setAboutData] = useState("")

    const Header = [
        {name: "#", field: "id", sortable: false},
        {name: "About", field: "about", sortable: false},
        {name: "Video URL", field: "video_url", sortable: false},
        {name: "Deep URL", field: "url_deeplunk", sortable: false},
        {name: "Like Count", field: "_like_count", sortable: false},
        {name: "Comment Count", field: "_comment_count", sortable: false},
        {name: "Interest", field: "interest", sortable: false},
        {name: "Tags", field: "tags", sortable: false},
        {name: "Location", field: "location", sortable: false},
        {name: "Created Date", field: "created_date", sortable: false},
        {name: "Comment List", field: "", sortable: false},
        {name: "Status", field: "status", sortable: false},
    ]


    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }
    function formatDate(date){
        return Moment(date).format('DD-MM-YYYY, h:mm:ss A')
    }

    
    function handleSearch(){
        setSearch(getSearch);
        setCurrentPage(getSearchPage);
        if(getSearch){
            history.push(`/social-list/pageno/${getSearchPage}/${getSearch}`)
        }
    }
    // Handle any changes to the currentPage state
    const handlePageChange = () => {
        setCurrentPage(() => {
            const paramPage = parseInt(pageno);
            if (!isNaN(paramPage)) {
                return paramPage;
            } else {
                return 1;
            }
        })
    };
    useEffect(() => {
        handlePageChange();
    }, [pageno,location]);

    useEffect(() => {
        showLoader()
        let query = ''
        search && (query = `keyword=${search}&`)
        const url = `${api_url}/get-social?${query}limit=${PageSize}&pageNo=${currentPage}`
        axios.get(url, {
            headers: {
                "Authorization": 'Bearer ' + api_key,
            }
        })
            .then(res => {
                hideLoader()
                if (res.status) {
                    settableData(res.data.data)
                    setTotalItems(res.data.totalRecords)
                }
            }).catch(
                err => {
                    notification(err, 'error', 'toast-bottom-right')
                    if(err.response.status===401 || err.response.status===400 ){
                        history.push('/logout')
                    }
                }
            );

    }, [currentPage, search])  // pass `value` as a dependency

    function tog_center() {
        removeBodyCss()
    }

    function media(prop) {
        setMediaModal(true)
        setMediaData(prop)
    }

    function ReadAbout(prop) {
        setAboutModal(true)
        setAboutData(prop)
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Social List</title>
                </MetaTags>
                <div className="container-fluid">
                    <Breadcrumbs title="Dashboard" path="/" breadcrumbItem="Social List"/>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <div className="row mb-2">
                                        <div className="col-md-6">

                                        </div>
                                        <div className="col-md-6 d-flex flex-row-reverse">
                                        <div onClick={handleSearch}
                                               className="btn btn-primary waves-effect waves-light ms-2"> Search</div>
                                            <Search
                                                onSearch={value => {
                                                    setGetSearch(value)
                                                    setGetSearchPage(1)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-striped">
                                            <TableHeader
                                                headers={Header}
                                            />
                                            <tbody style={{verticalAlign:'middle'}}>
                                            {loader && <tr><th colSpan={Header.length}><h4 className="text-center">Loading...</h4></th></tr>}
                                            {(!loader && tableData.length === 0) && <tr><th colSpan={Header.length}><h4 className="text-center">No data found</h4></th></tr>}
                                            {!loader && tableData.map((value, index) => (
                                                <tr key={++index}>
                                                    <th scope="row">
                                                        {PageSize * (currentPage - 1) + (index + 1)}
                                                    </th>
                                                    <td><div style={mystyle}
                                                             className="text-truncate">{value.about}</div>
                                                        <a className="" onClick={() => ReadAbout(value.about)}>Read
                                                            more <i
                                                                className="mdi mdi-arrow-right"></i></a></td>
                                                    <td>
                                                        {value.video_url && <button className="btn-sm btn btn-primary btn-label" onClick={() => media(value.video_url)}><i className="fas fa-video label-icon"></i> Video</button>}
                                                    </td>
                                                    <td>{value.url_deeplunk && <a target="_blank" href={value.url_deeplunk} rel="noreferrer">Deep Link</a>}</td>
                                                    <td>{value._like_count}</td>
                                                    <td>{value._comment_count}</td>
                                                    <td>{value.interest.map((value, key) => (
                                                        <span className="badge bg-primary font-size-12" key={key}>{value['name']}</span>
                                                    ))}</td>
                                                    <td>{value.tags.map((value, key) => (
                                                        <span className="badge bg-light font-size-12" key={key}>{value['name']}</span>
                                                    ))}</td>
                                                    <td>{value.location !== undefined && value.location_details.map((value, key) => (
                                                        <span className="badge bg-info font-size-12" key={key}>{value['name']}</span>
                                                    ))}</td>
                                                    <th>{value.created_date && <div style={{minWidth:"100px"}}>{formatDate(value.created_date)}</div>}</th>
                                                    <td><Link to={`/social-list/social-comment-list/${value._id}`}><i className="bx bx-comment"></i> Comment List</Link></td>
                                                    <td>{value.status === true ? <span className="badge badge-pill badge-soft-success font-size-13">Active</span> :
                                                        <span className="badge badge-pill badge-soft-error font-size-13">Inactive</span>}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <Row className="justify-content-center">
                                        <Col className="col-auto">
                                            <Pagination
                                                className="pagination-bar"
                                                currentPage={currentPage}
                                                totalCount={totalItems}
                                                pageSize={PageSize}
                                                onPageChange={page => history.push(`/social-list/pageno/${page}/${search}`)}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <Modal
                    size="lg"
                    isOpen={mediaModal}
                    toggle={() => {
                        tog_center()
                    }}
                    centered
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">Video</h5>
                        <button
                            type="button"
                            onClick={() => {
                                setMediaModal(false)
                                setMediaData("")
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <ReactPlayer playing={false} controls={true} width="100%" height="100%" url={mediaData} />
                    </div>
                </Modal>
                <Modal
                    size="lg"
                    isOpen={AboutModal}
                    toggle={() => {
                        tog_center()
                    }}
                    centered
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">About</h5>
                        <button
                            type="button"
                            onClick={() => {
                                setAboutModal(false)
                                setAboutData("")
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {AboutData}
                    </div>
                </Modal>
            </div>
        </React.Fragment>
    )
}

export default SocialList