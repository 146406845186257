import React, {useEffect, useState} from "react"
import { useParams, useHistory, useLocation } from 'react-router-dom';
import {Card, CardBody, Modal, Col, Row} from "reactstrap"
import MetaTags from 'react-meta-tags'
import Pagination from 'components/Common/Pagination'
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {TableHeader, Search} from "../Datatable/index"
import axios from "axios"
import notification from "../../components/Common/Notification"
import {apiKey} from "../../helpers/url_helper"
import {Link} from "react-router-dom"
let api_url = process.env.REACT_APP_API_URL
let api_key = apiKey()
let PageSize = 15
const useFullPageLoader = () => {
    const [loading, setLoading] = useState(false)
    return [
        loading ? 'Loading' : null,
        () => setLoading(true), //Show loader
        () => setLoading(false) //Hide Loader
    ]
}
function UserType(type,role){
    let roletype = "";
    role.map((val,key) => {
        if(type == val.id){
            roletype = val.name
        }
    })
    return (
        <span key={roletype}>{roletype}</span>
    )
}
const Coupons = (props) => {
    const history = useHistory();
    const location = useLocation();
    const { pId:pageno } = useParams();
    const [tableData, settableData] = useState([])
    const [userType, setUserType] = useState([])
    const [loader, showLoader, hideLoader] = useFullPageLoader()
    const [totalItems, setTotalItems] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const { searchQuery } = useParams();
    const [search, setSearch] = useState(() => {
        if (searchQuery) {
            return searchQuery;
          } else {
            return '';
          }
      });
    const [getSearch, setGetSearch] = useState(search);
    const [getSearchPage, setGetSearchPage] = useState(currentPage)
    
    const Header = [
        {name: "#", field: "id", sortable: false},
        {name: "Coupon Title", field: "coupon_title", sortable: false},
        {name: "Coupon Code", field: "coupon_code", sortable: false},
        {name: "Discount Type", field: "discount_type", sortable: false},
        {name: "Discount Value", field: "discount_value", sortable: false},
        {name: "Max Discount", field: "max_discount", sortable: false},
        {name: "Order Amount", field: "order_amount", sortable: false},
        // {name: "User Type", field: "", sortable: false},
        // {name: "Interests", field: "", sortable: false},
        // {name: "Partner", field: "", sortable: false},
        // {name: "Cities", field: "", sortable: false},
        // {name: "Deal Category", field: "", sortable: false},
        {name: "Deals", field: "", sortable: false},
        {name: "Status", field: "status", sortable: false},
        {name: "Action", field: "", sortable: false},
    ]
    const handleChange = (value) => {
        history.push(`/countries/pageno/${value}/${search}`)
    };
    // Handle any changes to the currentPage state
    const handlePageChange = () => {
        setCurrentPage(() => {
            const paramPage = parseInt(pageno);
            if (!isNaN(paramPage)) {
                return paramPage;
            } else {
                return 1;
            }
        })
    };
    function handleSearch(){
        setSearch(getSearch);
        setCurrentPage(getSearchPage);
        if(getSearch){
            history.push(`/coupons/pageno/${getSearchPage}/${getSearch}`)
        }
    }useEffect(() => {
        handlePageChange();
        // console.log('currenpage',currentPage)
    }, [pageno,location]);
useEffect(() => {
        axios.get(`${api_url}/user-roles`,{
            headers: {"Authorization": 'Bearer '+api_key}
        })
        .then(res => {
            hideLoader();
            if(res.status) {
                setUserType(res.data.data);
            }
        }).catch(
                err => {
                    notification(err, 'error', 'toast-bottom-right')
                    if(err.response.status===401 || err.response.status===400 ){
                        history.push('/logout')
                    }
                }
            );
    }, [])

    useEffect(() => {
        showLoader()
    
        const url = `${api_url}/get-coupons?limit=15&pageNo=${currentPage}&keyword=${search}`
        axios.get(url, {
            headers: {
                "Authorization": 'Bearer ' + api_key,
            }
        })
            .then(res => {
                hideLoader()
                if (res.status) {
                    settableData(res.data.data)
                    setTotalItems(res.data.totalRecords)
                }
            }).catch(
                err => {
                    notification(err, 'error', 'toast-bottom-right')
                    if(err.response.status===401 || err.response.status===400 ){
                        history.push('/logout')
                    }
                }
            );

    }, [currentPage, search])  // pass `value` as a dependency

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Coupons</title>
                </MetaTags>
                <div className="container-fluid">
                    <Breadcrumbs title="Dashboard" path="/" breadcrumbItem="Coupons"/>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <div className="row mb-2">
                                        <div className="col-md-6">

                                        </div>
                                        <div className="col-md-6 d-flex flex-row-reverse">
                                            <a href="/coupons/add" className="btn btn-primary waves-effect waves-light ms-2"><i className="bx bx-plus font-size-16 align-middle me-2"></i> Add</a>
                                            <div onClick={handleSearch}
                                               className="btn btn-primary waves-effect waves-light ms-2"> Search</div>
                                            <Search
                                                onSearch={value => {
                                                    setGetSearch(value)
                                                    setGetSearchPage(1)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-striped">
                                            <TableHeader
                                                headers={Header}
                                            />
                                            <tbody style={{verticalAlign:'middle'}}>
                                            {loader && <tr><th colSpan={Header.length}><h4 className="text-center">Loading...</h4></th></tr>}
                                            {(!loader && tableData.length === 0) && <tr><th colSpan={Header.length}><h4 className="text-center">No data found</h4></th></tr>}
                                            {!loader && tableData.map((value, index) => (
                                                <tr key={++index}>
                                                    <th scope="row">
                                                        {PageSize * (currentPage - 1) + (index + 1)}
                                                    </th>
                                                    <td>{value.coupon_title}</td>
                                                    <th>{value.coupon_code}</th>
                                                    <td>{value.discount_type !== undefined && value.discount_type[0].toUpperCase() + value.discount_type.slice(1)}</td>
                                                    <td>{value.discount_value !== undefined && value.discount_value}</td>
                                                    <td>{value.max_discount !== undefined && value.max_discount}</td>
                                                    <td>{value.order_amount}</td>
                                                    {/*<td>{value.user_type !== undefined && UserType(value.user_type,userType)}</td>*/}
                                                    {/*<td>{value.interest.map((value, key) => (*/}
                                                    {/*    <span className="font-size-12 badge bg-success rounded-pill"*/}
                                                    {/*          key={key}>{key+1}. {value['name']}</span>*/}
                                                    {/*))}</td>*/}
                                                    {/*<td>{value.partner.map((value, key) => (*/}
                                                    {/*    <span className="font-size-12 badge bg-primary rounded-pill"*/}
                                                    {/*          key={key}>{key+1}. {value['name']}</span>*/}
                                                    {/*))}</td>*/}
                                                    {/* <td>{value.city.map((value, key) => (
                                                        <span className="font-size-12 badge bg-info rounded-pill"
                                                              key={key}>{key+1}. {value['city_name']}</span>
                                                    ))}</td> */}
                                                    {/*<td>{value.deals_category.map((value, key) => (*/}
                                                    {/*    <span className="font-size-12 badge bg-warning rounded-pill"*/}
                                                    {/*          key={key}>{key+1}. {value['name']}</span>*/}
                                                    {/*))}</td>*/}
                                                     <td><ol type="number" className="ps-2">{value.deals.map((value, key) => (
                                                        <li className="font-size-12"
                                                              key={key}>{value['name']}</li>
                                                    ))}</ol></td>
                                                    <td>{value.status === true ? <span className="badge badge-pill badge-soft-success font-size-13">Active</span> :
                                                        <span className="badge badge-pill badge-soft-error font-size-13">Inactive</span>}</td>
                                                    <td><Link to={`/coupons/edit/${value._id}`}><i className="bx bxs-edit-alt"></i> </Link></td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <Row className="justify-content-center">
                                        <Col className="col-auto">
                                            <Pagination
                                                className="pagination-bar"
                                                currentPage={currentPage}
                                                totalCount={totalItems}
                                                pageSize={PageSize}
                                                onPageChange={page => history.push(`/coupons/pageno/${page}/${search}`)}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Coupons