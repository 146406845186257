import React from "react"
import MetaTags from "react-meta-tags"
import {Button, Card, CardBody, Col, Container, Label, Row,} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {apiKey} from "../../../helpers/url_helper"
import SimpleReactValidator from 'simple-react-validator'
import axios from "axios"
import toastr from "toastr"
import PropTypes from "prop-types"
import Select from "react-select";
import country from "../Country/Country";

let api_url = process.env.REACT_APP_API_URL
let api_key = apiKey()
const userSession = JSON.parse(localStorage.getItem("authUser"));
class StateAdd extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            status: true,
            name: "",
            selectedCountry:[],
            country: [],
            ordering: "",
            handleResponse: null,
            submit: false,
        }
        this.validator = new SimpleReactValidator({autoForceUpdate: this})
    }
    handleInput = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    handleFileChange = (event) => {
        this.setState({
            image: event.target.files[0]
        })
    }
    componentDidMount() {
        const headers = {'Content-Type': 'application/json', "Authorization": 'Bearer ' + api_key}

        fetch(`${api_url}/get-active-country?&status=1`, {headers})
            .then(response => response.json())
            .then(data => this.setState({country: this.selectData(data.data)}))
            .catch(err => {
                console.log("error:", err);
            });
    }
    selectData = a => {
        let b = []
        for (var c = 0; c < a.length; c++) b.push({
            label: a[c].name, value: a[c]._id
        })
        return b
    }
    handleFormSubmit = (e) => {
        e.preventDefault()
        if (this.validator.allValid()) {
            const formData = {
                "name": this.state.name,
                "countryId": this.state.selectedCountry.value,
                "ordering":  this.state.ordering,
                "status": this.state.status
            };
            this.setState({submit: true})
            axios({
                method: "post", url: `${api_url}/add-location-state`, data: formData, headers: {
                    'Content-Type': 'application/json',
                    "Authorization": 'Bearer ' + api_key,
                }
            }).then(response => {
                if (response.status) {
                    this.setState({submit: false})
                    toastr.success('State added successful.')
                    // eslint-disable-next-line react/prop-types
                    const {history} = this.props
                    // eslint-disable-next-line react/prop-types
                    history.push('/states/pageno/1');

                }
            }).catch(err => {
                toastr.error(err.message)
                this.setState({submit: false})
                alert(err.message)
            })
        } else {
            this.validator.showMessages()
            this.forceUpdate()
        }
    }

    render() {

        const {submit,country} = this.state
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>
                            State | {process.env.REACT_APP_PROJECTNAME}
                        </title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="State" path="/states/pageno/1" breadcrumbItem="Add State"/>
                        <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                        <form onSubmit={this.handleFormSubmit}>
                                            <Row>
                                                <Col md="3" className="mb-3">
                                                    <label className="control-label">
                                                        Country
                                                    </label>
                                                    <Select
                                                        isMulti={false}
                                                        name="country"
                                                        onChange={e => {this.setState({selectedCountry: e})}}
                                                        options={country}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                    {this.validator.message('selectedCountry', this.state.selectedCountry, 'required')}
                                                </Col>
                                                <Col md="3" className="mb-3">
                                                    <Label htmlFor="state">State Name</Label>
                                                    <input name="name" placeholder="Type State Name" type="text" className={"form-control"} onChange={this.handleInput}/>
                                                    {this.validator.message('name', this.state.name, 'required')}
                                                </Col>


                                                <Col md={3} className="mb-3">
                                                    <label htmlFor="ordering">Order No.</label>
                                                    <input name="ordering" placeholder="Type Ordering" type="text" className={"form-control"} onChange={this.handleInput}/>
                                                    {this.validator.message('ordering', this.state.ordering, 'required|numeric')}
                                                </Col>
                                                <Col md="auto" className="mb-3">
                                                    <Label htmlFor="">Status</Label>
                                                    <div className="square-switch mt-2">
                                                        <input
                                                            type="checkbox"
                                                            id="square-switch-status"
                                                            switch="none"
                                                            name="status"
                                                            checked={this.state.status}
                                                            onChange={() =>
                                                                this.setState({status: !this.state.status})
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="square-switch-status"
                                                            data-on-label="On"
                                                            data-off-label="Off"
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>

                                            <div className="d-flex flex-wrap gap-2 justify-content-end">
                                                <Button color="primary" type="submit" disabled={submit}>
                                                    {submit === true ? 'Please wait...' : 'Submit Data'}
                                                </Button>
                                            </div>
                                        </form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

StateAdd.propTypes = {
    name: PropTypes.object
}

export default StateAdd
