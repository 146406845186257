import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
} from "./actionTypes"

export const userForgetPassword = (user, history) => {

  console.log("test");
  console.log(user.email);

  // eslint-disable-next-line react/prop-types
  history.push({pathname: '/otp',    state: {
      email:user.email
    }})

 // return <Redirect to="/verifyOtp" email={user.email}/>;
 //  return {
 //    type: FORGET_PASSWORD,
 //    payload: { user, history },
 //  }

}
export const userOtp = (user, history) => {

  console.log("test");
  console.log(history,user);
  history.push({pathname: '/change-password',    state: {
      email:user.email
    }})
  // return {
  //   type: FORGET_PASSWORD,
  //   payload: { user, history },
  // }

}

export const userForgetPasswordSuccess = message => {
  return {
    type: FORGET_PASSWORD_SUCCESS,
    payload: message,
  }
}

export const userForgetPasswordError = message => {
  return {
    type: FORGET_PASSWORD_ERROR,
    payload: message,
  }
}
