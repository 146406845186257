import React from "react"
import MetaTags from "react-meta-tags"
import {Button, Card, CardBody, Col, Container, Label, Row,} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {apiKey} from "../../helpers/url_helper"
import SimpleReactValidator from 'simple-react-validator'
import axios from "axios"
import toastr from "toastr"
import PropTypes from "prop-types"

let api_url = process.env.REACT_APP_API_URL
let api_key = apiKey()

class FaqsAdd extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            rows: [],
            status: true,
            handleResponse: null,
            submit: false,
        }
        this.validator = new SimpleReactValidator({autoForceUpdate: this})
    }
    handleAddRow = () => {
        const item = {
            name: "",
        };
        this.setState({
            rows: [...this.state.rows, item],
        });
    };
    handleRemoveRow = (e, idx) => {
        if (typeof idx != "undefined") {
            document.getElementById("addr" + idx).remove()
        }
    };
    handleInput = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    handleFormSubmit = (e) => {
        e.preventDefault()
        let title = document.getElementsByName('title[]')
        let body = document.getElementsByName('body[]')
        let order = document.getElementsByName('ordering[]')
        let formData = [];
        for (let i = 0;i < title.length;i++){
            formData.push({
                "title": title[i].value,
                "body": body[i].value,
                "ordering": order[i].value,
                "status": this.state.status
            })
        }
        this.setState({submit: true})
        axios({
            method: "post", url: `${api_url}/add-faq`, data: formData, headers: {
                'Content-Type': 'application/json',
                "Authorization": 'Bearer ' + api_key,
            }
        }).then(response => {
            if (response.status) {
                this.setState({submit: false})
                toastr.success('Faqs added successful.')
                // eslint-disable-next-line react/prop-types
                const {history} = this.props
                // eslint-disable-next-line react/prop-types
                history.push('/faqs');

            }
        }).catch(err => {
            toastr.error(err.message)
            this.setState({submit: false})
            alert(err.message)
        })
    }

    render() {

        const {partner_name, ordering,submit} = this.state
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>
                            Faqs | {process.env.REACT_APP_PROJECTNAME}
                        </title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Faqs" path="/faqs" breadcrumbItem="Add Faqs"/>
                        <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                        <div className="d-flex justify-content-end">
                                            <Button onClick={this.handleAddRow} color="success" className="mt-3 mt-lg-0">
                                                Add{" "}
                                            </Button>
                                        </div>
                                        <form className="repeater" onSubmit={this.handleFormSubmit}>
                                            <div data-repeater-list="group-a">
                                                <Row data-repeater-item>
                                                    <Col md={12}><div className="d-flex align-items-center"><div className="font-size-20 text-success me-4">1.</div><hr style={{width:"250px"}}/></div> </Col>
                                                    <Col md={10}>
                                                        <Label htmlFor="">Title</Label>
                                                        <input className="form-control" name="title[]" required/>
                                                    </Col>
                                                    <Col md="2" className="mb-3">
                                                        <Label htmlFor="ordering">Order No.</Label>
                                                        <input name="ordering[]" type="number" placeholder="Order No." className={"form-control"}/>
                                                    </Col>
                                                    <Col md="12" className="mb-3">
                                                        <Label htmlFor="">Description</Label>
                                                        <textarea className="form-control" name="body[]" required></textarea>
                                                    </Col>
                                                </Row>
                                            </div>
                                            {this.state.rows.map((item, idx) => (
                                                <Row id={"addr" + idx} data-repeater-item key={idx}>
                                                    <Col md={12}><div className="d-flex align-items-center"><div className="font-size-20 text-success me-4">{idx+2}.</div><hr style={{width:"250px"}}/></div> </Col>
                                                    <Col md={10}>
                                                        <Label htmlFor="">Title</Label>
                                                        <input className="form-control" name="title[]" required/>
                                                    </Col>
                                                    <Col md="2" className="mb-3">
                                                        <Label htmlFor="ordering">Order No.</Label>
                                                        <input name="ordering[]" type="number" placeholder="Order No." className={"form-control"}/>
                                                    </Col>
                                                    <Col md="10" className="mb-3">
                                                        <Label htmlFor="">Description</Label>
                                                        <textarea className="form-control" name="body[]" required></textarea>
                                                    </Col>
                                                    <Col lg="2" className="align-self-center">
                                                        <div className="d-grid">
                                                            <Button
                                                                onClick={e =>
                                                                    this.handleRemoveRow(e, idx)
                                                                }
                                                                color="primary"
                                                                style={{ width: "100%" }}
                                                                block
                                                            >
                                                                {" "}
                                                                Delete
                                                            </Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            ))}
                                            <Row>
                                                <Col md="auto" className="mb-3">
                                                    <Label htmlFor="">Status</Label>
                                                    <div className="square-switch mt-2">
                                                        <input
                                                            type="checkbox"
                                                            id="square-switch-status"
                                                            switch="none"
                                                            name="status"
                                                            checked={this.state.status}
                                                            onChange={() =>
                                                                this.setState({status: !this.state.status})
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="square-switch-status"
                                                            data-on-label="On"
                                                            data-off-label="Off"
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <div className="d-flex flex-wrap gap-2 justify-content-end">
                                                <Button color="primary" type="submit" disabled={submit}>
                                                    {submit === true ? 'Please wait...' : 'Submit Data'}
                                                </Button>
                                            </div>
                                        </form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

FaqsAdd.propTypes = {
    location: PropTypes.object
}

export default FaqsAdd
