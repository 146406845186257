import React from "react"
import MetaTags from "react-meta-tags"
import { Button, Card, CardBody, Col, Container, Modal, Input, Label, Row, } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Select from "react-select"
import CreatableSelect from 'react-select/creatable';
import { apiKey } from "../../helpers/url_helper"
import SimpleReactValidator from 'simple-react-validator'
import axios from "axios"
import toastr from "toastr"
// import "style.min.css"
import PropTypes from "prop-types"
import { Editor } from '@tinymce/tinymce-react'

let api_url = process.env.REACT_APP_API_URL
let api_key = apiKey()
function getCityName(id, cities) {
    let selected_cities = [];
    for (var x = 0; x < id.length; x++) {
        const result = cities.find((name) => name['_id'] === id[x]);
        if (result !== undefined) {
            selected_cities.push({ label: result['city_name'], value: result['_id'] })
        }
    }
    return selected_cities;
}
function getDataName(id, data) {
    let selected = [];
    for (var x = 0; x < id.length; x++) {
        const result = data.find((name) => name['_id'] === id[x]);
        if (result !== undefined) {
            selected.push({ label: result['name'], value: result['_id'] })
        }
    }
    return selected;
}
function createRowData(data) {
    if (data !== undefined) {
        const content = htmlToDraft(data);
        if (content) {
            const contentState = ContentState.createFromBlockArray(content.contentBlocks);
            return EditorState.createWithContent(contentState);
        }
    }
}
class GuideEdit extends React.Component {
    selectRef = null;
    selectCategory = null;
    constructor(props) {
        super(props)
        this.state = {
            cities: [],
            categories: [],
            categories_master: [],
            interests: [],
            tags: [],
            active_deals: [],
            selectedCity: [],
            selectedCategory: [],
            selectedInterests: [],
            selectedTags: [],
            defaultCity: [],
            defaultCategory: [],
            defaultInterests: [],
            defaultTags: [],
            tags_data: [],
            is_deal_associated: false,
            recommended: false,
            show_on_main_page: true,
            status: true,
            title: "",
            deal_link: "",
            deal_id: "",
            body: "",
            ordering: "",
            handleResponse: null,
            submit: false,
            // custom tags for ------------
            tagModel: false,
            selected_interests: "",
            customTag: "",
            isLoading: false,
            totalLikes:0
            // editorState: EditorState.createEmpty(),
        }
        this.validator = new SimpleReactValidator({ autoForceUpdate: this })
    }

    componentDidMount() {
        const headers = { 'Content-Type': 'application/json', "Authorization": 'Bearer ' + api_key }
        const id = this.props.match.params.id
        this.setState({ edit_id: id })
        const params = {
            type: "guides",
            id: id
        }
        axios.get(`${api_url}/get-single-data`, {
            params, headers: {
                "Authorization": 'Bearer ' + api_key,
            }
        })
            .then(res => {
                if (res.data.status) {
                    let edit = res.data.data
                    this.setState({
                        city_data: edit['city'],
                        Category_data: edit['category'],
                        Interests_data: edit['interests'],
                        tags_data: edit['tags'],
                        title: edit['title'],
                        body: edit['body'],
                        old_body: edit['body'],
                        is_deal_associated: edit['is_deal_associated'],
                        deal_link: edit['deal_link'],
                        deal_id: edit['deal_id'],
                        ordering: edit['ordering'],
                        recommended: edit['recommended'],
                        show_on_main_page: edit['show_on_main_page'],
                        status: edit['status'],
                        totalLikes:edit['totalLikes']
                        // defaultInterests:edit['tags']
                    })
                } else {
                    toastr.danger('Server not response')
                }
            })
        fetch(`${api_url}/get-cities?&status=1`, { headers })
            .then(response => response.json())
            .then(data => {
                this.setState({ cities: data.data, defaultCity: getCityName(this.state.city_data, data.data) })
                this.setState({ selectedCity: this.state.defaultCity.map(({ value }) => value).join(',') })
                let city = ""
                if (this.state.city_data.length) {
                    city = { 'value': this.state.city_data[0] }
                }
                this.getCityBaseCategories(city)
            })

        fetch(`${api_url}/get-active-deals?&status=1`, { headers })
            .then(response => response.json())
            .then(data => this.setState({ active_deals: data.data }))
            .catch(err => {
                console.log("error:", err);
            });

        fetch(`${api_url}/get-interests?&status=1`, { headers })
            .then(response => response.json())
            .then(data => {
                this.setState({ interests: data.data, defaultInterests: getDataName(this.state.Interests_data, data.data) })
                this.setState({ selectedInterests: this.state.defaultInterests.map(({ value }) => value).join(',') })
                this.handleInterestTags(false, this.state.Interests_data)
            })
    }

    handleInterestTags = (e = false, g = false) => {
        let formData = [];
        if (e) {
            for (var a = 0; a < e.length; a++) {
                formData.push(e[a]['value'])
            }
        } else {
            for (var a = 0; a < g.length; a++) {
                formData.push(g[a])
            }
        }

        axios({
            method: "post", url: `${api_url}/get-interest-wise-tags`, data: { "interest": formData }, headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + api_key
            }
        }).then(response => {
            if (response.data) {
                let b = [], a = response.data.data;
                for (var c = 0; c < a.length; c++) b.push({
                    label: a[c].name,
                    value: a[c]._id
                });
                this.setState({ tags: b })
                this.setState({ tag_list: response.data.data }, this.handelSelectedTags)
                this.setState({ selectedTags: this.state.Interests_data })
            }
        }).catch(err => {
            toastr.error(err.message);
        });
    }

    getCityBaseCategories = (e) => {
        this.selectCategory.select.clearValue();
        axios({
            method: "post", url: `${api_url}/get-city-wise-category`, data: { "city": e.value }, headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + api_key
            }
        }).then(response => {
            if (response.data) {
                let b = [], a = response.data.data;
                for (var c = 0; c < a.length; c++) b.push({
                    label: a[c].name,
                    value: a[c]._id
                });
                this.setState({ categories: b, categories_master: a }, this.handleSelectedCategory)
            }
        }).catch(err => {
            toastr.error(err.message);
        });
    }

    deepLink = (e) => {
        const [option] = e.target.selectedOptions
        console.log(option.dataset.link);
        this.setState({ deal_link: option.dataset.link })
    }

    handelSelectedTags = () => {
        this.setState({ defaultTags: getDataName(this.state.tags_data, this.state.tag_list) })
    }

    handleSelectedCategory = () => {
        this.setState({ defaultCategory: getDataName(this.state.Category_data, this.state.categories_master) })
        this.setState({ selectedCategory: this.state.Category_data })
    }
    handleCity = selectedCity => {
        this.setState({ selectedCity: selectedCity.value })
        this.setState({ defaultCity: selectedCity })
    }
    handleCategory = selectedCategory => {
        if (selectedCategory) {
            this.setState({ selectedCategory: selectedCategory.map(({ value }) => value).join(',') })
            this.setState({ defaultCategory: selectedCategory })
        }
    }
    handleInterests = selectedInterests => {
        this.setState({ selectedInterests: selectedInterests.map(({ value }) => value).join(',') })
        this.setState({ defaultInterests: selectedInterests })
    }

    handleTags = selectedTags => {
        this.setState({ selectedTags: selectedTags.map(({ value }) => value).join(',') })
        this.setState({ defaultTags: selectedTags })
    }

    handleFileChange = (event) => {
        this.setState({
            cover_image: event.target.files[0]
        })
    }
    onEditorStateChange = (editorState) => {
        this.setState({
            editorState
        });
        // this.setState({ body: draftToHtml(convertToRaw(editorState.getCurrentContent())) })
        this.setState({ body: '' })
    }
    citiesData = a => {
        let b = []
        for (var c = 0; c < a.length; c++) b.push({
            label: a[c].city_name,
            value: a[c]._id
        })
        return b
    }
    selectData = a => {
        let b = []
        for (var c = 0; c < a.length; c++) b.push({
            label: a[c].name,
            value: a[c]._id
        })
        return b
    }
    handleInput = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    handleFormSubmit = (e) => {
        e.preventDefault()
        if (this.validator.allValid()) {
            const formData = new FormData()
            formData.append('title', this.state.title)
            var cover_image = document.getElementById("cover_image").files[0];
            if (cover_image) {
                formData.append('cover_image', this.state.cover_image)
            }
            formData.append('body', this.state.body)
            formData.append('deal_link', this.state.deal_link)
            formData.append('deal_id', (this.state.deal_id !== null ? this.state.deal_id : ""))
            formData.append('is_deal_associated', this.state.is_deal_associated)
            formData.append('status', this.state.status)
            formData.append('ordering', 0)
            formData.append('recommended', this.state.recommended)
            // Force true show on home page
            formData.append('show_on_main_page', true)
            formData.append('city', this.state.selectedCity)
            formData.append('category', this.state.selectedCategory)
            formData.append('interests', this.state.selectedInterests)
            formData.append('tags', this.state.selectedTags)
            formData.append('guideId', this.state.edit_id)
            formData.append("totalLikes", this.state.totalLikes);
            this.setState({ submit: true })
            axios({
                method: "post", url: `${api_url}/update-guide`, data: formData, headers: {
                    'Content-Type': 'application/json',
                    "Authorization": 'Bearer ' + api_key,
                }
            }).then(response => {
                if (response.status) {
                    this.setState({ submit: false })
                    toastr.success('Guide edit successful.')
                    // eslint-disable-next-line react/prop-types
                    const { history } = this.props
                    // eslint-disable-next-line react/prop-types
                    const pageno = this.props.match.params.pId; // Get the pageno param from the URL
                    // history.push(`/guides/pageno/${pageno}`); // Use pageno in the history.push statement
                    history.goBack();
                }
            }).catch(err => {
                toastr.error(err.message)
                this.setState({ submit: false })
                alert(err.message)
            })
        } else {
            this.validator.showMessages()
            this.forceUpdate()
        }
    }

    handleEditorChange = e => {
        // this.setState({ body: e.target.getContent() })
        this.setState({ body: '' })
    }

    handleCreate = (inputValue) => {
        this.setState({customTag:inputValue})
        this.setState({isLoading: true,tagModal:true})
    };
    // Custom Tags ==================================
    handleCustomTag = (e) => {
        e.preventDefault();
        var data = JSON.stringify({
        "name": this.state.customTag,
        "interest": this.state.selected_interests.value,
        "ordering": 1,
        "status": true,
        "user_id": 0,
        "is_user_defined": false,
    });
      axios({
          method: "post", url: `${api_url}/add-tag`, data: data, headers: {
              'Content-Type': 'application/json',
              "Authorization": 'Bearer ' + api_key,
          }
      })
      .then(response=> {
          toastr.success('Tag added successful.')
          if (response.data.status && response.data?.data?.upserted[0]?._id !== undefined) {
            this.setState({tags: [...this.state.tags, {label:this.state.customTag, value:response.data?.data?.upserted[0]?._id}]});
            this.setState({defaultTags: [...this.state.defaultTags, {label:this.state.customTag, value:response.data?.data?.upserted[0]?._id}]});
            this.setState({selected_interests: "",customTag:"",isLoading:false,tagModal:false})
          }
      })
      .catch(response => {
          toastr.error(response)
      });
  }
// Custom Tags end ================================

    render() {
        const { categories, cities, title, interests, ordering, defaultCategory, defaultCity, defaultInterests, active_deals, submit, isLoading, tags } = this.state
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>
                            Manage Guides | {process.env.REACT_APP_PROJECTNAME}
                        </title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Guides" path="/guides/pageno/1" breadcrumbItem="Edit Guide" />
                        <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                        <form onSubmit={this.handleFormSubmit}>
                                            <Row>
                                                <Col md="12" className="mb-3">
                                                    <Label htmlFor="title">Title</Label>
                                                    <input name="title" placeholder="Type Guide Title"
                                                        defaultValue={title} type="text" className={"form-control"}
                                                        onChange={this.handleInput} />
                                                    {this.validator.message('title', this.state.title, 'required|string')}
                                                </Col>
                                                {/* <Col md="12" className="mb-3">
                                                    <Label htmlFor="title">Description</Label>
                                                    <Editor
                                                        apiKey={process.env.REACT_APP_TINY_EDITOR}
                                                        initialValue={this.state.old_body}
                                                        init={{
                                                            height: 600,
                                                            menubar: true,
                                                            config: {},
                                                            plugins: [
                                                                'advlist autolink lists link image preview anchor',
                                                                'searchreplace code fullscreen',
                                                                'media paste'
                                                            ],
                                                            toolbar:
                                                                `preview  fullscreen | undo redo| link image media mediaembed | formatselect | bold italic | \
                                                                alignleft aligncenter alignright alignjustify | \
                                                                bullist numlist outdent indent | removeformat | code`,
                                                            image_title: true,
                                                            extended_valid_elements: 'script[src|async|defer|type|charset]',
                                                            forced_root_block: 'p',
                                                            keep_styles: true,
                                                            inline_boundaries: true,
                                                            branding: false,
                                                            visual: true
                                                        }}
                                                        onChange={this.handleEditorChange}
                                                    />
                                                    {this.validator.message('body', this.state.body, 'required')}
                                                </Col> */}
                                                <Col md="3" className="mb-3">
                                                    <Label htmlFor="cover_image">
                                                        Cover Image
                                                    </Label>
                                                    <input
                                                        id="cover_image"
                                                        name="cover_image"
                                                        placeholder="Cover Image"
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={this.handleFileChange}
                                                        className={"form-control"}
                                                    />
                                                    {/*{this.validator.message('cover_image', this.state.cover_image, '')}*/}
                                                </Col>
                                                <Col md="auto" className="mb-3">
                                                    <Label htmlFor="">Deal Associated</Label>
                                                    <div className="square-switch mt-2">
                                                        <input type="checkbox" id="square-switch2" defaultValue={1}
                                                            switch="none" name="is_deal_associated"
                                                            checked={this.state.is_deal_associated} onChange={() =>
                                                                this.setState({ is_deal_associated: !this.state.is_deal_associated })
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="square-switch2"
                                                            data-on-label="On"
                                                            data-off-label="Off"
                                                        />
                                                    </div>
                                                    {this.validator.message('is_deal_associated', this.state.is_deal_associated, 'required')}
                                                </Col>
                                                {this.state.is_deal_associated === true &&
                                                    <Col md="3" className="mb-3">
                                                        <Label htmlFor="deal_link">
                                                            Deals
                                                        </Label>
                                                        <select name="deal_id" value={this.state.deal_id}
                                                            className={"form-control deeplink"} onChange={e => {
                                                                this.handleInput(e);
                                                                this.deepLink(e)
                                                            }}>
                                                            {active_deals.map((val, key) => {
                                                                return <option key={key} data-link={val.deep_link}
                                                                    value={val._id}>{val.name}</option>
                                                            })}
                                                        </select>
                                                        {/*{this.validator.message("deal_id", this.state.deal_id, "required")}*/}
                                                    </Col>
                                                }
                                                {/* <Col md="auto" className="mb-3">
                                                    <Label htmlFor="">Recommended</Label>
                                                    <div className="square-switch mt-2">
                                                        <input type="checkbox" id="square-recommended" switch="none"
                                                            name="recommended" checked={this.state.recommended}
                                                            onChange={() =>
                                                                this.setState({ recommended: !this.state.recommended })
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="square-recommended"
                                                            data-on-label="On"
                                                            data-off-label="Off"
                                                        />
                                                    </div>
                                                    {this.validator.message('recommended', this.state.recommended, 'boolean')}
                                                </Col> */}
                                                {/* <Col md="auto" className="mb-3">
                                                    <Label htmlFor="">Show on main page</Label>
                                                    <div className="square-switch mt-2">
                                                        <input type="checkbox" id="square-show_on_main_page"
                                                            switch="none" name="show_on_main_page"
                                                            checked={this.state.show_on_main_page} onChange={() =>
                                                                this.setState({ show_on_main_page: !this.state.show_on_main_page })
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="square-show_on_main_page"
                                                            data-on-label="On"
                                                            data-off-label="Off"
                                                        />
                                                    </div>
                                                    {this.validator.message('show_on_main_page', this.state.show_on_main_page, 'boolean')}
                                                </Col> */}
                                                <Col md="3" className="mb-3">
                                                    <label className="control-label">
                                                        City
                                                    </label>
                                                    <Select
                                                        value={defaultCity}
                                                        isMulti={false}
                                                        name="cities"
                                                        onChange={e => { this.handleCity(e); this.getCityBaseCategories(e) }}
                                                        options={this.citiesData(cities)}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                    {this.validator.message('cities', this.state.selectedCity, 'required')}
                                                </Col>
                                                <Col md="3" className="mb-3">
                                                    <label className="control-label">
                                                        Category
                                                    </label>
                                                    <Select
                                                        ref={ref => {
                                                            this.selectCategory = ref;
                                                        }}
                                                        value={defaultCategory}
                                                        isMulti={true}
                                                        name="category"
                                                        onChange={this.handleCategory}
                                                        options={categories}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                    {this.validator.message('category', this.state.selectedCategory, 'required')}
                                                </Col>
                                                <Col md="3" className="mb-3">
                                                    <label className="control-label">
                                                        Interests
                                                    </label>
                                                    <Select
                                                        value={defaultInterests}
                                                        isMulti={true}
                                                        name="interests"
                                                        onChange={e => { this.handleInterests(e); this.handleInterestTags(e) }}
                                                        options={this.selectData(interests)}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                    {this.validator.message('interests', this.state.selectedInterests, 'required')}
                                                </Col>
                                                <Col md="3" className="mb-3">
                                                    <label className="control-label">Tags</label>
                                                    <CreatableSelect
                                                        isMulti={true}
                                                        value={this.state.defaultTags}
                                                        onChange={this.handleTags}
                                                        options={tags}
                                                        classNamePrefix="select2-selection"
                                                        isClearable
                                                        isDisabled={isLoading}
                                                        isLoading={isLoading}
                                                        onCreateOption={this.handleCreate}
                                                    />
                                                    {/*{this.validator.message('tags', this.state.selectedTags, 'required')}*/}
                                                </Col>
                                                <Col md="3" className="mb-3">
                                                <Label htmlFor="title">Static likes</Label>
                                                <input name="totalLikes" placeholder="Type Static Likes" type="number" className={"form-control"}
                                                    value={this.state.totalLikes}
                                                    onChange={this.handleInput} />
                                                {this.validator.message("title", this.state.totalLikes, "required|number")}
                                                </Col>
                                                {/*<Col md="auto" className="mb-3">*/}
                                                {/*    <Label htmlFor="validationCustom03">Order No.</Label>*/}
                                                {/*    <input*/}
                                                {/*        name="ordering"*/}
                                                {/*        type="number"*/}
                                                {/*        placeholder="Order No."*/}
                                                {/*        className={"form-control"}*/}
                                                {/*        onChange={this.handleInput}*/}
                                                {/*        defaultValue={ordering}*/}
                                                {/*    />*/}
                                                {/*    {this.validator.message('ordering', this.state.ordering, 'required|numeric')}*/}
                                                {/*</Col>*/}
                                                <Col md="3" className="mb-3">
                                                    <Label htmlFor="">Status</Label>
                                                    <div className="square-switch mt-2">
                                                        <input
                                                            type="checkbox"
                                                            id="square-switch-status"
                                                            switch="none"
                                                            name="status"
                                                            checked={this.state.status}
                                                            onChange={() =>
                                                                this.setState({ status: !this.state.status })
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="square-switch-status"
                                                            data-on-label="On"
                                                            data-off-label="Off"
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>

                                            <div className="d-flex flex-wrap gap-2 justify-content-end">
                                                <Button color="primary" type="submit" disabled={submit}>
                                                    {submit === true ? 'Please wait...' : 'Submit Data'}
                                                </Button>
                                            </div>
                                        </form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Modal
                    isOpen={this.state.tagModal}
                    centered
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">Create Tag</h5>
                        <button
                            type="button"
                            onClick={() => {
                            this.setState({tagModal:false,isLoading:false})
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={this.handleCustomTag}>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                            <label>Interests</label>
                            <Select isMulti={false} onChange={e => { this.setState({selected_interests : e})}} options={this.selectData(interests)} classNamePrefix="select2-selection" required/>
                            {this.state.selected_interests == '' && <div className="srv-validation-message">The category field is required.</div>}
                            </div>
                            <div className="col-md-6 mb-3">
                            <label>Tag Name</label>
                            <input className="form-control" defaultValue={this.state.customTag} placeholder="Enter Tag Name" onInput={(e) => this.setState({customTag : e.target.value})} required/>
                            </div>
                        </div>
                        <button type="submit" className="btn w-100 btn-primary">Submit</button>
                        </form>
                    </div>
                </Modal>
            </React.Fragment>
        )
    }
}

GuideEdit.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string
        })
    }),
    location: PropTypes.object
}

export default GuideEdit
