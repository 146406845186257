import React, {useEffect, useMemo, useState} from "react";
import { useHistory } from 'react-router-dom';
import {Card, CardBody, Col, Row} from "reactstrap"
import MetaTags from 'react-meta-tags'
import Pagination from 'components/Common/Pagination';
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {TableHeader, Search} from "../../Datatable/index";
import {apiKey} from "helpers/url_helper"
import {Link} from "react-router-dom";
import axios from 'axios';
import notification from "../../../components/Common/Notification"
import "react-image-lightbox/style.css";
import PropTypes from "prop-types";
import {Variants} from "../../Deals/Variant";
import toastr from "toastr";
let api_url = process.env.REACT_APP_API_URL;
let api_key = apiKey()

const useFullPageLoader = () => {
    const [loading, setLoading] = useState(false);

    return [
        loading ? 'Loading' : null,
        () => setLoading(true), //Show loader
        () => setLoading(false) //Hide Loader
    ];
};
let PageSize = 15;
const  useForceUpdate= (()=>{
    console.log("update render")
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update state to force render
    // A function that increment 👆🏻 the previous state like here
    // is better than directly setting `setValue(value + 1)`
})

const GuideReportSpams = (props) => {
    const history = useHistory();
    const [tableData, settableData] = useState([]);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [id, setId] = useState(props.match.params.id)
    const [approvalProcess, setApprovalProcess] = useState(false)
    const [approvalStatus, setApproval] = useState(false)
    const [ustatus, setuStatus] = useState([])
    const TableTopHeader = [
        {name: "#", field: "id", sortable: false},
        {name: "Guide", field: "guide", sortable: false},
        {name: "Comment", field: "country", sortable: false},
        {name: "Comment Owner", field: "user", sortable: false},
        // {name: "Status", field: "status", sortable: false},
        {name: "Active", field: "", sortable: false}
    ];

    const forceUpdate = useForceUpdate();
    useEffect(() => {
        showLoader()
        console.log(id);
        const url = (id!==undefined)?`${api_url}/get-spam-reports?type=guide&id=${id}&limit=${PageSize}&pageNo=${currentPage}`:`${api_url}/get-spam-reports?type=guide&limit=${PageSize}&pageNo=${currentPage}`
        axios.get(url,{
            headers: {
                "Authorization": 'Bearer '+api_key,
            }
        })
            .then(res => {
                hideLoader();
                if(res.status) {
                    settableData(res.data.data);
                    setTotalItems(res.data.totalRecords);

                }
            }).catch(
                err => {
                    notification(err, 'error', 'toast-bottom-right')
                    if(err.response.status===401 || err.response.status===400 ){
                        history.push('/logout')
                    }
                }
            );
    }, [currentPage])  // pass `value` as a dependency
    console.log('tableData',JSON.stringify(tableData));
    const handleApproved = (id,data,status,index) => {

      //  console.log('ustatus',index,JSON.stringify(data));

           // setApprovalProcess(true)
            let formData = {
                "Id": id,
                "status": status,
                "type":'guide_comment'
            }
            axios({
                method: "post", url: `${api_url}/update-status`, data: formData, headers: {
                    'Content-Type': 'application/json',
                    "Authorization": 'Bearer ' + api_key,
                }
            }).then(response => {
                setApprovalProcess(false)
                if (response.status) {
                   // setApproval(!approvalStatus)
                    console.log(data[index].guide_comments.status,'status',status);

                    data[index].guide_comments.status=status;
                    settableData(data);
                    // setuStatus( tableData.map((item) => {
                    //         console.log(item.id);
                    //         item.id === id ? {id:item.id, status: !item.status} : item
                    //         // console.log('ss', item.guide_comments._id);
                    //     }
                    // ))
                    forceUpdate()
                  //  console.log('tableData',JSON.stringify(tableData));
                    if(status)
                        toastr.success('Status Successfully Activate!')
                    else {
                        toastr.error('Status Successfully Inactive!')
                    }

                    //window.location.reload(true);

                }
            }).catch(err => {
                setApprovalProcess(false)
                toastr.error(err.response?.data?.message)
            })
    }
    function colorBox(param) {
        let colorBoxStyle = {height:'40px',width:'100px',background:param,border:'1px solid #eee',boxShadow:'rgb(158 158 158 / 44%) 0px 0px 10px',lineHeight:'40px',textAlign:'center',color:'#000'}
        return (
            <div style={colorBoxStyle}>{param}</div>
        )
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Guide Spam Comment Reports</title>
                </MetaTags>
                <div className="container-fluid">
                    {(id!==undefined)?
                    <Breadcrumbs title="Guides" path="/guides" breadcrumbItem="Guide Spam Comment Reports"/>:<Breadcrumbs title="Dashboard" path="/" breadcrumbItem="Guide Spam Comment Reports"/>
                    }
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>


                                    <table className="table table-striped">
                                        <TableHeader
                                            headers={TableTopHeader}
                                        />
                                        <tbody className="">

                                        {tableData.map((value,index) => (
                                            <tr key={++index}>
                                                <th scope="row">
                                                    {PageSize * (currentPage - 1) + (index + 1)}
                                                </th>
                                                <td>{value.guide_details.title}</td>
                                                <td>{value.guide_comments.comment}</td>
                                                <td>{value.guide_comments.user[0].name}</td>
                                                {/* <td>{value.image !== '' && <img className="img-thumbnail avatar-md" alt={value.image} src={value.image} width="300"/>}</td> */}
                                                {/*<td>{value.guide_comments.status?<span className="badge badge-pill badge-soft-success font-size-13">Active</span>: <span*/}
                                                {/*    className="badge badge-pill badge-soft-error font-size-13">Inactive</span>}</td>*/}
                                               <td>
                                                    <div className="square-switch">
                                                        <input type="checkbox" id={`square-switch-${index}`} switch="none" name="status" checked={value.guide_comments.status} onChange={(e) =>{handleApproved(value.guide_comments._id,tableData,!value.guide_comments.status,--index)}} /><label htmlFor={`square-switch-${index}`} data-on-label="Yes" data-off-label="No"/>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                    <Row className="justify-content-center">
                                        <Col className="col-auto">
                                            <Pagination
                                                className="pagination-bar"
                                                currentPage={currentPage}
                                                totalCount={totalItems}
                                                pageSize={PageSize}
                                                onPageChange={page => setCurrentPage(page)}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}
GuideReportSpams.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string
        })
    }),
    location: PropTypes.object
}
export default GuideReportSpams
