import React, {useEffect, useState} from "react"
import { useParams, useHistory, useLocation } from 'react-router-dom';
import {Card, CardBody, Modal, Col, Row} from "reactstrap"
import MetaTags from 'react-meta-tags'
import Pagination from 'components/Common/Pagination'
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {TableHeader, Search} from "../Datatable/index"
import ReactPlayer from 'react-player'
import {Link} from "react-router-dom"
import axios from "axios"
import notification from "../../components/Common/Notification"
import {apiKey} from "../../helpers/url_helper"
import Moment from 'moment';
import toastr from "toastr";
let api_url = process.env.REACT_APP_API_URL
let exportURL = api_url + "/get-reel-csv"
let api_key = apiKey()
let PageSize = 100
const useFullPageLoader = () => {
    const [loading, setLoading] = useState(false)
    return [
        loading ? 'Loading' : null,
        () => setLoading(true), //Show loader
        () => setLoading(false) //Hide Loader
    ]
}
const loaderStyle = {position:"fixed",left:"0",right:"0",top:"0",bottom:"0",zIndex:"9999",background:"rgba(0,0,0,.7)",display:"flex",alignItems:"center",justifyContent:"center"}
const Reels = (props) => {
    const history = useHistory();
    const location = useLocation();
    const { pId:pageno } = useParams();
    const [tableData, settableData] = useState([])
    const [loader, showLoader, hideLoader] = useFullPageLoader()
    const [csv, setCsv] = useState(null)
    const [totalItems, setTotalItems] = useState(0)
    const [currentPage, setCurrentPage] = useState(() => {
        const paramPage = parseInt(pageno);
        if (!isNaN(paramPage)) {
            return paramPage;
          } else {
            return 1;
          }
      });
      const { searchQuery } = useParams();
      const [search, setSearch] = useState(() => {
          if (searchQuery) {
              return searchQuery;
            } else {
              return '';
            }
        });
      const [getSearch, setGetSearch] = useState(search);
      const [getSearchPage, setGetSearchPage] = useState(currentPage)
      const [mediaModal, setMediaModal] = useState(false)
    const [mediaData, setMediaData] = useState("")
    const [approvalStatus, setApproval] = useState(false)
    const [approvalProcess, setApprovalProcess] = useState(false)
    const user_roles = [ 
        {id: 1, name: 'Superadmin', status: 1}, 
        {id: 2, name: 'Admin', status: 1}, 
        {id: 3, name: 'Creator', status: 1},
        {id: 4, name: 'Contributor', status: 1}, 
        {id: 5, name: 'Viewer', status: 1}
    ]
    const Header = [
        {name: "#", field: "id", sortable: false},
        {name: "Reel Title", field: "ct_reel_title", sortable: false},
        {name: "Reel thumbnail", field: "ct_reel_thumbnail", sortable: false},
        {name: "Reel video", field: "ct_reel_video", sortable: false},
        // {name: "Deal Associated", field: "is_deal_associated", sortable: false},
        // {name: "Associate Deals URL", field: "associate_deals_url", sortable: false},
        {name: "Interest", field: "interest", sortable: false},
        {name: "Tags", field: "tags", sortable: false},
        {name: "Creator Name", field: "creator_date", sortable: false},
        {name: "Creator Type", field: "creator_type", sortable: false},
        {name: "Associate Type", field: "associate_type", sortable: false},
        {name: "Associate Detail", field: "deal_details", sortable: false},
        {name: "Location", field: "location_details", sortable: false},
        {name: "Created Date", field: "created_date", sortable: false},
        // {name: "Ordering", field: "ordering", sortable: false},
        {name: "Approved", field: "is_approved", sortable: false},
        {name: "Total Spam Count", field: "spams_comment_count", sortable: false},        // {name: "Trending", field: "is_trending", sortable: false},
        {name: "Status", field: "status", sortable: false},
        {name: "Action", field: "", sortable: false}
    ]

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }
    function formatDate(date){
        return Moment(date).format('DD/MM/YYYY') //, h:mm:ss A
    }
    function handleSearch(){
        setSearch(getSearch);
        setCurrentPage(getSearchPage);
        if(getSearch){
            history.push(`/reels/pageno/${getSearchPage}/${getSearch}`)
        }
    }
    // Handle any changes to the currentPage state
    const handlePageChange = () => {
        setCurrentPage(() => {
            const paramPage = parseInt(pageno);
            if (!isNaN(paramPage)) {
                return paramPage;
              } else {
                return 1;
              }
          })
    };
    useEffect(() => {
        handlePageChange();
    }, [pageno,location]);

    useEffect(() => {
        settableData([])
        showLoader()
        // fetching table data
        let query = ''
        search && (query = `keyword=${search}&`)
        const url = `${api_url}/get-ctreels?${query}limit=${PageSize}&pageNo=${currentPage}`
        axios.get(url, {
            headers: {
                "Authorization": 'Bearer ' + api_key,
            }
        })
            .then(res => {
                if (res.status) {
                    settableData(res.data.data)
                    console.log(res.data.data)
                    setTotalItems(res.data.totalRecords) 
                }
                hideLoader()
            }).catch(
                err => {
                    notification(err, 'error', 'toast-bottom-right')
                    if(err.response.status===401 || err.response.status===400 ){
                        history.push('/logout')
                    }
                }
            );

    }, [currentPage, search, approvalStatus])  // pass `value` as a dependency

    function tog_center() {
        removeBodyCss()
    }

    function media(prop) {
        setMediaModal(true)
        setMediaData(prop)
    }

    function GuideBody(prop) {
        setbodyModal(true)
        setGuideBodyData(prop)
    }

    function category(prop) {
        setCategoryModal(true)
        setCategoryData(prop)
    }

    const handleApproved = (reel_id,data,status) => {
      if (data.ct_reel_video === ""){
          notification('Kindly add the video to approve the Reel', 'error', 'toast-top-right')
      }else {
        setApprovalProcess(true)
          let formData = {
              "Id": reel_id,
              "is_approved": status
          }
          axios({
              method: "post", url: `${api_url}/update-ctreels-approval`, data: formData, headers: {
                  'Content-Type': 'application/json',
                  "Authorization": 'Bearer ' + api_key,
              }
          }).then(response => {
            setApprovalProcess(false)
              if (response.status) {
                  setApproval(!approvalStatus)
                  toastr.success('Reel approval update.')
              }
          }).catch(err => {
            setApprovalProcess(false)
              toastr.error(err.response?.data?.message)
          })
      }
    }
    const handleUploadCSV=()=>{
        const url = `${api_url}/import-reels`
        let formData = new FormData();
        console.log(csv);
        // formData.append("id", id);
        formData.append("ct_reel_csv", csv);
        axios.post(url, formData, {
            headers: {
                "Authorization": 'Bearer ' + api_key,
            },
        })
            .then(res => {
                if (res.status) {
                    notification("Import done", 'success', 'toast-bottom-right')
                    window.location.reload(false);
                }
            }).catch(
                err => {
                    notification(err, 'error', 'toast-bottom-right')
                    if(err.response.status===401 || err.response.status===400 ){
                        history.push('/logout')
                    }
                }
            );
    }
    const handleChangeCSV=(e)=>{
        window.alert('csv changed')
        setCsv(e.target.files[0])
    }

    return (
        <React.Fragment>
            {approvalProcess === true && <div style={loaderStyle}><h4 className="text-white">Please Wait...</h4></div>}
            <div className="page-content">
                <MetaTags>
                    <title>Manage Reels</title>
                </MetaTags>
                <div className="container-fluid">
                    <Breadcrumbs title="Dashboard" path="/" breadcrumbItem="Reels"/>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <div className="row mb-2">
                                        <div className="col-md-6">
                                        <div>
                                            <a href={exportURL}
                                                target="_blank" rel="noreferrer"
                                               className="btn btn-primary waves-effect waves-light"><i
                                                className="bx bx-export font-size-16 align-middle me-2"></i> Export</a>
                                        </div>
                                        </div>
                                        <div className="col-md-6 d-flex flex-row-reverse">
                                            <div className="ms-2">
                                                <a href="/reels/add"
                                                   className="btn btn-primary waves-effect waves-light"><i
                                                    className="bx bx-plus font-size-16 align-middle me-2"></i> Add</a>
                                            </div>
                                            <div onClick={handleSearch}
                                               className="btn btn-primary waves-effect waves-light ms-2"> Search</div>
                                            <Search
                                                onSearch={value => {
                                                    setGetSearch(value)
                                                    setGetSearchPage(1)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-2 justify-content-end">
                                        <div className="col-md-6 d-flex flex-row-reverse">
                                            <div className="ms-2">
                                                <button onClick={handleUploadCSV}
                                                   className="btn btn-primary waves-effect waves-light"><i
                                                    className="bx bx-upload font-size-16 align-middle me-2"></i> Upload</button>
                                            </div>
                                            <input type="file" accept=".csv" id="custom-file-input" className="custom-file-input" onChange={handleChangeCSV} />
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-striped">
                                            <TableHeader
                                                headers={Header}
                                            />
                                            <tbody className="">
                                            {loader && <tr><th colSpan={Header.length}><h4 className="text-center">Loading...</h4></th></tr>}
                                            {(!loader && tableData.length === 0) && <tr><th colSpan={Header.length}><h4 className="text-center">No data found</h4></th></tr>}
                                            {tableData.map((value, index) => (
                                                <tr key={++index}>
                                                    <th scope="row">
                                                        {PageSize * (currentPage - 1) + (index + 1)}
                                                    </th>
                                                    <td><div style={{minWidth:"150px"}}>{value.ct_reel_title}</div></td>
                                                    <td><img src={value.ct_reel_thumbnail} className="img-thumbnail avatar-sm" width="200" alt="Reel Image"/></td>
                                                    <td>
                                                        <div className="btn-group btn-group-sm" role="group" aria-label="Reel Video">
                                                            {value.ct_reel_video && <button className="btn-sm btn-rounded btn btn-primary" onClick={() => media(value.ct_reel_video)}><i className="fas fa-photo-video"></i> Video</button>}
                                                            <Link className="btn-sm btn-rounded btn btn-outline-dark" to={`/reels/upload-video/${value._id}`}>Upload Video</Link>
                                                        </div>
                                                    </td>
                                                    {/*<td>{value.is_deal_associated!== undefined && value.is_deal_associated === true ? 'Yes' : 'No'}</td>*/}
                                                    {/*<td>{value.associate_deals_url && <a target="_blank" href={value.associate_deals_url} rel="noreferrer">Associate Deals</a>}</td>*/}
                                                    <td>{value.interest.map((value, key) => (
                                                      <span className="font-size-11 badge bg-info rounded-pill" key={key}>{value['name']}</span>
                                                    ))}</td>
                                                    <td>{value.tags.map((value, key) => (
                                                            <span className="font-size-11 badge bg-success rounded-pill" key={key}>{value['name']}</span>
                                                        ))}</td>
                                                    <td>{value.user_details.name&& <div style={{minWidth:"100px"}}>{value.user_details.name}</div>}</td>
                                                    <td>{value.user_details.roleId&& <div style={{minWidth:"100px"}}>{user_roles[value.user_details.roleId-1].name}</div>}</td>
                                                    <td>{value.associate_type !== undefined && value.associate_type}</td>
                                                    <td>{value.deal_details.length > 0 && value.deal_details.map((value, key) => (
                                                        <span className="font-size-11" key={key}>{value['name']}</span>
                                                    ))}
                                                        { value.deal_details.name !== undefined && <span className="font-size-11">{value.deal_details.name}</span>}
                                                    </td>
                                                    <td>{value.location !== undefined && value.location_details.map((value, key) => (
                                                        <span className="font-size-12" key={key}>{value['name']}</span>
                                                    ))}</td>
                                                    <th>{value.created_date && <div style={{minWidth:"100px"}}>{formatDate(value.created_date)}</div>}</th>
                                                    {/*<th>{value.ordering}</th>*/}
                                                    <td>
                                                        <div className="square-switch mt-2">
                                                            <input type="checkbox" value={true} id={`square-switch-${index}`} switch="none" name="status" checked={value.is_approved} onChange={(e) =>{handleApproved(value._id,value,!value.is_approved)}} /><label htmlFor={`square-switch-${index}`} data-on-label="Yes" data-off-label="No"/>
                                                        </div>
                                                    </td>
                                                    {/* <td>{value.is_trending !== undefined && value.is_trending === true ? <span className="badge badge-pill badge-soft-success font-size-13">Yes</span> :
                                                        <span className="badge badge-pill badge-soft-error font-size-13">No</span>}</td> */}
                                                    <th>{value.reel_comment_report_spams_counts>0? <Link  to={`/reels/spam_reports/${value._id}`} style={{color:"green"}}>{value.reel_comment_report_spams_counts}</Link>:0}</th>
                                                    <td>{value.status === true ? <span className="badge badge-pill badge-soft-success font-size-13">Active</span> :
                                                        <span className="badge badge-pill badge-soft-error font-size-13">Inactive</span>}</td>

                                                    <td>
                                                        <Link to={`/reels/${currentPage}/edit/${value._id}`}><i className="bx bxs-edit-alt"></i> </Link>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <Row className="justify-content-center">
                                        <Col className="col-auto">
                                            <Pagination
                                                className="pagination-bar"
                                                currentPage={currentPage}
                                                totalCount={totalItems}
                                                pageSize={PageSize}
                                                onPageChange={page => history.push(`/reels/pageno/${page}/${search}`)}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <Modal
                    size="lg"
                    isOpen={mediaModal}
                    toggle={() => {
                        tog_center()
                    }}
                    centered
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">Reel Video</h5>
                        <button
                            type="button"
                            onClick={() => {
                                setMediaModal(false)
                                setMediaData("")
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <ReactPlayer playing={true} controls={true} width="100%" height="100%" url={mediaData} />
                    </div>
                </Modal>
            </div>
        </React.Fragment>
    )
}

export default Reels
