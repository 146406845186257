import React from "react"
import {Col, InputGroup, Label, Row,} from "reactstrap"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import SimpleReactValidator from 'simple-react-validator'
import PropTypes from "prop-types"

export default class SpecialDate extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            special_price: "",
            special_dates: [],
        }
        this.validator = new SimpleReactValidator({autoForceUpdate: this})
    }

    handleInput = (e) => {
        this.setState({[e.target.name]: e.target.value}, () => {
            if (this.props.onChange) {
                this.props.onChange(this.state)
            }
        })
    }
    handleSpecialDate = (e) => {
        this.setState({special_dates: e}, () => {
            if (this.props.onChange) {
                // eslint-disable-next-line react/prop-types
                this.props.onChange(this.state)
            }
        })
    }
    handleDelete = () => {
        let idx = this.props.idx
        if (idx === 0) {
            document.getElementById("date-list-" + idx).style.display = "block"
        } else if (typeof idx != "undefined") {
            let d = document.getElementById("date-list-" + idx)
            if (d !== null) {
                document.getElementById("date-list-" + idx).remove()
                if (this.props.onChange) {
                    // eslint-disable-next-line react/prop-types
                    this.props.onChange(this.state)
                }
            }
        }
    }

    render() {
        return (
            <div id={`date-list-${this.props.idx}`}>
                <Row>
                    <Col md="4" className="mb-3">
                        <Label htmlFor="special_price">Special Price</Label>
                        <input name="special_price[]" type="text" defaultValue={this.props.price} placeholder="Special Price" onChange={this.handleInput}
                               className={"form-control"}/>
                        {this.validator.message('special_price', this.state.special_price, 'required')}
                    </Col>
                    <Col md="7" className="mb-3">
                        <Label htmlFor="special_dates">Special Dates</Label>
                        <InputGroup>
                            <Flatpickr
                                name="special_dates[]"
                                className="form-control d-block"
                                placeholder="Select Special Dates"
                                options={{
                                    enableTime: false,
                                    mode: "multiple",
                                    minDate: "today",
                                    dateFormat: "Y-m-d",
                                    defaultDate:this.props.defaultDate
                                }}
                                onChange={e => {
                                    this.handleSpecialDate(e)
                                }}
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">
                                <i className="mdi mdi-clock-outline"/>
                              </span>
                            </div>
                        </InputGroup>
                        {this.validator.message('special_dates', this.state.special_dates, 'required')}
                    </Col>
                    <Col md={1} className="align-self-center">{this.props.idx !== 0 &&
                        <a className="btn btn-rounded btn-sm btn-danger" onClick={this.handleDelete}><i
                            className="bx bx-minus"></i></a>}</Col>
                </Row>
            </div>
        )
    }
}

SpecialDate.propTypes = {
    onChange: PropTypes.any,
    idx: PropTypes.number,
    defaultDate: PropTypes.any,
    price: PropTypes.any,
}