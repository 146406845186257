import React from "react"
import MetaTags from "react-meta-tags"
import {Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Row,} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Select from "react-select"
import {apiKey} from "../../../helpers/url_helper"
import SimpleReactValidator from 'simple-react-validator'
import axios from "axios"
import toastr from "toastr"
// import "style.min.css"
import PropTypes from "prop-types"
let api_url = process.env.REACT_APP_API_URL
let api_key = apiKey()
const headers = { "Content-Type": "application/json", "Authorization": "Bearer " + api_key };
class BlogCategoryEdit extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            status: true,
            ordering:"",
            category_name:"",
            image:"",
            interest:"",
            interests_master: [],
            handleResponse: null,
            submit: false
        }
        this.validator = new SimpleReactValidator({autoForceUpdate: this})
    }

    componentDidMount() {
        this.load_interest()
        this.load_edit()
    }

    load_interest = () => {
        fetch(`${api_url}/get-interests?&status=1`, { headers })
            .then(response => response.json())
            .then(data => {
                if (data.status){
                    let interest_data = []
                    data.data.map((val,key) => {
                        interest_data.push({value:val._id,label:val.name})
                    })
                    this.setState({ interests_master: interest_data })
                }
            });
    }
    load_edit = () => {
        fetch(`${api_url}/get-single-data?type=blog_category&id=${this.props.match.params.id}`, { headers })
            .then(response => response.json())
            .then(data => {
                if (data.status){
                    let edit = data.data?.[0]
                    if (edit){
                        this.setState({
                            status: edit.status,
                          //  ordering: edit.ordering,
                            category_name: edit.name,
                            interest:{ value : edit?.interest_details?.[0]._id,label:edit?.interest_details?.[0].name},
                        })
                    }
                }
            });
    }
    handleFileChange = (event) => {
        this.setState({
            image: event.target.files[0]
        })
    }

    handleInput = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    handleFormSubmit = (e) => {
        e.preventDefault()
        if (this.validator.allValid()) {
            const formData = new FormData()
            if (this.state.image){
                formData.append('image', this.state.image)
            }
            formData.append('id', this.props.match.params.id)
            formData.append('name', this.state.category_name)
            formData.append('interest', this.state.interest?.value)
            formData.append('status', this.state.status)
            formData.append('ordering', 0)
            this.setState({submit: true})
            axios({
                method: "post", url: `${api_url}/update-blog-category`, data: formData, headers: headers
            }).then(response => {
                if (response.status) {
                    this.setState({submit: false})
                    toastr.success('Blog Categories update successful.')
                    // eslint-disable-next-line react/prop-types
                    const {history} = this.props
                    // eslint-disable-next-line react/prop-types
                    const pageno = this.props.match.params.pId; // Get the pageno param from the URL
                    history.push(`/blogs/categories`); // Use pageno in the history.push statement
                }
            }).catch(err => {
                toastr.error(err.message)
                this.setState({submit: false})
                alert(err.message)
            })
        } else {
            this.validator.showMessages()
            this.forceUpdate()
        }
    }

    render() {
        const { interests_master, ordering,submit,interest} = this.state
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>
                            Blog Categories | {process.env.REACT_APP_PROJECTNAME}
                        </title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Blog Categories" path="/blogs/categories" breadcrumbItem="Edit Category"/>
                        <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                        <form onSubmit={this.handleFormSubmit}>
                                            <Row>
                                                <Col md="4" className="mb-3">
                                                    <Label htmlFor="category_name">Category Name</Label>
                                                    <input name="category_name" placeholder="Type Category Name" defaultValue={this.state.category_name} type="text" className={"form-control"} onChange={this.handleInput}/>
                                                    {this.validator.message('category_name', this.state.category_name, 'required|string')}
                                                </Col>
                                                <Col md="4" className="mb-3">
                                                    <Label htmlFor="image">Image</Label>
                                                    <input type="file" accept="image/*" onChange={this.handleFileChange} className={"form-control"}/>
                                                    {/*{this.validator.message('image', this.state.image, 'required')}*/}
                                                </Col>
                                                <Col md="3" className="mb-3">
                                                    <label className="control-label">Interests</label>
                                                    <Select isMulti={false} value={interest} onChange={e => {this.setState({interest:e})}} options={interests_master} classNamePrefix="select2-selection"/>
                                                    {this.validator.message("interest", this.state.interest, "required")}
                                                </Col>
                                                {/*<Col md="3" className="mb-3">*/}
                                                {/*    <Label htmlFor="associate_deals_url">*/}
                                                {/*        Order No.*/}
                                                {/*    </Label>*/}
                                                {/*    <input name="ordering" placeholder="Type order No." type="text" defaultValue={ordering} className={"form-control"} onChange={this.handleInput}/>*/}
                                                {/*    {this.validator.message('ordering', this.state.ordering, 'required')}*/}
                                                {/*</Col>*/}
                                                <Col md="3" className="mb-3">
                                                    <Label htmlFor="">Status</Label>
                                                    <div className="square-switch mt-2">
                                                        {/* eslint-disable-next-line react/no-unknown-property */}
                                                        <input type="checkbox" id="square-switch-status" switch="none" name="status" checked={this.state.status} onChange={() =>
                                                            this.setState({status: !this.state.status})
                                                        }
                                                        />
                                                        <label htmlFor="square-switch-status" data-on-label="On" data-off-label="Off"/>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <div className="d-flex flex-wrap gap-2 justify-content-end">
                                                <Button color="primary" type="submit" disabled={submit}>
                                                    {submit === true ? 'Please wait...' : 'Submit Data'}
                                                </Button>
                                            </div>
                                        </form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

BlogCategoryEdit.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string
        })
    }),
    location: PropTypes.object
}

export default BlogCategoryEdit
