import React, {Component} from "react"
import MetaTags from "react-meta-tags"
import {Card, CardBody, Container} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import PropTypes from "prop-types"
import UploadFiles from "../../../../components/upload-files.component"

class VariantMedia extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoaded: false,
        }
    }

    render() {
        return (<React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>
                        Manage Variant Media | {process.env.REACT_APP_PROJECTNAME}
                    </title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title="Manage Variant" path={`/deals/variants/${this.props.match.params.deal}`} breadcrumbItem="Add Media"/>
                    <Card>
                        <CardBody>
                        <h5>{decodeURIComponent(this.props.match.params.title)}</h5>
                            <UploadFiles id={this.props.match.params.id} allow_file="image/*,video/mp4" type="deals_variants"/>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>)
    }
}

VariantMedia.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
            deal: PropTypes.string
        })
    }),
    location: PropTypes.object
}
export default VariantMedia
