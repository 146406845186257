import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  SOCIAL_LOGIN,
} from "./actionTypes"

import toastr from "toastr";
let api_url = process.env.REACT_APP_API_URL
export const  loginUser = async (user, history) => {
  console.log("test");
  console.log(history,user);
  const req2 = await fetch(`${api_url}/login`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      "email": user['email'],
      "password": user['password']
    }),
  });
  const data2 = await req2.json();
  if(data2.status){
    toastr.success('Login successful.')
    let object=data2.data;
    object.username=data2.data.name;
    localStorage.setItem('authUser',JSON.stringify(object));
    // loginSuccess(data2.data);
     location.href='dashboard';
  } else {
    toastr.error(data2.message);
  }


 // let formData = JSON.parse(JSON.stringify(user));
 //  var data =  JSON.stringify({
 //    "email": user['email'],
 //    "password": user['password']
 //  });
 //   axios.post(`${api_url}/login`, data,{ headers: {
 //      'Content-Type': 'application/json'
 //    }
 //  })
 //    .then(function (response) {
 //      toastr.success('Login successful.')
 //
 //    })
 //    .catch(function (response) {
 //      console.log(response);
 //      toastr.error(response)
 //    });
  // return {
  //   type: LOGIN_USER,
  //   payload: { user, history },
  // }
}

export const loginSuccess = user => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  }
}

export const logoutUser = history => {
  localStorage.setItem('authUser','');
  return {
    type: LOGOUT_USER,
    payload: { history },
  }
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

export const socialLogin = (data, history, type) => {
  return {
    type: SOCIAL_LOGIN,
    payload: { data, history, type },
  }
}
