import React, {Component, useEffect} from "react";
import MetaTags from "react-meta-tags";
import {Button, Card, CardBody, Col, Container, Input, Label, Row,} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {SketchPicker} from "react-color"
import "@vtaits/react-color-picker/dist/index.css"
import axios from 'axios';
import {apiKey} from "../../helpers/url_helper";
import PropTypes from "prop-types";
import toastr from "toastr";

let api_url = process.env.REACT_APP_API_URL;
let api_key = apiKey()

class CategoriesEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            validation: "",
            categoryName: "",
            // seasonal_title: "",
            text_color: "",
            default_rating: 5,
            user_rating: 0,
            ordering: 0,
            // is_seasonal: false,
            status: false,
            is_user_rating_visible: false,
            handleResponse: null,
            submit: false,
            edit_id: 0,
            simple_color1: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangeValidation = this.onChangeValidation.bind(this);
        this.reader = new FileReader();
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        this.setState({edit_id: id})
        const params = {
            type: "guide_categories",
            id: id
        };
        axios.get(`${api_url}/get-single-data`, {params,headers: {
                "Authorization": 'Bearer ' + api_key,
            }})
            .then(res => {
                if (res.data.status) {
                    let edit = res.data.data;
                    this.setState({
                        default_rating:edit['default_rating'],
                        // is_seasonal:edit['is_seasonal'],
                        is_user_rating_visible:edit['is_user_rating_visible'],
                        status:edit['status'],
                        user_rating:edit['user_rating'],
                        categoryName:edit['name'],
                        text_color:edit['text_color'],
                        ordering:edit['ordering'],
                        // seasonal_title:edit['seasonal_title'],
                    })
                    this.onChangeValidation("categoryName", edit['name'])
                    this.onChangeValidation("text_color", edit['text_color'])
                } else {
                    toastr.danger('Server not response')
                }
            })
    }

    onChangeFile = event => {
        const imageFile = event.target.files[0];

        if (!imageFile) {
            event.target.value = ""
            this.setState({invalidImage: 'Please select image.'});
            console.log('Please select image.')
            return false;
        }

        if (!imageFile.name.match(/\.(jpg|jpeg|png|gif)$/)) {
            this.setState({invalidImage: 'Please select valid image.'});
            event.target.value = ""
            console.log('Please select valid image.')
            return false;
        }
    }

    handleSubmit(e) {
        e.preventDefault();

        const modifiedV = {...this.state.validation};
        var categoryName = document.getElementById("categoryName").value;
        var filled_image = document.getElementById("filled_image").files[0];
        var unfilled_image = document.getElementById("unfilled_image").files[0];
        var text_color = document.getElementById("text_color").value;
        var default_rating = document.getElementById("default_rating").value;
        var user_rating = document.getElementById("user_rating").value;
        // var seasonal_title = document.getElementById("seasonal_title").value;
        var ordering = document.getElementById("ordering").value;
        // var seasonal = this.state.is_seasonal;
        var user_rating_visible = this.state.is_user_rating_visible;
        var active = this.state.status;

        modifiedV["categoryName"] = categoryName !== "";

        modifiedV["text_color"] = text_color !== "";

        this.setState({validation: modifiedV});

        const formData = new FormData();
        formData.append('catId', this.state.edit_id);
        if (filled_image){
            formData.append('filled_image', filled_image);
        }
        if (unfilled_image){
            formData.append('unfilled_image', unfilled_image);
        }
        formData.append('name', categoryName);
        formData.append('text_color', text_color);
        formData.append('ordering', ordering);
        formData.append('default_rating', default_rating);
        formData.append('user_rating', user_rating);
        formData.append('is_user_rating_visible', user_rating_visible);
        formData.append('status', active);
        // formData.append('is_seasonal', seasonal);
        // formData.append('seasonal_title', seasonal_title);
        this.setState({submit: true})
        axios.post(api_url + '/update-guide-category', formData, {
            headers: {
                "Authorization": 'Bearer ' + api_key,
            }
        }).then(response => {
            if (response.status) {
                this.setState({submit: false})
                toastr.success('Category update successful.')
                // eslint-disable-next-line react/prop-types
                const {history} = this.props;
                // eslint-disable-next-line react/prop-types
                history.goBack(); 
            }
        }).catch(err => {
            toastr.error(err.message)
            this.setState({submit: false})
            alert(err.message);
        });

    }

    twoCalls = e => {
        this.onChangeValidation("filled_image", e)
        this.onChangeFile(e)
    }

    //for change tooltip display propery
    onChangeValidation(fieldName, value) {
        const modifiedV = {...this.state.validation};
        if (value !== "") {
            modifiedV[fieldName] = true;
        } else {
            modifiedV[fieldName] = false;
        }

        if (modifiedV['unfilled_image']) {
            var unfilled_image = document.getElementById("unfilled_image").files[0];
            if (!unfilled_image) {
                document.getElementById("unfilled_image").value = ""
                this.setState({invalidUnfilledImage: 'Please select image.'});
                modifiedV["unfilled_image"] = false;
            }

            if (!unfilled_image.name.match(/\.(jpg|jpeg|png|gif)$/)) {
                this.setState({invalidUnfilledImage: 'Please select valid image.'});
                document.getElementById("unfilled_image").value = ""
                console.log('Please select valid image.')
                modifiedV["unfilled_image"] = false;
            }
        }
        this.setState({validation: modifiedV});
    }

    render() {
        const {
            simple_color2, categoryName, default_rating, user_rating, status, validation, invalidUnfilledImage, invalidImage, submit ,ordering ,text_color
        } = this.state;
        const handleChange = (event) => {
            this.setState({default_rating: event.target.value})
        }
        const handleChangeUser = (event) => {
            this.setState({user_rating: event.target.value})
        }
        const handleChangeStatus = (event) => {
            this.setState({status: !status})
        }
        const handleHor = color => {
            this.setState({text_color: color.hex})
        }
        return (<React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>
                        Manage Categories | {process.env.REACT_APP_PROJECTNAME}
                    </title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title="Categories" path="/categories/pageno/1" breadcrumbItem="Edit Categories"/>
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <form className="needs-validation" method="post" id="tooltipForm"
                                          onSubmit={e => {
                                              this.handleSubmit(e);
                                          }}>
                                        <Row>
                                            <Col md="4" className="mb-3">
                                                <Label htmlFor="Category">Category name</Label>
                                                <Input name="categoryName" id="categoryName"
                                                       placeholder="Category name" type="text" defaultValue={categoryName}
                                                       className={"form-control"} onChange={event => {
                                                    this.onChangeValidation("categoryName", event.target.value);
                                                }}
                                                       valid={validation["categoryName"] === true}
                                                       invalid={validation["categoryName"] !== true && validation["categoryName"] !== null}
                                                       required aria-required={true}/>
                                                <div
                                                    className={validation["categoryName"] === true ? "valid-feedback" : "invalid-feedback"}
                                                    name="validate" id="categoryName">
                                                </div>
                                            </Col>
                                            <Col md="3" className="mb-3">
                                                <Label htmlFor="filled_image">Filled Image</Label>
                                                <Input name="filled_image" id="filled_image"
                                                       placeholder="Filled Image" type="file"
                                                       accept={"image/png,image/jpeg"} className={"form-control"}
                                                       onChange={event => {
                                                           this.onChangeFile(event)
                                                       }}
                                                />
                                                {invalidImage &&
                                                    <div className="invalid-feedback">{invalidImage}</div>}
                                            </Col>
                                            <Col md="3" className="mb-3">
                                                <Label htmlFor="unfilled_image">Unfilled Image</Label>
                                                <Input name="unfilled_image" id="unfilled_image"
                                                       placeholder="Unfilled Image" type="file"
                                                       accept={"image/png,image/jpeg"} className={"form-control"}/>
                                                <div
                                                    className={validation["unfilled_image"] === true ? "valid-feedback" : "invalid-feedback"}
                                                    name="validate" id="unfilled_image">
                                                </div>
                                                {invalidUnfilledImage &&
                                                    <div className="invalid-feedback">{invalidUnfilledImage}</div>}
                                            </Col>
                                            <Col md="2" className="mb-3">
                                                <Label htmlFor="text_color">Text Color</Label>
                                                <Input name="text_color" id="text_color" placeholder="Text Color"
                                                       type="text" defaultValue={text_color}
                                                       className={"form-control form-control-color w-100"}
                                                       onChange={event => {
                                                           this.onChangeValidation("text_color", event.target.value)
                                                       }}
                                                       onClick={() => {
                                                           this.setState({simple_color2: !simple_color2})
                                                       }}
                                                       valid={validation["text_color"] === true}
                                                       invalid={validation["text_color"] !== true && validation["text_color"] !== null}
                                                       required aria-required={true} readOnly/>
                                                {simple_color2 ? (
                                                    <SketchPicker
                                                        color="#fff"
                                                        value={simple_color2}
                                                        width="160px"
                                                        onChangeComplete={handleHor}
                                                    />
                                                ) : null}
                                                <div
                                                    className={validation["text_color"] === true ? "valid-feedback" : "invalid-feedback"}
                                                    name="validate" id="text_color">
                                                </div>
                                            </Col>
                                            <Col md={3}>
                                                <div className="mb-3">
                                                    <label htmlFor="default_rating">Default Rating
                                                        ({default_rating})</label>
                                                    <input type="range" name="default_rating" step="0.1"
                                                           className="form-range" min="0" max="5"
                                                           defaultValue={default_rating} id="default_rating"
                                                           onChange={handleChange}/>
                                                </div>
                                            </Col>
                                            <Col md={3}>
                                                <div className="mb-3">
                                                    <label htmlFor="user_rating">User Rating ({user_rating})</label>
                                                    <input type="range" name="user_rating" step="0.1"
                                                           className="form-range" min="0" max="5"
                                                           defaultValue={user_rating} id="user_rating"
                                                           onChange={handleChangeUser}/>
                                                </div>
                                            </Col>
                                            <Col md="auto">
                                                <Label htmlFor="">User Rating Show</Label>
                                                <div className="square-switch mt-2">
                                                    <input type="checkbox" id="is_user_rating_visible" switch="none"
                                                           value="1" name="is_user_rating_visible"
                                                           checked={this.state.is_user_rating_visible}
                                                           onChange={() => this.setState({is_user_rating_visible: !this.state.is_user_rating_visible})}
                                                    />
                                                    <label
                                                        htmlFor="is_user_rating_visible"
                                                        data-on-label="On"
                                                        data-off-label="Off"
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="auto" className="mb-3">
                                                <Label htmlFor="ordering">Order No.</Label>
                                                <input name="ordering" type="number" id="ordering" defaultValue={ordering}
                                                       placeholder="Order No." className={"form-control"}
                                                />
                                            </Col>
                                            <Col md="3">
                                                <Label htmlFor="">Status</Label>
                                                <div className="square-switch mt-2">
                                                    <input type="checkbox" id="is_status" switch="none" value="1"
                                                           name="status" checked={this.state.status}
                                                           onChange={handleChangeStatus}/>
                                                    <label htmlFor="is_status" data-on-label="On"
                                                           data-off-label="Off"/>
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                                            <Button color="primary" type="submit" disabled={submit}>
                                                {submit === true ? 'Please wait...' : 'Submit Data'}
                                            </Button>
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>);
    }
}

CategoriesEdit.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string
        })
    }),
    location: PropTypes.object
};
export default CategoriesEdit;
