import React, { Component } from "react";

import { Link, withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Input,
  Row
} from "reactstrap";

// import images
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { apiError, loginUser, socialLogin } from "../../store/auth/login/actions";
import { registerUser, registerUserFailed } from "../../store/auth/register/actions";
import { userOtp } from "../../store/auth/forgetpwd/actions";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
class OTP extends Component {
  constructor(props) {
    console.log("props", props.history.location.state);
    super(props);
    this.state = {
      email: props.history.location.state.email
    };
  }
  handleChange = (e) => {
    const { maxLength, value, name } = e.target;
    const [fieldName, fieldIndex] = name.split("_");
console.log(value);
    // Check if they hit the max character length
    if (value.length >= maxLength) {
      // Check if it's not the last input field
      if (parseInt(fieldIndex, 10) < 4) {
        // Get the next input field
        const nextSibling = document.querySelector(
          `input[name=digit_${parseInt(fieldIndex, 10) + 1}]`
        );

        // If found, focus the next field
        if (nextSibling !== null) {
          nextSibling.focus();
        }
      }
    }
  }

  //meta title
  render() {
    return (
      <React.Fragment>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mb-5 ">
                  {/* <Link to="dashboard" className="d-block auth-logo">
                  <img
                    src={logodark}
                    alt=""
                    height="20"
                    className="auth-logo-dark mx-auto"
                  />
                  <img
                    src={logolight}
                    alt=""
                    height="20"
                    className="auth-logo-light mx-auto"
                  />
                </Link> */}
                  <h3>Curly Tales</h3>
                  <p className="mt-3">Curly Tales Admin & Dashboard </p>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card>
                  <CardBody>
                    <div className="p-2">
                      <div className="text-center">
                        <div className="avatar-md mx-auto">
                          <div className="avatar-title rounded-circle bg-light">
                            <i className="bx bxs-envelope h1 mb-0 text-primary"></i>
                          </div>
                        </div>
                        <div className="p-2 mt-4">
                          <h4>Verify your email</h4>
                          <p className="mb-5">
                            Please enter the 4 digit code sent to{" "}
                            <span className="fw-semibold">
                           {this.state.email}
                          </span>
                          </p>
                          <Formik
                            enableReinitialize={true}
                            initialValues={{
                              digit_1:
                                (this.state && this.state.digit_1) ||
                                "",
                              digit_2:
                                (this.state && this.state.digit_2) || "",
                              digit_3:
                                (this.state && this.state.digit_3) || "",
                              digit_4:
                                (this.state && this.state.digit_4) || "",
                              // digit1:
                              //   ( this.state && this.state.digit_1 && this.state.digit_2 && this.state.digit_3 && this.state.digit_4) || ""
                            }}
                            validationSchema={Yup.object().shape({
                              digit_1: Yup.string().required(
                                "Please Enter Digit"
                              ),
                              digit_2: Yup.string().required(
                                "Please Enter Digit"
                              ),
                              digit_3: Yup.string().required(
                                "Please Enter Digit"
                              ),
                              digit_4: Yup.string().required(
                                "Please Enter Digit"
                              )
                            })}
                            onSubmit={values => {
                              this.props.userOtp(values, this.props.history);
                            }}
                          >
                            {({ errors, status, touched }) => (

                              <Form className="form-horizontal">
                                <Row>
                                  <Col className="col-3">
                                    <div className="mb-3">
                                      <Label htmlFor="digit_1-input" className="visually-hidden">Dight 1</Label>
                                      <Field
                                        data-attr={errors.digit1}
                                        name="digit_1"
                                        type="text"
                                        maxLength={1}
                                      //  onChange={this.handleChange}
                                        className={
                                          "form-control form-control-lg text-center two-step"+
                                          (errors.digit_1 && touched.digit_1
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />

                                    </div>
                                  </Col>

                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="digit2-input" className="visually-hidden">Dight 2</Label>
                                      <Field
                                        name="digit_2"
                                        type="text"
                                        maxLength={1}
                                     //   onChange={this.handleChange}
                                        className={
                                          "form-control form-control-lg text-center two-step" +
                                          (errors.digit_2 && touched.digit_2
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                    </div>
                                  </Col>

                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="digit3-input" className="visually-hidden">Dight 3</Label>
                                      <Field
                                        name="digit_3"
                                        maxLength={1}
                                        type="text"
                                        //onChange={this.handleChange}
                                        className={
                                          "form-control form-control-lg text-center two-step" +
                                          (errors.digit_3 && touched.digit_3
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                    </div>
                                  </Col>

                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="digit4-input" className="visually-hidden">Dight 4</Label>
                                      <Field
                                        name="digit_4"
                                        maxLength={1}
                                        type="text"
                                      //  onChange={this.handleChange}
                                        className={
                                          "form-control form-control-lg text-center two-step" +
                                          (errors.digit_4 && touched.digit_4
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                    </div>
                                  </Col>

                                </Row>


                                <div className="mt-4">
                                  <button
                                    className="btn btn-primary w-md"
                                    type="submit"
                                  >
                                    Confirm
                                  </button>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </div>

                      </div>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    Did&apos;t receive a code ?{" "}
                    <a href="#" className="fw-medium text-primary">
                      {" "}
                      Resend{" "}
                    </a>{" "}
                  </p>
                  <p>
                    © {new Date().getFullYear()} Curly Talse. Crafted with{" "}
                    <i className="mdi mdi-heart text-danger"></i>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

OTP.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  userOtp: PropTypes.any
};

const mapStateToProps = state => {
  const { error } = state.Login;
  return { error };
};

export default withRouter(
  connect(mapStateToProps, { userOtp })(OTP)
);

