import React from "react"
import MetaTags from "react-meta-tags"
import {Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Row,} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Select from "react-select"
import {apiKey} from "../../helpers/url_helper"
import SimpleReactValidator from 'simple-react-validator'
import axios from "axios"
import toastr from "toastr"
// import "style.min.css"
import PropTypes from "prop-types"

let api_url = process.env.REACT_APP_API_URL
let api_key = apiKey()
function getCityName(id,cities){
  let selected_cities = [];
  for (var x = 0; x < id.length; x++){
    const result = cities.find( (name) => name['_id'] === id[x]);
    if (result !== undefined){
      selected_cities.push({label:result['city_name'],value:result['_id']})
    }
  }
  return selected_cities;
}
function getDataName(id,data){
  let selected = [];
  for (var x = 0; x < id.length; x++){
    const result = data.find( (name) => name['_id'] === id[x]);
    if (result !== undefined){
      selected.push({label:result['name'],value:result['_id']})
    }
  }
  return selected;
}
function selected_country(id,data){
  let selected = [];
  for (var x = 0; x < data.length; x++){
    const result = data.find( (name) => name['_id'] === id);
    if (result !== undefined){
      selected.push({label:result['name'],value:result['_id']})
    }
  }
  return selected;
}
const mystyle = {
  height: "110px",
  overflow: "hidden",
  width: "auto",
  objectFit:"cover"
}
class CitiesEdit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      categories: [],
      country: [],
      selectedCategories: [],
      defaultCategories: [],
      defaultCountry: [],
      status: true,
      seasonal_category:"",
      city_name:"",
      ordering:"",
      country_name:"",
      season_title:"",
      is_seasonal: false,
      old_image: "",
      handleResponse: null,
      selectedCity:[],
      selectedCityText:[],
      cities: [],
      city:"",
      submit: false
    }
    this.validator = new SimpleReactValidator({autoForceUpdate: this})
  }

  componentDidMount() {
    const headers = {'Content-Type': 'application/json', "Authorization": 'Bearer ' + api_key}
    fetch(`${api_url}/get-active-city?status=1`, {headers})
        .then(response => response.json())
        .then(data => this.setState({cities: data.data}))
        .catch(err => {
          // console.log("error:", err);
        });
    const id = this.props.match.params.id
    this.setState({edit_id: id})
    const params = {
      type: "city",
      id: id
    }
    axios.get(`${api_url}/get-single-data`, {
      params, headers: {
        "Authorization": 'Bearer ' + api_key,
      }
    })
      .then(res => {
        if (res.data.status) {
          let edit = res.data.data
          // console.log('edit', edit)
          this.setState({
            country_name :edit['country'],
            categories :edit['categories'],
            city_name: edit['city_name'],
            seasonal_category: edit['seasonal_category'] !== undefined ? edit['seasonal_category'] : "",
            ordering: edit['ordering'],
            show_on_main_page: edit['show_on_main_page'],
            season_title: edit['season_title'] !== undefined ? edit['season_title'] : "",
            is_seasonal: edit['is_seasonal_active'] !== undefined ? edit['is_seasonal_active']:false,
            status: edit['status'],
            old_image: edit['city_image'],
            selectedCity:edit['cityId'],
            city:edit['cityId']
          })
          // console.log(this.state.selectedCity,'selectedCity');
        } else {
          toastr.danger('Server not response')
        }
      })

    fetch(`${api_url}/get-guide-category?limit=100`, {headers})
      .then(response => response.json())
      .then(data => {
        this.setState({ categories: data.data, defaultCategories: getDataName(this.state.categories, data.data) });
        this.setState({selectedCategories: this.state.defaultCategories.map(({value}) => value).join(',')})
      })

    fetch(`${api_url}/get-country`, {headers})
      .then(response => response.json())
      .then(data => {
        this.setState({ country: data.data, defaultCountry: selected_country(this.state.country_name, data.data) });
        this.setState({selectedCountry: this.state.defaultCountry.map(({value}) => value).join(',')})
      })
  }

  handleChangeSeasonal = () => {
    this.setState({is_seasonal: !this.state.is_seasonal})
  }

  handleCategories = selectedCategories => {
    this.setState({selectedCategories: selectedCategories.map(({value}) => value).join(',')})
    this.setState({defaultCategories: selectedCategories})
  }

  handleCountry = selectedCountry => {
    this.setState({defaultCountry: selectedCountry });
    this.setState({country_name: selectedCountry['value'] });
  };

  handleSeasonalCategories = selected => {
    this.setState({seasonal_category: selected.value})
  }

  handleFileChange = (event) => {
    this.setState({
      city_image: event.target.files[0]
    })
  }

  selectData = a => {
    let b = []
    for (var c = 0; c < a.length; c++) b.push({
      label: a[c].name,
      value: a[c]._id
    })
    return b
  }
  handleInput = (e) => {
    this.setState({[e.target.name]: e.target.value})
  }
  selectDataDash = a => {
    let b = [];
    for (var c = 0; c < a.length; c++) b.push({
      label: a[c].name,
      value: a[c]._id
    });
    return b;
  };
  handleFormSubmit = (e) => {
    e.preventDefault()
    if (this.validator.allValid()) {
      const formData = new FormData()
      var city_image = document.getElementById("city_image").files[0];
      if (city_image){
        formData.append('city_image', this.state.city_image)
      }
      // formData.append('city_name', this.state.city_name)
      //formData.append('city_name', this.state.selectedCityText)
      if(this.state.selectedCityText != '' && this.state.selectedCityText != undefined){
        formData.append('city_name', this.state.selectedCityText)
      }
      formData.append('lcityId',this.state.selectedCity)
      formData.append('status', this.state.status)
      formData.append('ordering', this.state.ordering)
      formData.append('categories', this.state.selectedCategories)
      formData.append('seasonal_category',this.state.seasonal_category)
      formData.append('country',this.state.country_name)
      formData.append('season_title',this.state.season_title)
      formData.append('is_seasonal_active',this.state.is_seasonal)
      formData.append('cityId', this.state.edit_id)
      this.setState({submit: true})
      axios({
        method: "post", url: `${api_url}/update-city`, data: formData, headers: {
          "Authorization": 'Bearer ' + api_key,
        }
      }).then(response => {
        if (response.status) {
          this.setState({submit: false})
          toastr.success('City edit successful.')
          // eslint-disable-next-line react/prop-types
          const {history} = this.props
          // eslint-disable-next-line react/prop-types
          const pageno = this.props.match.params.pId; // Get the pageno param from the URL
          history.goBack(); // Use pageno in the history.push statement
          
        }
      }).catch(err => {
        toastr.error(err.message)
        this.setState({submit: false})
        alert(err.message)
      })
    } else {
      this.validator.showMessages()
      this.forceUpdate()
    }
  }
  handleCity = (e) => {
    var index = e.target.selectedIndex;
    var optionElement = e.target.childNodes[index]
    // console.log(optionElement.getAttribute('data-id'));

    this.setState({selectedCity:e.target.value})
    this.setState({selectedCityText:optionElement.getAttribute('data-id')})
  }
  render() {

    const { city_name, categories,country, defaultCategories,ordering,submit,cities,selectedCity} = this.state
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>
              Manage City | {process.env.REACT_APP_PROJECTNAME}
            </title>
          </MetaTags>
          <Container fluid={true}>
            <Breadcrumbs title="Cities" path="/cities/pageno/1" breadcrumbItem="Edit Cities"/>
            <Row>
              <Col xl="10">
                <Card>
                  <CardBody>
                    <form onSubmit={this.handleFormSubmit}>
                      <Row>
                        <Col md="3" className="mb-3">
                          <label className="control-label">
                            City
                          </label>
                          <select name="city" value={selectedCity} className={"form-select"} onChange={e => {this.handleInput(e);this.handleCity(e)}}>
                            <option>Select</option>
                            {this.selectData(cities).map((opt, key) => {
                              return <option data-id={opt.label} key={key} value={opt.value} >{opt.label}</option>;
                            })}
                          </select>
                          {this.validator.message('city', this.state.city, 'required')}
                        </Col>
                        {/*<Col md="6" className="mb-3">*/}
                        {/*  <Label htmlFor="city_name">City Name</Label>*/}
                        {/*  <input name="city_name" placeholder="Type City Name"*/}
                        {/*         defaultValue={city_name} type="text" className={"form-control"}*/}
                        {/*         onChange={this.handleInput}/>*/}
                        {/*  {this.validator.message('city_name', this.state.city_name, 'required|string')}*/}
                        {/*</Col>*/}

                        <Col md="6" className="mb-3">
                          <Label htmlFor="cover_image">
                            City Image
                          </Label>
                          <input
                            id="city_image"
                            name="city_image"
                            placeholder="City Image"
                            type="file"
                            accept="image/*"
                            onChange={this.handleFileChange}
                            className={"form-control"}
                          />
                          {/*{this.validator.message('cover_image', this.state.cover_image, '')}*/}
                        </Col>
                        <Col md="6" className="mb-3">
                          <label className="control-label">
                            Categories
                          </label>
                          <Select
                            value={defaultCategories}
                            isMulti={true}
                            name="categories"
                            onChange={this.handleCategories}
                            options={this.selectData(categories)}
                            classNamePrefix="select2-selection"
                          />
                          {this.validator.message('categories', this.state.selectedCategories, 'required')}
                        </Col>
                        <Col md="3" className="mb-3">
                          <label className="control-label">Country</label>
                          <Select
                            value={this.state.defaultCountry}
                            isMulti={false}
                            name="country"
                            onChange={this.handleCountry}
                            options={this.selectDataDash(country)}
                            classNamePrefix="select2-selection"
                          />
                          {this.validator.message("country_name", this.state.country_name, "required")}
                        </Col>
                        <Col md="auto">
                          <Label htmlFor="">Seasonal</Label>
                          <div className="square-switch mt-2">
                            <input type="checkbox" id="is_seasonal" switch="none" name="is_seasonal" checked={this.state.is_seasonal} onChange={this.handleChangeSeasonal}/>
                            <label
                                htmlFor="is_seasonal"
                                data-on-label="On"
                                data-off-label="Off"
                            />
                          </div>
                        </Col>
                        {this.state.is_seasonal === true &&
                            <>
                              <Col md="3" className="mb-3">
                                <label className="control-label">Seasonal category</label>
                                <Select isMulti={false} name="seasonal_category" value={defaultCategories.find(({ value }) => value === this.state.seasonal_category)} onChange={this.handleSeasonalCategories} options={defaultCategories} classNamePrefix="select2-selection"
                                />
                                {/*{this.validator.message('seasonal_category', this.state.seasonal_category, 'required')}*/}
                              </Col>
                              <Col md="3" className="mb-3">
                                <Label htmlFor="season_title">Seasonal Title</Label>
                                <input name="season_title" id="season_title" type="text" defaultValue={this.state.season_title} onChange={this.handleInput} placeholder="Seasonal Title" className={"form-control"}/>
                                {/*{this.validator.message('season_title', this.state.season_title, 'required')}*/}
                              </Col>
                            </>
                        }
                        <Col md="3" className="mb-3">
                          <Label htmlFor="associate_deals_url">
                            Order No.
                          </Label>
                          <input
                            name="ordering"
                            placeholder="Type order No."
                            type="text"
                            defaultValue={ordering}
                            className={"form-control"}
                            onChange={this.handleInput}
                          />
                          {this.validator.message('ordering', this.state.ordering, 'required')}
                        </Col>


                        <Col md="3" className="mb-3">
                          <Label htmlFor="">Status</Label>
                          <div className="square-switch mt-2">
                            <input
                              type="checkbox"
                              id="square-switch-status"
                              switch="none"
                              name="status"
                              checked={this.state.status}
                              onChange={() =>
                                this.setState({status: !this.state.status})
                              }
                            />
                            <label
                              htmlFor="square-switch-status"
                              data-on-label="On"
                              data-off-label="Off"
                            />
                          </div>
                        </Col>
                      </Row>

                      <div className="d-flex flex-wrap gap-2 justify-content-end">
                        <Button color="primary" type="submit" disabled={submit}>
                          {submit === true ? 'Please wait...' : 'Submit Data'}
                        </Button>
                      </div>
                    </form>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={2}>
                <Card>
                  <CardBody>
                    <h6 className={'mb-4'}>Old Image</h6>
                    {!this.state.old_image && <h5 className="text-danger">No image</h5>}
                    {this.state.old_image && <div className="text-center"><img src={this.state.old_image} className="img-fluid" style={mystyle} alt="image"/></div>}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

CitiesEdit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }),
  location: PropTypes.object
}

export default CitiesEdit
