import React from "react"
import MetaTags from "react-meta-tags"
import {Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Row,} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Select from "react-select"
import {apiKey} from "../../helpers/url_helper"
import SimpleReactValidator from 'simple-react-validator'
import axios from "axios"
import toastr from "toastr"
// import "style.min.css"
import PropTypes from "prop-types"
import {value} from "lodash/seq"

let api_url = process.env.REACT_APP_API_URL
let api_key = apiKey()
function getCityName(id,cities){
  let selected_cities = [];
  for (var x = 0; x < id.length; x++){
    const result = cities.find( (name) => name['_id'] === id[x]);
    if (result !== undefined){
      selected_cities.push({label:result['city_name'],value:result['_id']})
    }
  }
  return selected_cities;
}
function getDataName(id,data){
  let selected = [];
  for (var x = 0; x < id.length; x++){
    const result = data.find( (name) => name['_id'] === id[x]);
    if (result !== undefined){
      selected.push({label:result['name'],value:result['id']})
    }
  }
  return selected;
}
function selected_country(id,data){
  let selected = [];
  for (var x = 0; x < data.length; x++){
    const result = data.find( (name) => name['_id'] === id);
    if (result !== undefined){
      selected.push({label:result['name'],value:result['id']})
    }
  }
  return selected;
}
class CitiesAdd extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      categories: [],
      country: [],
      selectedCategories: [],
      defaultCategories: [],
      defaultCountry: [],
      selectedCity:[],
      city: [],
      status: true,
      seasonal_category:"",
      season_title:"",
      city_name:"",
      ordering:"",
      country_name:"",
      handleResponse: null,
      is_seasonal: false,
      submit: false
    }
    this.validator = new SimpleReactValidator({autoForceUpdate: this})
  }

  componentDidMount() {
    const headers = {'Content-Type': 'application/json', "Authorization": 'Bearer ' + api_key}

    fetch(`${api_url}/get-guide-category?limit=100`, {headers})
      .then(response => response.json())
      .then(data => this.setState({categories: data.data}))

    fetch(`${api_url}/get-country`, {headers})
      .then(response => response.json())
      .then(data => this.setState({country: data.data,defaultCountry:selected_country(this.state.country_name,data.data)}))
    fetch(`${api_url}/get-active-city?&status=1`, {headers})
        .then(response => response.json())
        .then(data => this.setState({city: this.selectData(data.data)}))
        .catch(err => {
          console.log("error:", err);
        });
  }

  handleChangeSeasonal = (event) => {
    this.setState({is_seasonal: !this.state.is_seasonal})
  }

  handleCategories = selectedCategories => {
    this.setState({selectedCategories: selectedCategories.map(({value}) => value).join(',')})
    this.setState({defaultCategories: selectedCategories})
  }
  handleSeasonalCategories = selected => {
    this.setState({seasonal_category: selected.value})
  }

  handleCountry = selectedCountry => {
    this.setState({defaultCountry: selectedCountry });
    this.setState({country_name: selectedCountry['value'] });
  };


  handleFileChange = (event) => {
    this.setState({
      city_image: event.target.files[0]
    })
  }

  selectData = a => {
    let b = []
    for (var c = 0; c < a.length; c++) b.push({
      label: a[c].name,
      value: a[c]._id
    })
    return b
  }
  handleInput = (e) => {
    this.setState({[e.target.name]: e.target.value})
  }
  selectDataDash = a => {
    let b = [];
    for (var c = 0; c < a.length; c++) b.push({
      label: a[c].name,
      value: a[c]._id
    });
    return b;
  };
  handleFormSubmit = (e) => {
    e.preventDefault()
    if (this.validator.allValid()) {
      const formData = new FormData()
      var city_image = document.getElementById("city_image").files[0];

      if (city_image){
        formData.append('city_image', this.state.city_image)
      }
      formData.append('city_name',this.state.selectedCity.label)
      formData.append('cityId', this.state.selectedCity.value)
   //   formData.append('city_name', this.state.city_name)
      formData.append('status', this.state.status)
      formData.append('ordering', this.state.ordering)
      formData.append('categories', this.state.selectedCategories)
      formData.append('seasonal_category', this.state.seasonal_category)
      formData.append('country',this.state.country_name)
      formData.append('season_title',this.state.seasonal_title)
      formData.append('is_seasonal_active',this.state.is_seasonal)
      this.setState({submit: true})
      axios({
        method: "post", url: `${api_url}/add-city`, data: formData, headers: {
          "Authorization": 'Bearer ' + api_key,
        }
      }).then(response => {
        if (response.status) {
          this.setState({submit: false})
          toastr.success('City added successful.')
          // eslint-disable-next-line react/prop-types
          const {history} = this.props
          // eslint-disable-next-line react/prop-types
          history.push('/cities/pageno/1');
        }
      }).catch(err => {
        toastr.error(err.message)
        this.setState({submit: false})
        alert(err.message)
      })
    } else {
      this.validator.showMessages()
      this.forceUpdate()
    }
  }
  handleCity = (e) => {
    console.log(e);
    //  this.setState({selectedCity:e.value})
  }
  render() {

    const { city,city_name, categories,country, defaultCategories,defaultCountry,ordering,submit} = this.state

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>
              Manage City | {process.env.REACT_APP_PROJECTNAME}
            </title>
          </MetaTags>
          <Container fluid={true}>
            <Breadcrumbs title="Cities" path="/cities/pageno/1" breadcrumbItem="Add Cities"/>
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <form onSubmit={this.handleFormSubmit}>
                      <Row>
                        {/*<Col md="6" className="mb-3">*/}
                        {/*  <Label htmlFor="city_name">City Name</Label>*/}
                        {/*  <input name="city_name" placeholder="Type City Name" defaultValue={city_name} type="text" className={"form-control"} onChange={this.handleInput}/>*/}
                        {/*  {this.validator.message('city_name', this.state.city_name, 'required|string')}*/}
                        {/*</Col>*/}
                        <Col md="3" className="mb-3">
                          <label className="control-label">
                            City
                          </label>
                          <Select
                              isMulti={false}
                              name="city"
                              onChange={e => {this.setState({selectedCity: e}); console.log(e);}}
                              options={city}
                              classNamePrefix="select2-selection"
                          />
                          {this.validator.message('selectedCity', this.state.selectedCity, 'required')}
                        </Col>
                        <Col md="6" className="mb-3">
                          <Label htmlFor="cover_image">
                            City Image
                          </Label>
                          <input
                            id="city_image"
                            name="city_image"
                            placeholder="City Image"
                            type="file"
                            accept="image/*"
                            onChange={this.handleFileChange}
                            className={"form-control"}
                          />
                          {this.validator.message('city_image', this.state.city_image, '')}
                        </Col>
                        <Col md="6" className="mb-3">
                          <label className="control-label">
                            Categories
                          </label>
                          <Select
                            value={defaultCategories}
                            isMulti={true}
                            name="categories"
                            onChange={this.handleCategories}
                            options={this.selectData(categories)}
                            classNamePrefix="select2-selection"
                          />
                          {this.validator.message('categories', this.state.selectedCategories, 'required')}
                        </Col>
                        <Col md="auto">
                          <Label htmlFor="">Seasonal</Label>
                          <div className="square-switch mt-2">
                            <input type="checkbox" id="is_seasonal" switch="none" name="is_seasonal" checked={this.state.is_seasonal} onChange={this.handleChangeSeasonal}/>
                            <label htmlFor="is_seasonal" data-on-label="On" data-off-label="Off"/>
                          </div>
                        </Col>
                        {this.state.is_seasonal === true &&
                            <>
                            <Col md="3" className="mb-3">
                              <label className="control-label">
                                Seasonal category
                              </label>
                              <Select isMulti={false} name="seasonal_category" onChange={this.handleSeasonalCategories} options={defaultCategories} classNamePrefix="select2-selection"
                              />
                              {this.validator.message('seasonal_category', this.state.seasonal_category, 'required')}
                            </Col>
                            <Col md="3" className="mb-3">
                              <Label htmlFor="seasonal_title">Seasonal Title</Label>
                              <input name="seasonal_title" id="seasonal_title" type="text" onChange={this.handleInput} placeholder="Seasonal Title" className={"form-control"}/>
                              {this.validator.message('seasonal_title', this.state.seasonal_title, 'required')}
                            </Col>
                            </>
                        }
                        <Col md="3" className="mb-3">
                          <label className="control-label">Country</label>
                          <Select
                            value={this.state.defaultCountry}
                            isMulti={false}
                            name="country"
                            onChange={this.handleCountry}
                            options={this.selectDataDash(country)}
                            classNamePrefix="select2-selection"
                          />
                          {this.validator.message("country_name", this.state.country_name, "required")}
                        </Col>

                        <Col md="3" className="mb-3">
                          <Label htmlFor="associate_deals_url">
                            Order No.
                          </Label>
                          <input
                            name="ordering"
                            placeholder="Type order No."
                            type="text"
                            defaultValue={ordering}
                            className={"form-control"}
                            onChange={this.handleInput}
                          />
                          {this.validator.message('ordering', this.state.ordering, 'required')}
                        </Col>


                        <Col md="3" className="mb-3">
                          <Label htmlFor="">Status</Label>
                          <div className="square-switch mt-2">
                            <input
                              type="checkbox"
                              id="square-switch-status"
                              switch="none"
                              name="status"
                              checked={this.state.status}
                              onChange={() =>
                                this.setState({status: !this.state.status})
                              }
                            />
                            <label
                              htmlFor="square-switch-status"
                              data-on-label="On"
                              data-off-label="Off"
                            />
                          </div>
                        </Col>
                      </Row>

                      <div className="d-flex flex-wrap gap-2 justify-content-end">
                        <Button color="primary" type="submit" disabled={submit}>
                          {submit === true ? 'Please wait...' : 'Submit Data'}
                        </Button>
                      </div>
                    </form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

CitiesAdd.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }),
  location: PropTypes.object
}

export default CitiesAdd
