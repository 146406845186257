import React from "react"
import MetaTags from "react-meta-tags"
import {Button, Card, CardBody, Col, Container, Label, Row,} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Select from "react-select"
import {apiKey} from "../../helpers/url_helper"
import SimpleReactValidator from 'simple-react-validator'
import axios from "axios"
import toastr from "toastr"
import "style.min.css"
import PropTypes from "prop-types"

let api_url = process.env.REACT_APP_API_URL
let api_key = apiKey()
const mystyle = {
    height: "110px",
    overflow: "hidden",
    width: "auto",
    objectFit:"cover"
}
class OriginalSeasonsEdit extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            originalData: [],
            status: true,
            season_no: "",
            season_name: "",
            cover_image: "",
            trailer_cover_image: "",
            old_cover_image: "",
            old_trailer_cover_image: "",
            trailer_url: "",
            original: "",
            ordering: "",
            selectedOriginal: "",
            handleResponse: null,
            submit: false
        }
        this.validator = new SimpleReactValidator({autoForceUpdate: this})
    }

    componentDidMount() {
        const headers = {'Content-Type': 'application/json', "Authorization": 'Bearer ' + api_key}

        fetch(`${api_url}/get-shows`, {headers})
            .then(response => response.json())
            .then(data => this.setState({originalData: data.data})).catch(err => {
            console.log(err)
        })

        const id = this.props.match.params.id
        this.setState({edit_id: id})
        const params = {
            type: "season",
            id: id
        }
        axios.get(`${api_url}/get-single-data`, {
            params, headers: {
                "Authorization": 'Bearer ' + api_key,
            }
        }).then(res => {
            if (res.data.status) {
                let edit = res.data.data
                this.setState({
                    season_no: edit['season_no'],
                    season_name: edit['searson_name'],
                    old_trailer_cover_image: edit['trailer_cover_image'],
                    // old_cover_image: edit['cover_image'],
                    trailer_url: edit['trailer_url'],
                    ordering: edit['ordering'],
                    status: edit['status'],
                    original: edit['original'],
                    selectedOriginal: edit['original'],
                })
            } else {
                toastr.danger('Server not response')
            }
        })
    }

    handleFileChange = (event) => {
        this.setState({
            cover_image: event.target.files[0]
        })
    }

    handleTrailerFileChange = (event) => {
        this.setState({
            trailer_cover_image: event.target.files[0]
        })
    }

    selectData = a => {
        let b = []
        for (var c = 0; c < a.length; c++) b.push({
            label: a[c].name,
            value: a[c]._id
        })
        return b
    }
    handleInput = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }
    handleFormSubmit = (e) => {
        e.preventDefault()
        if (this.validator.allValid()) {
            const formData = new FormData()
            formData.append('season_no', this.state.season_no)
            formData.append('season_name', this.state.season_name)
            // var cover_image = document.getElementById("cover_image").files[0];
            var trailer_cover_image = document.getElementById("trailer_cover_image").files[0];
            // if (cover_image){
            //     formData.append('cover_image', this.state.cover_image)
            // }
            if (trailer_cover_image){
                formData.append('trailer_cover_image', this.state.trailer_cover_image)
            }
            formData.append('trailer_url', this.state.trailer_url)
            formData.append('original', this.state.original)
            formData.append('status', this.state.status)
            formData.append('ordering', this.state.ordering)
            formData.append('seasonId', this.state.edit_id)
            this.setState({submit: true})
            axios({
                method: "post", url: `${api_url}/update-season`, data: formData, headers: {
                    'Content-Type': 'application/json',
                    "Authorization": 'Bearer ' + api_key,
                }
            }).then(response => {
                if (response.status) {
                    this.setState({submit: false})
                    toastr.success('Original season update successful.')
                    // eslint-disable-next-line react/prop-types
                    const {history} = this.props
                    // eslint-disable-next-line react/prop-types
                    const pageno = this.props.match.params.pId; // Get the pageno param from the URL
                    history.goBack();
                }
            }).catch(err => {
                toastr.error(err.message)
                this.setState({submit: false})
                alert(err.message)
            })
        } else {
            this.validator.showMessages()
            this.forceUpdate()
        }
    }

    render() {

        const {season_name, trailer_url, season_no, originalData, ordering, selectedOriginal, submit} = this.state

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>
                            Manage Originals | {process.env.REACT_APP_PROJECTNAME}
                        </title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Original Seasons" path="/seasons/pageno/1" breadcrumbItem="Edit Original Seasons"/>
                        <Row>
                            <Col xl="8">
                                <Card>
                                    <CardBody>
                                        <form onSubmit={this.handleFormSubmit}>
                                            <Row>
                                                <Col md="2" className="mb-3">
                                                    <Label htmlFor="validationCustom03">Season No.</Label>
                                                    <input
                                                        name="season_no"
                                                        type="text"
                                                        placeholder="Season No."
                                                        className={"form-control"}
                                                        onChange={this.handleInput}
                                                        defaultValue={season_no}
                                                    />
                                                    {this.validator.message('season_no', this.state.season_no, 'required')}
                                                </Col>
                                                <Col md="6" className="mb-3">
                                                    <Label htmlFor="season_name">Season Name</Label>
                                                    <input name="season_name" placeholder="Type Season Name" defaultValue={season_name} type="text" className={"form-control"}
                                                           onChange={this.handleInput}/>
                                                    {this.validator.message('season_name', this.state.season_name, 'required|string')}
                                                </Col>
                                                <Col md="4" className="mb-3">
                                                    <label className="control-label">Originals</label>
                                                    <select name="original" className={"form-select"} onChange={(e) => this.setState({original: e.target.value})}>
                                                        <option>Select</option>
                                                        {this.selectData(originalData).map((opt, key) => {
                                                            let q = selectedOriginal === opt.value ? 'selected' :''
                                                            return <option key={key} value={opt.value} selected={q} >{opt.label}</option>;
                                                        })}
                                                    </select>
                                                    {this.validator.message('original', this.state.original, 'required')}
                                                </Col>
                                                {/*<Col md="6" className="mb-3">*/}
                                                {/*    <Label htmlFor="cover_image">*/}
                                                {/*        Logo Image*/}
                                                {/*    </Label>*/}
                                                {/*    <input*/}
                                                {/*        id="cover_image"*/}
                                                {/*        name="cover_image"*/}
                                                {/*        placeholder="Cover Image"*/}
                                                {/*        type="file"*/}
                                                {/*        accept="image/*"*/}
                                                {/*        onChange={this.handleFileChange}*/}
                                                {/*        className={"form-control"}*/}
                                                {/*    />*/}
                                                {/*    /!*{this.validator.message('cover_image', this.state.cover_image, 'required')}*!/*/}
                                                {/*</Col>*/}
                                                <Col md="6" className="mb-3">
                                                    <Label htmlFor="trailer_cover_image">
                                                        Season Thumbnail
                                                    </Label>
                                                    <input
                                                        id="trailer_cover_image"
                                                        name="trailer_cover_image"
                                                        placeholder="Season Thumbnail"
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={this.handleTrailerFileChange}
                                                        className={"form-control"}
                                                    />
                                                    {/*{this.validator.message('trailer_cover_image', this.state.trailer_cover_image, 'required')}*/}
                                                </Col>
                                                <Col md="6" className="mb-3">
                                                    <Label htmlFor="trailer_url">Trailer URL</Label>
                                                    <input name="trailer_url" placeholder="Type Trailer URL" defaultValue={trailer_url} type="url" className={"form-control"}
                                                           onChange={this.handleInput}/>
                                                    {this.validator.message('trailer_url', this.state.trailer_url, 'required')}
                                                </Col>
                                                <Col md="2" className="mb-3">
                                                    <Label htmlFor="validationCustom03">Order No.</Label>
                                                    <input
                                                        name="ordering"
                                                        type="number"
                                                        placeholder="Order No."
                                                        className={"form-control"}
                                                        onChange={this.handleInput}
                                                        defaultValue={ordering}
                                                    />
                                                    {this.validator.message('ordering', this.state.ordering, 'required|numeric')}
                                                </Col>
                                                <Col md="3" className="mb-3">
                                                    <Label htmlFor="">Status</Label>
                                                    <div className="square-switch mt-2">
                                                        <input
                                                            type="checkbox"
                                                            id="square-switch-status"
                                                            switch="none"
                                                            name="status"
                                                            checked={this.state.status}
                                                            onChange={() =>
                                                                this.setState({status: !this.state.status})
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="square-switch-status"
                                                            data-on-label="On"
                                                            data-off-label="Off"
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>

                                            <div className="d-flex flex-wrap gap-2 justify-content-end">
                                                <Button color="primary" type="submit" disabled={submit}>
                                                    {submit === true ? 'Please wait...' : 'Submit Data'}
                                                </Button>
                                            </div>
                                        </form>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xl="4">
                                <Card>
                                    <CardBody>
                                        {/*<h6>Old Logo</h6>*/}
                                        {/*<img src={this.state.old_cover_image} className="img-fluid" style={mystyle} alt="cover image"/>*/}
                                        {/*<hr/>*/}
                                        <h6>Season Thumbnail</h6>
                                        <img src={this.state.old_trailer_cover_image} className="img-fluid" style={mystyle} alt="cover image"/>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

OriginalSeasonsEdit.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string
        })
    }),
    location: PropTypes.object
}

export default OriginalSeasonsEdit
