import React from "react"
import MetaTags from "react-meta-tags"
import {Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Row,} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Select from "react-select"
import {apiKey} from "../../helpers/url_helper"
import SimpleReactValidator from 'simple-react-validator'
import axios from "axios"
import toastr from "toastr"
import "style.min.css"
import PropTypes from "prop-types"
import {value} from "lodash/seq"

let api_url = process.env.REACT_APP_API_URL
let api_key = apiKey()

class OriginalsAdd extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            tags: [],
            selectedTags: [],
            defaultTags: [],
            is_popular: false,
            status: true,
            originals_name: "",
            show_on_main_page:true,
            about: "",
            cover_image: "",
            thumbnail: "",
            ordering: "",
            handleResponse: null,
            submit: false,
            totalLikes:0
        }
        this.validator = new SimpleReactValidator({autoForceUpdate: this})
    }

    componentDidMount() {
        const headers = {'Content-Type': 'application/json', "Authorization": 'Bearer ' + api_key}

        fetch(`${api_url}/get-tags`, {headers})
            .then(response => response.json())
            .then(data => this.setState({tags: data.data}))
    }

    handleTags = selectedTags => {
        this.setState({selectedTags: selectedTags.map(({value}) => value).join(',')})
        this.setState({defaultTags: selectedTags})
    }

    handleFileChange = (event) => {
        this.setState({
            cover_image: event.target.files[0]
        })
    }
    handleThumbnailFileChange = (event) => {
        this.setState({
           thumbnail: event.target.files[0]
        })
    }

    selectData = a => {
        let b = []
        for (var c = 0; c < a.length; c++) b.push({
            label: a[c].name,
            value: a[c]._id
        })
        return b
    }
    handleInput = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }
    handleFormSubmit = (e) => {
        e.preventDefault()
        if (this.validator.allValid()) {
            const formData = new FormData()
            formData.append('name', this.state.originals_name)
            var cover_image = document.getElementById("cover_image").files[0];
            if (cover_image){
                formData.append('cover_image', this.state.cover_image)
            }
            var thumbnail = document.getElementById("thumbnail").files[0];
            if (thumbnail){
                formData.append('thumbnail', this.state.thumbnail)
            }
            formData.append('about', this.state.about)
            formData.append('is_popular', this.state.is_popular)
            formData.append('show_on_main_page', this.state.show_on_main_page)
            formData.append('status', this.state.status)
            formData.append('ordering', this.state.ordering)
            formData.append('tags', this.state.selectedTags)
            formData.append('totalLikes', this.state.totalLikes)
            this.setState({submit: true})
            axios({
                method: "post", url: `${api_url}/add-show`, data: formData, headers: {
                    'Content-Type': 'application/json',
                    "Authorization": 'Bearer ' + api_key,
                }
            }).then(response => {
                if (response.status) {
                    this.setState({submit: false})
                    toastr.success('Original added successful.')
                    // eslint-disable-next-line react/prop-types
                    const {history} = this.props
                    // eslint-disable-next-line react/prop-types
                    history.push('/originals/pageno/1');

                }
            }).catch(err => {
                toastr.error(err.message)
                this.setState({submit: false})
                alert(err.message)
            })
        } else {
            this.validator.showMessages()
            this.forceUpdate()
        }
    }

    render() {

        const {originals_name, tags, about, ordering, defaultTags, submit} = this.state

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>
                            Manage Originals | {process.env.REACT_APP_PROJECTNAME}
                        </title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Originals" path="/originals/pageno/1" breadcrumbItem="Add Original"/>
                        <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                        <form onSubmit={this.handleFormSubmit}>
                                            <Row>
                                                <Col md="12" className="mb-3">
                                                    <Label htmlFor="originals_name">Original Name</Label>
                                                    <input name="originals_name" placeholder="Type Original Name"
                                                           defaultValue={originals_name} type="text" className={"form-control"}
                                                           onChange={this.handleInput}/>
                                                    {this.validator.message('originals_name', this.state.originals_name, 'required|string')}
                                                </Col>
                                                <Col md="12" className="mb-3">
                                                    <Label htmlFor="about">About</Label>
                                                    <textarea className="form-control" name="about" id="textarea" defaultValue={about} rows="5" placeholder="Enter about" onChange={this.handleInput}></textarea>
                                                    {this.validator.message('about', this.state.about, 'required')}
                                                </Col>
                                                <Col md="3" className="mb-3">
                                                    <Label htmlFor="cover_image">
                                                        Logo
                                                    </Label>
                                                    <input
                                                        id="cover_image"
                                                        name="cover_image"
                                                        placeholder="Logo Image"
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={this.handleFileChange}
                                                        className={"form-control"}
                                                    />
                                                    {this.validator.message('cover_image', this.state.cover_image, 'required')}
                                                </Col>
                                                <Col md="3" className="mb-3">
                                                    <Label htmlFor="thumbnail">
                                                        Thumbnail
                                                    </Label>
                                                    <input
                                                        id="thumbnail"
                                                        name="thumbnail"
                                                        placeholder="Thumbnail Image"
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={this.handleThumbnailFileChange}
                                                        className={"form-control"}
                                                    />
                                                    {this.validator.message('thumbnail', this.state.thumbnail, 'required')}
                                                </Col>
                                                <Col md="auto" className="mb-3">
                                                    <Label htmlFor="is_popular">Popular</Label>
                                                    <div className="square-switch mt-2">
                                                        <input type="checkbox" id="square-is_popular" defaultValue={1}
                                                               switch="none" name="is_deal_associated"
                                                               checked={this.state.is_popular} onChange={() =>
                                                            this.setState({is_popular: !this.state.is_popular})
                                                        }
                                                        />
                                                        <label
                                                            htmlFor="square-is_popular"
                                                            data-on-label="On"
                                                            data-off-label="Off"
                                                        />
                                                    </div>
                                                    {this.validator.message('is_popular', this.state.is_popular, 'required')}
                                                </Col>
                                                {/* <Col md="auto" className="mb-3">
                                                    <Label htmlFor="show_on_main_page">Show on main page</Label>
                                                    <div className="square-switch mt-2">
                                                        <input type="checkbox" id="square-show_on_main_page" defaultValue={1}
                                                               switch="none" name="show_on_main_page"
                                                               checked={this.state.show_on_main_page} onChange={() =>
                                                          this.setState({show_on_main_page: !this.state.show_on_main_page})
                                                        }
                                                        />
                                                        <label
                                                          htmlFor="square-show_on_main_page"
                                                          data-on-label="On"
                                                          data-off-label="Off"
                                                        />
                                                    </div>
                                                </Col> */}
                                                <Col md="3" className="mb-3">
                                                    <label className="control-label">Tags</label>
                                                    <Select
                                                        value={defaultTags}
                                                        isMulti={true}
                                                        onChange={this.handleTags}
                                                        options={this.selectData(tags)}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                    {this.validator.message('tags', this.state.selectedTags,'optional')}
                                                </Col>
                                                <Col md="2" className="mb-3">
                                                    <Label htmlFor="validationCustom03">Order No.</Label>
                                                    <input
                                                        name="ordering"
                                                        type="number"
                                                        placeholder="Order No."
                                                        className={"form-control"}
                                                        onChange={this.handleInput}
                                                        defaultValue={ordering}
                                                    />
                                                    {this.validator.message('ordering', this.state.ordering, 'required|numeric')}
                                                </Col>
                                                <Col md="3" className="mb-3">
                                                <Label htmlFor="title">Static likes</Label>
                                                <input name="totalLikes" placeholder="Type Static Likes" type="number" className={"form-control"}
                                                    value={this.state.totalLikes}
                                                    onChange={this.handleInput} />
                                                {this.validator.message("title", this.state.totalLikes, "required|number")}
                                                </Col>
                                                <Col md="3" className="mb-3">
                                                    <Label htmlFor="">Status</Label>
                                                    <div className="square-switch mt-2">
                                                        <input
                                                            type="checkbox"
                                                            id="square-switch-status"
                                                            switch="none"
                                                            name="status"
                                                            checked={this.state.status}
                                                            onChange={() =>
                                                                this.setState({status: !this.state.status})
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="square-switch-status"
                                                            data-on-label="On"
                                                            data-off-label="Off"
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>

                                            <div className="d-flex flex-wrap gap-2 justify-content-end">
                                                <Button color="primary" type="submit" disabled={submit}>
                                                    {submit === true ? 'Please wait...' : 'Submit Data'}
                                                </Button>
                                            </div>
                                        </form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

OriginalsAdd.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string
        })
    }),
    location: PropTypes.object
}

export default OriginalsAdd
