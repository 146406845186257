import React from "react"
import MetaTags from "react-meta-tags"
import {Button, Card, CardBody, Col, Container, Label, Row,} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {apiKey} from "../../../helpers/url_helper"
import SimpleReactValidator from 'simple-react-validator'
import axios from "axios"
import toastr from "toastr"
import PropTypes from "prop-types"
import Select from "react-select";

let api_url = process.env.REACT_APP_API_URL
let api_key = apiKey()
const mystyle = {
    height: "110px",
    overflow: "hidden",
    width: "auto",
    objectFit:"cover"
}
const headers = {'Content-Type': 'application/json', "Authorization": 'Bearer ' + api_key}
class DealCitiesAdd extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            status: true,
            city_name: "",
            selectedCity:[],
            city: [],
            image: "",
            ordering: "",
            handleResponse: null,
            submit: false,
        }
        this.validator = new SimpleReactValidator({autoForceUpdate: this})
    }
    componentDidMount() {
        const headers = {'Content-Type': 'application/json', "Authorization": 'Bearer ' + api_key}

        fetch(`${api_url}/get-active-city?&status=1`, {headers})
            .then(response => response.json())
            .then(data => this.setState({city: this.selectData(data.data)}))
            .catch(err => {
                console.log("error:", err);
            });
    }
    selectData = a => {
        let b = []
        for (var c = 0; c < a.length; c++) b.push({
            label: a[c].name, value: a[c]._id
        })
        return b
    }
    handleInput = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }
    handleFileChange = (event) => {
        this.setState({
            image: event.target.files[0]
        })
    }
    handleFileLogoChange = (event) => {
        this.setState({
            logo: event.target.files[0]
        })
    }

    handleFormSubmit = (e) => {
        e.preventDefault()
        if (this.validator.allValid()) {
            const formData = new FormData()
            formData.append('name',this.state.selectedCity.label)
            formData.append('cityId', this.state.selectedCity.value)
            var image = document.getElementById("image").files[0];
            if (image){
                formData.append('image', this.state.image)
            }
            formData.append('status', this.state.status)
            formData.append('ordering', this.state.ordering)
            this.setState({submit: true})
            axios({
                method: "post", url: `${api_url}/add-deal-city`, data: formData, headers: {
                    'Content-Type': 'application/json',
                    "Authorization": 'Bearer ' + api_key,
                }
            }).then(response => {
                if (response.status) {
                    this.setState({submit: false})
                    toastr.success('Deal City add successful.')
                    // eslint-disable-next-line react/prop-types
                    const {history} = this.props
                    // eslint-disable-next-line react/prop-types
                    history.push('/deals/cities/pageno/1');

                }
            }).catch(err => {
                toastr.error(err.response?.data?.message)
                this.setState({submit: false})
            })
        } else {
            this.validator.showMessages()
            this.forceUpdate()
        }
    }
    handleCity = (e) => {
        // console.log(e);
      //  this.setState({selectedCity:e.value})
    }
    render() {

        const {city_name,ordering,submit,selectedCity,city} = this.state
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>
                            Deal Cities | {process.env.REACT_APP_PROJECTNAME}
                        </title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Deal Cities" path="/deals/cities/pageno/1" breadcrumbItem="Add City"/>
                        <Row>
                            <Col xl="10">
                                <Card>
                                    <CardBody>
                                        <form onSubmit={this.handleFormSubmit}>
                                            <Row>
                                                <Col md="3" className="mb-3">
                                                    <label className="control-label">
                                                        City
                                                    </label>
                                                    <Select
                                                        isMulti={false}
                                                        name="city"
                                                        onChange={e => {this.setState({selectedCity: e}); this.handleCity(e)}}
                                                        options={city}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                    {this.validator.message('selectedCity', this.state.selectedCity, 'required')}
                                                </Col>
                                                {/*<Col md="4" className="mb-3">*/}
                                                {/*    <Label htmlFor="city_name">City Name</Label>*/}
                                                {/*    <input name="city_name" placeholder="Type City Name" defaultValue={city_name} type="text" className={"form-control"} onChange={this.handleInput}/>*/}
                                                {/*    {this.validator.message('city_name', this.state.city_name, 'required')}*/}
                                                {/*</Col>*/}
                                                <Col md="4" className="mb-3">
                                                    <Label htmlFor="image">Image</Label>
                                                    <input
                                                        id="image"
                                                        name="image"
                                                        placeholder="Image"
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={this.handleFileChange}
                                                        className={"form-control"}
                                                    />
                                                    {this.validator.message('image', this.state.image, 'required')}
                                                </Col>
                                                <Col md="2" className="mb-3">
                                                    <Label htmlFor="ordering">Order No.</Label>
                                                    <input
                                                        name="ordering"
                                                        type="number"
                                                        placeholder="Order No."
                                                        className={"form-control"}
                                                        onChange={this.handleInput}
                                                        defaultValue={ordering}
                                                    />
                                                    {this.validator.message('ordering', this.state.ordering, 'required|numeric')}
                                                </Col>
                                                <Col md="auto" className="mb-3">
                                                    <Label htmlFor="">Status</Label>
                                                    <div className="square-switch mt-2">
                                                        <input
                                                            type="checkbox"
                                                            id="square-switch-status"
                                                            switch="none"
                                                            name="status"
                                                            checked={this.state.status}
                                                            onChange={() =>
                                                                this.setState({status: !this.state.status})
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="square-switch-status"
                                                            data-on-label="On"
                                                            data-off-label="Off"
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>

                                            <div className="d-flex flex-wrap gap-2 justify-content-end">
                                                <Button color="primary" type="submit" disabled={submit}>
                                                    {submit === true ? 'Please wait...' : 'Submit Data'}
                                                </Button>
                                            </div>
                                        </form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

DealCitiesAdd.propTypes = {
    location: PropTypes.object
}

export default DealCitiesAdd
