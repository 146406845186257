import React, {useEffect, useMemo, useState} from "react";
import { useParams, useHistory, useLocation } from 'react-router-dom';
import {Card, CardBody, CardTitle, Col, Row} from "reactstrap"
import MetaTags from 'react-meta-tags'
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {TableHeader, Pagination, Search} from "../Datatable/index";
import {apiKey} from "helpers/url_helper"

import axios from 'axios';
import notification from "components/Common/Notification";
let api_url = process.env.REACT_APP_API_URL;
let api_key = apiKey()

const useFullPageLoader = () => {
    const [loading, setLoading] = useState(false);

    return [
        loading ? 'Loading' : null,
        () => setLoading(true), //Show loader
        () => setLoading(false) //Hide Loader
    ];
};
let PageSize = 15;
const Interests = (props) => {
    const history = useHistory();
    const location = useLocation();
    const { pId:pageno } = useParams();
    const [tableData, settableData] = useState([]);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(() => {
        const paramPage = parseInt(pageno);
        if (!isNaN(paramPage)) {
            return paramPage;
          } else {
            return 1;
          }
      });
      const { searchQuery } = useParams();
      const [search, setSearch] = useState(() => {
          if (searchQuery) {
              return searchQuery;
            } else {
              return '';
            }
        });
      const [getSearch, setGetSearch] = useState(search);
      const [getSearchPage, setGetSearchPage] = useState(currentPage)
      const [sorting, setSorting] = useState({field: "", order: ""});

    const ITEMS_PER_PAGE = 50;

    const CountryHeader = [
        {name: "#", field: "id", sortable: false},
        {name: "Interest Name", field: "name", sortable: false},
        {name: "Interest Image", field: "image", sortable: false},
        {name: "Ordering", field: "ordering", sortable: false},
        {name: "Status", field: "status", sortable: false},
        // {name: "Action", field: "", sortable: false}
    ];
    const getData = () => {
        let query = ''
        search && ( query = `keyword=${search}&`)
        //   const url = `${api_url}search/movie?${query}&api_key=${api_key}&page=${currentPage}`
        const url = `${api_url}/get-interests?${query}&limit=${PageSize}&pageNo=${currentPage}`
        axios.get(url,{
            headers: {
                "Authorization": 'Bearer '+api_key,
            }
        })
          .then(res => {
              hideLoader();
              if(res.status) {
                  console.log(res.data.data);
                  settableData(res.data.data);
                  setCurrentPage(1);
                  setTotalItems(res.data.total_results);
              }
              // setComments(res.data.results);
              // setCurrentPage(res.data.page);
              // setTotalItems(res.data.total_results);
          }).catch(
            err => {
                notification(err, 'error', 'toast-bottom-right')
                if(err.response.status===401 || err.response.status===400 ){
                    history.push('/logout')
                }
            }
        );
        //   settableData(sample_data);
   //     setCurrentPage(1);
    //    setTotalItems(sample_data.length);
    };

    function handleSearch(){
        setSearch(getSearch);
        setCurrentPage(getSearchPage);
        if(getSearch){
            history.push(`/interests/pageno/${getSearchPage}/${getSearch}`)
        }
    }const handleChange = (value) => {
        history.push(`/interests/pageno/${value}/${search}`)
    };
    // Handle any changes to the currentPage state
    const handlePageChange = () => {
        setCurrentPage(() => {
            const paramPage = parseInt(pageno);
            if (!isNaN(paramPage)) {
                return paramPage;
            } else {
                return 1;
            }
        })
    };
    useEffect(() => {
        handlePageChange();
    }, [pageno,location]);

    useEffect(getData, [currentPage,search]);

    const commentsData = useMemo(() => {
        let computedComments = tableData;

        if (search) {
            computedComments = computedComments.filter(
              comment =>
                comment.name.toLowerCase().includes(search.toLowerCase()) ||
                comment.image.toLowerCase().includes(search.toLowerCase())
            );
        }
        setTotalItems(computedComments.length);

        //Sorting comments
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort(
              (a, b) =>
                reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        //Current Page slice
        return computedComments.slice(
          (currentPage - 1) * ITEMS_PER_PAGE,
          (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE,
        );
    }, []);
    return (
      <React.Fragment>
          <div className="page-content">
              <MetaTags>
                  <title>Manage Interests</title>
              </MetaTags>
              <div className="container-fluid">
                  <Breadcrumbs title="Dashboard" path="/" breadcrumbItem="Interests"/>
                  <Row>
                      <Col className="col-12">
                          <Card>
                              <CardBody>
                                  {/*<div className="d-flex justify-content-end mb-3">*/}
                                  {/*    <div>*/}
                                  {/*        <a href="/interests/add"*/}
                                  {/*           className="btn btn-primary waves-effect waves-light"><i*/}
                                  {/*            className="bx bx-plus font-size-16 align-middle me-2"></i> Add</a>*/}
                                  {/*    </div>*/}
                                  {/*</div>*/}
                                  <div className="row">
                                      <div className="col-md-6">

                                      </div>
                                      <div className="col-md-6 d-flex flex-row-reverse">
                                      <div onClick={handleSearch}
                                               className="btn btn-primary waves-effect waves-light ms-2"> Search</div>
                                            <Search
                                                onSearch={value => {
                                                    setGetSearch(value)
                                                    setGetSearchPage(1)
                                                }}
                                            />
                                      </div>
                                  </div>

                                  <table className="table table-striped">
                                      <TableHeader
                                        headers={CountryHeader}
                                        onSorting={(field, order) =>
                                          setSorting({field, order})
                                        }
                                      />
                                      <tbody className="">

                                      {tableData.map((value,index) => (
                                          <tr key={++index}>
                                              <th scope="row">
                                                  {PageSize * (currentPage - 1) + (index + 1)}
                                              </th>
                                            <td>{value.name}</td>
                                            <td><img src={value.image} className="img-thumbnail avatar-sm"
                                                     width="200" alt={value.name}/></td>
                                            <td>{value.ordering}</td>
                                            <td>{value.status === true ? <span className="badge badge-pill badge-soft-success font-size-13">Active</span> : <span className="badge badge-pill badge-soft-error font-size-13">Inactive</span>}</td>
                                            {/*<td><Link to={`/interests/edit/${value.id}`}><i className="bx bxs-edit-alt"></i> </Link></td>*/}
                                        </tr>
                                      ))}
                                      </tbody>
                                  </table>
                                  <Pagination
                                    total={totalItems}
                                    itemsPerPage={20}
                                    currentPage={currentPage}
                                    onPageChange={page => handleChange(page)}
                                  />
                              </CardBody>
                          </Card>
                      </Col>
                  </Row>
              </div>
          </div>
      </React.Fragment>
    )
}

export default Interests
