import React, {useEffect, useMemo, useState} from "react"
import { useHistory } from 'react-router-dom';
import {Button, Card, CardBody, CardTitle, Col, Label, Modal, Row} from "reactstrap"
import MetaTags from 'react-meta-tags'
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {TableHeader, Pagination, Search} from "../Datatable/index"
import {apiKey} from "helpers/url_helper"

import axios from 'axios'
import Moment from "moment/moment"
import toastr from "toastr"
import notification from "components/Common/Notification"

let api_url = process.env.REACT_APP_API_URL
let api_key = apiKey()

const useFullPageLoader = () => {
    const [loading, setLoading] = useState(false)    
    const history = useHistory();

    return [
        loading ? 'Loading' : null,
        () => setLoading(true), //Show loader
        () => setLoading(false) //Hide Loader
    ]
}
let PageSize=15
const OrderCancelReason = (props) => {
    const [tableData, settableData] = useState([])
    const [loader, showLoader, hideLoader] = useFullPageLoader()
    const [totalItems, setTotalItems] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [search, setSearch] = useState("")
    const [sorting, setSorting] = useState({field: "", order: ""})
    const [bodyModal, setbodyModal] = useState(false)
    const [submit, setSubmit] = useState(false)
    const [Error, setError] = useState("")
    const [Id, setId] = useState("")
    const [CancelReason, setCancelReason] = useState("")
    const [Ordering, setOrdering] = useState("")
    const [Status, setStatus] = useState(false)

    const Header = [
        {name: "#", field: "id", sortable: false},
        {name: "Name", field: "name", sortable: false},
        {name: "Ordering", field: "ordering", sortable: false},
        {name: "Created Date", field: "created_date", sortable: false},
        {name: "Status", field: "status", sortable: false},
        {name: "Action", field: "", sortable: false}
    ]
    const getData = () => {
        let query = ''
        search && (query = `keyword=${search}&`)
        const url = `${api_url}/get-order-cancel-reason?${query}limit=${PageSize}&pageNo=${currentPage}`
        axios.get(url, {
            headers: {
                "Authorization": 'Bearer ' + api_key,
            }
        })
            .then(res => {
                hideLoader()
                if (res.status) {
                    settableData(res.data.data)
                    setCurrentPage(1)
                    setTotalItems(res.data.total_results)
                }
            }).catch(
                err => {
                    notification(err, 'error', 'toast-bottom-right')
                    if(err.response.status===401 || err.response.status===400 ){
                        history.push('/logout')
                    }
                }
            );
    }
    const FormSubmit = (e) => {
        e.preventDefault();
        let formData, url = "";
        if (Id) {
            formData = {
                "name": CancelReason,
                "ordering": Ordering,
                "status": Status,
                "id": Id
            }
            url = "update-order-cancel-reason"
        } else {
            formData = {
                "name": CancelReason,
                "ordering": Ordering,
                "status": Status
            }
            url = "add-order-cancel-reason"
        }
        if (!CancelReason || !Ordering) {
            setError("Please fill all fields")
            return false
        }
        setSubmit(true)
        axios({
            method: "post", url: `${api_url}/${url}`, data: formData, headers: {
            "Authorization": 'Bearer ' + api_key,
            },
        }).then(response => {
            if (response.status) {
                setSubmit(false)
                toastr.success("Order cancel reason data add")
                close()
                setCurrentPage(0)
            }
        }).catch(err => {
            toastr.error(err.message)
            setSubmit(false)
            alert(err.message)
        })
    }

    function handleAction(prop) {
        setbodyModal(true)
        setId(prop)
    }
    function close(){
        setbodyModal(false)
        setId("")
        setCancelReason("")
        setOrdering("")
        setStatus(false)
    }
    function tog_center() {
        document.body.classList.add("no_padding")
    }

    useEffect(getData, [currentPage, search])
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Order Cancel Reason</title>
                </MetaTags>
                <div className="container-fluid">
                    <Breadcrumbs title="Dashboard" path="/" breadcrumbItem="Order Cancel Reason"/>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <div className="d-flex justify-content-end mb-3">
                                        <div>

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">

                                        </div>
                                        <div className="col-md-6 d-flex flex-row-reverse">
                                            <a href="#" onClick={() => handleAction("")}
                                               className="btn btn-primary waves-effect waves-light ms-2"><i
                                                className="bx bx-plus font-size-16 align-middle me-2"></i> Add</a>
                                            <Search
                                                onSearch={value => {
                                                    setSearch(value)
                                                    setCurrentPage(1)
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <table className="table table-striped">
                                        <TableHeader
                                            headers={Header}
                                            onSorting={(field, order) =>
                                                setSorting({field, order})
                                            }
                                        />
                                        <tbody className="">{loader && <tr>
                                            <th colSpan={Header.length}><h4 className="text-center">Loading...</h4></th>
                                        </tr>}
                                        {(!loader && tableData.length === 0) && <tr>
                                            <th colSpan={Header.length}><h4 className="text-center">No data found</h4>
                                            </th>
                                        </tr>}
                                        {tableData.map((value, index) => (
                                            <tr key={++index}>
                                                <th scope="row">
                                                    {PageSize * (currentPage - 1) + (index + 1)}
                                                </th>
                                                <td>{value.name}</td>
                                                <td>{value.ordering}</td>
                                                <td>{Moment(value.created_date).format('DD-MM-YYYY')}</td>
                                                <td>{value.status === true ? <span
                                                        className="badge badge-pill badge-soft-success font-size-13">Active</span> :
                                                    <span
                                                        className="badge badge-pill badge-soft-error font-size-13">Inactive</span>}</td>
                                                <td><a href="#" onClick={() => {
                                                    handleAction(value._id)
                                                    setCancelReason(value.name)
                                                    setOrdering(value.ordering)
                                                    setStatus(value.status)
                                                }}><i className="bx bxs-edit-alt"></i> </a></td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                    <Row className="justify-content-center">
                                        <Col className="col-auto">
                                            <Pagination
                                                className="pagination-bar"
                                                currentPage={currentPage}
                                                totalCount={totalItems}
                                                pageSize={15}
                                                onPageChange={page => setCurrentPage(page)}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
            <Modal
                isOpen={bodyModal}
                toggle={() => {
                    tog_center()
                }}
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">Manage</h5>
                    <button
                        type="button"
                        onClick={() => {
                            close()
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <form onSubmit={event => {
                        FormSubmit(event)
                    }} method={"post"}>
                        <Row>
                            <Col md="12" className="mb-3">
                                <Label htmlFor="CancelReason">Cancel Reason</Label>
                                <textarea name="CancelReason" placeholder="Type Cancel Reason"
                                          defaultValue={CancelReason} onInput={event => {
                                    setCancelReason(event.target.value)
                                }} className={"form-control"} required></textarea>
                            </Col>
                            <Col md={"auto"} className={"mb-3"}>
                                <Label htmlFor="">Order No.</Label>
                                <input className="form-control" defaultValue={Ordering} onInput={e => {
                                    setOrdering(e.target.value)
                                }} required/>
                            </Col>
                            <Col md="3">
                                <Label htmlFor="">Status</Label>
                                <div className="square-switch mt-2">
                                    <input
                                        type="checkbox"
                                        id="square-switch1"
                                        switch="none"
                                        name="status"
                                        checked={Status}
                                        onChange={() => setStatus(!Status)}
                                    />
                                    <label
                                        htmlFor="square-switch1"
                                        data-on-label="On"
                                        data-off-label="Off"
                                    />
                                </div>
                            </Col>
                        </Row>
                        {Error !== '' && <p className="text-danger">{Error}</p>}
                        <div className="d-grid">
                            <Button color="primary" className="btn-block" type="submit" disabled={submit}>
                                {submit === true ? "Please wait..." : "Submit Data"}
                            </Button>
                        </div>
                    </form>
                </div>
            </Modal>
        </React.Fragment>
    )
}

export default OrderCancelReason
