import React from "react"
import MetaTags from "react-meta-tags"
import {Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Row,} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Select from "react-select"
import {apiKey} from "../../helpers/url_helper"
import SimpleReactValidator from 'simple-react-validator'
import axios from "axios"
import toastr from "toastr"
import "style.min.css"
import PropTypes from "prop-types"

let api_url = process.env.REACT_APP_API_URL
let api_key = apiKey()

class OriginalSeasonsAdd extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            originalData: [],
            status: true,
            season_no: "",
            season_name: "",
            cover_image: "",
            trailer_cover_image: "",
            trailer_url: "",
            original: "",
            ordering: "",
            handleResponse: null,
            submit: false
        }
        this.validator = new SimpleReactValidator({autoForceUpdate: this})
    }

    componentDidMount() {
        const headers = {'Content-Type': 'application/json', "Authorization": 'Bearer ' + api_key}

        fetch(`${api_url}/get-shows`, {headers})
            .then(response => response.json())
            .then(data => this.setState({originalData: data.data}))
    }

    handleOriginal = selectedTags => {
        console.log(selectedTags['value'])
        this.setState({original: selectedTags['value']})
    }

    handleFileChange = (event) => {
        this.setState({
            cover_image: event.target.files[0]
        })
    }

    handleTrailerFileChange = (event) => {
        this.setState({
            trailer_cover_image: event.target.files[0]
        })
    }

    selectData = a => {
        let b = []
        for (var c = 0; c < a.length; c++) b.push({
            label: a[c].name,
            value: a[c]._id
        })
        return b
    }
    handleInput = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }
    handleFormSubmit = (e) => {
        e.preventDefault()
        if (this.validator.allValid()) {
            const formData = new FormData()
            formData.append('season_no', this.state.season_no)
            formData.append('season_name', this.state.season_name)
            // var cover_image = document.getElementById("cover_image").files[0];
            var trailer_cover_image = document.getElementById("trailer_cover_image").files[0];
            // if (cover_image){
            //     formData.append('cover_image', this.state.cover_image)
            // }
            if (trailer_cover_image){
                formData.append('trailer_cover_image', this.state.trailer_cover_image)
            }
            formData.append('trailer_url', this.state.trailer_url)
            formData.append('original', this.state.original)
            formData.append('status', this.state.status)
            formData.append('ordering', this.state.ordering)
            this.setState({submit: true})
            axios({
                method: "post", url: `${api_url}/add-season`, data: formData, headers: {
                    'Content-Type': 'application/json',
                    "Authorization": 'Bearer ' + api_key,
                }
            }).then(response => {
                if (response.status) {
                    this.setState({submit: false})
                    toastr.success('Original season added successful.')
                    // eslint-disable-next-line react/prop-types
                    const {history} = this.props
                    // eslint-disable-next-line react/prop-types
                    history.push('/seasons/pageno/1');

                }
            }).catch(err => {
                toastr.error(err.message)
                this.setState({submit: false})
                alert(err.message)
            })
        } else {
            this.validator.showMessages()
            this.forceUpdate()
        }
    }

    render() {

        const {originalData, season_no, ordering, submit} = this.state

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>
                            Manage Originals | {process.env.REACT_APP_PROJECTNAME}
                        </title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Original Seasons" path="/seasons/pageno/1" breadcrumbItem="Add Original Seasons"/>
                        <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                        <form onSubmit={this.handleFormSubmit}>
                                            <Row>
                                                <Col md="2" className="mb-3">
                                                    <Label htmlFor="validationCustom03">Season No.</Label>
                                                    <input
                                                        name="season_no"
                                                        type="text"
                                                        placeholder="Season No."
                                                        className={"form-control"}
                                                        onChange={this.handleInput}
                                                        defaultValue={season_no}
                                                    />
                                                    {this.validator.message('season_no', this.state.season_no, 'required')}
                                                </Col>
                                                <Col md="6" className="mb-3">
                                                    <Label htmlFor="season_name">Season Name</Label>
                                                    <input name="season_name" placeholder="Type Season Name"  type="text" className={"form-control"}
                                                           onChange={this.handleInput}/>
                                                    {this.validator.message('season_name', this.state.season_name, 'required|string')}
                                                </Col>
                                                <Col md="4" className="mb-3">
                                                    <label className="control-label">Originals</label>
                                                    <Select
                                                        isMulti={false}
                                                        onChange={this.handleOriginal}
                                                        options={this.selectData(originalData)}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                    {this.validator.message('original', this.state.original, 'required')}
                                                </Col>
                                                {/*<Col md="3" className="mb-3">*/}
                                                {/*    <Label htmlFor="cover_image">*/}
                                                {/*        Logo*/}
                                                {/*    </Label>*/}
                                                {/*    <input*/}
                                                {/*        id="cover_image"*/}
                                                {/*        name="cover_image"*/}
                                                {/*        placeholder="Logo Image"*/}
                                                {/*        type="file"*/}
                                                {/*        accept="image/*"*/}
                                                {/*        onChange={this.handleFileChange}*/}
                                                {/*        className={"form-control"}*/}
                                                {/*    />*/}
                                                {/*    {this.validator.message('cover_image', this.state.cover_image, 'required')}*/}
                                                {/*</Col>*/}
                                                <Col md="3" className="mb-3">
                                                    <Label htmlFor="trailer_cover_image">
                                                        Season Thumbnail
                                                    </Label>
                                                    <input
                                                        id="trailer_cover_image"
                                                        name="trailer_cover_image"
                                                        placeholder="Season Thumbnail"
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={this.handleTrailerFileChange}
                                                        className={"form-control"}
                                                    />
                                                    {this.validator.message('trailer_cover_image', this.state.trailer_cover_image, 'required')}
                                                </Col>
                                                <Col md="4" className="mb-3">
                                                    <Label htmlFor="trailer_url">Trailer URL</Label>
                                                    <input name="trailer_url" placeholder="Type Trailer URL"  type="url" className={"form-control"}
                                                           onChange={this.handleInput}/>
                                                    {this.validator.message('trailer_url', this.state.trailer_url, 'required')}
                                                </Col>
                                                <Col md="2" className="mb-3">
                                                    <Label htmlFor="validationCustom03">Order No.</Label>
                                                    <input
                                                        name="ordering"
                                                        type="number"
                                                        placeholder="Order No."
                                                        className={"form-control"}
                                                        onChange={this.handleInput}
                                                        defaultValue={ordering}
                                                    />
                                                    {this.validator.message('ordering', this.state.ordering, 'required|numeric')}
                                                </Col>
                                                <Col md="3" className="mb-3">
                                                    <Label htmlFor="">Status</Label>
                                                    <div className="square-switch mt-2">
                                                        <input
                                                            type="checkbox"
                                                            id="square-switch-status"
                                                            switch="none"
                                                            name="status"
                                                            checked={this.state.status}
                                                            onChange={() =>
                                                                this.setState({status: !this.state.status})
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="square-switch-status"
                                                            data-on-label="On"
                                                            data-off-label="Off"
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>

                                            <div className="d-flex flex-wrap gap-2 justify-content-end">
                                                <Button color="primary" type="submit" disabled={submit}>
                                                    {submit === true ? 'Please wait...' : 'Submit Data'}
                                                </Button>
                                            </div>
                                        </form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

OriginalSeasonsAdd.propTypes = {
    location: PropTypes.object
}

export default OriginalSeasonsAdd
