import React from "react"
import { Card, CardBody, CardTitle, Progress } from "reactstrap"
import { Link } from "react-router-dom"
const TotalUsers = ({ usercount, creatorscount, contributorcount }) => {
  return (
    <React.Fragment>
      <Card>
        <CardBody>
        <CardTitle className="mb-4">Total Users</CardTitle>

          <div className="text-center">
            <div className="mb-4">
              <i className="bx bx-user text-primary display-4" />
            </div>
            <h3>{usercount + creatorscount + contributorcount}</h3>
            <p>Total</p>
            {/* <Link className="text-decoration-underline text-reset" to={`/deals`}>More<i className="mdi mdi-arrow-right"></i></Link> */}
          </div>

        
          <div className="table-responsive mt-4">
            <table className="table align-middle table-nowrap">
              <tbody>
                <tr>
                  <td style={{ width: "30%" }}>
                    <p className="mb-0">User</p>
                  </td>
                  <td style={{ width: "25%" }}>
                    <h5 className="mb-0">{usercount}</h5>
                  </td>
                  <td>
                    <Progress
                      value={usercount}
                      color="primary"
                      className="bg-transparent progress-sm"
                      size="sm"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="mb-0">Contributor</p>
                  </td>
                  <td>
                    <h5 className="mb-0">{contributorcount}</h5>
                  </td>
                  <td>
                    <Progress
                      value={contributorcount}
                      color="success"
                      className="bg-transparent progress-sm"
                      size="sm"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="mb-0">Creators</p>
                  </td>
                  <td>
                    <h5 className="mb-0">{creatorscount}</h5>
                  </td>
                  <td>
                    <Progress
                      value={creatorscount}
                      color="warning"
                      className="bg-transparent progress-sm"
                      size="sm"
                    />
                  </td>
                </tr>
               
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default TotalUsers
