import React, {useEffect, useState} from "react"
import { useParams, useHistory, useLocation } from 'react-router-dom';
import {Card, CardBody, Modal, Col, Row} from "reactstrap"
import MetaTags from 'react-meta-tags'
import Pagination from 'components/Common/Pagination'
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {TableHeader, Search} from "../Datatable/index"
import ReactPlayer from 'react-player'
// import Hls from "hls.js";
import {Link} from "react-router-dom"
import axios from "axios"
import notification from "../../components/Common/Notification"
import {apiKey} from "../../helpers/url_helper"
import {Image} from "react-bootstrap"
import toastr from "toastr"
import {
    GridContextProvider,
    GridDropZone,
    GridItem,
    swap,
  } from "react-grid-dnd"

let api_url = process.env.REACT_APP_API_URL
let exportURL = api_url + "/get-guide-csv"
let api_key = apiKey()
let PageSize = 15
const useFullPageLoader = () => {
    const [loading, setLoading] = useState(false)
    // const [media, setMedia] = useState([]);

    return [
        loading ? 'Loading' : null,
        () => setLoading(true), //Show loader
        () => setLoading(false) //Hide Loader
    ]
}
const mystyle = {
    width: "200px",
    overflow: "hidden",
    maxHeight: "40px",
    textOverflow: "ellipsis",
    whiteSpace: "pre-line",
}

const Guides = (props) => {
    const history = useHistory();
    const location = useLocation();
    const { pId:pageno } = useParams();
    const [tableData, settableData] = useState([])
    const [loader, showLoader, hideLoader] = useFullPageLoader()
    const [totalItems, setTotalItems] = useState(0)
    const [currentPage, setCurrentPage] = useState(() => {
        const paramPage = parseInt(pageno);
        if (!isNaN(paramPage)) {
            return paramPage;
          } else {
            return 1;
          }
      });
    const { searchQuery } = useParams();
    const [search, setSearch] = useState(() => {
        if (searchQuery) {
            return searchQuery;
          } else {
            return '';
          }
      });
    const [getSearch, setGetSearch] = useState(search);
    const [getSearchPage, setGetSearchPage] = useState(currentPage)
    const [mediaModal, setMediaModal] = useState(false)
    const [mediaData, setMediaData] = useState([])
    const [mainId, setMainId] = useState("")
    const [is_delete, setDelete] = useState(false);
    const [bodyModal, setbodyModal] = useState(false)
    const [GuideBodyData, setGuideBodyData] = useState("")
    const [CategoryModal, setCategoryModal] = useState(false)
    const [CategoryData, setCategoryData] = useState([]);
    const [activeMediaIndex, setActiveMediaIndex] = useState();


    const Header = [
        {name: "#", field: "id", sortable: false},
        {name: "City", field: "city", sortable: false},
        {name: "Guides Title", field: "title", sortable: false},
        // {name: "Description ", field: "body", sortable: false},
        {name: "Cover Image", field: "cover_image", sortable: false},
        // {name: "Deal Associated", field: "is_deal_associated", sortable: false},
        {name: "Deal", field: "deal_link", sortable: false},
        // {name: "Recommended", field: "recommended", sortable: false},
        // {name: "Show on main page", field: "show_on_main_page", sortable: false},
        {name: "Media", field: "media", sortable: false},
        {name: "Categories", field: "category", sortable: false},
        {name: "Interests", field: "interests", sortable: false},
        // {name: "Tags", field: "tags", sortable: false},
        // {name: "Order No.", field: "ordering", sortable: false},
        {name: "Total Spam Count", field: "spams_comment_count", sortable: false},
        {name: "Status", field: "status", sortable: false},
        {name: "deep_link", field: "status", sortable: false},
        {name: "Action", field: "", sortable: false}
    ]


    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }
    // Handle any changes to the currentPage state
    const handlePageChange = () => {
        setCurrentPage(() => {
            const paramPage = parseInt(pageno);
            if (!isNaN(paramPage)) {
                return paramPage;
              } else {
                return 1;
              }
          })
        };
    // 
    function handleSearch(){
        setSearch(getSearch);
        setCurrentPage(getSearchPage);
        if(getSearch){
            history.push(`/guides/pageno/${getSearchPage}/${getSearch}`)
        }
    }
    useEffect(() => {
        handlePageChange();
    }, [pageno, location]);


    useEffect(() => {
        showLoader()
        let query = ''
        search && (query = `keyword=${search}`)
        const url = `${api_url}/get-guides?${query}&limit=${PageSize}&pageNo=${currentPage}`
        axios.get(url, {
            headers: {
                "Authorization": 'Bearer ' + api_key,
            }
        })
            .then(res => {
                hideLoader()
                if (res.status) {
                    settableData(res.data.data)
                    setTotalItems(res.data.totalRecords)
                }
            }).catch(
                err => {
                    notification(err, 'error', 'toast-bottom-right')
                    if(err.response.status===401 || err.response.status===400 ){
                        history.push('/logout')
                    }
                }
            );
    }, [ is_delete,currentPage,search])  // pass `value` as a dependency

    function tog_center() {
        removeBodyCss()
    }

    function GuideBody(prop) {
        setbodyModal(true)
        setGuideBodyData(prop)
    }

    function category(prop) {
        setCategoryModal(true)
        setCategoryData(prop)
    }
    const deleteMedia = (data,index) => {
        let formData = {
            "id": mainId,
            "media_id": data._id,
            "type": "guides"
        }
        axios({
            method: "post", url: `${api_url}/delete-media-image`, data: formData, headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + api_key
            }
        }).then(response => {
            if (response.status){
                removeMedia(index)
                setCurrentPage(1)
                setDelete(!is_delete)
                notification('Delete success','success','toast-bottom-right')
            }
        }).catch(err => {
            toastr.error(err.message);
        });
    }
    const removeMedia = (index) => {
        let newMedia = []
        mediaData.map((val,key) => {
            if (key !== index){
                newMedia.push(val)
            }
        })
        setMediaData(newMedia)
    }
    const handleCopy = (value) => {
        let a = navigator.clipboard.writeText(value)
        navigator.clipboard.readText().then(function(data) {
            toastr.success(`Successfully Copied - ${data}`);
        });
    }

     // save Rearranged List to DB
  const saveReorderedList=(reorderedList)=>{
    console.log(mainId)
    let payload = {
        id: mainId,
        media : reorderedList
    }
    // Api call to save reordersList to the data base
    const url = `${api_url}/update--guide-media-image`;
    axios({
            method: "post", url, data: payload, headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + api_key
            }
        })
        .then(res => {
            if(res.status) {
                notification('Saved','success','toast-bottom-right')
            }
        }).catch(err => notification(err,'error','toast-bottom-right'));
  }
  // Function to handle drag and drop events    
  function media(prop,id,index) {        
      setMediaModal(true)
      setMediaData(prop)
      setMainId(id)
      setActiveMediaIndex(index-1)
    }
    function updateTableMediaAtIndex(index,rearrangedList){
        const updatedMediaData = [...tableData];
        updatedMediaData[activeMediaIndex].media = rearrangedList;
        settableData(updatedMediaData);
        console.log('idx',index)
        console.log(updatedMediaData[activeMediaIndex].media)
        console.log(tableData)
    }
    function onChange(sourceId,sourceIndex,targetIndex) {
     const nextState = swap(mediaData, sourceIndex, targetIndex);
     setMediaData(nextState);
     saveReorderedList(nextState);
    //  console.log('next',nextState);
     updateTableMediaAtIndex(activeMediaIndex, nextState);
   }
    

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Manage Guides</title>
                </MetaTags>
                <div className="container-fluid">
                    <Breadcrumbs title="Dashboard" path="/" breadcrumbItem="Guides"/>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <div className="d-flex justify-content-between mb-3 gap-3">
                                        <div>
                                            <a href={exportURL}
                                                target="_blank" rel="noreferrer"
                                               className="btn btn-primary waves-effect waves-light"><i
                                                className="bx bx-export font-size-16 align-middle me-2"></i> Export</a>
                                        </div>
                                        <div>
                                            <a href="/guides/add"
                                               className="btn btn-primary waves-effect waves-light"><i
                                                className="bx bx-plus font-size-16 align-middle me-2"></i> Add</a>
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-md-12 d-flex flex-row-reverse">
                                            <div onClick={handleSearch}
                                               className="btn btn-primary waves-effect waves-light ms-2"> Search</div>
                                            <Search
                                                onSearch={value => {
                                                    setGetSearch(value)
                                                    setGetSearchPage(1)
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="table-responsive">
                                        <table className="table table-striped">
                                            <TableHeader
                                                headers={Header}
                                            />
                                            <tbody className="">{loader && <tr><th colSpan={Header.length}><h4 className="text-center">Loading...</h4></th></tr>}
                                            {(!loader && tableData.length === 0) && <tr><th colSpan={Header.length}><h4 className="text-center">No data found</h4></th></tr>}
                                            {!loader && tableData.map((value, index) => (
                                                <tr key={++index}>
                                                    <th scope="row">
                                                        {PageSize * (currentPage - 1) + (index + 1)}
                                                    </th>
                                                    <td>
                                                        {value.cities.map((value, key) => (
                                                            <span className="font-size-12 badge bg-success rounded-pill"
                                                                  key={key}>{value['city_name']}</span>
                                                        ))}
                                                    </td>
                                                    <td>{value.title}</td>
                                                    {/* <td>
                                                        <div style={mystyle}
                                                             className="text-truncate">{<div dangerouslySetInnerHTML={{ __html: value.body }} />}</div>
                                                        <a className="" onClick={() => GuideBody(value.body)}>Read
                                                            more <i
                                                                className="mdi mdi-arrow-right"></i></a></td> */}
                                                    <td><img src={value.cover_image} className="img-thumbnail avatar-sm"
                                                             width="200" alt={value.cover_image}/></td>
                                                    <td>{ value.is_deal_associated === true && value.deal_details.length && <span className="font-size-12">{value.deal_details[0]['name']}</span>}</td>
                                                    {/* <td>{value.recommended === true ? 'Yes' : 'No'}</td> */}
                                                    {/* <td>{value.show_on_main_page === true ? 'Yes' : 'No'}</td> */}
                                                    <td>
                                                        <div
                                                            className="btn-group btn-group-sm"
                                                            role="group"
                                                            aria-label="Basic example"
                                                        >
                                                            {value.media.length > 0 &&
                                                                <button className="btn-sm btn-rounded btn btn-primary"
                                                                        onClick={() => media(value.media,value._id, index)}>Media</button>}
                                                            <Link className="btn-sm btn-rounded btn btn-secondary"
                                                                  to={`/guides/media/${value._id}`}>Add Media</Link>
                                                        </div>
                                                    </td>
                                                    <td>{value.categoriesList.map((val, key) => (
                                                        <a onClick={() => category(value.categoriesList[key])}
                                                           className="font-size-12 badge bg-secondary rounded-pill"
                                                           key={key}>{val['name']}</a>
                                                    ))}</td>
                                                    <td>{value.interestsList.map((value, key) => (
                                                        <span className="font-size-12 badge bg-info rounded-pill"
                                                              key={key}>{value['name']}</span>
                                                    ))}</td>
                                                    {/*<td>{value.tagsList.map((value, key) => (*/}
                                                    {/*    <span className="font-size-12 badge bg-warning rounded-pill"*/}
                                                    {/*          key={key}>{value['name']}</span>*/}
                                                    {/*))}</td>*/}
                                                    {/*<th>{value.ordering}</th>*/}
                                                    <th>{value.guide_comment_report_spams_counts>0? <Link  to={`/guides/spam_reports/${value._id}`} style={{color:"green"}}>{value.guide_comment_report_spams_counts}</Link>:value.guide_comment_report_spams_counts}</th>
                                                    <td>{value.status === true ? <span
                                                            className="badge badge-pill badge-soft-success font-size-13">Active</span> :
                                                        <span
                                                            className="badge badge-pill badge-soft-error font-size-13">Inactive</span>}
                                                    </td>
                                                    <td>
                                                        <button style={{backgroundColor:'green',padding:'1px 10px'}} className="btn btn-primary waves-effect waves-light copu_btn" onClick={() => {handleCopy(value.deep_link)}}>Copy</button>
                                                    </td>

                                                    <td>

                                                        <Link to={`/guides/${currentPage}/edit/${value._id}`}><i
                                                            className="bx bxs-edit-alt"></i> </Link>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <Row className="justify-content-center">
                                        <Col className="col-auto">
                                            <Pagination
                                                className="pagination-bar"
                                                currentPage={currentPage}
                                                totalCount={totalItems}
                                                pageSize={PageSize}
                                                onPageChange={page => history.push(`/guides/pageno/${page}/${search}`)}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <Modal
                    size="lg"
                    isOpen={mediaModal}
                    toggle={() => {
                        tog_center()
                    }}
                    centered
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">Media</h5>
                        <button
                            type="button"
                            onClick={() => {
                                setMediaModal(false)
                                setMediaData([])
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Row className="g-2">
                        <GridContextProvider onChange={onChange}>
                        <GridDropZone
                        id="items"
                        boxesPerRow={3}
                        rowHeight={220}
                        style={{ height: 220 * Math.ceil(mediaData.length / 3) }}
                        >
                            {mediaData.map((value, key) => (
                            value['url'].split(".").pop() === 'mp4' || value['url'].split(".").pop() === 'm3u8' ?
                            <GridItem key={value['_id']}>
                                    <div className="position-relative">
                                        <span style={{"cursor":"pointer","position":"absolute","top":"10px","right":"10px","zIndex":"5","background":"#fff","height":"25px","width":"25px","textAlign":"center","lineHeight":"25px","borderRadius":"10px","color":"#e91e63"}} onClick={() => deleteMedia(value,key)}><i className="mdi mdi-trash-can d-block font-size-16"></i></span>
                                        <ReactPlayer playing={false} controls={true} width="100%" height="100%" url={value['url']} />
                                    </div>
                            </GridItem>
                            :
                            <GridItem key={value['_id']}>
                                    <div className="img-box" style={{margin:'10px'}}>
                                        <span style={{"cursor":"pointer","position":"absolute","top":"10px","right":"10px","zIndex":"5","background":"#fff","height":"25px","width":"25px","textAlign":"center","lineHeight":"25px","borderRadius":"10px","color":"#e91e63"}} onClick={() => deleteMedia(value,key)}><i className="mdi mdi-trash-can d-block font-size-16"></i></span>
                                        <img className="img-main" src={value['url']} alt={value['_id']} draggable="false"/>
                                        <img className="img-back" src={value['url']} alt={value['_id']} draggable="false"/>
                                    </div>
                            </GridItem>
                        ))}
                        </GridDropZone>
                    </GridContextProvider>
                            {mediaData.length === 0 && <h4 className="text-center">No Media</h4>}
                        </Row>
                    </div>
                </Modal>
                <Modal
                    isOpen={bodyModal}
                    toggle={() => {
                        tog_center()
                    }}
                    centered
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">Guide Description</h5>
                        <button
                            type="button"
                            onClick={() => {
                                setbodyModal(false)
                                setGuideBodyData("")
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <p>{<div dangerouslySetInnerHTML={{ __html: GuideBodyData }} />}</p>
                    </div>
                </Modal>
                <Modal
                    size="lg"
                    isOpen={CategoryModal}
                    toggle={() => {
                        tog_center()
                    }}
                    centered
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">Category</h5>
                        <button
                            type="button"
                            onClick={() => {
                                setCategoryModal(false)
                                setCategoryData("")
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <dl className="row mb-0">
                            <dt className="col-sm-4">Category</dt>
                            <dd className="col-sm-8">{CategoryData['name']}</dd>
                            <dt className="col-sm-4">Text Color</dt>
                            <dd className="col-sm-8">{CategoryData['text_color']}</dd>
                            <dt className="col-sm-4">Default Rating</dt>
                            <dd className="col-sm-8">{CategoryData['default_rating']}</dd>
                            <dt className="col-sm-4">User Rating Visible</dt>
                            <dd className="col-sm-8">{CategoryData['user_rating_visible'] && 'Yes'}</dd>
                            <dt className="col-sm-4">Seasonal</dt>
                            <dd className="col-sm-8">{CategoryData['seasonal'] && 'Yes'}</dd>
                            {CategoryData['seasonal'] && <dd className="col-12">
                                <dl className="row mb-0">
                                    <dt className="col-sm-4">Seasonal Title</dt>
                                    <dd className="col-sm-8">{CategoryData['seasonal_title']}</dd>
                                </dl>
                            </dd>
                            }
                        </dl>
                        <hr/>
                        <Row>
                            <Col md={6}>
                                <h6>Filled Image</h6>
                                <Image className="img-fluid" src={CategoryData['filled_image']}/>
                            </Col>
                            <Col md={6}>
                                <h6>Unfilled Image</h6>
                                <Image className="img-fluid" src={CategoryData['unfilled_image']}/>
                            </Col>
                        </Row>
                    </div>
                </Modal>
            </div>
        </React.Fragment>
    )
}

export default Guides
