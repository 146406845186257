import React, {useEffect, useRef, useState} from "react"
import { useParams, useHistory, useLocation } from 'react-router-dom';
import {Card, CardBody, Modal, Col, Row, Label, Button} from "reactstrap"
import MetaTags from 'react-meta-tags'
import Pagination from 'components/Common/Pagination'
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {TableHeader, Search} from "../Datatable/index"
import axios from "axios"
import { CSVLink } from "react-csv";
import notification from "../../components/Common/Notification"
import {apiKey} from "../../helpers/url_helper"
import Moment from 'moment';
import toastr from "toastr"
import {Link} from "react-router-dom"
import Select from "react-select";
import Flatpickr from "react-flatpickr";
let api_url = process.env.REACT_APP_API_URL
let api_key = apiKey()
let PageSize = 15
const headers = { "Content-Type": "application/json", "Authorization": "Bearer " + api_key };
const useFullPageLoader = () => {
    const [loading, setLoading] = useState(false)
    return [
        loading ? 'Loading' : null,
        () => setLoading(true), //Show loader
        () => setLoading(false) //Hide Loader
    ]
}

const Status = (status) => {
    if (status === 'Upcoming'){
        return (<span className="badge bg-info">Upcoming</span>)
    }else if (status === 'Completed'){
        return (<span className="badge bg-success">Completed</span>)
    }else{
        return (<span className="badge bg-warning">{status}</span>)
    }
}
// eslint-disable-next-line react/prop-types
const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    return (
        text.length < 50 ? <p className="read-text">{text}</p> : <p className="read-text">
            {isReadMore ? text.slice(0, 50) : text}
            <span onClick={toggleReadMore} className="read-or-hide">
        {isReadMore ? "...read more" : " show less"}
      </span>
        </p>
    );
};
let Csvheaders = [
    { label: "Order Id", key: "order_id" },
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Phone", key: "phone" },
    { label: "Total Amount", key: "totalAmount" },
    { label: "Status", key: "status" },
    { label: "Deal", key: "deal_name" },
    { label: "Parters", key: "partner_name" },
    { label: "Categroies", key: "deal_category" },
     { label: "Deal City", key: "deal_city" },
    { label: "Booking Date", key: "booking_date" },
    { label: "From Date", key: "from_date" },
    { label: "To Date", key: "to_date" }
]

const Orders = (props) => {
    const history = useHistory();
    const location = useLocation();
    const { pId:pageno } = useParams();


    const [tableData, settableData] = useState([])
    const [cancelReason, setCancelReason] = useState([])
    const [loader, showLoader, hideLoader] = useFullPageLoader()
    const [totalItems, setTotalItems] = useState(0)
    const [currentPage, setCurrentPage] = useState(() => {
        const paramPage = parseInt(pageno);
        if (!isNaN(paramPage)) {
            return paramPage;
          } else {
            return 1;
          }
      });
      const { searchQuery } = useParams();
      const [search, setSearch] = useState(() => {
          if (searchQuery) {
              return searchQuery;
            } else {
              return '';
            }
        });
      const [getSearch, setGetSearch] = useState(search);
      const [getSearchPage, setGetSearchPage] = useState(currentPage)
      const [bodyModal, setbodyModal] = useState(false)
    const [submit, setSubmit] = useState(false)
    const [LoadReason, setLoadReason] = useState(false)
    const [OrderId, setOrderId] = useState("")
    const [ReasonId, setReasonId] = useState("")
    const [Remark, setRemark] = useState("")
    const [Error, setError] = useState("")
    const [DataLoading, setDataLoading] = useState(false)
    const [newData, setNewData] = useState([])
    const [status, setStatus] = useState("")
    const [partners, setPartner] = useState([])
    const [selectPartner, setSelectedPartner]  = useState('');
    const [cities, setCity] = useState([])
    const [selectCity, setSelectedCity]  = useState('');
    const [dealCategory, setDealCategory] = useState([])
    const [selectCategory, setSelectedCategory]  = useState('');
    const [deals, setDeal] = useState([])
    const [selectDeal, setSelectedDeal]  = useState('');
    const [selectFromDate, setSelectedFromDate]  = useState('');
    const [selectToDate, setSelectedToDate]  = useState('');
    const csvInstance = useRef();
    const fetchData = () => {
        fetch(`${api_url}/get-deals?&status=1`, {headers})
            .then(response => response.json())
            .then(data =>setDeal(data.data))
            .catch(err => {
                console.log("error:", err);
        });
        fetch(`${api_url}/get-deal-categories?&status=1`, {headers})
            .then(response => response.json())
            .then(data =>setDealCategory(data.data))
            .catch(err => {
                console.log("error:", err);
            });
        fetch(`${api_url}/get-deal-city?&status=1`, {headers})
            .then(response => response.json())
            .then(data =>setCity(data.data))
            .catch(err => {
                console.log("error:", err);
            });
        fetch(`${api_url}/get-deal-partner?&status=1`, {headers})
            .then(response => response.json())
            .then(data =>setPartner(data.data))
            .catch(err => {
                console.log("error:", err);
            });
    };
    const Header = [
        {name: "Order ID", field: "Order ID", sortable: false},
        {name: "Partner", field: "Partner", sortable: false},
        {name: "Category", field: "Category", sortable: false},
        { name: "Deal City",field: "Deal City", sortable: false },
        {name: "Deal", field: "Deal", sortable: false},

        {name: "Total Amount", field: "Final Amount", sortable: false},
        {name: "From Date", field: "From Date", sortable: false},
        {name: "To Date", field: "To Date", sortable: false},
        {name: "Transaction id", field: "Transaction id", sortable: false},
        {name: "Booking Date", field: "Booking Date", sortable: false},
        {name: "Status", field: "status", sortable: false},
        {name: "Action", field: "", sortable: false}
    ]

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }
    function formatDate(date){
        return Moment(date).format('YYYY-MM-DD')
    }
    
    function handleSearch(){
        setSearch(getSearch);
        setCurrentPage(getSearchPage);
        if(getSearch){
            history.push(`/orders/pageno/${getSearchPage}/${getSearch}`)
        }
    }
    // Handle any changes to the currentPage state
const handlePageChange = () => {
    setCurrentPage(() => {
        const paramPage = parseInt(pageno);
        if (!isNaN(paramPage)) {
            return paramPage;
          } else {
            return 1;
          }
      })
};
useEffect(() => {
    handlePageChange();
}, [pageno,location]);

    useEffect(() => {
        fetchData();
        showLoader()
        let query = ''
        search && (query = `keyword=${search}&`)
        const url = `${api_url}/get-user-orders?${query}limit=${PageSize}&pageNo=${currentPage}${status !== "" ? `&status=${status}`:''}${selectPartner !== "" ? `&partner=${selectPartner}`:''}${selectDeal !== "" ? `&deal=${selectDeal}`:''}${selectCategory !== "" ? `&deal_category=${selectCategory}`:''}${selectCity !== "" ? `&location=${selectCity}`:''}${selectFromDate !== "" ? `&from_date=${selectFromDate}`:''}${selectToDate !== "" ? `&to_date=${selectToDate}`:''}`
        axios.get(url, {
            headers: headers
        })
            .then(res => {
                hideLoader()
                if (res.status) {
                    settableData(res.data.data)
                    setTotalItems(res.data.totalRecords)
                }
            }).catch(
                err => {
                    notification(err, 'error', 'toast-bottom-right')
                    if(err.response.status===401 || err.response.status===400 ){
                        history.push('/logout')
                    }
                }
            );
    }, [currentPage, search, status,selectPartner,selectDeal,selectCategory,selectCity,selectToDate,selectFromDate])  // pass `value` as a dependency

    function tog_center() {
        removeBodyCss()
    }

    useEffect( () => {
        if (LoadReason === false){
            fetch(`${api_url}/get-order-cancel-reason?pageNo=1&limit=100&status=1`, { headers })
                .then(response => response.json())
                .then(data => setCancelReason(data.data));
            setLoadReason(true)
        }
    })

    function handleAction(prop) {
        setbodyModal(true)
        setReasonId("")
        setRemark("")
        setOrderId(prop)
    }

    function FormSubmit(e) {
        e.preventDefault();
        if (!ReasonId){
            setError("Select Cancellation Reason")
            return false;
        }
        let formData = {
            "order_cancel_id":ReasonId,
            "remark":Remark,
            "orderId":OrderId
        }
        setSubmit(true)
        axios({
            method: "post", url: `${api_url}/update-order_status`, data: formData, headers: headers
        }).then(response => {
            if (response.status) {
                setSubmit(false)
                toastr.success("Order has been canceled");
                setbodyModal(false)
                setOrderId("")
                setCurrentPage(0)
            }
        }).catch(err => {
            toastr.error(err.message);
            setSubmit(false)
            alert(err.message);
        });
    }

    const ExportOrders = () => {
        setDataLoading(true)
        let query = ''
        search && (query = `keyword=${search}`)
        const url = `${api_url}/export-orders?${query}${status !== "" ? `&status=${status}`:''}${selectPartner !== "" ? `&partner=${selectPartner}`:''}${selectDeal !== "" ? `&deal=${selectDeal}`:''}${selectCategory !== "" ? `&deal_category=${selectCategory}`:''}${selectCity !== "" ? `&location=${selectCity}`:''}${selectFromDate !== "" ? `&from_date=${selectFromDate}`:''}${selectToDate !== "" ? `&to_date=${selectToDate}`:''}`
        fetch(url, { headers })
        .then(response => response.json())
        .then(data => {
            if(data.status){
                setNewData(data.data)
                if (newData && csvInstance.current && csvInstance.current.link) {
                    setTimeout(() => {
                        csvInstance.current.link.click();
                        setNewData([]);
                    });
                }
            }else {
                toastr.error("Order export failed");
            }
            setDataLoading(false)
        }).catch(e => {
            toastr.error("Order export failed");
            setDataLoading(false)
        });
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Manage Orders</title>
                </MetaTags>
                <div className="container-fluid">
                    <Breadcrumbs title="Dashboard" path="/" breadcrumbItem="Orders"/>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <div className="row mb-2">
                                        <div className="col-md-6">

                                        </div>
                                        <div className="col-md-12 d-flex flex-row-reverse">
                                            <button className="btn btn-outline-primary ms-2" disabled={DataLoading} onClick={() => ExportOrders()}>{DataLoading === false ? 'Export':'Progressing'}</button>
                                            {Csvheaders.length > 0 && <CSVLink data={newData} headers={Csvheaders} filename={`users-orders.csv`} target="_blank" ref={csvInstance}></CSVLink>}
                                            <div onClick={handleSearch}
                                               className="btn btn-primary waves-effect waves-light ms-2" style={{minWidth:'fit-content'}}>Search</div>
                                            <Search
                                                onSearch={value => {
                                                    setGetSearch(value)
                                                    setGetSearchPage(1)
                                                }}
                                            />
                                            <div className="me-2">
                                                <Flatpickr
                                                    style={{maxWidth:"140px"}}
                                                    className="form-control d-block"
                                                    placeholder="To Date "
                                                    name="to_date"
                                                    onChange={date => {
                                                        if(date[0]!==undefined)
                                                            setSelectedToDate(formatDate(date[0]));
                                                        else
                                                            setSelectedToDate('')
                                                        setCurrentPage(1)
                                                    }}
                                                    options={{
                                                        enableTime: true,
                                                        altInput: true,
                                                        altFormat: "F j, Y",
                                                        dateFormat: "Y-m-d"
                                                    }}
                                                />
                                            </div>
                                            <div className="me-2">
                                                <Flatpickr
                                                    style={{maxWidth:"140px"}}
                                                    className="form-control d-block"
                                                    placeholder="From Date"
                                                    name="from_date"
                                                    onChange={date => {

                                                        if(date[0]!==undefined)
                                                            setSelectedFromDate(formatDate(date[0]));
                                                        else
                                                            setSelectedFromDate('');
                                                        setCurrentPage(1)
                                                    }}
                                                    options={{
                                                        enableTime: true,
                                                        altInput: true,
                                                        altFormat: "F j, Y",
                                                        dateFormat: "Y-m-d"
                                                    }}
                                                />
                                            </div>

                                            <div className="me-3">
                                                <select style={{maxWidth:"190px"}} className="form-select"  value={selectCategory} onChange={(e) =>
                                                {setSelectedCategory(e.currentTarget.value)
                                                    setCurrentPage(1)
                                                } }>
                                                    <option value="" selected>Deal Category</option>
                                                    {dealCategory.map((item) => (
                                                        <option key={item._id} value={item._id}>
                                                            {item.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="me-3">
                                                <select style={{maxWidth:"130px"}} className="form-select"  value={selectPartner} onChange={(e) =>
                                                {setSelectedPartner(e.currentTarget.value)
                                                    setCurrentPage(1)
                                                } }>
                                                    <option value="" selected>Partner</option>
                                                    {partners.map((item) => (
                                                        <option key={item._id} value={item._id}>
                                                            {item.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="me-3">
                                                <select style={{maxWidth:"130px"}} className="form-select"  value={selectCity} onChange={(e) =>
                                                {setSelectedCity(e.currentTarget.value)
                                                    setCurrentPage(1)
                                                } }>
                                                    <option value="" selected>Deal City</option>
                                                    {cities.map((item) => (
                                                        <option key={item._id} value={item._id}>
                                                            {item.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="me-3">
                                                <select style={{maxWidth:"150px"}} className="form-select"  value={selectDeal} onChange={(e) =>
                                                {setSelectedDeal(e.currentTarget.value)
                                                    setCurrentPage(1)
                                                } }>
                                                    <option value="" selected>Deal</option>
                                                    {deals.map((item) => (
                                                        <option key={item._id} value={item._id}>
                                                            {item.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="me-3">
                                                <select className="form-select" value={status} onChange={(e) => {
                                                    (setStatus(e.target.value))
                                                    setCurrentPage(1)
                                                }}>
                                                    <option value="" selected>Status</option>
                                                    <option value={'Cancelled'}>Cancelled</option>
                                                    <option value={'Completed'}>Completed</option>
                                                    <option value={'Upcoming'}>Upcoming</option>
                                                </select>
                                            </div>


                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-striped">
                                            <TableHeader
                                                headers={Header}
                                            />
                                            <tbody className="">{loader && <tr><th colSpan={Header.length}><h4 className="text-center">Loading...</h4></th></tr>}
                                            {(!loader && tableData.length === 0) && <tr><th colSpan={Header.length}><h4 className="text-center">No data found</h4></th></tr>}
                                            {!loader && tableData.map((value, index) => (
                                                <tr key={++index}>
                                                    <th scope="row">
                                                        <div className={"font-size-12"}>{value['order_id'] !== undefined && value['order_id']}{value['order_id'] === undefined && <span className="text-danger">Not Available</span>}</div>
                                                    </th>
                                                    <td>{(typeof value['deal_details'][0]?.partners === 'object' && value['deal_details'][0]['partners'][0]['name'] !== undefined) && value['deal_details'][0]['partners'][0]['name']}</td>
                                                    <td>{(typeof value['deal_details'][0]?.deal_category_details === 'object' && value['deal_details'][0]?.deal_category_details?.[0]['name'] !== undefined) && value['deal_details'][0]?.deal_category_details[0]['name']}</td>
                                                    <td>{(typeof value['deal_details'][0]?.locations === 'object' && value['deal_details'][0]?.locations?.[0]['name'] !== undefined) && value['deal_details'][0]?.locations[0]['name']}</td>
                                                    <td><div style={{maxWidth:"180px"}}>{value.deal_details !== undefined && value.deal_details.map((val,key) => {
                                                        return ( <ReadMore key={key}>{val.name}</ReadMore>)
                                                    })}</div></td>
                                                    <td>{value.totalAmount !== undefined && value.totalAmount}</td>
                                                    <td>{value.order_details?.[0]?.meta.start_date !== undefined && value.order_details?.[0]?.meta.start_date !== null && <time>{Moment(value.order_details?.[0].meta.start_date).format('DD/MM/YYYY')}</time>}</td>
                                                    <td>{value.order_details?.[0]?.meta.end_date !== undefined && value.order_details?.[0]?.meta.end_date !== null  && <time>{Moment(value.order_details?.[0]?.meta.end_date).format('DD/MM/YYYY')}</time>}</td>
                                                    <td>{value['transaction_id'] !== undefined && <span className={"badge badge-soft-primary font-size-13"}>{value['transaction_id']}</span>}</td>
                                                    <td>{value['created_date'] !== undefined && <time>{Moment(value['created_date']).format('DD/MM/YYYY')}</time>}</td>
                                                    <td>{value.status !== undefined && Status(value.status)}</td>
                                                    <td><div className="btn-group btn-group-sm"><Link className="btn btn-outline-secondary" to={`/orders/${value?._id}`}><i className="mdi mdi-information font-size-18"></i></Link>{value.status !== undefined && (value.status === 'Upcoming' || value.status === 'Pending') && <button className="btn btn-outline-secondary" onClick={() =>{handleAction(value.order_details?.[0]?.orderId)}}><i className="bx bxs-edit-alt"></i> </button>}</div></td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <Row className="justify-content-center">
                                        <Col className="col-auto">
                                            <Pagination
                                                className="pagination-bar"
                                                currentPage={currentPage}
                                                totalCount={totalItems}
                                                pageSize={PageSize}
                                                onPageChange={page => history.push(`/orders/pageno/${page}/${search}`)}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <Modal
                    isOpen={bodyModal}
                    toggle={() => {
                        tog_center()
                    }}
                    centered
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">Cancel Order</h5>
                        <button
                            type="button"
                            onClick={() => {
                                setbodyModal(false)
                                setOrderId("")
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={event => {FormSubmit(event)}} method={"post"}>
                            <Row>
                                <Col md="12" className="mb-3">
                                    <Label htmlFor="remark">Remark</Label>
                                    <textarea name="remark" placeholder="Type Remark" onInput={event => {setRemark(event.target.value)}} className={"form-control"}></textarea>
                                </Col>
                                <Col md={12} className="mb-3">
                                    <Label htmlFor="reason">Cancellation Reason</Label>
                                    <select name="reason" className={"form-control"} onChange={event => {setReasonId(event.target.value)}}>
                                        <option value="" disabled selected={true}>Select a reason</option>
                                        {cancelReason.map((value,key) => {
                                            return (<option key={key} value={value._id}>{value.name}</option>)
                                        })}
                                    </select>
                                </Col>
                            </Row>
                            {Error !== '' && <p className="text-danger">{Error}</p>}
                            <div className="d-grid">
                                <Button color="primary" className="btn-block" type="submit" disabled={submit}>
                                    {submit === true ? "Please wait..." : "Submit Data"}
                                </Button>
                            </div>
                        </form>
                    </div>
                </Modal>
            </div>
        </React.Fragment>
    )
}

export default Orders
