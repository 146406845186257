import React from "react"
import MetaTags from "react-meta-tags"
import {Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Row,} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Select from "react-select"
import {apiKey} from "../../helpers/url_helper"
import SimpleReactValidator from 'simple-react-validator'
import axios from "axios"
import toastr from "toastr"
import "style.min.css"
import PropTypes from "prop-types"
import {value} from "lodash/seq"
import Popular_original_episode from "./index"

let api_url = process.env.REACT_APP_API_URL
let api_key = apiKey()
const headers = {'Content-Type': 'application/json', "Authorization": 'Bearer ' + api_key}
class Popular_original_episode_edit extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            tags: [],
            original_master: [],
            seasons_master: [],
            episodes_master: [],
            defaultTags: [],
            status: true,
            episode_id: "",
            season_id: "",
            original_id: "",
            ordering: "",
            episode_no: "",
            handleResponse: null,
            submit: false
        }
        this.validator = new SimpleReactValidator({autoForceUpdate: this})
    }

    componentDidMount() {
        this.loadEdit()
        fetch(`${api_url}/get-shows?keyword=&status=1`, {headers})
            .then(response => response.json())
            .then(data => {
                let originals = [];
                data.data.map((val,key) => {
                    originals.push({value:val._id,label:val.name})
                })
                this.setState({original_master: originals})
            })
    }
    loadEdit = () => {
        fetch(`${api_url}/get-single-data?type=popular_original&id=${this.props.match.params.id}`, {headers})
            .then(response => response.json())
            .then(data => {
                if (data.status){
                    let edit = data.data[0]
                    this.setState({
                        status: edit.status,
                        episode_id: {value:edit.original_season_episodes[0].id,label:edit.original_season_episodes[0].episode_name},
                        season_id: {value:edit.original_seasons[0].id,label:edit.original_seasons[0].searson_name},
                        original_id: {value:edit.originals[0].id,label:edit.originals[0].name},
                        ordering: edit.ordering,
                    },this.callMaster)
                }
            })
    }
    callMaster = () => {
        this.handleSeason()
        this.handleEpisode()
    }
    handleSeason = () => {
        fetch(`${api_url}/get-season?keyword=&status=1&original_id=${this.state.original_id?.value}`, {headers})
            .then(response => response.json())
            .then(data => {
                let seasons = [];
                data.data.map((val,key) => {
                    seasons.push({value:val._id,label:val.searson_name})
                })
                this.setState({seasons_master: seasons})
            })
    }

    handleEpisode = () => {
        fetch(`${api_url}/get-episodes?keyword=&status=1&original_season_id=${this.state.season_id?.value}`, {headers})
            .then(response => response.json())
            .then(data => {
                let episodes = [];
                data.data.map((val,key) => {
                    episodes.push({value:val._id,label:val.episode_name})
                })
                this.setState({episodes_master: episodes})
            })
    }

    handleFileChange = (event) => {
        this.setState({
            trending_image: event.target.files[0]
        })
    }
    handleInput = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }
    handleFormSubmit = (e) => {
        e.preventDefault()
        if (this.validator.allValid()) {
            let formData = {
                id:this.props.match.params.id,
                episode_id:this.state.episode_id?.value,
                season_id:this.state.season_id?.value,
                original_id:this.state.original_id?.value,
                status:this.state.status,
                ordering:this.state.ordering
            }
            this.setState({submit: true})
            axios({
                method: "post", url: `${api_url}/update-popular-original-episode`, data: formData, headers: {
                    'Content-Type': 'application/json',
                    "Authorization": 'Bearer ' + api_key,
                }
            }).then(response => {
                if (response.status) {
                    this.setState({submit: false})
                    toastr.success('Popular Original Episode update successful.')
                    console.log(response)
                    // eslint-disable-next-line react/prop-types
                    const {history} = this.props
                    // eslint-disable-next-line react/prop-types
                    const pageno = this.props.match.params.pId; // Get the pageno param from the URL
                    history.goBack();
                }
            }).catch(err => {
                toastr.error(err.message)
                console.log(err.message)
                this.setState({submit: false})
            })
        } else {
            this.validator.showMessages()
            this.forceUpdate()
        }
    }

    render() {

        const {original_master, seasons_master, episodes_master, episode_no, ordering, defaultTags, submit} = this.state

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>
                            Popular Original Episode | {process.env.REACT_APP_PROJECTNAME}
                        </title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Popular Original Episode" path="/trending-episodes/pageno/1" breadcrumbItem="Edit Popular Original Episode"/>
                        <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                        <form onSubmit={this.handleFormSubmit}>
                                            <Row>
                                                <Col md="4" className="mb-3">
                                                    <label className="control-label">Originals</label>
                                                    <Select
                                                        value={this.state.original_id}
                                                        isMulti={false}
                                                        onChange={e => {this.setState({original_id:e},this.handleSeason)}}
                                                        options={original_master}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                    {this.validator.message('episode_id', this.state.episode_id, 'required')}
                                                </Col>
                                                <Col md="4" className="mb-3">
                                                    <label className="control-label">Seasons</label>
                                                    <Select
                                                        value={this.state.season_id}
                                                        isMulti={false}
                                                        onChange={e => this.setState({season_id:e},this.handleEpisode)}
                                                        options={seasons_master}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                    {this.validator.message('season_id', this.state.season_id, 'required')}
                                                </Col>
                                                <Col md="4" className="mb-3">
                                                    <label className="control-label">Episodes</label>
                                                    <Select
                                                        value={this.state.episode_id}
                                                        isMulti={false}
                                                        onChange={e => this.setState({episode_id:e})}
                                                        options={episodes_master}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                    {this.validator.message('episode_id', this.state.episode_id, 'required')}
                                                </Col>
                                                <Col md="2" className="mb-3">
                                                    <Label htmlFor="validationCustom03">Order No.</Label>
                                                    <input
                                                        name="ordering"
                                                        type="number"
                                                        placeholder="Order No."
                                                        className={"form-control"}
                                                        onChange={this.handleInput}
                                                        defaultValue={ordering}
                                                    />
                                                    {this.validator.message('ordering', this.state.ordering, 'required|numeric')}
                                                </Col>
                                                <Col md="3" className="mb-3">
                                                    <Label htmlFor="">Status</Label>
                                                    <div className="square-switch mt-2">
                                                        <input
                                                            type="checkbox"
                                                            id="square-switch-status"
                                                            switch="none"
                                                            name="status"
                                                            checked={this.state.status}
                                                            onChange={() =>
                                                                this.setState({status: !this.state.status})
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="square-switch-status"
                                                            data-on-label="On"
                                                            data-off-label="Off"
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>

                                            <div className="d-flex flex-wrap gap-2 justify-content-end">
                                                <Button color="primary" type="submit" disabled={submit}>
                                                    {submit === true ? 'Please wait...' : 'Submit Data'}
                                                </Button>
                                            </div>
                                        </form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

Popular_original_episode_edit.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string
        })
    }),
    location: PropTypes.object
}

export default Popular_original_episode_edit
