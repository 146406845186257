import React, {Component} from "react"
import MetaTags from "react-meta-tags"
import {Card, CardBody, Container} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import PropTypes from "prop-types"
import axios from "axios"
import UploadFiles from "../../components/upload-files.component"
import { apiKey } from "../../helpers/url_helper"
import UploadFileVideoDraft from "components/upload-video-draft.component"
let api_url = process.env.REACT_APP_API_URL
let api_key = apiKey()
class UploadVideoDraf extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            id: this.props.match.params.id,
            type: '',
            progress: 0,
            title:''
        }
    }

    componentDidMount() {
        const id = this.props.match.params.id
        this.setState({id: id})
        const params = {
            type: "ctreels", id: id
        }
        axios.get(`${api_url}/get-single-data`,  {
                params, headers: {
                "Authorization": 'Bearer ' + api_key,
                }
            }
          )
            .then(res => {
                if (res.status) {
                    if (res.data) {
                        let edit = res.data.data
                        console.log(edit)
                        this.setState({
                            title: edit['ct_reel_title'],
                        })
                        
                    }
                } else {
                    toastr.danger('Server not response')
                }
            })
    }

    render() {
        return (<React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>
                        Reel Video Upload | {process.env.REACT_APP_PROJECTNAME}
                    </title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title="Manage Reels" path="/reels/pageno/1" breadcrumbItem="Upload Video"/>
                    
                    <Card>
                        <CardBody>
                        <h3 style={{fontSize:'14px'}}>{this.state.title}</h3>
                            <UploadFileVideoDraft id={this.props.match.params.id} allow_file="video/mp4" type="ctreels"/>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>)
    }
}

UploadVideoDraf.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string
        })
    }),
    location: PropTypes.object
}
export default UploadVideoDraf
