import React, {Component} from "react";
import MetaTags from "react-meta-tags";
import {Button, Card, CardBody, CardTitle, Col, Container, FormGroup, Input, Label, Row,} from "reactstrap";
import {Formik, Field, Form, ErrorMessage} from "formik";
import * as Yup from "yup";
import makeAnimated from "react-select/animated"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Interests from "./index";

class InterestsAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            image: "",
            validation: "",
            status: true,
        };
    }

    render() {

        const handleFileUpload = (event) => {
            this.setState({image: event.currentTarget.files[0]})
        };

        const Offsymbol = () => {
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        fontSize: 12,
                        color: "#fff",
                        paddingRight: 2
                    }}
                >
                    {" "}
                    Off
                </div>
            )
        }

        const OnSymbol = props => {
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        fontSize: 12,
                        color: "#fff",
                        paddingRight: 2
                    }}
                >
                    {" "}
                    On
                </div>
            )
        }
        const {validation} = this.state;
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>
                            Manage Interests | {process.env.REACT_APP_PROJECTNAME}
                        </title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Interests" path="/interests/pageno/1" breadcrumbItem="Add Interest"/>
                        <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                        <Formik
                                            enableReinitialize={true}
                                            initialValues={{
                                                InterestName: (this.state && this.state.InterestName) || "",
                                                ordering: (this.state && this.state.ordering) || "",
                                            }}
                                            validationSchema={Yup.object().shape({
                                                InterestName: Yup.string().required(
                                                    "Please Enter Interest Name"
                                                ),
                                                ordering: Yup.string().required(
                                                    "Please Enter Ordering Number"
                                                ),
                                                image: Yup.mixed().required("You need to provide a file")
                                            })}
                                            onSubmit={values => {
                                                console.log(values);
                                            }}
                                        >
                                            {({errors, status, touched}) => (
                                                <Form className="needs-validation">
                                                    <Row>
                                                        <Col md="4">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor="validationCustom01">
                                                                    Interest name
                                                                </Label>
                                                                <Field
                                                                    name="CountryName"
                                                                    placeholder="Country name"
                                                                    type="text"
                                                                    className={
                                                                        "form-control" +
                                                                        (errors.InterestName && touched.InterestName
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="CountryName"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="4">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor="validationCustom02">
                                                                    Interest Image
                                                                </Label>
                                                                <Field
                                                                    name="image"
                                                                    placeholder="City Image"
                                                                    type="file"
                                                                    accept="image/*"
                                                                    onChange={handleFileUpload}
                                                                    className={
                                                                        "form-control" +
                                                                        (errors.image && touched.image
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="image"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="3">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor="validationCustom03">Order No.</Label>
                                                                <Field
                                                                    name="ordering"
                                                                    type="number"
                                                                    placeholder="Order No."
                                                                    className={
                                                                        "form-control" +
                                                                        (errors.ordering && touched.ordering
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="ordering"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="3">
                                                            <Label htmlFor="">Status</Label>
                                                            <div className="square-switch mt-2">
                                                                <input
                                                                    type="checkbox"
                                                                    id="square-switch1"
                                                                    switch="none"
                                                                    name="status"
                                                                    checked={this.state.status}
                                                                    onChange={() =>
                                                                        this.setState({status: !this.state.status})
                                                                    }
                                                                />
                                                                <label
                                                                    htmlFor="square-switch1"
                                                                    data-on-label="On"
                                                                    data-off-label="Off"
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <div className="d-flex flex-wrap gap-2 justify-content-end">
                                                        <Button color="primary" type="submit">
                                                            Submit Data
                                                        </Button>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default InterestsAdd;
