import React, {Component} from "react"
import MetaTags from "react-meta-tags"
import {Card, CardBody, Container} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import PropTypes from "prop-types"
import UploadFiles from "../../components/upload-files.component"
import axios from "axios"
import { apiKey } from "../../helpers/url_helper"
let api_url = process.env.REACT_APP_API_URL
let api_key = apiKey()
class DealMedia extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoaded: false,
            title: "",
        }
    }
    componentDidMount() {
        const id = this.props.match.params.id
        this.setState({ edit_id: id })
        const params = {
            type: "deals",
            id: id
        }
        axios.get(`${api_url}/get-single-data`, {
            params, headers: {
                "Authorization": 'Bearer ' + api_key,
            }
        })
            .then(res => {
                if (res.data.status) {
                    let edit = res.data.data
                    
                    this.setState({
                        title: edit[0]['name'],
                    })
                } else {
                    toastr.danger('Server not response')
                }
            })
    }

    render() {
        return (<React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>
                        Manage Deals Media | {process.env.REACT_APP_PROJECTNAME}
                    </title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title="Manage Deals" path="/deals/pageno/1" breadcrumbItem="Add Media"/>
                    <Card>
                        <CardBody>
                        <h3 style={{fontSize:'14px'}}>{this.state.title}</h3>
                            <UploadFiles id={this.props.match.params.id} allow_file="image/*,video/mp4" type="deals"/>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>)
    }
}

DealMedia.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string
        })
    }),
    location: PropTypes.object
}
export default DealMedia
