import React from "react"
import MetaTags from "react-meta-tags"
import {Button, Card, CardBody, Col, Container, Label, Row,} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {apiKey} from "../../helpers/url_helper"
import SimpleReactValidator from 'simple-react-validator'
import axios from "axios"
import toastr from "toastr"
import PropTypes from "prop-types"
import notification from "../../components/Common/Notification"

let api_url = process.env.REACT_APP_API_URL
let api_key = apiKey()

class FaqsEdit extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            title: "",
            description: "",
            ordering: "",
            status: true,
            handleResponse: null,
            submit: false,
        }
        this.validator = new SimpleReactValidator({autoForceUpdate: this})
    }

    componentDidMount() {
        this.getFaq()
    }

    handleInput = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }
    getFaq = () => {
        const url = `${api_url}/get-single-data?type=faq&id=${this.props.match.params.id}`
        axios.get(url, {
            headers: {
                "Authorization": 'Bearer ' + api_key,
            }
        })
            .then(res => {
                if (res.status) {
                    let data = res.data.data
                    this.setState({
                        title: data.title,
                        description: data.body,
                        ordering: data.ordering,
                        status: data.status
                    })
                }
            }).catch(err => notification(err, 'error', 'toast-bottom-right'))
    }
    handleFormSubmit = (e) => {
        e.preventDefault()
        if (this.validator.allValid()) {
            let formData = {
                "title": this.state.title,
                "body": this.state.description,
                "ordering": this.state.ordering,
                "status": this.state.status,
                "id": this.props.match.params.id
            }
            this.setState({submit: true})
            axios({
                method: "post", url: `${api_url}/update-faq`, data: formData, headers: {
                    'Content-Type': 'application/json',
                    "Authorization": 'Bearer ' + api_key,
                }
            }).then(response => {
                if (response.status) {
                    this.setState({submit: false})
                    toastr.success('Faqs edit successful.')
                    // eslint-disable-next-line react/prop-types
                    const {history} = this.props
                    // eslint-disable-next-line react/prop-types
                    history.goBack();
                }
            }).catch(err => {
                toastr.error(err.message)
                this.setState({submit: false})
                alert(err.message)
            })
        } else {
            this.validator.showMessages()
            this.forceUpdate()
        }
    }

    render() {
        const {submit} = this.state
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>
                            Faqs | {process.env.REACT_APP_PROJECTNAME}
                        </title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Faqs" path="/faqs" breadcrumbItem="Edit Faqs"/>
                        <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                        <form className="repeater" onSubmit={this.handleFormSubmit}>
                                            <div data-repeater-list="group-a">
                                                <Row data-repeater-item>
                                                    <Col md={10}>
                                                        <Label htmlFor="">Title</Label>
                                                        <input className="form-control" name="title" defaultValue={this.state.title} onChange={this.handleInput} required/>
                                                        {this.validator.message('title', this.state.title, 'required')}
                                                    </Col>
                                                    <Col md="2" className="mb-3">
                                                        <Label htmlFor="ordering">Order No.</Label>
                                                        <input name="ordering" type="number" defaultValue={this.state.ordering} onChange={this.handleInput} placeholder="Order No." className={"form-control"}/>
                                                        {this.validator.message('ordering', this.state.ordering, 'required')}
                                                    </Col>
                                                    <Col md="12" className="mb-3">
                                                        <Label htmlFor="">Description</Label>
                                                        <textarea className="form-control" name="description" defaultValue={this.state.description} onChange={this.handleInput} required></textarea>
                                                        {this.validator.message('description', this.state.description, 'required')}
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Row>
                                                <Col md="auto" className="mb-3">
                                                    <Label htmlFor="">Status</Label>
                                                    <div className="square-switch mt-2">
                                                        <input
                                                            type="checkbox"
                                                            id="square-switch-status"
                                                            name="status"
                                                            checked={this.state.status}
                                                            onChange={() =>
                                                                this.setState({status: !this.state.status})
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="square-switch-status"
                                                            data-on-label="On"
                                                            data-off-label="Off"
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <div className="d-flex flex-wrap gap-2 justify-content-end">
                                                <Button color="primary" type="submit" disabled={submit}>
                                                    {submit === true ? 'Please wait...' : 'Submit Data'}
                                                </Button>
                                            </div>
                                        </form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

FaqsEdit.propTypes = {
    location: PropTypes.object,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string
        })
    }),
}

export default FaqsEdit
