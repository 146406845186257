import React from "react"
import MetaTags from "react-meta-tags"
import {Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Row,} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Select from "react-select"
import {apiKey} from "../../helpers/url_helper"
import {get} from "../../helpers/api_helper"
import SimpleReactValidator from 'simple-react-validator'
import axios from "axios"
import toastr from "toastr"
import "style.min.css"
import PropTypes from "prop-types"
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import AsyncSelect from "react-select/async"

let api_url = process.env.REACT_APP_API_URL
let api_key = apiKey()
const headers = {'Content-Type': 'application/json', "Authorization": 'Bearer ' + api_key}
function getDataName(id, data) {
    let selected = []
    if (data.length){
        for (var x = 0; x < id.length; x++) {
            const result = data.find((name) => name['_id'] === id[x])
            if (result !== undefined) {
                selected.push({label: result['name'], value: result['_id']})
            }
        }
        // console.log(id,data)
    }
    return selected
}

class ReelEdit extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            users: [],
            tagslist: [],
            associateMaster: [],
            Tags: "",
            defaultTags: [],
            interestslist: [],
            Interest: "",
            defaultInterest: [],
            dealslist: [],
            deal_id: "",
            user_id:"",
            is_trending: false,
            status: true,
            ct_reel_title: "",
            associate_deals_url: "",
            ct_reel_thumbnail: [],
            reel_thumbnail: "",
            ordering: "",
            location: "",
            locations: [],
            selectedlocation: [],
            values: "",
            associate_type: "",
            handleResponse: null,
            is_associated: false,
            is_approved: false,
            submit: false,
            is_load_form: false,
            _like_count:0
        }
        this.validator = new SimpleReactValidator({autoForceUpdate: this})
    }

    componentDidMount() {
        get(`${api_url}/get-interests?&status=1`, {headers})
            .then(data => this.setState({interestslist: data.data}))
        get(`${api_url}/user-list?pageNo=1&limit=90000&role_ids=3,4`, {headers})
            .then(data => this.setState({users: data.data}))
        get(`${api_url}/get-active-deals?&status=1`, {headers})
            .then(res => this.setState({dealslist: res.data}))

        const id = this.props.match.params.id
        this.setState({edit_id: id})
        const params = {
            type: "ctreels", id: id
        }
        get(`${api_url}/get-single-data`, {params, headers})
            .then(res => {
                if (res.status) {
                    if (res.data) {
                        let edit = res.data
                        console.log(res.data)
                        this.setState({
                            defaultTags: edit['tags'],
                            defaultInterest: edit['interest'],
                            Tags:edit['tags'],
                            deal_id:(!edit['deal_id']?"":edit['deal_id']),
                            ct_reel_title: edit['ct_reel_title'],
                            associate_deals_url: edit['associate_deals_url'],
                            reel_thumbnail: edit['ct_reel_thumbnail'],
                            ordering: edit['ordering'],
                            is_associated: edit['is_deal_associated'],
                            associate_type: edit['associate_type'],
                            is_approved: (!edit['is_approved']?false:edit['is_approved']),
                            location: edit['location'] !== undefined ? edit['location'] : "",
                            is_trending: edit['is_trending'] !== undefined ? edit['is_trending']: false,
                            status: edit['status'],
                            _like_count:edit['_like_count'],
                            user_id: (!edit['user_id']?"":edit['user_id']),
                        })
                        edit['associate_type'] !== undefined && this.handleAssociateType(edit['associate_type'])
                        if (typeof edit['interest'] === 'object' && edit['interest'].length > 0){
                            this.setState({Interest:edit['interest'][0]})
                        }else {
                            this.setState({Interest:edit['interest']})
                        }
                        this.setState({is_load_form:true})
                        this.handleInterestTags(this.state.Interest)
                        console.log('user_id from db',this.state.user_id)
                    }
                } else {
                    toastr.danger('Server not response')
                }
            })

        fetch(`${api_url}/get-location?status=1&keyword=${this.state.values}`, {headers})
            .then(response => response.json())
            .then(data => {
                this.setState({active_deals: data.data})
                let locations = data.data
                for(let i = 0;i < locations.length;i++){
                    if (locations[i]._id == this.state.location){
                        this.setState({selectedlocation: {_id:locations[i]._id,name:locations[i].name}})
                    }
                }
            })
            .catch(err => {
                console.log("error:", err);
            });
    }

    getOptions = () => {
        return axios.get(`${api_url}/get-location?status=1&keyword=${this.state.values}`,{ headers })
            .then((res) => {
                let data = res.data.data;
                this.setState({locations:data})
                return res.data.data;
            })
            .catch(
                err => console.log(err)
            );
    }

    handleInterestTags = e => {
        let interest = document.getElementById('Interest').value
        if (!interest){
            interest = this.state.Interest
        }
        axios({
            method: "post", url: `${api_url}/get-interest-wise-tags`, data: {"interest": [interest]}, headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + api_key
            }
        }).then(response => {
            if (response.data){
                let b = [],a = response.data.data;
                for (var c = 0; c < a.length; c++) b.push({
                    label: a[c].name,
                    value: a[c]._id
                });
                this.setState({tagslist: b})
            }
        }).catch(err => {
            // toastr.error(err.message);
        });
    }

    handleTags = defaultTags => {
        this.setState({Tags: defaultTags})
        this.setState({defaultTags})
    }

    handleInterests = defaultInterest => {
        this.setState({Interest: defaultInterest})
        this.setState({defaultInterest})
    }

    handleDeals = e => {
        const [option] = e.target.selectedOptions
        this.setState({deal_id: e.target.value,associate_deals_url:option.dataset.link})
    }

    handleFileChange = (event) => {
        this.setState({
            ct_reel_thumbnail: event.target.files[0]
        })
    }

    selectData = a => {
        let b = []
        for (var c = 0; c < a.length; c++) b.push({
            label: a[c].name, value: a[c]._id
        })
        return b
    }
    handleInput = (e) => {
        this.setState({[e.target.name]: e.target.value})
        console.log('user changes to',e.target.value)

    }

    handleInputChange = value => {
        this.setState({values:value})
    };
    selectLocation = value => {
        this.setState({location:value._id})
        this.setState({selectedlocation:value})
    }

    handleAssociateType = (value) => {
        this.setState({associate_type:value})
    //    guide,blog,original, deal
        let type = value
        let url = "";
        if (type === "guide"){
            url = `${api_url}/get-guides?status=1`
        }else if (type === "blog"){
            url = `${api_url}/get-blog?status=1`
        }else if (type === "deal"){
            url = `${api_url}/get-deals?status=1`
        }else if (type === "original"){
            url = `${api_url}/get-shows?status=1`
        }
        if (url){
            axios({
                method: "get", url: `${url}`, headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + api_key
                }
            }).then(response => {
                if (response.data){
                    let b = [],a = response.data.data;
                    for (var c = 0; c < a.length; c++) {
                        let deep_link;
                        if (type === "guide" || type === "deal"){
                            deep_link = a[c].deep_link
                        }
                        let title;
                        if (type === "deal" || type === "original"){
                            title = a[c].name
                        }else {
                            title = a[c].title
                        }
                        b.push({
                            label: title,
                            deep_link: deep_link,
                            value: a[c]._id
                        });
                    }
                    this.setState({associateMaster: b})
                }
            }).catch(err => {
                toastr.error(err.message);
            });
        }
    }

    handleFormSubmit = (e) => {
        e.preventDefault()
        if (this.validator.allValid()) {
            const formData = new FormData()
            formData.append('ct_reel_title', this.state.ct_reel_title)
            var ct_reel_thumbnail = document.getElementById("ct_reel_thumbnail").files[0]
            if (ct_reel_thumbnail) {
                formData.append('ct_reel_thumbnail', ct_reel_thumbnail)
            }
            // formData.append('is_deal_associated', this.state.is_deal_associated)
            let deep_link = ""
            if (this.state.associate_deals_url !== undefined){
                deep_link = this.state.associate_deals_url
            }
            this.state.is_associated  === true && formData.append('associate_deals_url', deep_link)
            this.state.is_associated  === true && formData.append('associate_type', this.state.associate_type)
            formData.append('is_trending', this.state.is_trending)
            formData.append('status', this.state.status)
            formData.append('ordering', 0)
            formData.append('_like_count', this.state._like_count)
            formData.append('tags', this.state.Tags)
            formData.append('interest', this.state.Interest)
            formData.append('is_deal_associated', this.state.is_associated)
            this.state.location  !== '' && formData.append('location', this.state.location)
            this.state.is_associated  === true && formData.append('deal_id', this.state.deal_id)
            formData.append('ctreelsId', this.state.edit_id)
            // formData.append('is_approved', this.state.is_approved)
            formData.append('user_id', this.state.user_id)
            this.setState({submit: true})
            axios({
                method: "post", url: `${api_url}/update-ctreels`, data: formData, headers: {
                    'Content-Type': 'application/json', "Authorization": 'Bearer ' + api_key,
                }
            }).then(response => {
                if (response.status) {
                    this.setState({submit: false})
                    toastr.success('Reel update successful.')
                    // eslint-disable-next-line react/prop-types
                    const {history} = this.props
                    // eslint-disable-next-line react/prop-types
                    const pageno = this.props.match.params.pId; // Get the pageno param from the URL
                    history.goBack();
                }
            }).catch(err => {
                toastr.error(err.message)
                this.setState({submit: false})
                alert(err.message)
            })
            console.log('uploaded', this.state.user_id)
        } else {
            this.validator.showMessages()
            this.forceUpdate()
        }
    }

    render() {
        const {
            ct_reel_title,
            interestslist,
            ordering,
            defaultTags,
            defaultInterest,
            submit
        } = this.state
        return (<React.Fragment>
            {this.state.is_load_form === true &&
                <div className="page-content">
                    <MetaTags>
                        <title>
                            Manage Reels | {process.env.REACT_APP_PROJECTNAME}
                        </title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Reels" path="/reels/pageno/1" breadcrumbItem="Edit Reel"/>
                        <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                        <form onSubmit={this.handleFormSubmit}>
                                            <Row>
                                                <Col md="9" className="mb-3">
                                                    <Label htmlFor="originals_name">Reel Title</Label>
                                                    <input name="ct_reel_title" placeholder="Type Reel Title"
                                                           defaultValue={ct_reel_title} type="text"
                                                           className={"form-control"}
                                                           onChange={this.handleInput}/>
                                                    {this.validator.message('ct_reel_title', this.state.ct_reel_title, 'required|string')}
                                                </Col>
                                                <Col md={3} className="mb-3">
                                                    <label className="control-label">Location</label>
                                                    <AsyncSelect defaultOptions name="form-field-name" value={this.state.selectedlocation}
                                                                 cacheOptions placeholder="Search location" loadOptions={this.getOptions} getOptionLabel={e => e.name} getOptionValue={e => e._id} onInputChange={this.handleInputChange} onChange={this.selectLocation}/>
                                                </Col>
                                                <Col md="3" className="mb-3">
                                                    <Label htmlFor="ct_reel_thumbnail">
                                                        Reel thumbnail
                                                    </Label>
                                                    <input
                                                        id="ct_reel_thumbnail"
                                                        name="ct_reel_thumbnail"
                                                        placeholder="Reel Thumbnail"
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={this.handleFileChange}
                                                        className={"form-control"}
                                                    />
                                                    {/* {this.validator.message('cover_image', this.state.cover_image, 'required')} */}
                                                </Col>
                                                {/*<Col md="2" className="mb-3">*/}
                                                {/*    <Label htmlFor="validationCustom03">Order No.</Label>*/}
                                                {/*    <input*/}
                                                {/*        name="ordering"*/}
                                                {/*        type="number"*/}
                                                {/*        placeholder="Order No."*/}
                                                {/*        className={"form-control"}*/}
                                                {/*        onChange={this.handleInput}*/}
                                                {/*        defaultValue={ordering}*/}
                                                {/*    />*/}
                                                {/*    {this.validator.message('ordering', this.state.ordering, 'required|numeric')}*/}
                                                {/*</Col>*/}
                                                <Col md="1" className="mb-3">
                                                    <Label htmlFor="">Status</Label>
                                                    <div className="square-switch mt-2">
                                                        <input
                                                            type="checkbox"
                                                            id="square-switch-status"
                                                            switch="none"
                                                            name="status"
                                                            checked={this.state.status}
                                                            onChange={() => this.setState({status: !this.state.status})}
                                                        />
                                                        <label
                                                            htmlFor="square-switch-status"
                                                            data-on-label="On"
                                                            data-off-label="Off"
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md="2" className="mb-3">
                                                    <Label htmlFor="">Is Associated</Label>
                                                    <div className="square-switch mt-2">
                                                        <input
                                                            type="checkbox"
                                                            id="square-switch-associated"
                                                            switch="none"
                                                            name="is_associated"
                                                            checked={this.state.is_associated}
                                                            onChange={() => this.setState({is_associated: !this.state.is_associated})}
                                                        />
                                                        <label
                                                            htmlFor="square-switch-associated"
                                                            data-on-label="On"
                                                            data-off-label="Off"
                                                        />
                                                    </div>
                                                </Col>
                                                {this.state.is_associated === true &&
                                                    <Col md={3}>
                                                        <label className="control-label">Associate Type</label>
                                                        <select name="associate_type" defaultValue={this.state.associate_type} className={"form-control"}
                                                                onChange={event => {
                                                                    this.handleAssociateType(event.target.value)
                                                                }}>
                                                            <option value="" selected={true} disabled={true}>Select a
                                                                option
                                                            </option>
                                                            <option value="guide">Guide</option>
                                                            <option value="blog">Blogs</option>
                                                            <option value="original">Original</option>
                                                            <option value="deal">Deal</option>
                                                        </select>
                                                    </Col>
                                                }
                                                {this.state.is_associated === true && this.state.associate_type !== '' &&
                                                    <Col md={5}>
                                                        <label className="control-label">Associate {this.state.associate_type}</label>
                                                        <select name="deal_id" value={this.state.deal_id} className={"form-control deeplink"} onChange={e => {
                                                            this.handleInput(e);
                                                            this.handleDeals(e)
                                                        }}>
                                                            <option value="" selected disabled>Select a option</option>
                                                            {this.state.associateMaster.map((val, key) => {
                                                                return <option key={key} data-link={val.deep_link}
                                                                               value={val.value}>{val.label}</option>
                                                            })}
                                                        </select>
                                                        {/*{this.validator.message('Deals', this.state.deal_id, 'required')}*/}
                                                    </Col>
                                                }
                                                <Col md="3" className="mb-4">
                                                    <label className="control-label">Creator/Contributor</label>
                                                    <select name="user_id" value={this.state.user_id} className={"form-control deeplink"} onChange={ e => {this.handleInput(e)}}>
                                                        <option value="" selected disabled>Select a user</option>
                                                        {this.state.users.map((val,key) =>{
                                                            return <option key={key} value={val.id}>{val.name}</option>
                                                        })}
                                                    </select>
                                                    {this.validator.message('user_id', this.state.user_id, 'required')}
                                                </Col>
                                                <Col md="auto" className="mb-4">
                                                    <label className="control-label">Interest</label>
                                                    <select id={"Interest"} name="Interest" value={this.state.Interest} className={"form-control deeplink"} onChange={ e => {this.handleInput(e);this.handleInterestTags(e)}}>
                                                        <option value="" selected disabled>Select a option</option>
                                                        {interestslist.map((val,key) =>{
                                                            return <option key={key} data-link={val.deep_link} value={val._id}>{val.name}</option>
                                                        })}
                                                    </select>
                                                    {this.validator.message('Interest', this.state.Interest, 'required')}
                                                </Col>
                                                {this.state.tagslist.length &&
                                                    <Col md="6" className="mb-3">
                                                        <label className="control-label">Tags</label>
                                                        <DualListBox
                                                            options={this.state.tagslist}
                                                            selected={defaultTags}
                                                            onChange={this.handleTags}
                                                        />
                                                        {/* {this.validator.message('Tags', this.state.Tags, 'required')} */}
                                                    </Col>
                                                }
                                                <Col md="3" className="mb-3">
                                                <Label htmlFor="title">Static likes</Label>
                                                <input name="_like_count" placeholder="Type Static Likes" type="number" className={"form-control"}
                                                    value={this.state._like_count}
                                                    onChange={this.handleInput} />
                                                {this.validator.message("title", this.state._like_count, "required|number")}
                                                </Col>
                                                {/*<Col md="auto" className="mb-3">*/}
                                                {/*    <Label htmlFor="">Approved</Label>*/}
                                                {/*    <div className="square-switch mt-2">*/}
                                                {/*        <input type="checkbox" id="square-switch-is_approved" switch="none" name="status" checked={this.state.is_approved} onChange={() => this.setState({is_approved: !this.state.is_approved})}/>*/}
                                                {/*        <label htmlFor="square-switch-is_approved" data-on-label="On" data-off-label="Off"/>*/}
                                                {/*    </div>*/}
                                                {/*</Col>*/}
                                                {/* <Col md="auto" className="mb-3">
                                                    <Label htmlFor="">Is Trending</Label>
                                                    <div className="square-switch mt-2">
                                                        <input type="checkbox" id="square-switch-is_trending" switch="none" name="status" checked={this.state.is_trending} onChange={() => this.setState({is_trending: !this.state.is_trending})}/>
                                                        <label htmlFor="square-switch-is_trending" data-on-label="Yes" data-off-label="No"/>
                                                    </div>
                                                </Col> */}
                                            </Row>

                                            <div className="d-flex flex-wrap gap-2 justify-content-end">
                                                <Button color="primary" type="submit" disabled={submit}>
                                                    {submit === true ? 'Please wait...' : 'Submit Data'}
                                                </Button>
                                            </div>
                                        </form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            }
        </React.Fragment>)
    }
}

ReelEdit.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string
        })
    }), location: PropTypes.object
}

export default ReelEdit
