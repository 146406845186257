import React, {useEffect, useState} from "react"
import { useHistory } from 'react-router-dom';
import {Card, CardBody, Modal, Col, Row, CardTitle} from "reactstrap"
import MetaTags from 'react-meta-tags'
import Pagination from 'components/Common/Pagination'
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {TableHeader, Search} from "../../Datatable/index"
import PropTypes from "prop-types"
import axios from "axios"
import notification from "../../../components/Common/Notification"
import {apiKey} from "../../../helpers/url_helper"
import Moment from 'moment'
import {size, map} from "lodash"
import StayTable from "./table/stay"
import NonStayTable from "./table/non_stay"
import ShowMedia from "./table/media"
import useMedia from "./table/useMedia"
import {capitalFirst} from "echarts/lib/util/format"
import toastr from "toastr"

let api_url = process.env.REACT_APP_API_URL
let api_key = apiKey()
let PageSize = 15
const useFullPageLoader = () => {
    const [loading, setLoading] = useState(false)
    return [
        loading ? 'Loading' : null,
        () => setLoading(true), //Show loader
        () => setLoading(false) //Hide Loader
    ]
}

function formatDate(date) {
    return Moment(date).format('DD-MM-YYYY')
}

export const specialPrice = (data) => {
    let prices = []
    if (data !== undefined && data !== null) {
        for (let a = 0; a < data.length; a++) {
            if (data[a].price !== undefined && typeof (data[a].price) === 'string' || typeof (data[a].price) === 'number') {
                prices.push(data[a].price)
            } else if (data[a].price !== undefined && typeof (data[a].price) === 'object') {
                let price_Array = data[a].price ? data[a].price:[]
                for (let i = 0; i < price_Array.length; i++) {
                    prices.push(price_Array[i])
                }
            }
        }
    }
    return (
        <div>
            {map(prices, (tag, index) => index < 2 && (
                <span className="badge bg-primary font-size-11 m-1" key={"prices" + index}>{tag}</span>))}
            {size(prices) > 2 && (
                <span className="badge bg-primary font-size-11 m-1" key={"prices"}>{size(prices) - 2} + more</span>)}
        </div>
    )
}
//deals_variants
export const specialDate = (data) => {
    let dates = []
    if (data !== undefined && data !== null) {
        for (let a = 0; a < data.length; a++) {
            if (data[a].date !== undefined && typeof (data[a].date) === 'string') {
                dates.push(data[a].date)
            } else if (data[a].dates !== undefined && typeof (data[a].dates) === 'object') {
                let date_Array = data[a].dates
                for (let i = 0; i < date_Array.length; i++) {
                    dates.push(date_Array[i])
                }
            }
        }
    }
    return (
        <div>
            {map(dates, (tag, index) => index < 2 && (
                <span className="badge bg-primary font-size-11 m-1" key={"dates" + index}>{formatDate(tag)}</span>))}
            {size(dates) > 2 && (
                <span className="badge bg-primary font-size-11 m-1" key={"dates"}>{size(dates) - 2} + more</span>)}
        </div>
    )
}

export const Variants = (props) => {
    const history = useHistory();
    const [Header, setHeader] = useState([])
    const [tableData, settableData] = useState([])
    const [MediaData, setMediaData] = useState([])
    const [loader, showLoader, hideLoader] = useFullPageLoader()
    const [totalItems, setTotalItems] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [search, setSearch] = useState("")
    const [variant, setVariant] = useState("")
    const [deal, setDeal] = useState([])
    const [id, setId] = useState(props.match.params.id)
    const {isShowing, toggle} = useMedia()
    const [is_delete, setDelete] = useState(false)
    const [mainId, setMainId] = useState("")
    useEffect(() => {
        const params = {
            type: "deals",
            id: id
        }
        axios.get(`${api_url}/get-single-data`, {params, headers: {"Authorization": 'Bearer ' + api_key}})
            .then(res => {
                let data = res.data.data
                if (data !== null && Object.keys(data).length) {
                    setVariant(data[0].type)
                    setId(data[0]._id)
                    setDeal(data[0])
                }
                "stay" === variant ? setHeader([{name: "#", field: "_id", sortable: !1}, {
                    name: "Variant Name",
                    field: "name",
                    sortable: !1
                }, {name: "Media", field: "media", sortable: !1}, {
                    name: "Weekday Price",
                    field: "price_meta",
                    sortable: !1
                }, {name: "Weekend Price", field: "price_meta", sortable: !1}, {
                    name: "Special Price",
                    field: "price_meta",
                    sortable: !1
                }, {name: "Special Dates", field: "specific", sortable: !1}, {
                    name: "Black Out Dates",
                    field: "_id",
                    sortable: !1
                }, {name: "No. of Rooms available (per day)", field: "location", sortable: !1}, {
                    name: "Status",
                    field: "status",
                    sortable: !1
                }, {name: "Action", field: "", sortable: !1}]) : "non-stay" === variant && setHeader([{
                    name: "#",
                    field: "id",
                    sortable: !1
                }, {name: "Variant Name", field: "", sortable: !1}, {
                    name: "Media",
                    field: "name",
                    sortable: !1
                }, {name: "Price", field: "cover_image", sortable: !1}, {
                    name: "No. of tickets available",
                    field: "media",
                    sortable: !1
                }, {name: "Status", field: "status", sortable: !1}, {name: "Action", field: "", sortable: !1}])
            }).catch((error) => {
            console.log(error)
        })
    }, [variant])

    useEffect(() => {
        showLoader()
        let query = ''
        search && (query = `keyword=${search}&`)
        const url = `${api_url}/get-deals-variantList?${query}limit=${PageSize}&pageNo=${currentPage}&deal=${props.match.params.id}`
        axios.get(url, {
            headers: {
                "Authorization": 'Bearer ' + api_key,
            }
        })
            .then(res => {
                hideLoader()
                if (res.status) {
                    settableData(res.data.data)
                    setTotalItems(res.data.totalRecords)
                }
            }).catch(
                err => {
                    notification(err, 'error', 'toast-bottom-right')
                    if(err.response.status===401 || err.response.status===400 ){
                        history.push('/logout')
                    }
                }
            );
    }, [currentPage, search, is_delete])  // pass `value` as a dependency

    function tog_center() {
        document.body.classList.add("no_padding")
    }

    function mediadata(data, id) {
        toggle()
        setMediaData(data)
        setMainId(id)
    }

    const deleteMedia = (data, index) => {
        if (data._id === undefined){
            notification('Media id missing! Please delete manual', 'error', 'toast-bottom-right')
            return false;
        }
        let formData = {
            "id": mainId,
            "media_id": data._id,
            "type": "deals_variants"
        }
        axios({
            method: "post", url: `${api_url}/delete-media-image`, data: formData, headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + api_key
            }
        }).then(response => {
            if (response.status) {
                removeMedia(index)
                // setCurrentPage(1)
                setDelete(!is_delete)
                notification('Delete success', 'success', 'toast-bottom-right')
            }else {
                notification(response.message, 'error', 'toast-bottom-right')
            }
        }).catch(err => {
            toastr.error(err.response.data.message)
        })
    }
    const removeMedia = (index) => {
        setMediaData([
            ...MediaData.slice(0, index),
        ])
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{deal.name !== undefined && capitalFirst(deal.name)} - Manage Variants</title>
                </MetaTags>
                <div className="container-fluid">
                    <Breadcrumbs title="Deals" path="/deals" breadcrumbItem="Variants"/>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    {Header.length > 0 &&
                                        <div>
                                            <div className="row mb-2">
                                                <div className="col-md-6">
                                                    <CardTitle>{deal.name !== undefined && capitalFirst(deal.name)}</CardTitle>
                                                </div>
                                                <div className="col-md-6 d-flex flex-row-reverse">
                                                    <div className="ms-2">
                                                        {variant === 'stay' &&
                                                            <a href={`/deals/variants/add/stay/${props.match.params.id}`}
                                                               className="btn btn-primary waves-effect waves-light"><i
                                                                className="bx bx-plus font-size-16 align-middle me-2"></i> Add</a>
                                                        }
                                                        {variant === 'non-stay' &&
                                                            <a href={`/deals/variants/add/non-stay/${props.match.params.id}`}
                                                               className="btn btn-primary waves-effect waves-light"><i
                                                                className="bx bx-plus font-size-16 align-middle me-2"></i> Add</a>
                                                        }
                                                    </div>
                                                    <Search
                                                        onSearch={value => {
                                                            setSearch(value)
                                                            setCurrentPage(1)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="table-responsive">
                                                <table className="table table-striped">
                                                    <TableHeader
                                                        headers={Header}
                                                    />
                                                    <tbody className="">{loader && <tr>
                                                        <th colSpan={Header.length}><h4
                                                            className="text-center">Loading...</h4></th>
                                                    </tr>}
                                                    {(!loader && tableData.length === 0) && <tr>
                                                        <th colSpan={Header.length}><h4 className="text-center">No data
                                                            found</h4></th>
                                                    </tr>}
                                                    {!loader && variant === 'stay' &&
                                                        <StayTable media={mediadata} tableData={tableData}
                                                                   PageSize={PageSize} currentPage={currentPage}/>}
                                                    {!loader && variant === 'non-stay' &&
                                                        <NonStayTable media={mediadata} tableData={tableData}
                                                                      PageSize={PageSize} currentPage={currentPage}/>}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <Row className="justify-content-center">
                                                <Col className="col-auto">
                                                    <Pagination
                                                        className="pagination-bar"
                                                        currentPage={currentPage}
                                                        totalCount={totalItems}
                                                        pageSize={PageSize}
                                                        onPageChange={page => setCurrentPage(page)}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
            <ShowMedia
                isShowing={isShowing}
                hide={toggle}
                MediaData={MediaData}
                deleteMedia={deleteMedia}
            />
        </React.Fragment>
    )
}
Variants.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string
        })
    }),
    location: PropTypes.object
}
export default {Variants, specialDate, specialPrice}