import React, {useEffect, useMemo, useState} from "react";
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Card, CardBody, CardTitle, Col, Modal, Row } from "reactstrap";
import MetaTags from 'react-meta-tags'
import Pagination from 'components/Common/Pagination';
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {TableHeader, Search} from "../Datatable/index";
import axios from 'axios';
import {Link} from "react-router-dom";
import {apiKey} from "../../helpers/url_helper";
import notification from "../../components/Common/Notification";
import Moment from "moment";
import ReactPlayer from "react-player";
import toastr from "toastr";
import {
    GridContextProvider,
    GridDropZone,
    GridItem,
    swap,
  } from "react-grid-dnd";

let api_url = process.env.REACT_APP_API_URL;
let exportURL = api_url + "/get-blog-csv"
let api_key = apiKey()
let PageSize = 15;

const useFullPageLoader = () => {
    const [loading, setLoading] = useState(false);

    return [
        loading ? 'Loading' : null,
        () => setLoading(true), //Show loader
        () => setLoading(false) //Hide Loader
    ];
};
function formatDate(date){
    return Moment(date).format('DD-MM-YYYY')
}
function setData(data) {
    let arr = [];
    if (data.length){
        for (var i = 0; i < data.length;i++){
            let blog = data[i]
            arr.push({
                _id:blog['_id'],
                title:blog['title'],
                cover_image:blog['cover_image'],
                blog_creator_image:blog['blog_creator_image'],
                media:blog['media'],
                blog_creator:blog['blog_creator'],
                created_by:blog['created_by'],
                seo_details: {meta_keywords:blog['meta_keywords'],
                seo_title:blog['seo_title'],
                seo_description:blog['seo_description']},
                blog_content:blog['blog_content'],
                show_on_main_page:blog['show_on_main_page'],
                recommended:blog['recommended'],
                is_deal_associated:blog['is_deal_associated'],
                deal_details:blog?.deal_details,
                blog_categories:blog?.blog_categories,
                hex_color:blog['hex_color'],
                interestsList:blog['interestsList'],
                associate_deals_url:blog['associate_deals_url'],
                tagsList:blog['tagsList'],
                blog_date:blog['blog_date'],
                location_details:blog['location_details'],
                status:blog['status'],
                deep_link:blog['deep_link']
            })
        }
    }
    return arr;
}
// function seoDetails(seo_details) {
//     if (seo_details){
//         return (
//           <ul className="social list-unstyled" style={{minWidth:'120px',marginBottom:0}}>
//               {seo_details.seo_title && <li> SEO Title : {seo_details.seo_title}</li>}
//               {seo_details.seo_description && <li>SEO Description : {seo_details.seo_description}</li>}
//               {seo_details.meta_keywords && <li>Meta Keywords : {seo_details.meta_keywords}</li>}
//
//           </ul>
//         )
//     }
// }
const Blogs = (props) => {
    const history = useHistory();
    const location = useLocation();
    const { pId:pageno } = useParams();
    const { searchQuery } = useParams();
    const [tableData, settableData] = useState([]);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [mediaModal, setMediaModal] = useState(false)
    const [mediaData, setMediaData] = useState([])
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(() => {
        const paramPage = parseInt(pageno);
        if (!isNaN(paramPage)) {
            return paramPage;
          } else {
            return 1;
          }
      });
      const [search, setSearch] = useState(() => {
        if (searchQuery) {
            return searchQuery;
          } else {
            return '';
          }
      });
    const [getSearch, setGetSearch] = useState(search);
    const [getSearchPage, setGetSearchPage] = useState(currentPage)
    const [mainId, setMainId] = useState("")
    const [is_delete, setDelete] = useState(false);
    // const [sorting, setSorting] = useState({field: "", order: ""});

    const BlogsHeader = [
        {name: "#", field: "id", sortable: false},
        {name: "Blog Title", field: "title",  sortable: false},
        {name: "Blog Cover Image", field: "cover_image",  sortable: false},
        // {name: "Blog Creator Image", field: "blog_creator_image",  sortable: false},
        {name: "Media", field: "media",  sortable: false},
        {name: "Blog Creator", field: "blog_creator",  sortable: false},
        // {name: "Blog Content", field: "blog_content",  sortable: false},
        {name: "Show on Main Page", field: "show_on_main_page",  sortable: false},
        // {name: "Recommended", field: "recommended",  sortable: false},
        // {name: "Deal Associated", field: "is_deal_associated",  sortable: false},
        {name: "Deal", field: "associate_deals_url",  sortable: false},
        // {name: "Hex Color", field: "hex_color",  sortable: false},
        {name: "Blog categories", field: "blog_categories",  sortable: false},
        {name: "Location", field: "location_details",  sortable: false},
        {name: "Blog Date", field: "blog_date",  sortable: false},

        // {name: "Meta Keywords", field: "",  sortable: false},
        // {name: "SEO Title", field: "",  sortable: false},
        // {name: "SEO Description", field: "",  sortable: false},
        {name: "Total Spam Count", field: "spams_comment_count", sortable: false},

        {name: "Status", field: "status",  sortable: false},
        {name: "Deep Link", field: "deep_link", sortable: false},

        // {name: "Created By", field: "created_by",  sortable: false},
        {name: "Action", field: "", sortable: false},

    ]
    function media(prop,id) {
        setMediaModal(true)
        setMediaData(prop)
        setMainId(id)
    }
    // Handle any changes to the currentPage state
    const handlePageChange = () => {
        setCurrentPage(() => {
            const paramPage = parseInt(pageno);
            if (!isNaN(paramPage)) {
                return paramPage;
              } else {
                return 1;
              }
          })
    };
    function handleSearch(){
        setSearch(getSearch);
        setCurrentPage(getSearchPage);
        if(getSearch){
            history.push(`/blogs/pageno/${getSearchPage}/${getSearch}`)
        }
    }
    useEffect(() => {
        handlePageChange();
    }, [pageno, location]);

    useEffect(() => {
        showLoader()
        let query = ''
        search && ( query = `keyword=${search}`)
        const url = `${api_url}/get-blog?${query}&limit=${PageSize}&pageNo=${currentPage}`
        axios.get(url,{
            headers: {
                "Authorization": 'Bearer '+api_key,
            }
        })
            .then(res => {
                if(res.status) {
                    let tableData = setData(res.data.data);
                    settableData(tableData);
                    setTotalItems(res.data.totalRecords);
                }
                hideLoader();
            }).catch(
                err => {
                    notification(err, 'error', 'toast-bottom-right')
                    if(err.response.status===401 || err.response.status===400 ){
                        history.push('/logout')
                    }
                }
            );

    }, [currentPage,search,is_delete])  // pass `value` as a dependency
    
    const deleteMedia = (data,index) => {
        let formData = {
            "id": mainId,
            "media_id": data._id,
            "type": "blogs"
        }
        axios({
            method: "post", url: `${api_url}/delete-media-image`, data: formData, headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + api_key
            }
        }).then(response => {
            if (response.status){
                removeMedia(index)
                setCurrentPage(1)
                setDelete(!is_delete)
                notification('Delete success','success','toast-bottom-right')
            }
        }).catch(err => {
            toastr.error(err.message);
        });
    }
    const removeMedia = (index) => {
        let newMedia = []
        mediaData.map((val,key) => {
            if (key !== index){
                newMedia.push(val)
            }
        })
        setMediaData(newMedia)
    }
    const handleCopy = (value) => {
        let a = navigator.clipboard.writeText(value)
        navigator.clipboard.readText().then(function(data) {
            toastr.success(`Successfully Copied - ${data}`);
        });
    }
     // save Rearranged List to DB
     const saveReorderedList=(reorderedList)=>{
    // Api call to save reordersList to the data base
    let payload = {
        id: mainId,
        media : reorderedList
    }
    const url = `${api_url}/update-blog-media-image`;
    axios({
        method: "post", url, data: payload, headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + api_key
        }
    })
    .then(res => {
        if(res.status) {
            notification('Saved','success','toast-bottom-right')
        }
    }).catch(err => notification(err,'error','toast-bottom-right'));
  }

  
// Function to handle drag and drop events    
function onChange(sourceId,sourceIndex,targetIndex) {
    const nextState = swap(mediaData, sourceIndex, targetIndex);
    setMediaData(nextState);
    saveReorderedList(nextState);
  }
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Manage Scoops</title>
                </MetaTags>
                <div className="container-fluid">
                    <Breadcrumbs title="Dashboard" path="/" breadcrumbItem="Scoops"/>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <div className="d-flex justify-content-end mb-3">
                                        <div>
                                            <a href="/blogs/add"
                                               className="btn btn-primary waves-effect waves-light"><i
                                                className="bx bx-plus font-size-16 align-middle me-2"></i> Add</a>
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-md-6">
                                        <div>
                                            <a href={exportURL}
                                                target="_blank" rel="noreferrer"
                                               className="btn btn-primary waves-effect waves-light"><i
                                                className="bx bx-export font-size-16 align-middle me-2"></i> Export</a>
                                        </div>
                                        </div>
                                        <div className="col-md-6 d-flex flex-row-reverse">
                                        <div onClick={handleSearch}
                                               className="btn btn-primary waves-effect waves-light ms-2">Search</div>
                                            <Search
                                                onSearch={value => {
                                                    setGetSearch(value)
                                                    setGetSearchPage(1)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                    <table className="table table-striped">
                                        <TableHeader
                                            headers={BlogsHeader}
                                            // onSorting={(field, order) =>
                                            //     setSorting({field, order})
                                            // }
                                        />
                                        <tbody className="">{loader && <tr><th colSpan={BlogsHeader.length}><h4 className="text-center">Loading...</h4></th></tr>}
                                        {(!loader && tableData.length === 0) && <tr><th colSpan={BlogsHeader.length}><h4 className="text-center">No data found</h4></th></tr>}
                                        {!loader && tableData.map((value,index) => (
                                            <tr key={++index}>
                                                <th scope="row">
                                                    {PageSize * (currentPage - 1) + (index + 1)}
                                                </th>
                                                <td><div style={{display:"-webkit-box",maxWidth:"100px","-webkit-line-clamp":"3","-webkit-box-orient":"vertical",overflow:"hidden"}}>{value.title.substring(0, 30)}</div></td>
                                                <td><img src={value.cover_image} className="img-thumbnail avatar-sm" width="200" alt={value.cover_image}/></td>
                                                {/*<td><img src={value.blog_creator_image} className="img-thumbnail avatar-sm" width="200" alt={value.blog_creator_image}/></td>*/}
                                                <td> <div
                                                  className="btn-group btn-group-sm"
                                                  role="group"
                                                  aria-label="Basic example"
                                                >
                                                    {value.media !==undefined && value.media.length > 0 &&
                                                      <button className="btn-sm btn-rounded btn btn-primary"
                                                              onClick={() => media(value.media,value._id)}>Media</button>}
                                                    <Link className="btn-sm btn-rounded btn btn-secondary"
                                                          to={`/blogs/media/${value._id}`}>Add Media</Link>
                                                </div></td>

                                                <td>{value.blog_creator}</td>
                                                <td>{value.show_on_main_page === true ? 'Yes' : 'No'}</td>
                                                {/* <td>{value.recommended === true ? 'Yes' : 'No'}</td> */}
                                                <td> {value.is_deal_associated === true && value.deal_details !== undefined && value.deal_details.map((val,key) => (<span key={key}  style={{display:"-webkit-box",maxWidth:"150px","-webkit-line-clamp":"3","-webkit-box-orient":"vertical",overflow:"hidden"}} className="font-size-12">{val['name']}</span>))}</td>
                                                <td>{value.blog_categories !== undefined && value.blog_categories.map((value, key) => (
                                                  <span className="font-size-12 badge bg-info rounded-pill"
                                                        key={key}>{value['name']}</span>
                                                ))}</td>
                                                <td>{value.location_details !== undefined && value.location_details.map((value, key) => (
                                                  <span className="font-size-12 badge bg-warning rounded-pill"
                                                        key={key}>{value['name']}</span>
                                                ))}</td>
                                                <td>{formatDate(value.blog_date)}</td>
                                                <th>{value.blog_comment_report_spams_counts>0? <Link  to={`/blogs/spam_reports/${value._id}`} style={{color:"green"}}>{value.blog_comment_report_spams_counts}</Link>:0}</th>
                                                <td>{value.status === true ? <span className="badge badge-pill badge-soft-success font-size-13">Active</span> : <span className="badge badge-pill badge-soft-error font-size-13">Inactive</span>}</td>
                                                <td>
                                                    <button style={{backgroundColor:'green',padding:'1px 10px'}} className="btn btn-primary waves-effect waves-light copu_btn" onClick={() => {handleCopy(value.deep_link)}}>Copy</button>
                                                </td>
                                                <th>{value.blog_comment_report_spams_counts>0? <Link  to={`/blogs/spam_reports/${value._id}`} style={{color:"green"}}>{value.blog_comment_report_spams_counts}</Link>:value.blog_comment_report_spams_counts}</th>
                                                {/*<td className={'font-size-13'}>{(value.created_by!==undefined)?value.created_by.name:''}</td>*/}
                                                <td><Link to={`/blogs/${currentPage}/edit/${value._id}`}><i className="bx bxs-edit-alt"></i> </Link></td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                    </div>
                                    <Row className="justify-content-center">
                                        <Col className="col-auto">
                                            <Pagination
                                                className="pagination-bar"
                                                currentPage={currentPage}
                                                totalCount={totalItems}
                                                pageSize={PageSize}
                                                onPageChange={page => history.push(`/blogs/pageno/${page}/${search}`)}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
            <Modal
              size="lg"
              isOpen={mediaModal}
              toggle={() => {
                  tog_center()
              }}
              centered
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">Media</h5>
                    <button
                      type="button"
                      onClick={() => {
                          setMediaModal(false)
                          setMediaData([])
                      }}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                <Row className="g-2">
                    <GridContextProvider onChange={onChange}>
                        <GridDropZone
                        id="items"
                        boxesPerRow={3}
                        rowHeight={220}
                        style={{ height: 220 * Math.ceil(mediaData.length / 3) }}
                        >
                        {mediaData.map((value, key) => (
                            value['url'].split(".").pop() === 'mp4' || value['url'].split(".").pop() === 'm3u8' ?
                            <GridItem key={value['_id']}>
                                    <div className="position-relative">
                                        <span style={{"cursor":"pointer","position":"absolute","top":"10px","right":"10px","zIndex":"5","background":"#fff","height":"25px","width":"25px","textAlign":"center","lineHeight":"25px","borderRadius":"10px","color":"#e91e63"}} onClick={() => deleteMedia(value,key)}><i className="mdi mdi-trash-can d-block font-size-16"></i></span>
                                        <ReactPlayer playing={false} controls={true} width="100%" height="100%" url={value['url']} />
                                    </div>
                            </GridItem>
                            :
                            <GridItem key={value['_id']}>
                                    <div className="img-box" style={{margin:'10px'}}>
                                        <span style={{"cursor":"pointer","position":"absolute","top":"10px","right":"10px","zIndex":"5","background":"#fff","height":"25px","width":"25px","textAlign":"center","lineHeight":"25px","borderRadius":"10px","color":"#e91e63"}} onClick={() => deleteMedia(value,key)}><i className="mdi mdi-trash-can d-block font-size-16"></i></span>
                                        <img className="img-main" src={value['url']} alt={value['_id']} draggable="false"/>
                                        <img className="img-back" src={value['url']} alt={value['_id']} draggable="false"/>
                                    </div>
                            </GridItem>
                        ))}
                        </GridDropZone>
                    </GridContextProvider>
                        {mediaData.length === 0 && <h4 className="text-center">No Media</h4>}
                    </Row>
                </div>
            </Modal>
        </React.Fragment>
    )
}

export default Blogs
