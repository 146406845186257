import React, {useEffect, useMemo, useState} from "react";
import { useParams, useHistory, useLocation } from 'react-router-dom';
import {Card, CardBody, Col, Row} from "reactstrap"
import MetaTags from 'react-meta-tags'
import Pagination from 'components/Common/Pagination';
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {TableHeader, Search} from "../Datatable/index";
import {apiKey} from "helpers/url_helper"
import {Link} from "react-router-dom";
import axios from 'axios';
import notification from "../../components/Common/Notification"
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";
let api_url = process.env.REACT_APP_API_URL;
let api_key = apiKey()
let exportURL = api_url + "/get-season-csv"

const useFullPageLoader = () => {
    const [loading, setLoading] = useState(false);

    return [
        loading ? 'Loading' : null,
        () => setLoading(true), //Show loader
        () => setLoading(false) //Hide Loader
    ];
};
let PageSize = 15;
const OriginalSeasons = (props) => {
    const history = useHistory();
    const location = useLocation();
    const { pId:pageno } = useParams();
    const [tableData, settableData] = useState([]);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(() => {
        const paramPage = parseInt(pageno);
        if (!isNaN(paramPage)) {
            return paramPage;
          } else {
            return 1;
          }
      });
      const { searchQuery } = useParams();
      const [search, setSearch] = useState(() => {
          if (searchQuery) {
              return searchQuery;
            } else {
              return '';
            }
        });
      const [getSearch, setGetSearch] = useState(search);
      const [getSearchPage, setGetSearchPage] = useState(currentPage)
      const [isFits, setisFits] = useState(false);
    const [main_img, setMainImg] = useState("");
    const [isOpen, setisOpen] = useState(false);
    const [videoId, setVideoId] = useState("");

    const CountryHeader = [
        {name: "#", field: "id", sortable: false},
        {name: "Original", field: "original", sortable: false},
        {name: "Season No.", field: "season_no", sortable: false},
        {name: "Season Name", field: "searson_name", sortable: false},
        {name: "Season Thumbnail", field: "trailer_cover_image", sortable: false},
        // {name: "Logo", field: "cover_image", sortable: false},
        {name: "Trailer URL", field: "trailer_url", sortable: false},
        {name: "Ordering", field: "ordering", sortable: false},
        {name: "Status", field: "status", sortable: false},
        {name: "Action", field: "", sortable: false}
    ];

    const handleVideo = (url) => {
        var regExp = /https:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;
        var match = url.match(regExp);
        if (match){
            setisOpen(true);
            setVideoId(match[2])
        }else {
            notification('Video url not correct','error','toast-bottom-right')
        }
    }
    function handleSearch(){
        setSearch(getSearch);
        setCurrentPage(getSearchPage);
        if(getSearch){
            history.push(`/seasons/pageno/${getSearchPage}/${getSearch}`)
        }
    }
    // Handle any changes to the currentPage state
    const handlePageChange = () => {
        setCurrentPage(() => {
            const paramPage = parseInt(pageno);
            if (!isNaN(paramPage)) {
                return paramPage;
              } else {
                return 1;
              }
          })
    };
    useEffect(() => {
        handlePageChange();
    }, [pageno,location]);

    useEffect(() => {
        showLoader()
        let query = ''
        search && ( query = `keyword=${search}`)
        const url = `${api_url}/get-season?${query}&limit=${PageSize}&pageNo=${currentPage}`
        axios.get(url,{
            headers: {
                "Authorization": 'Bearer '+api_key,
            }
        })
            .then(res => {
                hideLoader();
                if(res.status) {
                    settableData(res.data.data);
                    setTotalItems(res.data.totalRecords);
                }
            }).catch(
                err => {
                    notification(err, 'error', 'toast-bottom-right')
                    if(err.response.status===401 || err.response.status===400 ){
                        history.push('/logout')
                    }
                }
            );
    }, [currentPage,search])  // pass `value` as a dependency

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Manage Originals Seasons</title>
                </MetaTags>
                <div className="container-fluid">
                    <Breadcrumbs title="Dashboard" path="/" breadcrumbItem="Originals Seasons"/>
                    {isFits ? (
                        <Lightbox
                            mainSrc={main_img}
                            enableZoom={false}
                            onCloseRequest={() => {
                                setisFits(!isFits);
                            }}
                        />
                    ) : null}
                    <ModalVideo
                        videoId={videoId}
                        channel="vimeo"
                        isOpen={isOpen}
                        onClose={() => {
                            setisOpen(false);
                        }}
                    />
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <div className="d-flex justify-content-between mb-3 gap-3">
                                        <div>
                                            <a href={exportURL}
                                                target="_blank" rel="noreferrer"
                                               className="btn btn-primary waves-effect waves-light"><i
                                                className="bx bx-export font-size-16 align-middle me-2"></i> Export</a>
                                        </div>
                                        <div>
                                            <a href="/seasons/add"
                                               className="btn btn-primary waves-effect waves-light"><i
                                                className="bx bx-plus font-size-16 align-middle me-2"></i> Add</a>
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-md-12 d-flex flex-row-reverse">
                                        <div onClick={handleSearch}
                                               className="btn btn-primary waves-effect waves-light ms-2"> Search</div>
                                            <Search
                                                onSearch={value => {
                                                    setGetSearch(value)
                                                    setGetSearchPage(1)
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <table className="table table-striped">
                                        <TableHeader
                                            headers={CountryHeader}
                                        />
                                        <tbody className="">
                                        {loader && <tr><th colSpan={CountryHeader.length}><h4 className="text-center">Loading...</h4></th></tr>}
                                        {(!loader && tableData.length === 0) && <tr><th colSpan={CountryHeader.length}><h4 className="text-center">No data found</h4></th></tr>}
                                        {!loader && tableData.map((value,index) => (
                                            <tr key={value._id}>
                                                <th scope="row">
                                                    {PageSize * (currentPage - 1) + (index + 1)}
                                                </th>
                                                <td>{(value.original[0] !== undefined && value.original[0].name)?value.original[0].name:''}</td>
                                                <td>{value.season_no !== null && value.season_no}</td>
                                                <td>{value.searson_name}</td>
                                                <td>
                                                    <img
                                                        onClick={() => {
                                                            setisFits(true);
                                                            setMainImg(value.trailer_cover_image)
                                                        }}
                                                        className="img-thumbnail avatar-sm"
                                                        alt={value.searson_name}
                                                        src={value.trailer_cover_image}
                                                        width="200"
                                                    />
                                                </td>
                                                {/*<td>*/}
                                                {/*    <img*/}
                                                {/*        onClick={() => {*/}
                                                {/*            setisFits(true);*/}
                                                {/*            setMainImg(value.cover_image)*/}
                                                {/*        }}*/}
                                                {/*        className="img-thumbnail avatar-sm"*/}
                                                {/*        alt={value.searson_name}*/}
                                                {/*        src={value.cover_image}*/}
                                                {/*        width="200"*/}
                                                {/*    />*/}
                                                {/*</td>*/}
                                                <td><button className="btn btn-sm btn-outline-dark" onClick={() => {handleVideo(value.trailer_url)}} rel="noreferrer">{value.trailer_url}</button></td>
                                                <td>{value.ordering}</td>
                                                <td>{value.status?'Active':'Inactive'}</td>
                                                <td><Link to={`/seasons/${currentPage}/edit/${value._id}`}><i className="bx bxs-edit-alt"></i> </Link></td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                    <Row className="justify-content-center">
                                        <Col className="col-auto">
                                            <Pagination
                                                className="pagination-bar"
                                                currentPage={currentPage}
                                                totalCount={totalItems}
                                                pageSize={PageSize}
                                                onPageChange={page => history.push(`/seasons/pageno/${page}/${search}`)}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

export default OriginalSeasons
