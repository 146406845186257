import React from "react"
import MetaTags from "react-meta-tags"
import {Button, Card, CardBody, Col, Container, InputGroup, Label, Row,} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {apiKey} from "../../helpers/url_helper"
import SimpleReactValidator from 'simple-react-validator'
import axios from "axios"
import toastr from "toastr"
import PropTypes from "prop-types"
import notification from "../../components/Common/Notification"
import country_code from "../../common/data/countrycodes"
import Select from "react-select"
const user_detail = JSON.parse(localStorage.getItem("authUser"));
let api_url = process.env.REACT_APP_API_URL
let api_key = apiKey()
const headers = {'Content-Type': 'application/json', "Authorization": 'Bearer ' + api_key}
function genPassword() {
    const chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ"
    const passwordLength = 8
    let password = ""
    for (let i = 0; i <= passwordLength; i++) {
        const randomNumber = Math.floor(Math.random() * chars.length)
        password += chars.substring(randomNumber, randomNumber +1);
    }
    document.getElementById("password").value = password;
}
class UsersAdd extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            username: "",
            name: "",
            email: "",
            country_code: "",
            mobile: "",
            email_verified: false,
            mobile_verified: false,
            status: false,
            social_youtube: "",
            social_website: "",
            social_facebook: "",
            social_instagram: "",
            photo: "",
            profile_pic: [],
            user_role: [],
            phone_code: [],
            bio: "",
            role: "",
            password: "",
            createdId: user_detail.id,
            designation: "",
            social_static_followers:0,
        }
        this.validator = new SimpleReactValidator({autoForceUpdate: this})
    }

    componentDidMount() {
        this.country_code()
        const url = `${api_url}/user-roles?status=1`
        axios.get(url,{headers})
            .then(res => {
                res.status && this.setState({user_role:res.data.data});
                this.user_role()
            }).catch(err => notification(err,'error','toast-bottom-right'));
    }
    user_role = () => {
        let user_role_master = [];
        this.state.user_role.map((val,key) => {
            user_role_master.push({value:val.id,label:val.name})
            if (this.state.roleId === val.id){
                this.setState({selectedRole:{value:val.id,label:val.name}})
            }
        })
        this.setState({roles:user_role_master})
    }
    country_code = () => {
        let country_code_master = []
        country_code.map((val,key) => {
            country_code_master.push({value:val.dial_code,label:val.name+' '+val.dial_code})
        })
        this.setState({phone_code:country_code_master})
    }
    handleInput = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    handleFileChange = (event) => {
        this.setState({
            profile_pic: event.target.files[0]
        })
    }

    handleFormSubmit = async (e) => {
        e.preventDefault()
        if (this.validator.allValid()) {
            const formData = new FormData()
            formData.append('username', this.state.username)
            formData.append('name', this.state.name)
            formData.append('email', this.state.email)
            formData.append('country_code', this.state.country_code?.value)
            formData.append('mobile', this.state.mobile)
            formData.append('roleId', this.state.roleId)
            this.state.profile_pic && formData.append('photo', this.state.profile_pic)
            formData.append('email_verified', this.state.email_verified?1:0)
            formData.append('mobile_verified', this.state.mobile_verified?1:0)
            formData.append('status', this.state.status?1:0)
            formData.append('social_youtube', this.state.social_youtube)
            formData.append('social_website', this.state.social_website)
            formData.append('social_facebook', this.state.social_facebook)
            formData.append('social_instagram', this.state.social_instagram)
            formData.append('bio', this.state.bio)
            formData.append('designation', this.state.designation)
            formData.append('password', this.state.password)
            formData.append('createdId', this.state.createdId)
            formData.append('social_static_followers',this.state.social_static_followers)
            this.setState({submit: true})
            this.setState({ submit: true });
            const req2 = await fetch(`${api_url}/add-user`, {
                method: "POST",
                headers: {
                    "Authorization": 'Bearer '+api_key,
                },
                body: formData
            });
            const data2 = await req2.json();
            if (data2.status) {
                this.setState({ submit: false });
                toastr.success('User added successful.')
                const { history } = this.props;
                //history.push('/users/pageno/1');

            } else {
                toastr.error(data2.message);
                this.setState({ submit: false });
            }
            // axios({
            //     method: "post", url: `${api_url}/add-user`, data: formData, headers
            // }).then(response => {
            //     if (response.status) {
            //         this.setState({submit: false})
            //         toastr.success('User update successful.')
            //         // eslint-disable-next-line react/prop-types
            //         const {history} = this.props
            //         // eslint-disable-next-line react/prop-types
            //         history.push('/users')
            //     } else {
            //         toastr.error(response.message)
            //     }
            // }).catch(err => {
            //     toastr.error(err.message)
            //  //   this.setState({submit: false})
            //    // alert(err.message)
            //  //   toastr.error(err.message)
            // })
        } else {
            this.validator.showMessages()
            this.forceUpdate()
        }
    }

    render() {
        const {submit} = this.state
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>
                            Manage User | {process.env.REACT_APP_PROJECTNAME}
                        </title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Manage User" path="/users/pageno/1" breadcrumbItem="Add User"/>
                        <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                        <form onSubmit={this.handleFormSubmit}>
                                            <Row>
                                                <Col md="3" className="mb-3">
                                                    <Label htmlFor="username">Username</Label>
                                                    <input name="username" placeholder="Type Username"
                                                           defaultValue={this.state.username} type="text"
                                                           className={"form-control"} onChange={this.handleInput}/>
                                                    {this.validator.message('username', this.state.username, 'required')}
                                                </Col>
                                                
                                                <Col md={3} className="mb-3">
                                                    <Label htmlFor="role">User Role</Label>
                                                    <Select
                                                        value={this.state.selectedRole}
                                                        isMulti={false}
                                                        onChange={e => {this.setState({selectedRole:e, roleId:e.value})}}
                                                        options={this.state.roles}
                                                        classNamePrefix="select2-selection"
                                                    />

                                                    {this.validator.message('role', this.state.roleId, 'required')}
                                                
                                                </Col>
                                                {/* {this.state.role === '3' && */}
                                                    <Col md="3" className="mb-3">
                                                        <Label htmlFor="designation">Designation</Label>
                                                        <input name="designation" placeholder="Type Designation"
                                                               defaultValue={this.state.designation} type="text"
                                                               className={"form-control"} onChange={this.handleInput}/>
                                                        {/* {this.validator.message('designation', this.state.designation, 'required')} */}
                                                    </Col>
                                                {/* } */}
                                                <Col md="3" className="mb-3">
                                                    <Label htmlFor="name">Full Name</Label>
                                                    <input name="name" placeholder="Type Full Name"
                                                           defaultValue={this.state.name} type="text"
                                                           className={"form-control"} onChange={this.handleInput}/>
                                                    {this.validator.message('name', this.state.name, 'required')}
                                                </Col>
                                                <Col md="3" className="mb-3">
                                                    <Label htmlFor="email">Email Address</Label>
                                                    <input name="email" placeholder="Type Email Address"
                                                           defaultValue={this.state.email} type="email"
                                                           className={"form-control"} onChange={this.handleInput}/>
                                                    {this.validator.message('email', this.state.email, 'required|email')}
                                                </Col>
                                                <Col md="2" className="mb-3">
                                                    <Label htmlFor="country_code">Country Code</Label>
                                                    <Select
                                                        value={this.state.country_code}
                                                        isMulti={false}
                                                        onChange={e => this.setState({country_code:e})}
                                                        options={this.state.phone_code}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                    {this.validator.message('country_code', this.state.country_code, 'required')}
                                                </Col>
                                                <Col md="3" className="mb-3">
                                                    <Label htmlFor="mobile">Mobile Number</Label>
                                                    <input name="mobile" placeholder="Type Mobile Number"
                                                           defaultValue={this.state.mobile} type="text"
                                                           className={"form-control"} onChange={this.handleInput}/>
                                                    {this.validator.message('mobile', this.state.mobile, 'required|phone')}
                                                </Col>
                                                <Col md="auto" className="mb-3">
                                                    <Label htmlFor="">Email Verified</Label>
                                                    <div className="square-switch mt-2">
                                                        {/* eslint-disable-next-line react/no-unknown-property */}
                                                        <input type="checkbox" id="square-switch-email-verified" switch="none" name="status" checked={this.state.email_verified} onChange={() => this.setState({email_verified: !this.state.email_verified})}/>
                                                        <label htmlFor="square-switch-email-verified" data-on-label="Yes" data-off-label="No"/>
                                                    </div>
                                                </Col>
                                                <Col md="auto" className="mb-3">
                                                    <Label htmlFor="">Mobile Verified</Label>
                                                    <div className="square-switch mt-2">
                                                        {/* eslint-disable-next-line react/no-unknown-property */}
                                                        <input type="checkbox" id="square-switch-mobile-verified" switch="none" name="mobile_verified" checked={this.state.mobile_verified} onChange={() => this.setState({mobile_verified: !this.state.mobile_verified})}/>
                                                        <label htmlFor="square-switch-mobile-verified" data-on-label="Yes" data-off-label="No"/>
                                                    </div>
                                                </Col>
                                                <Col md="auto" className="mb-3">
                                                    <Label htmlFor="">Status</Label>
                                                    <div className="square-switch mt-2">
                                                        {/* eslint-disable-next-line react/no-unknown-property */}
                                                        <input type="checkbox" id="square-switch-status" switch="none" name="status" checked={this.state.status} onChange={() => this.setState({status: !this.state.status})}/>
                                                        <label htmlFor="square-switch-status" data-on-label="On" data-off-label="Off"/>
                                                    </div>
                                                </Col>
                                                <Col md="4" className="mb-3">
                                                    <Label htmlFor="social_youtube">Youtube</Label>
                                                    <input name="social_youtube" placeholder="Type Youtube Link" defaultValue={this.state.social_youtube} type="url" className={"form-control"} onChange={this.handleInput}/>
                                                    {/*{this.validator.message('social_youtube', this.state.social_youtube, 'required|url')}*/}
                                                </Col>
                                                <Col md="4" className="mb-3">
                                                    <Label htmlFor="social_website">Website</Label>
                                                    <input name="social_website" placeholder="Type Website Link" defaultValue={this.state.social_website} type="url" className={"form-control"} onChange={this.handleInput}/>
                                                    {/*{this.validator.message('social_website', this.state.social_website, 'required|url')}*/}
                                                </Col>
                                                <Col md="4" className="mb-3">
                                                    <Label htmlFor="social_facebook">Facebook</Label>
                                                    <input name="social_facebook" placeholder="Type Facebook Link" defaultValue={this.state.social_facebook} type="url" className={"form-control"} onChange={this.handleInput}/>
                                                    {/*{this.validator.message('social_facebook', this.state.social_facebook, 'required|url')}*/}
                                                </Col>
                                                <Col md="4" className="mb-3">
                                                    <Label htmlFor="social_instagram">Instagram</Label>
                                                    <input name="social_instagram" placeholder="Type Instagram Link" defaultValue={this.state.social_instagram} type="text" className={"form-control"} onChange={this.handleInput}/>
                                                    {/*{this.validator.message('social_instagram', this.state.social_instagram, 'required|url')}*/}
                                                </Col>
                                                <Col md="3" className="mb-3">
                                                    <Label htmlFor="social_instagram">Profile Picture</Label>
                                                    <input name="photo" placeholder="Select profile picture" accept="image/png,image/jpg,image.webp" type="file" className={"form-control"} onChange={this.handleFileChange}/>
                                                    {/*{this.validator.message('social_instagram', this.state.social_instagram, 'required|url')}*/}
                                                </Col>
                                                <Col md="4" className="mb-3">
                                                    <Label htmlFor="social_static_followers">Static Followers</Label>
                                                    <input name="social_static_followers" placeholder="Type Static followers" defaultValue={this.state.social_static_followers} type="number" min={0} className={"form-control"} onChange={this.handleInput}/>
                                                    {/*{this.validator.message('social_youtube', this.state.social_youtube, 'required|url')}*/}
                                                </Col>
                                                <Col md="6" className="mb-3">
                                                    <Label htmlFor="bio">Bio</Label>
                                                    <textarea name="bio" placeholder="Type Bio" className={"form-control"} value={this.state.bio} onChange={this.handleInput}>{this.state.bio}</textarea>
                                                    {/*{this.validator.message('bio', this.state.bio, 'required')}*/}
                                                </Col>
                                                <Col md="3" className="mb-3">
                                                    <Label htmlFor="password">Password</Label>
                                                    <InputGroup>
                                                        <input name="password" placeholder="Type Password" id="password" defaultValue={this.state.password} type="text" className={"form-control"} onChange={this.handleInput}/>
                                                        <div className="input-group-append">
                                                            <button type="button" onClick={genPassword} className="btn btn-primary">
                                                                <i className="fas fa-lock" aria-hidden="true"/>
                                                            </button>
                                                        </div>
                                                    </InputGroup>
                                                    {this.validator.message('password', this.state.password, 'required')}
                                                </Col>
                                            </Row>

                                            <div className="d-flex flex-wrap gap-2 justify-content-end">
                                                <Button color="primary" type="submit" disabled={submit}>
                                                    {submit === true ? 'Please wait...' : 'Submit Data'}
                                                </Button>
                                            </div>
                                        </form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

UsersAdd.propTypes = {
    location: PropTypes.object
}

export default UsersAdd
