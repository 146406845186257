import React from "react"
import MetaTags from "react-meta-tags"
import {Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Row,} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Select from "react-select"
import {apiKey} from "../../helpers/url_helper"
import SimpleReactValidator from 'simple-react-validator'
import axios from "axios"
import toastr from "toastr"
import "style.min.css"
import PropTypes from "prop-types"
import {value} from "lodash/seq"

let api_url = process.env.REACT_APP_API_URL
let api_key = apiKey()
const headers = {'Content-Type': 'application/json', "Authorization": 'Bearer ' + api_key}
class Original_trending_add extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            edit: [],
            original_master: [],
            seasons_master: [],
            episodes_master: [],
            defaultTags: [],
            status: true,
            episode_id: "",
            season_id: "",
            original_id: "",
            trending_image: "",
            ordering: "",
            episode_no: "",
            handleResponse: null,
            submit: false
        }
        this.validator = new SimpleReactValidator({autoForceUpdate: this})
    }

    componentDidMount() {
        fetch(`${api_url}/get-shows?keyword=&status=1`, {headers})
            .then(response => response.json())
            .then(data => {
                let originals = [];
                data.data.map((val,key) => {
                    originals.push({value:val._id,label:val.name})
                })
                this.setState({original_master: originals})
            })
    }

    handleSeason = () => {
        fetch(`${api_url}/get-season?keyword=&status=1&original_id=${this.state.original_id?.value}`, {headers})
            .then(response => response.json())
            .then(data => {
                let seasons = [];
                data.data.map((val,key) => {
                    seasons.push({value:val._id,label:val.searson_name})
                })
                this.setState({seasons_master: seasons})
            })
    }

    handleEpisode = () => {
        fetch(`${api_url}/get-episodes?keyword=&status=1&original_season_id=${this.state.season_id?.value}`, {headers})
            .then(response => response.json())
            .then(data => {
                let episodes = [];
                data.data.map((val,key) => {
                    episodes.push({value:val._id,label:val.episode_name})
                })
                this.setState({episodes_master: episodes})
            })
    }

    handleFileChange = (event) => {
        this.setState({
            trending_image: event.target.files[0]
        })
    }

    handleInput = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    handleFormSubmit = (e) => {
        e.preventDefault()
        if (this.validator.allValid()) {
            const formData = new FormData()
            formData.append('episode_id', this.state.episode_id?.value)
            var trending_image = document.getElementById("trending_image").files[0];
            if (trending_image){
                formData.append('trending_image', trending_image)
            }
            formData.append('season_id', this.state.season_id?.value)
            formData.append('original_id', this.state.original_id?.value)
            formData.append('status', this.state.status)
            formData.append('ordering', this.state.ordering)
            this.setState({submit: true})
            axios({
                method: "post", url: `${api_url}/add-original-trending`, data: formData, headers: {
                    'Content-Type': 'application/json',
                    "Authorization": 'Bearer ' + api_key,
                }
            }).then(response => {
                if (response.status) {
                    this.setState({submit: false})
                    toastr.success('Original Trending added successful.')
                    // eslint-disable-next-line react/prop-types
                    const {history} = this.props
                    // eslint-disable-next-line react/prop-types
                    history.push('/trending-episodes/pageno/1');

                }
            }).catch(err => {
                toastr.error(err.message)
                this.setState({submit: false})
            })
        } else {
            this.validator.showMessages()
            this.forceUpdate()
        }
    }

    render() {

        const {original_master, seasons_master, episodes_master, episode_no, ordering, defaultTags, submit} = this.state

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>
                            Original Trending | {process.env.REACT_APP_PROJECTNAME}
                        </title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Original Trending" path="/trending-episodes/pageno/1" breadcrumbItem="Add Original Trending"/>
                        <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                        <form onSubmit={this.handleFormSubmit}>
                                            <Row>
                                                <Col md="4" className="mb-3">
                                                    <label className="control-label">Originals</label>
                                                    <Select
                                                        value={this.state.original_id}
                                                        isMulti={false}
                                                        onChange={e => {this.setState({original_id:e},this.handleSeason)}}
                                                        options={original_master}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                    {this.validator.message('episode_id', this.state.episode_id, 'required')}
                                                </Col>
                                                <Col md="4" className="mb-3">
                                                    <label className="control-label">Seasons</label>
                                                    <Select
                                                        value={this.state.season_id}
                                                        isMulti={false}
                                                        onChange={e => this.setState({season_id:e},this.handleEpisode)}
                                                        options={seasons_master}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                    {this.validator.message('season_id', this.state.season_id, 'required')}
                                                </Col>
                                                <Col md="4" className="mb-3">
                                                    <label className="control-label">Episodes</label>
                                                    <Select
                                                        value={this.state.episode_id}
                                                        isMulti={false}
                                                        onChange={e => this.setState({episode_id:e})}
                                                        options={episodes_master}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                    {this.validator.message('episode_id', this.state.episode_id, 'required')}
                                                </Col>
                                                <Col md="4" className="mb-3">
                                                    <Label htmlFor="trending_image">
                                                        Trending Image
                                                    </Label>
                                                    <input
                                                        id="trending_image"
                                                        name="trending_image"
                                                        placeholder="Trending Image"
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={this.handleFileChange}
                                                        className={"form-control"}
                                                    />
                                                    {this.validator.message('trending_image', this.state.trending_image, '')}
                                                </Col>
                                                {/*<Col md="2" className="mb-3">*/}
                                                {/*    <Label htmlFor="episode_no">Episode No.</Label>*/}
                                                {/*    <input*/}
                                                {/*        name="episode_no"*/}
                                                {/*        type="text"*/}
                                                {/*        placeholder="Episode No."*/}
                                                {/*        className={"form-control"}*/}
                                                {/*        onChange={this.handleInput}*/}
                                                {/*        defaultValue={episode_no}*/}
                                                {/*    />*/}
                                                {/*    {this.validator.message('episode_no', this.state.episode_no, 'required')}*/}
                                                {/*</Col>*/}
                                                <Col md="2" className="mb-3">
                                                    <Label htmlFor="validationCustom03">Order No.</Label>
                                                    <input
                                                        name="ordering"
                                                        type="number"
                                                        placeholder="Order No."
                                                        className={"form-control"}
                                                        onChange={this.handleInput}
                                                        defaultValue={ordering}
                                                    />
                                                    {this.validator.message('ordering', this.state.ordering, 'required|numeric')}
                                                </Col>
                                                <Col md="3" className="mb-3">
                                                    <Label htmlFor="">Status</Label>
                                                    <div className="square-switch mt-2">
                                                        {/* eslint-disable-next-line react/no-unknown-property */}
                                                        <input  type="checkbox" id="square-switch-status" switch="none"
                                                            name="status"
                                                            checked={this.state.status}
                                                            onChange={() =>
                                                                this.setState({status: !this.state.status})
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="square-switch-status"
                                                            data-on-label="On"
                                                            data-off-label="Off"
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>

                                            <div className="d-flex flex-wrap gap-2 justify-content-end">
                                                <Button color="primary" type="submit" disabled={submit}>
                                                    {submit === true ? 'Please wait...' : 'Submit Data'}
                                                </Button>
                                            </div>
                                        </form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

Original_trending_add.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string
        })
    }),
    location: PropTypes.object
}

export default Original_trending_add
