import React from "react"
import ReactDOM from 'react-dom'
import {Col, Modal, Row} from "reactstrap"
import PropTypes from "prop-types"
import ReactPlayer from "react-player"

const ShowMedia = ({isShowing, hide, MediaData, deleteMedia}) => isShowing ? ReactDOM.createPortal(
    <React.Fragment>
        <Modal
            size="lg"
            isOpen={isShowing}
            toggle={hide}
            centered
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0">Media</h5>
                <button
                    type="button"
                    onClick={hide}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <Row className="g-2">
                    {MediaData.map((value, key) => (
                        value['url'].split(".").pop() === 'mp4' ?
                            <Col key={value['_id']} md={8}>
                                <div className="position-relative">
                                    <span style={{
                                        "cursor": "pointer",
                                        "position": "absolute",
                                        "top": "10px",
                                        "right": "10px",
                                        "zIndex": "5",
                                        "background": "#fff",
                                        "height": "25px",
                                        "width": "25px",
                                        "textAlign": "center",
                                        "lineHeight": "25px",
                                        "borderRadius": "10px",
                                        "color": "#e91e63"
                                    }} onClick={() => deleteMedia(value, key)}><i
                                        className="mdi mdi-trash-can d-block font-size-16"></i></span>
                                    <ReactPlayer playing={false} controls={true} width="100%" height="100%"
                                                 url={[value['url']]}/>
                                </div>
                            </Col>
                            :
                            <Col key={value['_id']} md={4}>
                                <div className="img-box">
                                    <span style={{
                                        "cursor": "pointer",
                                        "position": "absolute",
                                        "top": "10px",
                                        "right": "10px",
                                        "zIndex": "5",
                                        "background": "#fff",
                                        "height": "25px",
                                        "width": "25px",
                                        "textAlign": "center",
                                        "lineHeight": "25px",
                                        "borderRadius": "10px",
                                        "color": "#e91e63"
                                    }} onClick={() => deleteMedia(value, key)}><i
                                        className="mdi mdi-trash-can d-block font-size-16"></i></span>
                                    <img className="img-main" src={value['url']} alt={value['_id']}/>
                                    <img className="img-back" src={value['url']} alt={value['_id']}/>
                                </div>
                            </Col>

                    ))}
                </Row>
            </div>
        </Modal>
    </React.Fragment>, document.body
) : null

ShowMedia.propTypes = {
    deleteMedia: PropTypes.any,
    MediaData: PropTypes.array,
    isShowing: PropTypes.any,
    hide: PropTypes.any
}

export default ShowMedia