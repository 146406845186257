import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import avatar from "../../assets/images/users/avatar-1.jpg"
import profile from "../../assets/images/profile-img.png"
import logoImg from "../../assets/images/companies/img-6.png"

// actions
import { editProfile, resetProfileFlag } from "../../store/actions"

const ChangePassword = () => {
  //meta title
  document.title = "Profile | Skote - React Admin & Dashboard Template"

  const dispatch = useDispatch()

  const [email, setemail] = useState("")
  const [name, setname] = useState("")
  const [idx, setidx] = useState(1)

  const { error, success } = useSelector(state => ({
    error: state.Profile.error,
    success: state.Profile.success,
  }))

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        setname(obj.displayName)
        setemail(obj.email)
        setidx(obj.uid)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        setname(obj.username)
        setemail(obj.email)
        setidx(obj.uid)
      }
      setTimeout(() => {
        dispatch(resetProfileFlag())
      }, 3000)
    }
  }, [dispatch, success])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      current: "",
      new: "",
      confirm: "",
      idx: idx || "",
    },
    validationSchema: Yup.object({
      current: Yup.string()
        .required("Please Enter Current Password")
        .min(8, "Must be 8 characters or more"),
      new: Yup.string()
        .required("Please Enter New Password")
        .min(8, "Must be 8 characters or more"),
      confirm: Yup.string()
        .required("Please Re-Enter New Password")
        .min(8, "Must be 8 characters or more")
      // .matches(/[a-z]+/, "One lowercase character")
      // .matches(/[A-Z]+/, "One uppercase character")
      // .matches(/[@$!%*#?&]+/, "One special character")
      // .matches(/\d+/, "One number"),
    }),
    onSubmit: values => {
      dispatch(editProfile(values))
    },
  })

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          {/* <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">Password Changed Successfully.</Alert> : null}
            </Col>
          </Row> */}
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-softbg-soft-primary">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5  style={{color: "#FFF"}}>Password Reset</h5>
                        <p style={{color: "#FFF"}}>Create new password.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logoImg}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {/* {forgetError && forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {forgetError}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null} */}

                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <div className="form-group">


                        <div className="mb-3">
                          <Label className="form-label">New Password</Label>
                          <Input
                            name="new"
                            // value={name}
                            className="form-control"
                            placeholder="Enter New password"
                            type="password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            // value={validation.values.username || ""}
                            invalid={
                              validation.touched.new && validation.errors.new
                                ? true
                                : false
                            }
                          />
                          {validation.touched.new && validation.errors.new ? (
                            <FormFeedback type="invalid">
                              {validation.errors.new}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">
                            Confirm New Password
                          </Label>
                          <Input
                            name="confirm"
                            // value={name}
                            className="form-control"
                            placeholder="Confirm New Password"
                            type="password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            // value={validation.values.username || ""}
                            invalid={
                              validation.touched.confirm &&
                              validation.errors.confirm
                                ? true
                                : false
                            }
                          />
                          {validation.touched.confirm &&
                          validation.errors.confirm ? (
                            <FormFeedback type="invalid">
                              {validation.errors.confirm}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <Input name="idx" value={idx} type="hidden" />
                      </div>
                      <div className="text-left mt-4">
                        <Button type="submit" color="primary">
                          Change Password
                        </Button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ChangePassword)
