import React, {Component} from "react";
import MetaTags from "react-meta-tags";
import {withRouter} from 'react-router-dom';
import {Button, Card, CardBody, CardTitle, Col, Container, FormGroup, Input, Label, Row,} from "reactstrap";
import {Formik, Field, Form, ErrorMessage} from "formik";
import * as Yup from "yup";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import axios from "axios";
import {apiKey} from "../../helpers/url_helper";

let api_url = process.env.REACT_APP_API_URL;
let api_key = apiKey()
const userSession = JSON.parse(localStorage.getItem("authUser"));

class TagsAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            interests: "",
            tag: "",
            ordering: "",
            InterestsSample: [],
            status: true,
        };
    }

    componentDidMount() {
        const url = `${api_url}/get-interests`
        axios.get(url,{headers: {
                "Authorization": 'Bearer ' + api_key,
            }})
            .then(res => {

                if (res.status) {
                    let arr = []
                    for (var i = 0; i < res.data.data.length; i++) {

                        arr.push({label: res.data.data[i].name, value: res.data.data[i]._id});

                    }
                    // InterestsSample.setState(arr);
                    this.setState({InterestsSample: arr})
                }

            })
    }

    render() {
        const {history} = this.props;
        const {InterestsSample} = this.state;
        toastr.options = {
            positionClass: 'toast-bottom-center',
        };
        const Interests = InterestsSample.map((Interest, key) => (<option value={Interest.value} key={key}>
            {Interest.label}
        </option>));
        const onSubmit = async (values, {setSubmitting, setErrors, setStatus, resetForm}) => {
            try {
                let formData = JSON.parse(JSON.stringify(values));
                var data = JSON.stringify({
                    "name": formData['tag'],
                    "interest": formData['interests'],
                    "ordering": formData['ordering'],
                    "status": formData['status'],
                    "user_id": 0,
                    "is_user_defined": formData['is_user_defined'],
                });
                axios({
                    method: "post", url: `${api_url}/add-tag`, data: data, headers: {
                        'Content-Type': 'application/json',
                        "Authorization": 'Bearer ' + api_key,
                    }
                })
                    .then(function (response) {
                        toastr.success('Tag added successful.')
                        if (response.data.status) {
                            setSubmitting(false);
                            history.push('/tags/pageno/1');

                        }
                    })
                    .catch(function (response) {
                        console.log(response);
                        toastr.error(response)
                    });
                resetForm({})
                setStatus({success: true})
            } catch (error) {
                setStatus({success: false})
                setSubmitting(false)
                setErrors({submit: error.message})
            }
        }

        return (<React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>
                        Manage Tags | {process.env.REACT_APP_PROJECTNAME}
                    </title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title="Tags" path="/tags/pageno/1" breadcrumbItem="Add Tags"/>
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <Formik
                                        enableReinitialize={true}
                                        initialValues={{
                                            tag: (this.state && this.state.tag) || "",
                                            interests: (this.state && this.state.interests) || "",
                                            ordering: (this.state && this.state.ordering) || "",
                                            status: (this.state && this.state.status) || "",
                                        }}
                                        validationSchema={Yup.object().shape({
                                            tag: Yup.string().required("Please Enter Tags Name"),
                                            interests: Yup.string().required("Please Select Interests"),
                                            ordering: Yup.string().required("Please Enter Ordering Number"),
                                        })}
                                        onSubmit={onSubmit}
                                    >
                                        {({errors, isSubmitting, touched, isValid, status, values}) => (
                                            <Form className="needs-validation">
                                                <Row>
                                                    <Col md="3">
                                                        <FormGroup className="mb-3">
                                                            <Label htmlFor="validationCustom01">
                                                                Tags name
                                                            </Label>
                                                            <Field
                                                                name="tag"
                                                                placeholder="Tag name"
                                                                type="text"
                                                                // onChange={this.handleTagChange}
                                                                className={"form-control" + (errors.tag && touched.tag ? " is-invalid" : "")}
                                                            />
                                                            <ErrorMessage
                                                                name="tag"
                                                                component="div"
                                                                className="invalid-feedback"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={3}>
                                                        <FormGroup className="mb-3">
                                                            <label className="label" htmlFor="interests">
                                                                Interests
                                                            </label>
                                                            <div className="control">
                                                                <Field name="interests" as="select"
                                                                       className={"form-control is-fullwidth" + (errors.interests && touched.interests ? " is-invalid" : "")}>
                                                                    <option value={""}>Select an option</option>
                                                                    {Interests}
                                                                </Field>
                                                                <ErrorMessage
                                                                    name="interests"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={3}>
                                                        <FormGroup className="mb-3">
                                                            <label className="label" htmlFor="is_user_defined">Defined by ( CT/ User)</label>
                                                            <div className="control">
                                                                <Field name="is_user_defined" as="select"
                                                                       className={"form-control is-fullwidth" + (errors.is_user_defined && touched.is_user_defined ? " is-invalid" : "")}>
                                                                    <option value={""}>Select an option</option>
                                                                    <option value="true">User</option>
                                                                    <option value="false">CT</option>
                                                                </Field>
                                                                <ErrorMessage
                                                                    name="is_user_defined"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="3">
                                                        <FormGroup className="mb-3">
                                                            <Label htmlFor="validationCustom03">Ordering No.</Label>
                                                            <Field
                                                                name="ordering"
                                                                type="number"
                                                                placeholder="Ordering No."
                                                                className={"form-control" + (errors.ordering && touched.ordering ? " is-invalid" : "")}
                                                            />
                                                            <ErrorMessage
                                                                name="ordering"
                                                                component="div"
                                                                className="invalid-feedback"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="3">
                                                        <Label htmlFor="">Status</Label>
                                                        <div className="square-switch mt-2">
                                                            <input
                                                                type="checkbox"
                                                                id="square-switch1"
                                                                switch="none"
                                                                name="status"
                                                                checked={this.state.status}
                                                                onChange={() => this.setState({status: !this.state.status})}
                                                            />
                                                            <label
                                                                htmlFor="square-switch1"
                                                                data-on-label="On"
                                                                data-off-label="Off"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <div className="d-flex flex-wrap gap-2 justify-content-end">
                                                    <Button color="primary" type="submit">
                                                        Submit Data
                                                    </Button>
                                                </div>
                                            </Form>)}
                                    </Formik>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>);
    }
}

export default TagsAdd;
