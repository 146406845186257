import React from "react"
import ReactApexChart from "react-apexcharts"

const dountchart = ({ chartdata }) => {

    const series = chartdata
    const options = {
        labels: ["Cancelled order", "Completed order", "Upcoming order"],
        legend: {
            show: true,
            position: "bottom",
            horizontalAlign: "center",
            verticalAlign: "middle",
            floating: false,
            fontSize: "14px",
            offsetX: 0,
            offsetY: -10,
        },
        responsive: [
            {
                breakpoint: 600,
                options: {
                    chart: {
                        height: 240,
                    },
                    legend: {
                        show: false,
                    },
                },
            },
        ],
    }

    return (
        <ReactApexChart
            options={options}
            series={series}
            type="donut"
            height="320"
        />
    )
}

export default dountchart
