import React from "react"
import { useParams } from 'react-router-dom';
import MetaTags from "react-meta-tags"
import {Button, Card, CardBody, Col, Container, Modal, Input, Label, Row,} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Select from "react-select"
import {apiKey} from "../../helpers/url_helper"
import SimpleReactValidator from 'simple-react-validator'
import axios from "axios"
import toastr from "toastr"
// import "style.min.css"
import PropTypes from "prop-types"
import CreatableSelect from 'react-select/creatable';
import AsyncSelect from "react-select/async"
import {get} from "../../helpers/api_helper";
import { Editor } from '@tinymce/tinymce-react'
let api_url = process.env.REACT_APP_API_URL
let api_key = apiKey();
const headers = {'Content-Type': 'application/json', "Authorization": 'Bearer ' + api_key}
const mystyle = {
  height: "110px",
  overflow: "hidden",
  width: "auto",
  objectFit:"cover"
}
function getCityName(id,cities){
  let selected_cities = [];
  for (var x = 0; x < id.length; x++){
    const result = cities.find( (name) => name['_id'] === id[x]);
    if (result !== undefined){
      selected_cities.push({label:result['city_name'],value:result['_id']})
    }
  }
  return selected_cities;
}
function getDataName(id,data){
  let selected = [];
  if(id!==undefined) {
    for (var x = 0; x < id.length; x++) {
      const result = data.find((name) => name['_id'] === id[x]);
      if (result !== undefined) {
        selected.push({ label: result['name'], value: result['_id'] })
      }
    }
  }
  return selected;
}


class BlogEdit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      validation: "",
      users: [],
      categories: [],
      interests: [],
      tags: [],
      active_deals: [],
      tag_list: [],
      selectedInterests: [],
      selectedTags: [],
      defaultInterests: [],
      defaultTags: [],
      is_deal_associated: false,
      recommended: false,
      show_on_main_page: false,
      status: true,
      associate_deals_url:"",
      deal_id: "",
      title: "",
      blog_creator:"",
      blog_creator_image: "",
      cover_image:"",
      hex_color:"",
      blog_content: "",
      old_blog_content: "",
      meta_keywords: "",
      seo_title: "",
      seo_description: "",
      handleResponse: null,
      location: "",
      locations: [],
      selectedlocation: [],
      edit_blog_category: [],
      category: "",
      selectedCategories: "",
      values: "",
      submit: false,
      // custom tags for ------------
      tagModel: false,
      selected_category: "",
      customTag: "",
      selectedTagsArray: [],
      categories_interest: [],
      isLoading: false,
      // editorState: EditorState.createEmpty(),
      user_id:"",
      totalLikes:0
    }
    this.validator = new SimpleReactValidator({autoForceUpdate: this})
  }

  componentDidMount() {
    const id = this.props.match.params.id
    this.setState({edit_id: id})
    const params = {
      type: "blogs",
      id: id
    }
    get(`${api_url}/user-list?pageNo=1&limit=90000&role_ids=3,4`, {headers})
        .then(data => this.setState({users: data.data}))
    axios.get(`${api_url}/get-single-data`, {
      params, headers: {
        "Authorization": 'Bearer ' + api_key,
      }
    })
      .then(res => {
        if (res.data.status) {
          let edit = res.data.data
          this.setState({
            edit_blog_category: edit['blog_category'],
            edit_tags: edit['tags'],
            title: edit['title'],
            blog_creator: edit['blog_creator'],
            blog_creator_image: edit['blog_creator_image'],
            cover_image: edit['cover_image'],
            blog_content: edit['blog_content'],
            old_blog_content: edit['blog_content'],
            seo_title: edit['seo_title'],
            meta_keywords: edit['meta_keywords'],
            seo_description: edit['seo_description'],
            is_deal_associated: edit['is_deal_associated'],
              deal_id: !edit['deal_id'] === null ? edit['deal_id'] : "",
            associate_deals_url: edit['associate_deals_url'],
            recommended: edit['recommended'],
            show_on_main_page: edit['show_on_main_page'],
            location: edit['location'],
            status: edit['status'],
            totalLikes:edit['totalLikes'],
            user_id: (!edit['user_id']?"":edit['user_id']),
          },this.getCategories)
        } else {
          toastr.danger('Server not response')
        }
      })
    // fetch(`${api_url}/get-interests?&status=1`, {headers})
    //   .then(response => response.json())
    //   .then(data => {
    //     this.setState({interests: data.data,defaultInterests: getDataName(this.state.edit_interests,data.data)})
    //     this.setState({selectedInterests: this.state.defaultInterests.map(({value}) => value).join(',')})
    //     this.handleInterestTags(false,this.state.edit_interests)
    //   })

    fetch(`${api_url}/get-active-deals?&status=1`, {headers})
        .then(response => response.json())
        .then(data => this.setState({active_deals: data.data}))
        .catch(err => {
          console.log("error:", err);
        });
    fetch(`${api_url}/get-location`, {headers})
        .then(response => response.json())
        .then(data => {
          let locations = data.data
          for(let i = 0;i < locations.length;i++){
            if (locations[i]._id == this.state.location){
              this.setState({selectedlocation: {_id:locations[i]._id,name:locations[i].name}})
            }
          }
        })
        .catch(err => {
          console.log("error:", err);
        });
  }

  getOptions = () => {
    return axios.get(`${api_url}/get-location?status=1&keyword=${this.state.values}`,{ headers })
        .then((res) => {
          let data = res.data.data;
          this.setState({locations:data})
          return res.data.data;
        })
        .catch(
            err => console.log(err)
        );
  }

  handleInputChange = value => {
    this.setState({values:value})
  };
  selectLocation = value => {
    this.setState({location:value._id})
    this.setState({selectedlocation:value})
  }
  handleInterestTags = (e) => {
    axios({
      method: "post", url: `${api_url}/get-interest-wise-tags`, data: {"interest": e}, headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + api_key
      }
    }).then(response => {
      if (response.data){
        let b = [],a = response.data.data;
        for (var c = 0; c < a.length; c++) b.push({
          label: a[c].name,
          value: a[c]._id
        });
        this.setState({tags: b})
        this.setState({tag_list:response.data.data},this.handelSelectedTags)
        this.setState({selectedTags: this.state.edit_tags})
      }
    }).catch(err => {
      toastr.error(err.message);
    });
  }
  getCategories = () => {
    fetch(`${api_url}/get-blog-categories?&status=1`, { headers })
        .then(response => response.json())
        .then(data => {
          if (data.status){
            let categories = []
            let category = [], categories_interest = []
            data.data.map((val) => {
              categories.push({value: val._id,label: val.name,interest:val.interest})
              this.state.edit_blog_category.map((value) => {
                if (val._id === value){
                  category.push({value: val._id,label: val.name,interest:val.interest})
                }
              })
              if(val?.interest !== undefined){
                categories_interest.push({value: val._id,label: val.name,interest:val.interest})
              }
            })
            this.setState({categories,categories_interest})
            this.handleCategories(category)
          }
        });
  }
  handelSelectedTags = () => {
    this.setState({defaultTags: getDataName(this.state.edit_tags,this.state.tag_list)})
  }

  handleCategories = selectedCategories => {
    let interests = []
    for (let i = 0;i < selectedCategories.length;i++){
      interests.push(selectedCategories[i].interest)
    }
    this.handleInterestTags(interests)
    this.setState({ category: selectedCategories });
  };

  handleTags = selectedTags => {
    this.setState({defaultTags: selectedTags})
  }

  handleFileChange = (event) => {
    this.setState({
      cover_image: event.target.files[0]
    })
  }
  handleBlogCreatorFileChange = (event) => {
    this.setState({
      blog_creator_image: event.target.files[0]
    })
  }
  onEditorStateChange = (editorState) => {
    this.setState({
        editorState
    });
    this.setState({blog_content:draftToHtml(convertToRaw(editorState.getCurrentContent()))})
  }
  onChangeValidation(fieldName, value) {
    const modifiedV = {...this.state.validation};
    if (value !== "") {
      modifiedV[fieldName] = true;
    } else {
      modifiedV[fieldName] = false;
    }

    if (modifiedV['unfilled_image']) {
      var unfilled_image = document.getElementById("unfilled_image").files[0];
      if (!unfilled_image) {
        document.getElementById("unfilled_image").value = ""
        this.setState({invalidUnfilledImage: 'Please select image.'});
        modifiedV["unfilled_image"] = false;
      }

      if (!unfilled_image.name.match(/\.(jpg|jpeg|png|gif|webp)$/)) {
        this.setState({invalidUnfilledImage: 'Please select valid image.'});
        document.getElementById("unfilled_image").value = ""
        console.log('Please select valid image.')
        modifiedV["unfilled_image"] = false;
      }
    }
    this.setState({validation: modifiedV});
  }
  selectData = a => {
    let b = []
    for (var c = 0; c < a.length; c++) b.push({
      label: a[c].name,
      value: a[c]._id
    })
    return b
  }
  handleInput = (e) => {
    this.setState({[e.target.name]: e.target.value})
  }
  deepLink = (e) => {
    const [option] = e.target.selectedOptions
    this.setState({associate_deals_url:option.dataset.link})
  }
  handleFormSubmit = (e) => {
    e.preventDefault()
    if (this.validator.allValid()) {
      const formData = new FormData()
      var cover_image = document.getElementById("cover_image").files[0];
      if (cover_image){
        formData.append('cover_image', this.state.cover_image)
      }
      formData.append('title', this.state.title)
      formData.append('blog_creator', this.state.blog_creator)
      formData.append('blog_content', this.state.blog_content)
      if (this.state.is_deal_associated === true){
        formData.append('associate_deals_url', this.state.associate_deals_url)
        formData.append('deal_id', this.state.deal_id)
      }
      formData.append('is_deal_associated', this.state.is_deal_associated)
      formData.append('status', this.state.status)
      formData.append('recommended', this.state.recommended)
      formData.append('show_on_main_page', this.state.show_on_main_page)
      formData.append("blog_category", this.state.category.map(({ value }) => value).join(","));
      formData.append('tags', this.state.defaultTags.map(({value}) => value).join(','))
      formData.append('meta_keywords', this.state.meta_keywords)
      formData.append('seo_title', this.state.seo_title)
      formData.append('seo_description', this.state.seo_description)
      // formData.append('hex_color', hex_color.toString())
      formData.append("location", this.state.location);
      formData.append('blogId', this.state.edit_id)
      formData.append("user_id", this.state.user_id);
      formData.append("totalLikes", this.state.totalLikes);
      // console.log(formData)
      this.setState({submit: true})
      axios({
        method: "post", url: `${api_url}/update-blog`, data: formData, headers: {
          "Authorization": 'Bearer ' + api_key,
        }
      }).then(response => {
        if (response.status) {
          this.setState({submit: false})
          toastr.success('Blog edit successful.')
          // eslint-disable-next-line react/prop-types
          const {history} = this.props
          // eslint-disable-next-line react/prop-types
          const pageno = this.props.match.params.pId; // Get the pageno param from the URL
          history.goBack(); // Use pageno in the history.push statement
  
        }
      }).catch(err => {
        toastr.error(err.message)
        this.setState({submit: false})
        alert(err.message)
      })
    } else {
      this.validator.showMessages()
      this.forceUpdate()
    }
  }

  handleEditorChange = e => {
    this.setState({blog_content: e.target.getContent() })
  }

  handleCreate = (inputValue) => {
    this.setState({customTag:inputValue})
    this.setState({isLoading: true,tagModal:true})
  };

  // Custom Tags ==================================
  handleCustomTag = (e) => {
    e.preventDefault();
    var data = JSON.stringify({
      "name": this.state.customTag,
      "interest": this.state.selected_category.interest,
      "ordering": 1,
      "status": true,
      "user_id": 0,
      "is_user_defined": false,
  });
      axios({
          method: "post", url: `${api_url}/add-tag`, data: data, headers: {
              'Content-Type': 'application/json',
              "Authorization": 'Bearer ' + api_key,
          }
      })
      .then(response=> {
          toastr.success('Tag added successful.')
          if (response.data.status && response.data?.data?.upserted[0]?._id !== undefined) {
            this.setState({tags: [...this.state.tags, {label:this.state.customTag, value:response.data?.data?.upserted[0]?._id}]});
            this.setState({defaultTags: [...this.state.defaultTags, {label:this.state.customTag, value:response.data?.data?.upserted[0]?._id}]});
            this.setState({selected_category: "",customTag:"",isLoading:false,tagModal:false})
          }
      })
      .catch(response => {
          toastr.error(response)
      });
  }
// Custom Tags end ================================

  render() {

    const { title, categories,meta_keywords,seo_title,seo_description, isLoading,active_deals, submit ,categories_interest} = this.state

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>
              Manage Scoops | {process.env.REACT_APP_PROJECTNAME}
            </title>
          </MetaTags>
          <Container fluid={true}>
            <Breadcrumbs title="Scoops" path={`/blogs/pageno/1`} breadcrumbItem="Edit Scoop"/>
            <Row>
              <Col xl="9">
                <Card>
                  <CardBody>
                    <form onSubmit={this.handleFormSubmit}>
                      <Row>
                        <Col md="12" className="mb-3">
                          <Label htmlFor="title">Title</Label>
                          <input name="title" placeholder="Type Scoop Title"
                                 defaultValue={title} type="text" className={"form-control"}
                                 onChange={this.handleInput}/>
                          {this.validator.message('title', this.state.title, 'required|string')}
                        </Col>
                        <Col md="12" className="mb-3">
                          <Label htmlFor="blog_creator">Scoop Creator</Label>
                          <select name="user_id" value={this.state.user_id} className={"form-control deeplink"} onChange={ e => {this.handleInput(e)}}>
                            <option value="" selected disabled>Select a user</option>
                            {this.state.users.map((val,key) =>{
                              return <option key={key} value={val.id}>{val.name}</option>
                            })}
                          </select>
                          {/*<input name="blog_creator" placeholder="Type Blog Creator"*/}
                          {/*       defaultValue={blog_creator} type="text" className={"form-control"}*/}
                          {/*       onChange={this.handleInput}/>*/}
                          {/*{this.validator.message('blog_creator', this.state.blog_creator, 'required|string')}*/}
                        </Col>
                        <Col md="12" className="mb-3">
                          <Label htmlFor="blog_content">Scoop Content</Label>
                          {/* <CKEditor
                              editor={ ClassicEditor }
                              data={this.state.blog_content}
                              onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                this.setState({blog_content:data})
                              } }
                          /> */}
                          <Editor
                            apiKey={process.env.REACT_APP_TINY_EDITOR}
                            initialValue={this.state.old_blog_content}
                            init={{
                                height: 600,
                                menubar: false,
                                config: {},
                                plugins: [
                                'advlist autolink lists link image preview anchor',
                                'searchreplace code fullscreen',
                                'media paste'
                                ],
                                toolbar:
                                `preview  fullscreen | undo redo| link image media mediaembed | formatselect | bold italic | \
                                    alignleft aligncenter alignright alignjustify | \
                                    bullist numlist outdent indent | removeformat | code`,
                                image_title: true,
                                extended_valid_elements : 'script[src|async|defer|type|charset]',
                                forced_root_block : 'p',
                                keep_styles: true,
                                inline_boundaries: true,
                                branding: false,
                                visual: true
                            }}
                            onChange={this.handleEditorChange}
                            />
                          {this.validator.message('blog_content', this.state.blog_content, 'required')}
                        </Col>
                        <Col md="12" className="mb-3">
                          <Label htmlFor="title">Meta Keywords</Label>
                          <input name="meta_keywords" placeholder="Type Meta Keywords"
                                 defaultValue={meta_keywords} type="text" className={"form-control"}
                                 onChange={this.handleInput}/>
                          {/*{this.validator.message('meta_keywords', this.state.meta_keywords, 'required|string')}*/}
                        </Col>
                        <Col md="12" className="mb-3">
                          <Label htmlFor="seo_title">SEO Title</Label>
                          <input name="seo_title" placeholder="Type SEO Title"
                                 defaultValue={seo_title} type="text" className={"form-control"}
                                 onChange={this.handleInput}/>
                          {/*{this.validator.message('seo_title', this.state.seo_title, 'required|string')}*/}
                        </Col>
                        <Col md="12" className="mb-3">
                          <Label htmlFor="seo_description">SEO Description</Label>
                          <textarea className="form-control" name="seo_description" id="textarea"
                                    defaultValue={seo_description} rows="5"
                                    placeholder="This textarea has a limit of 225 chars."
                                    onChange={this.handleInput}></textarea>
                          {/*{this.validator.message('seo_description', this.state.seo_description, 'required')}*/}
                        </Col>

                        <Col md="3" className="mb-3">
                          <Label htmlFor="cover_image">
                            Cover Image
                          </Label>
                          <input
                            id="cover_image"
                            name="cover_image"
                            placeholder="Cover Image"
                            type="file"
                            accept="image/*"
                            onChange={this.handleFileChange}
                            className={"form-control"}
                          />
                          {/*{this.validator.message('cover_image', this.state.cover_image, '')}*/}
                        </Col>
                        {/*<Col md="3" className="mb-3">*/}
                        {/*  <Label htmlFor="blog_creator_image">*/}
                        {/*    Blog Creator Image*/}
                        {/*  </Label>*/}
                        {/*  <input*/}
                        {/*    id="blog_creator_image"*/}
                        {/*    name="blog_creator_image"*/}
                        {/*    placeholder="Blog Creator Image"*/}
                        {/*    type="file"*/}
                        {/*    accept="image/*"*/}
                        {/*    onChange={this.handleBlogCreatorFileChange}*/}
                        {/*    className={"form-control"}*/}
                        {/*  />*/}
                          {/*{this.validator.message('cover_image', this.state.cover_image, '')}*/}
                        {/*</Col>*/}
                            <Col md="auto" className="mb-3">
                              <Label htmlFor="">Deal Associated</Label>
                              <div className="square-switch mt-2">
                                <input type="checkbox" id="square-switch2" defaultValue={1}
                                       switch="none" name="is_deal_associated"
                                       checked={this.state.is_deal_associated} onChange={() =>
                                    this.setState({is_deal_associated: !this.state.is_deal_associated})
                                }
                                />
                                <label
                                    htmlFor="square-switch2"
                                    data-on-label="On"
                                    data-off-label="Off"
                                />
                              </div>
                              {this.validator.message('is_deal_associated', this.state.is_deal_associated, 'required')}
                            </Col>
                        {this.state.is_deal_associated === true &&
                            <Col md="3" className="mb-3">
                              <Label htmlFor="associate_deals_url">
                                Deals
                              </Label>
                              <select name="deal_id" value={this.state.deal_id} className={"form-control deeplink"} onChange={e => {this.handleInput(e);this.deepLink(e)}}>
                                <option value="" selected disabled>Select a deal</option>
                                {active_deals.map((val,key) =>{
                                  return <option key={key} data-link={val.deep_link} value={val._id}>{val.name}</option>
                                })}
                              </select>
                              {/*{this.validator.message("deal_id", this.state.deal_id, "required")}*/}
                            </Col>
                        }
                        {/* <Col md="auto" className="mb-3">
                          <Label htmlFor="">Recommended</Label>
                          <div className="square-switch mt-2">
                            <input type="checkbox" id="square-recommended" switch="none"
                                   name="recommended" checked={this.state.recommended}
                                   onChange={() =>
                                     this.setState({recommended: !this.state.recommended})
                                   }
                            />
                            <label
                              htmlFor="square-recommended"
                              data-on-label="On"
                              data-off-label="Off"
                            />
                          </div>
                          {this.validator.message('recommended', this.state.recommended, 'boolean')}
                        </Col> */}
                        <Col md="auto" className="mb-3">
                          <Label htmlFor="">Show on main page</Label>
                          <div className="square-switch mt-2">
                            <input type="checkbox" id="square-show_on_main_page"
                                   switch="none" name="show_on_main_page"
                                   checked={this.state.show_on_main_page} onChange={() =>
                              this.setState({show_on_main_page: !this.state.show_on_main_page})
                            }
                            />
                            <label
                              htmlFor="square-show_on_main_page"
                              data-on-label="On"
                              data-off-label="Off"
                            />
                          </div>
                          {this.validator.message('show_on_main_page', this.state.show_on_main_page, 'boolean')}
                        </Col>
                        <Col md="3" className="mb-3">
                          <label className="control-label">
                            Categories
                          </label>
                          <Select isMulti={true} name="categories" value={this.state.category} onChange={e => { this.handleCategories(e)}} options={categories} classNamePrefix="select2-selection"/>
                          {this.validator.message("category", this.state.category, "required")}
                        </Col>
                        <Col md="3" className="mb-3">
                          <label className="control-label">Tags</label>
                          <CreatableSelect
                                  isMulti={true}
                                  value={this.state.defaultTags}
                                  onChange={this.handleTags}
                                  options={this.state.tags}
                                  classNamePrefix="select2-selection"
                                  isClearable
                                  isDisabled={isLoading}
                                  isLoading={isLoading}
                                  onCreateOption={this.handleCreate}
                              />
                          {/*{this.validator.message('tags', this.state.selectedTags, 'required')}*/}
                        </Col>
                        <Col md={3} className="mb-3">
                          <label className="control-label">Location</label>
                          <AsyncSelect defaultOptions name="form-field-name" value={this.state.selectedlocation}
                                       cacheOptions placeholder="Search location" loadOptions={this.getOptions} getOptionLabel={e => e.name} getOptionValue={e => e._id} onInputChange={this.handleInputChange} onChange={this.selectLocation}/>
                        </Col>
                        <Col md="3" className="mb-3">
                                                <Label htmlFor="title">Static likes</Label>
                                                <input name="totalLikes" placeholder="Type Static Likes" type="number" className={"form-control"}
                                                    value={this.state.totalLikes}
                                                    onChange={this.handleInput} />
                                                {this.validator.message("title", this.state.totalLikes, "required|number")}
                                                </Col>
                        <Col md="3" className="mb-3">
                          <Label htmlFor="">Status</Label>
                          <div className="square-switch mt-2">
                            <input
                              type="checkbox"
                              id="square-switch-status"
                              switch="none"
                              name="status"
                              checked={this.state.status}
                              onChange={() =>
                                this.setState({status: !this.state.status})
                              }
                            />
                            <label
                              htmlFor="square-switch-status"
                              data-on-label="On"
                              data-off-label="Off"
                            />
                          </div>
                        </Col>
                      </Row>

                      <div className="d-flex flex-wrap gap-2 justify-content-end">
                        <Button color="primary" type="submit" disabled={submit}>
                          {submit === true ? 'Please wait...' : 'Submit Data'}
                        </Button>
                      </div>
                    </form>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="3">
                <Card>
                  <CardBody>
                    <h6>Blog Cover Image</h6>
                    <img src={this.state.cover_image} className="img-fluid" style={mystyle} alt="Cover image"/>
                    <hr/>
                    <h6>Blog Creator Image</h6>
                    <img src={this.state.blog_creator_image} className="img-fluid" style={mystyle} alt="Blog Creator image"/>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Modal
            isOpen={this.state.tagModal}
            centered
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0">Create Tag</h5>
                <button
                    type="button"
                    onClick={() => {
                      this.setState({tagModal:false,isLoading:false})
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <form onSubmit={this.handleCustomTag}>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label>Categories</label>
                      <Select isMulti={false} onChange={e => { this.setState({selected_category : e})}} options={categories_interest} classNamePrefix="select2-selection" required/>
                      {this.state.selected_category == '' && <div className="srv-validation-message">The category field is required.</div>}
                    </div>
                    <div className="col-md-6 mb-3">
                      <label>Tag Name</label>
                      <input className="form-control" defaultValue={this.state.customTag} placeholder="Enter Tag Name" onInput={(e) => this.setState({customTag : e.target.value})} required/>
                    </div>
                  </div>
                  <button type="submit" className="btn w-100 btn-primary">Submit</button>
                </form>
            </div>
        </Modal>
      </React.Fragment>
    )
  }
}

BlogEdit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }),
  location: PropTypes.object
}

export default BlogEdit
