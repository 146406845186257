import React, {useEffect, useState} from "react"
import { useHistory } from 'react-router-dom';
import {Card, CardBody, Modal, Col, Row} from "reactstrap"
import MetaTags from 'react-meta-tags'
import Pagination from 'components/Common/Pagination'
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {TableHeader, Search} from "../Datatable/index"
import axios from "axios"
import notification from "../../components/Common/Notification"
import {apiKey} from "../../helpers/url_helper"
import Moment from 'moment';
import PropTypes from "prop-types"
import {useParams} from "react-router-dom/cjs/react-router-dom"
let api_url = process.env.REACT_APP_API_URL
let api_key = apiKey()
let PageSize = 15
const useFullPageLoader = () => {
    const [loading, setLoading] = useState(false)
    return [
        loading ? 'Loading' : null,
        () => setLoading(true), //Show loader
        () => setLoading(false) //Hide Loader
    ]
}
const mystyle = {
    width: "200px",
    overflow: "hidden",
    maxHeight: "40px",
    textOverflow: "ellipsis",
    whiteSpace: "pre-line",
}
const SocialCommentList = (props) => {    
    const history = useHistory();
    const [tableData, settableData] = useState([])
    const [loader, showLoader, hideLoader] = useFullPageLoader()
    const [totalItems, setTotalItems] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [search, setSearch] = useState("")
    const [mediaModal, setMediaModal] = useState(false)
    const [userData, setUserData] = useState([])
    const [AboutModal, setAboutModal] = useState(false)
    const [AboutData, setAboutData] = useState("")
    const params = useParams();
    const Header = [
        {name: "#", field: "id", sortable: false},
        {name: "Comment", field: "comment", sortable: false},
        {name: "User Name", field: "", sortable: false},
        {name: "Created Date", field: "created_date", sortable: false},
        {name: "Status", field: "status", sortable: false},
    ]


    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }
    function formatDate(date){
        return Moment(date).format('DD-MM-YYYY, h:mm:ss A')
    }

    useEffect(() => {
        let id = params['id']
        showLoader()
        let query = ''
        search && (query = `keyword=${search}&`)
        const url = `${api_url}/get-social-comment-list?${query}limit=${PageSize}&pageNo=${currentPage}&socialId=${id}`
        axios.get(url, {
            headers: {
                "Authorization": 'Bearer ' + api_key,
            }
        })
            .then(res => {
                hideLoader()
                if (res.status) {
                    settableData(res.data.data)
                    setTotalItems(res.data.totalRecords)
                }
            }).catch(
                err => {
                    notification(err, 'error', 'toast-bottom-right')
                    if(err.response.status===401 || err.response.status===400 ){
                        history.push('/logout')
                    }
                }
            );

    }, [currentPage, search])  // pass `value` as a dependency

    function tog_center() {
        removeBodyCss()
    }

    function UserDetail(prop) {
        setMediaModal(true)
        setUserData(prop)
    }

    function ReadAbout(prop) {
        setAboutModal(true)
        setAboutData(prop)
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Social Comment List</title>
                </MetaTags>
                <div className="container-fluid">
                    <Breadcrumbs title="Social List" path="/social-list" breadcrumbItem="Social Comment List"/>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <div className="row mb-2">
                                        <div className="col-md-6">

                                        </div>
                                        <div className="col-md-6 d-flex flex-row-reverse">
                                            <Search
                                                onSearch={value => {
                                                    setSearch(value)
                                                    setCurrentPage(1)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-striped">
                                            <TableHeader
                                                headers={Header}
                                            />
                                            <tbody style={{verticalAlign:'middle'}}>
                                            {loader && <tr><th colSpan={Header.length}><h4 className="text-center">Loading...</h4></th></tr>}
                                            {(!loader && tableData.length === 0) && <tr><th colSpan={Header.length}><h4 className="text-center">No data found</h4></th></tr>}
                                            {!loader && tableData.map((value, index) => (
                                                <tr key={++index}>
                                                    <th scope="row">
                                                        {PageSize * (currentPage - 1) + (index + 1)}
                                                    </th>
                                                    <td><div style={mystyle} className="text-truncate">{value.comment}</div>{value.comment.length > 40 && <a className="" onClick={() => ReadAbout(value.comment)}>Read more <i className="mdi mdi-arrow-right"></i></a>}
                                                    </td>
                                                    <th>{value.userdetails && <a className="" onClick={() => UserDetail(value.userdetails)}>{value.userdetails.name}</a>}</th>
                                                    <th>{value.entry_date && <div style={{minWidth:"100px"}}>{formatDate(value.entry_date)}</div>}</th>
                                                    <td>{value.status === true ? <span className="badge badge-pill badge-soft-success font-size-13">Active</span> :
                                                        <span className="badge badge-pill badge-soft-error font-size-13">Inactive</span>}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <Row className="justify-content-center">
                                        <Col className="col-auto">
                                            <Pagination
                                                className="pagination-bar"
                                                currentPage={currentPage}
                                                totalCount={totalItems}
                                                pageSize={PageSize}
                                                onPageChange={page => setCurrentPage(page)}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <Modal
                    size="sm"
                    isOpen={mediaModal}
                    toggle={() => {
                        tog_center()
                    }}
                    centered
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">User Detail</h5>
                        <button
                            type="button"
                            onClick={() => {
                                setMediaModal(false)
                                setUserData([])
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <dl className="row mb-0">
                            <dt className="col-4">Name</dt>
                            <dd className="col-8">{userData.name}</dd>
                            <dt className="col-4">Mobile</dt>
                            <dd className="col-8">{userData.mobile}</dd>
                            <dt className="col-4">Email</dt>
                            <dd className="col-8">{userData.email}</dd>
                            <dt className="col-4">Designation</dt>
                            <dd className="col-8">{userData.designation}</dd>
                            <dt className="col-4">Bio</dt>
                            <dd className="col-8">{userData.bio}</dd>
                        </dl>
                    </div>
                </Modal>
                <Modal
                    size="lg"
                    isOpen={AboutModal}
                    toggle={() => {
                        tog_center()
                    }}
                    centered
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">Comment</h5>
                        <button
                            type="button"
                            onClick={() => {
                                setAboutModal(false)
                                setAboutData("")
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {AboutData}
                    </div>
                </Modal>
            </div>
        </React.Fragment>
    )
}
SocialCommentList.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string
        })
    })
};
export default SocialCommentList