import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Media
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import {apiKey} from "../../helpers/url_helper"
import toastr from "toastr"
import notification from "../../components/Common/Notification"
import {Link} from "react-router-dom"
import Pagination from "../../components/Common/Pagination"
let api_url = process.env.REACT_APP_API_URL
let api_key = apiKey()
let PageSize = 15;
class Faqs extends Component {
    constructor(props) {
        super(props)
        this.state = {
            search:"",
            totalRecords:0,
            data:[],
            current_page:1
        }
    }

    componentDidMount() {
        this.getFaq()
    }
    getFaq = () => {
        let query = ''
        this.state.search && (query = `keyword=${this.state.search}&`)
        const url = `${api_url}/get-faqs?${query}limit=${PageSize}&pageNo=${this.state.current_page}`
        axios.get(url, {
            headers: {
                "Authorization": 'Bearer ' + api_key,
            }
        })
            .then(res => {
                if (res.status) {
                    this.setState({data:res.data.data,totalRecords:res.data.totalRecords})
                }
            }).catch(
                err => {
                    notification(err, 'error', 'toast-bottom-right')
                    if(err.response.status===401 || err.response.status===400 ){
                        const {history} = this.props;
                        history.push('/logout')
                    }
                }
            );
    }

    handelSearch = (e) => {
        this.setState({search:e.target.value,current_page:1},this.getFaq)
    }
    setCurrentPage = (page) => {
        this.setState({current_page:page},this.getFaq)
    }
    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>FAQs</title>
                    </MetaTags>
                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title="Dashboard" path="/" breadcrumbItem="Faqs"/>

                        <div className="checkout-tabs">
                            <Card>
                                <CardBody>
                                    <Row className="justify-content-end">
                                        <Col md={"auto"}>
                                            <input
                                                type="text"
                                                className="form-control"
                                                style={{ width: "240px" }}
                                                placeholder="Search"
                                                value={this.state.search}
                                                onChange={e => this.handelSearch(e)}
                                            />
                                        </Col>
                                        <Col md={"auto"}>
                                            <Link to={"/faqs/add"} className="btn btn-primary waves-effect waves-light"><i className="bx bx-plus font-size-16 align-middle me-2"></i> Add</Link>
                                        </Col>
                                    </Row>
                                    <hr/>
                                    {this.state.data.length === 0 && <h4 className="text-center">No data found</h4> }
                                    {this.state.data.map((item, idx) => (
                                        <Media className="faq-box mb-4" key={idx}>
                                            <div className="faq-icon me-3">
                                                <i className="bx bx-help-circle font-size-20 text-success" />
                                            </div>
                                            <Media body>
                                                <Row className="justify-content-between">
                                                    <Col md={10}><h5 className="font-size-15">{item.title}</h5></Col>
                                                    <Col md={"auto"}><Link to={`/faqs/edit/${item._id}`}><i className="bx bxs-pencil"></i></Link></Col>
                                                </Row>
                                                <p className="text-muted">{item.body}</p>
                                            </Media>
                                        </Media>
                                    ))}
                                    <Row className="justify-content-center">
                                        <Col className="col-auto">
                                            <Pagination
                                                className="pagination-bar"
                                                currentPage={this.state.current_page}
                                                totalCount={this.state.totalRecords}
                                                pageSize={PageSize}
                                                onPageChange={page => this.setCurrentPage(page)}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </div>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default Faqs
