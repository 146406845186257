import React from "react"
import MetaTags from "react-meta-tags"
import {Button, Card, CardBody, Col, Container, Label, Row,} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {apiKey} from "../../../helpers/url_helper"
import SimpleReactValidator from 'simple-react-validator'
import axios from "axios"
import toastr from "toastr"
import PropTypes from "prop-types"

let api_url = process.env.REACT_APP_API_URL
let api_key = apiKey()

class DealPartnersAdd extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            status: true,
            partner_name: "",
            // image: "",
            // logo: "",
            // city: "",
            // about: "",
            ordering: "",
            handleResponse: null,
            submit: false,
        }
        this.validator = new SimpleReactValidator({autoForceUpdate: this})
    }
    handleInput = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }
    handleFileChange = (event) => {
        this.setState({
            image: event.target.files[0]
        })
    }
    handleLogoFileChange = (event) => {
        this.setState({
            logo: event.target.files[0]
        })
    }

    handleFormSubmit = (e) => {
        e.preventDefault()
        if (this.validator.allValid()) {
            const formData = new FormData()
            formData.append('name', this.state.partner_name)
            // var image = document.getElementById("image").files[0];
            // if (image){
            //     formData.append('image', this.state.image)
            // }
            // var logo = document.getElementById("logo").files[0];
            // if (logo){
            //     formData.append('logo', this.state.logo)
            // }
            // formData.append('city', this.state.city)
            // formData.append('about', this.state.about)
            formData.append('status', this.state.status)
            formData.append('ordering', this.state.ordering)
            this.setState({submit: true})
            axios({
                method: "post", url: `${api_url}/add-deal-partner`, data: formData, headers: {
                    'Content-Type': 'application/json',
                    "Authorization": 'Bearer ' + api_key,
                }
            }).then(response => {
                if (response.status) {
                    this.setState({submit: false})
                    toastr.success('Deal Partner added successful.')
                    // eslint-disable-next-line react/prop-types
                    const {history} = this.props
                    // eslint-disable-next-line react/prop-types
                    history.push('/deals/partners/pageno/1');

                }
            }).catch(err => {
                toastr.error(err.message)
                this.setState({submit: false})
                alert(err.message)
            })
        } else {
            this.validator.showMessages()
            this.forceUpdate()
        }
    }

    render() {

        const {partner_name, ordering,submit} = this.state
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>
                            Deal Partners | {process.env.REACT_APP_PROJECTNAME}
                        </title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Deal Partners" path="/deals/partners/pageno/1" breadcrumbItem="Add Deal Partner"/>
                        <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                        <form onSubmit={this.handleFormSubmit}>
                                            <Row>
                                                <Col md="6" className="mb-3">
                                                    <Label htmlFor="partner_name">Partner Name</Label>
                                                    <input name="partner_name" placeholder="Type Partner Name" defaultValue={partner_name} type="text" className={"form-control"} onChange={this.handleInput}/>
                                                    {this.validator.message('partner_name', this.state.partner_name, 'required')}
                                                </Col>
                                                {/* <Col md="3" className="mb-3">
                                                    <Label htmlFor="image">Image</Label>
                                                    <input
                                                        id="image"
                                                        name="image"
                                                        placeholder="Image"
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={this.handleFileChange}
                                                        className={"form-control"}
                                                    />
                                                    {this.validator.message('image', this.state.image, 'required')}
                                                </Col>
                                                <Col md="3" className="mb-3">
                                                    <Label htmlFor="logo">Logo</Label>
                                                    <input
                                                      id="logo"
                                                      name="logo"
                                                      placeholder="Logo"
                                                      type="file"
                                                      accept="image/*"
                                                      onChange={this.handleLogoFileChange}
                                                      className={"form-control"}
                                                    />
                                                    {this.validator.message('logo', this.state.logo, 'required')}
                                                </Col>
                                                <Col md="3" className="mb-3">
                                                    <Label htmlFor="city">City</Label>
                                                    <input
                                                        name="city"
                                                        type="text"
                                                        placeholder="City"
                                                        className={"form-control"}
                                                        onChange={this.handleInput}
                                                    />
                                                    {this.validator.message('city', this.state.city, 'required')}
                                                </Col>
                                                <Col md="8" className="mb-3">
                                                    <Label htmlFor="about">About</Label>
                                                    <textarea
                                                        name="about"
                                                        type="text"
                                                        placeholder="Enter About"
                                                        className={"form-control"}
                                                        onChange={this.handleInput}
                                                    ></textarea>
                                                    {this.validator.message('about', this.state.about, 'required')}
                                                </Col> */}
                                                <Col md="2" className="mb-3">
                                                    <Label htmlFor="ordering">Order No.</Label>
                                                    <input
                                                        name="ordering"
                                                        type="number"
                                                        placeholder="Order No."
                                                        className={"form-control"}
                                                        onChange={this.handleInput}
                                                        defaultValue={ordering}
                                                    />
                                                    {this.validator.message('ordering', this.state.ordering, 'required|numeric')}
                                                </Col>
                                                <Col md="auto" className="mb-3">
                                                    <Label htmlFor="">Status</Label>
                                                    <div className="square-switch mt-2">
                                                        <input
                                                            type="checkbox"
                                                            id="square-switch-status"
                                                            switch="none"
                                                            name="status"
                                                            checked={this.state.status}
                                                            onChange={() =>
                                                                this.setState({status: !this.state.status})
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="square-switch-status"
                                                            data-on-label="On"
                                                            data-off-label="Off"
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>

                                            <div className="d-flex flex-wrap gap-2 justify-content-end">
                                                <Button color="primary" type="submit" disabled={submit}>
                                                    {submit === true ? 'Please wait...' : 'Submit Data'}
                                                </Button>
                                            </div>
                                        </form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

DealPartnersAdd.propTypes = {
    location: PropTypes.object
}

export default DealPartnersAdd
