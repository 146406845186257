import React from 'react'
// import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'
import PropTypes from "prop-types"

class LocationSearchInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = { address: this.props.address }
    }
    handleChange = (address) => {
        this.setState({ address })
    }

    // When the user selects an autocomplete suggestion...
    handleSelect = (address) => {
        // Pull in the setFormLocation function from the parent ReportForm
        // eslint-disable-next-line react/prop-types
        const setFormLocation = this.props.setFormLocation
        this.setState({address})
        geocodeByAddress(address)
            .then(function(results){
                setFormLocation(results)
            })
            .catch(error => console.error('Error', error))
    }

    render() {
        const renderInput = ({ getInputProps, getSuggestionItemProps, suggestions }) => (
            <div className="autocomplete-root">
                <input className="form-control" {...getInputProps()} />
                <ul className="autocomplete-dropdown-container list-unstyled">
                    {suggestions.map((suggestion,key) => (
                        <li key={key} {...getSuggestionItemProps(suggestion)} className="suggestion">
                            <span>{suggestion.description}</span>
                        </li>
                    ))}
                </ul>
            </div>
        );

        // Limit the suggestions to show only cities in the US
        const searchOptions = {
            // types: ['(cities)'],
            componentRestrictions: {country: "in"}
        }

        return (
            <PlacesAutocomplete
                value={this.state.address}
                onChange={this.handleChange}
                onSelect={this.handleSelect}
                // Pass the search options prop
                searchOptions={searchOptions}
            >
                {renderInput}
            </PlacesAutocomplete>
        );
    }
}
LocationSearchInput.propTypes = {
    setFormLocation: PropTypes.any,
    address: PropTypes.string,
}
export default LocationSearchInput