import React from "react"
import MetaTags from "react-meta-tags"
import {Button, Card, CardBody, Col, Container, Label, Row,} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {apiKey} from "../../../helpers/url_helper"
import SimpleReactValidator from 'simple-react-validator'
import axios from "axios"
import toastr from "toastr"
import PropTypes from "prop-types"

let api_url = process.env.REACT_APP_API_URL
let api_key = apiKey()
const mystyle = {
    height: "110px",
    overflow: "hidden",
    width: "auto",
    objectFit:"cover"
}
const headers = {'Content-Type': 'application/json', "Authorization": 'Bearer ' + api_key}
class DealPartnersEdit extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            status: true,
            partner_name: "",
            // image: "",
            // logo:"",
            // old_image: "",
            // old_logo: "",
            // city: "",
            // about: "",
            ordering: "",
            handleResponse: null,
            submit: false,
        }
        this.validator = new SimpleReactValidator({autoForceUpdate: this})
    }

    componentDidMount() {
        const id = this.props.match.params.id
        this.setState({edit_id: id})
        const params = {type: "deals_partner", id: id}
        axios.get(`${api_url}/get-single-data`, {
            params, headers
        })
            .then(res => {
                if (res.data.status) {
                    let edit = res.data.data
                    this.setState({
                        partner_name: edit['name'],
                        // old_image: edit['image'],
                        // old_logo: edit['logo'],
                        // city: edit['city'],
                        // about: edit['about'],
                        ordering: edit['ordering'],
                        status: edit['status']
                    })
                } else {
                    toastr.danger('Server not response')
                }
            })
    }

    handleInput = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }
    handleFileChange = (event) => {
        this.setState({
            image: event.target.files[0]
        })
    }
    handleFileLogoChange = (event) => {
        this.setState({
            logo: event.target.files[0]
        })
    }

    handleFormSubmit = (e) => {
        e.preventDefault()
        if (this.validator.allValid()) {
            const formData = new FormData()
            formData.append('name', this.state.partner_name)
            // var image = document.getElementById("image").files[0];
            // if (image){
            //     formData.append('image', this.state.image)
            // }
            // var logo = document.getElementById("logo").files[0];
            // if (logo){
            //     formData.append('logo', this.state.logo)
            // }
            // console.log(this.state.status);
            // formData.append('city', this.state.city)
            // formData.append('about', this.state.about)
            formData.append('status', this.state.status)
            formData.append('ordering', this.state.ordering)
            formData.append('pDealId',  this.state.edit_id)
            this.setState({submit: true})
            axios({
                method: "post", url: `${api_url}/update-deal-partner`, data: formData, headers: {
                    'Content-Type': 'application/json',
                    "Authorization": 'Bearer ' + api_key,
                }
            }).then(response => {
                if (response.status) {
                    this.setState({submit: false})
                    this.setState({submit: false})
                    toastr.success('Deal Partner update successful.')
                    // eslint-disable-next-line react/prop-types
                    const {history} = this.props
                    // eslint-disable-next-line react/prop-types
                    const pageno = this.props.match.params.pId; // Get the pageno param from the URL
                    history.goBack();
                }
            }).catch(err => {
                toastr.error(err.message)
                this.setState({submit: false})
                alert(err.message)
            })
        } else {
            this.validator.showMessages()
            this.forceUpdate()
        }
    }

    render() {

        const {partner_name, city, about, ordering,submit} = this.state
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>
                            Deal Partners | {process.env.REACT_APP_PROJECTNAME}
                        </title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Deal Partners" path="/deals/partners/pageno/1" breadcrumbItem="Edit Deal Partner"/>
                        <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                        <form onSubmit={this.handleFormSubmit}>
                                            <Row>
                                                <Col md="4" className="mb-3">
                                                    <Label htmlFor="partner_name">Partner Name</Label>
                                                    <input name="partner_name" placeholder="Type Partner Name" defaultValue={partner_name} type="text" className={"form-control"} onChange={this.handleInput}/>
                                                    {this.validator.message('partner_name', this.state.partner_name, 'required')}
                                                </Col>
                                                {/* <Col md="4" className="mb-3">
                                                    <Label htmlFor="image">Image</Label>
                                                    <input
                                                        id="image"
                                                        name="image"
                                                        placeholder="Image"
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={this.handleFileChange}
                                                        className={"form-control"}
                                                    />
                                                </Col>
                                                <Col md="4" className="mb-3">
                                                    <Label htmlFor="image">Logo</Label>
                                                    <input
                                                      id="logo"
                                                      name="logo"
                                                      placeholder="Logo"
                                                      type="file"
                                                      accept="image/*"
                                                      onChange={this.handleFileLogoChange}
                                                      className={"form-control"}
                                                    />
                                                </Col>
                                                <Col md="4" className="mb-3">
                                                    <Label htmlFor="city">City</Label>
                                                    <input
                                                        name="city"
                                                        type="text"
                                                        defaultValue={city}
                                                        placeholder="City"
                                                        className={"form-control"}
                                                        onChange={this.handleInput}
                                                    />
                                                    {this.validator.message('city', this.state.city, 'required')}
                                                </Col>
                                                <Col md="8" className="mb-3">
                                                    <Label htmlFor="about">About</Label>
                                                    <textarea
                                                        name="about"
                                                        type="text"
                                                        rows={5}
                                                        defaultValue={about}
                                                        placeholder="Enter About"
                                                        className={"form-control"}
                                                        onChange={this.handleInput}
                                                    ></textarea>
                                                    {this.validator.message('about', this.state.about, 'required')}
                                                </Col> */}
                                                <Col md="2" className="mb-3">
                                                    <Label htmlFor="ordering">Order No.</Label>
                                                    <input
                                                        name="ordering"
                                                        type="number"
                                                        placeholder="Order No."
                                                        className={"form-control"}
                                                        onChange={this.handleInput}
                                                        defaultValue={ordering}
                                                    />
                                                    {this.validator.message('ordering', this.state.ordering, 'required|numeric')}
                                                </Col>
                                                <Col md="auto" className="mb-3">
                                                    <Label htmlFor="">Status</Label>
                                                    <div className="square-switch mt-2">
                                                        <input
                                                            type="checkbox"
                                                            id="square-switch-status"
                                                            switch="none"
                                                            name="status"
                                                            checked={this.state.status}
                                                            onChange={() =>
                                                                this.setState({status: !this.state.status})
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="square-switch-status"
                                                            data-on-label="On"
                                                            data-off-label="Off"
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>

                                            <div className="d-flex flex-wrap gap-2 justify-content-end">
                                                <Button color="primary" type="submit" disabled={submit}>
                                                    {submit === true ? 'Please wait...' : 'Submit Data'}
                                                </Button>
                                            </div>
                                        </form>
                                    </CardBody>
                                </Card>
                            </Col>
                            {/* <Col xl="2">
                                <Card>
                                    <CardBody>
                                        <h6>Old Image</h6>
                                        {!this.state.old_image && <h5 className="text-danger">No image</h5>}
                                        {this.state.old_image && <img src={this.state.old_image} className="img-fluid" style={mystyle} alt="image"/>}
                                    </CardBody>
                                    <CardBody>
                                        <h6>Old Logo</h6>
                                        {!this.state.old_image && <h5 className="text-danger">No image</h5>}
                                        {this.state.old_logo && <img src={this.state.old_logo} className="img-fluid" style={mystyle} alt="image"/>}
                                    </CardBody>
                                </Card>
                            </Col> */}
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

DealPartnersEdit.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string
        })
    }),
    location: PropTypes.object
}

export default DealPartnersEdit
