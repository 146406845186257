import React, {useEffect, useState} from "react"
import { useHistory } from 'react-router-dom';
import {Card, CardBody, Modal, Col, Row} from "reactstrap"
import MetaTags from 'react-meta-tags'
import Pagination from 'components/Common/Pagination'
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {TableHeader, Search} from "../Datatable/index"
import axios from "axios"
import notification from "../../components/Common/Notification"
import {apiKey} from "../../helpers/url_helper"
import {Link} from "react-router-dom"
let api_url = process.env.REACT_APP_API_URL
let api_key = apiKey()
let PageSize = 15
const useFullPageLoader = () => {
    const [loading, setLoading] = useState(false)
    return [
        loading ? 'Loading' : null,
        () => setLoading(true), //Show loader
        () => setLoading(false) //Hide Loader
    ]
}

const CMSPage = (props) => {
    const history = useHistory();
    const [tableData, settableData] = useState([])
    const [loader, showLoader, hideLoader] = useFullPageLoader()
    const [totalItems, setTotalItems] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [search, setSearch] = useState("")
    const [AboutModal, setAboutModal] = useState(false)
    const [AboutData, setAboutData] = useState("")

    const Header = [
        {name: "#", field: "id", sortable: false},
        {name: "Page Title", field: "page_title", sortable: false},
        {name: "Description", field: "description", sortable: false},
        {name: "Status", field: "status", sortable: false},
        {name: "Action", field: "", sortable: false},
    ]


    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    useEffect(() => {
        showLoader()
        const url = `${api_url}/get-cms`
        axios.get(url, {
            headers: {
                "Authorization": 'Bearer ' + api_key,
            }
        })
            .then(res => {
                hideLoader()
                if (res.status) {
                    settableData(res.data.data)
                    setTotalItems(res.data.totalRecords)
                }
            }).catch(
                err => {
                    notification(err, 'error', 'toast-bottom-right')
                    if(err.response.status===401 || err.response.status===400 ){
                        history.push('/logout')
                    }
                }
            );

    }, [currentPage, search])  // pass `value` as a dependency

    function tog_center() {
        removeBodyCss()
    }

    function ReadAbout(prop) {
        setAboutModal(true)
        setAboutData(prop)
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>CMS Page</title>
                </MetaTags>
                <div className="container-fluid">
                    <Breadcrumbs title="Dashboard" path="/" breadcrumbItem="CMS Page"/>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <div className="row mb-2">
                                        <div className="col-md-6">

                                        </div>
                                        {/* <div className="col-md-6 d-flex flex-row-reverse">
                                            <a href="/cms-page/add" className="btn btn-primary waves-effect waves-light ms-2"><i className="bx bx-plus font-size-16 align-middle me-2"></i> Add</a>
                                            <Search
                                                onSearch={value => {
                                                    setSearch(value)
                                                    setCurrentPage(1)
                                                }}
                                            />
                                        </div> */}
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-striped">
                                            <TableHeader
                                                headers={Header}
                                            />
                                            <tbody style={{verticalAlign:'middle'}}>
                                            {loader && <tr><th colSpan={Header.length}><h4 className="text-center">Loading...</h4></th></tr>}
                                            {(!loader && tableData.length === 0) && <tr><th colSpan={Header.length}><h4 className="text-center">No data found</h4></th></tr>}
                                            {!loader && tableData.map((value, index) => (
                                                <tr key={++index}>
                                                    <th scope="row">
                                                        {PageSize * (currentPage - 1) + (index + 1)}
                                                    </th>
                                                    <th>{value.page_title}</th>
                                                    {/*<td><div style={mystyle} className="text-truncate">{value.description.substring(0, 250)}</div>*/}
                                                    <td><div dangerouslySetInnerHTML={{ __html: value.description.substring(0, 250) }} />
                                                        <a className="" onClick={() => ReadAbout(value.description)}>Read
                                                            more <i
                                                                className="mdi mdi-arrow-right"></i></a></td>
                                                    <td>{value.status === true ? <span className="badge badge-pill badge-soft-success font-size-13">Active</span> :
                                                        <span className="badge badge-pill badge-soft-error font-size-13">Inactive</span>}</td>
                                                    <td><Link to={`/cms-page/edit/${value._id}`}><i className="bx bxs-edit-alt"></i> </Link></td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <Row className="justify-content-center">
                                        <Col className="col-auto">
                                            <Pagination
                                                className="pagination-bar"
                                                currentPage={currentPage}
                                                totalCount={totalItems}
                                                pageSize={PageSize}
                                                onPageChange={page => setCurrentPage(page)}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <Modal
                    size="lg"
                    isOpen={AboutModal}
                    toggle={() => {
                        tog_center()
                    }}
                    centered
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">Description</h5>
                        <button
                            type="button"
                            onClick={() => {
                                setAboutModal(false)
                                setAboutData("")
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div dangerouslySetInnerHTML={{ __html: AboutData }} />
                    </div>
                </Modal>
            </div>
        </React.Fragment>
    )
}

export default CMSPage