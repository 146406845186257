import React, {useEffect, useMemo, useState} from "react";
import { useParams, useHistory, useLocation } from 'react-router-dom';
import {Card, CardBody, Modal, Col, Row} from "reactstrap"
import MetaTags from 'react-meta-tags'
import Pagination from 'components/Common/Pagination';
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {TableHeader, Search} from "../Datatable/index";
import {apiKey} from "helpers/url_helper"
import {Link} from "react-router-dom";
import axios from 'axios';
import notification from "../../components/Common/Notification"
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";
import {Image} from "react-bootstrap"
let api_url = process.env.REACT_APP_API_URL;
let api_key = apiKey()
let exportURL = api_url + "/get-episodes-csv"

const useFullPageLoader = () => {
    const [loading, setLoading] = useState(false);

    return [
        loading ? 'Loading' : null,
        () => setLoading(true), //Show loader
        () => setLoading(false) //Hide Loader
    ];
};
const mystyle = {
    width: "200px",
    overflow: "hidden",
    maxHeight: "40px",
    textOverflow: "ellipsis",
    whiteSpace: "pre-line",
}
let PageSize = 15;
const OriginalSeasons = (props) => {
    const history = useHistory();
    const location = useLocation();
    const { pId:pageno } = useParams();
    const [tableData, settableData] = useState([]);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(() => {
        const paramPage = parseInt(pageno);
        if (!isNaN(paramPage)) {
            return paramPage;
          } else {
            return 1;
          }
      });
      const { searchQuery } = useParams();
      const [search, setSearch] = useState(() => {
          if (searchQuery) {
              return searchQuery;
            } else {
              return '';
            }
        });
      const [getSearch, setGetSearch] = useState(search);
      const [getSearchPage, setGetSearchPage] = useState(currentPage)
      const [isFits, setisFits] = useState(false);
    const [main_img, setMainImg] = useState("");
    const [isOpen, setisOpen] = useState(false);
    const [videoId, setVideoId] = useState("");
    const [bodyModal, setbodyModal] = useState(false)
    const [BodyData, setBodyData] = useState("")
    const [SeasonModal, setSeasonModal] = useState(false)
    const [SeasonData, setSeasonData] = useState("")

    const CountryHeader = [
        {name: "#", field: "id", sortable: false},
        {name: "Original Name", field: "episode_name", sortable: false},
        {name: "Episode No.", field: "episode_no", sortable: false},
        {name: "Episode Name", field: "episode_name", sortable: false},
        {name: "Season", field: "original_season", sortable: false},
        {name: "Horizontal Image", field: "cover_image", sortable: false},
        {name: "Vertical Image", field: "vertical_image", sortable: false},
        {name: "About", field: "about", sortable: false},
        {name: "Video URL", field: "video_url", sortable: false},
        {name: "Ordering", field: "ordering", sortable: false},
        {name: "Highlight episode", field: "highlight_episode", sortable: false},
        {name: "Status", field: "status", sortable: false},
        {name: "Action", field: "", sortable: false}
    ];

    const handleVideo = (url) => {
        var regExp = /https:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;
        var match = url.match(regExp);
        if (match){
            setisOpen(true);
            setVideoId(match[2])
        }else {
            notification('Video url not correct','error','toast-bottom-right')
        }
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }
    function handleSearch(){
        setSearch(getSearch);
        setCurrentPage(getSearchPage);
        if(getSearch){
            history.push(`/episodes/pageno/${getSearchPage}/${getSearch}`)
        }
    }
    // Handle any changes to the currentPage state
    const handlePageChange = () => {
        setCurrentPage(() => {
            const paramPage = parseInt(pageno);
            if (!isNaN(paramPage)) {
                return paramPage;
              } else {
                return 1;
              }
          })
    };
    useEffect(() => {
        handlePageChange();
    }, [pageno,location]);

    useEffect(() => {
        showLoader()
        let query = ''
        search && ( query = `keyword=${search}`)
        const url = `${api_url}/get-episodes?${query}&limit=${PageSize}&pageNo=${currentPage}`
        axios.get(url,{
            headers: {
                "Authorization": 'Bearer '+api_key,
            }
        })
            .then(res => {
                hideLoader();
                if(res.status) {
                    settableData(res.data.data);
                    setTotalItems(res.data.totalRecords);
                }
            }).catch(
                err => {
                    notification(err, 'error', 'toast-bottom-right')
                    if(err.response.status===401 || err.response.status===400 ){
                        history.push('/logout')
                    }
                }
            );
    }, [currentPage,search])  // pass `value` as a dependency

    function tog_center() {
        removeBodyCss()
    }

    function ReadMore(prop) {
        setbodyModal(true)
        setBodyData(prop)
    }
    function OpenSeason(prop) {
        if (prop.length){
            setSeasonModal(true)
            setSeasonData(prop[0])
        }else {
            notification('Data empty','error','toast-bottom-right')
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Manage Originals Episode</title>
                </MetaTags>
                <div className="container-fluid">
                    <Breadcrumbs title="Dashboard" path="/" breadcrumbItem="Originals Episode"/>
                    {isFits ? (
                        <Lightbox
                            mainSrc={main_img}
                            enableZoom={false}
                            onCloseRequest={() => {
                                setisFits(!isFits);
                            }}
                        />
                    ) : null}
                    <ModalVideo
                        videoId={videoId}
                        channel="vimeo"
                        isOpen={isOpen}
                        onClose={() => {
                            setisOpen(false);
                        }}
                    />
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <div className="d-flex justify-content-between mb-3 gap-3">
                                        <div>
                                            <a href={exportURL}
                                                target="_blank" rel="noreferrer"
                                               className="btn btn-primary waves-effect waves-light"><i
                                                className="bx bx-export font-size-16 align-middle me-2"></i> Export</a>
                                        </div>
                                        <div>
                                            <a href="/episodes/add"
                                               className="btn btn-primary waves-effect waves-light"><i
                                                className="bx bx-plus font-size-16 align-middle me-2"></i> Add</a>
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-md-12 d-flex flex-row-reverse">
                                        <div onClick={handleSearch}
                                               className="btn btn-primary waves-effect waves-light ms-2"> Search</div>
                                            <Search
                                                onSearch={value => {
                                                    setGetSearch(value)
                                                    setGetSearchPage(1)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    
                                    <div className="table-responsive">
                                    <table className="table table-striped">
                                        <TableHeader
                                            headers={CountryHeader}
                                        />
                                        <tbody className="">
                                        {loader && <tr><th colSpan={CountryHeader.length}><h4 className="text-center">Loading...</h4></th></tr>}
                                        {tableData.map((value,index) => (
                                            <tr key={++index}>
                                                <th scope="row">
                                                    {PageSize * (currentPage - 1) + (index + 1)}
                                                </th>
                                                <td>{value.original_season_details[0]!== undefined && value.original_season_details.map((val,key) => {
                                                    if(val['original_details'][0]!==undefined && val['original_details'][0]['name']){
                                                        return (
                                                            <span key={key}>{val['original_details'][0]['name']}</span>
                                                        )
                                                    }
                                                })

                                                }</td>
                                                <td>{value.episode_no !== null && value.episode_no}</td>
                                                <td>{value.episode_name}</td>
                                                <td><a onClick={() => OpenSeason(value.original_season_details)} className="text-decoration-underline">{(value.original_season_details[0]!==undefined)?value.original_season_details[0].searson_name:''}</a></td>
                                                <td>
                                                    {value.cover_image &&
                                                        <img
                                                            onClick={() => {
                                                                setisFits(true);
                                                                setMainImg(value.cover_image)
                                                            }}
                                                            className="img-thumbnail avatar-sm"
                                                            alt={value.episode_name}
                                                            src={value.cover_image}
                                                            width="200"
                                                        />
                                                    }
                                                </td>
                                                <td>
                                                    {value.vertical_image !== undefined &&
                                                        <img
                                                            onClick={() => {
                                                                setisFits(true);
                                                                setMainImg(value.vertical_image)
                                                            }}
                                                            className="img-thumbnail avatar-sm"
                                                            alt={value.episode_name}
                                                            src={value.vertical_image}
                                                            width="200"
                                                        />
                                                    }
                                                </td>
                                                <td><div style={mystyle}
                                                         className="text-truncate">{value.about}</div>
                                                    {/*<a className="" onClick={() => ReadMore(value.about)}>Read*/}
                                                    {/*    more <i className="mdi mdi-arrow-right"></i></a>*/}
                                                </td>
                                                <td>{value.video_url && <button className="btn btn-light btn-label" onClick={() => {handleVideo(value.video_url)}} rel="noreferrer"><i className="bx bxl-vimeo label-icon"></i> Video</button> }</td>
                                                <td>{value.ordering}</td>
                                                <td>{value?.home_episode !== undefined && value.home_episode?'Yes':'No'}</td>
                                                <td>{value.status?'Active':'Inactive'}</td>
                                                <td><Link to={`/episodes/${currentPage}/edit/${value._id}`}><i className="bx bxs-edit-alt"></i> </Link></td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                    </div>
                                    <Row className="justify-content-center">
                                        <Col className="col-auto">
                                            <Pagination
                                                className="pagination-bar"
                                                currentPage={currentPage}
                                                totalCount={totalItems}
                                                pageSize={PageSize}
                                                onPageChange={page => history.push(`/episodes/pageno/${page}/${search}`)}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Modal
                        isOpen={bodyModal}
                        toggle={() => {
                            tog_center()
                        }}
                        centered
                    >
                        <div className="modal-header">
                            <h5 className="modal-title mt-0">Episode About</h5>
                            <button
                                type="button"
                                onClick={() => {
                                    setbodyModal(false)
                                    setBodyData("")
                                }}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>{BodyData}</p>
                        </div>
                    </Modal>
                    <Modal
                        size="lg"
                        isOpen={SeasonModal}
                        toggle={() => {
                            tog_center()
                        }}
                        centered
                    >
                        <div className="modal-header">
                            <h5 className="modal-title mt-0">Season</h5>
                            <button
                                type="button"
                                onClick={() => {
                                    setSeasonModal(false)
                                    setSeasonData("")
                                }}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <dl className="row mb-0">
                                <dt className="col-sm-4">Season name</dt>
                                <dd className="col-sm-8">{SeasonData['searson_name']}</dd>
                                <dt className="col-sm-4">Trailer URL</dt>
                                <dd className="col-sm-8"><span style={{wordBreak:"break-all"}}>{SeasonData['trailer_url']}</span></dd>
                                <dt className="col-sm-4">Order No.:</dt>
                                <dd className="col-sm-8">{SeasonData['ordering']}</dd>
                                <dt className="col-sm-4">Status</dt>
                                <dd className="col-sm-8">{SeasonData['status'] === true ? 'Active' : 'Inactive'}</dd>
                            </dl>
                            <hr/>
                            <Row>
                                <Col md={6}>
                                    <h6>Cover Image</h6>
                                    <Image className="img-fluid" src={SeasonData['cover_image']}/>
                                </Col>
                                <Col md={6}>
                                    <h6>Trailer Image</h6>
                                    <Image className="img-fluid" src={SeasonData['trailer_cover_image']}/>
                                </Col>
                            </Row>
                        </div>
                    </Modal>
                </div>
            </div>
        </React.Fragment>
    )
}

export default OriginalSeasons
