import React from "react"
import MetaTags from "react-meta-tags"
import {Button, Card, CardBody, Col, Container, Label, Row,} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {apiKey} from "../../helpers/url_helper"
import SimpleReactValidator from 'simple-react-validator'
import axios from "axios"
import toastr from "toastr"
import PropTypes from "prop-types"

let api_url = process.env.REACT_APP_API_URL
let api_key = apiKey()
let headers = {
    'Content-Type': 'application/json',
    "Authorization": 'Bearer ' + api_key,
}
const mystyle = {
    height: "110px",
    overflow: "hidden",
    width: "auto",
    objectFit:"cover"
}
const userSession = JSON.parse(localStorage.getItem("authUser"));
class LocationEdit extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            status: true,
            location: "",
            ordering: "",
            is_user_defined: false,
            handleResponse: null,
            old_image: "",
            submit: false,
            dataLoaded: false,
            selectedCity:[],
            selectedCityText:[],
            cities: [],
            city:"",
        }
        this.validator = new SimpleReactValidator({autoForceUpdate: this})
    }
    handleInput = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    handleFileChange = (event) => {
        this.setState({
            image: event.target.files[0]
        })
    }
    componentDidMount() {
        fetch(`${api_url}/get-active-city?status=1`, {headers})
            .then(response => response.json())
            .then(data => this.setState({cities: data.data}))
            .catch(err => {
                console.log("error:", err);
            });
        if (this.state.dataLoaded === false){
            const params = {
                type: "location",
                id: this.props.match.params.id
            }
            axios.get(`${api_url}/get-single-data`, {
                params, headers: headers
            })
                .then(res => {
                    if (res.data.status) {
                        let edit = res.data.data
                        this.setState({
                            status: edit.status,
                            location: edit.name,
                            old_image: edit.image,
                            ordering: edit.ordering,
                            is_user_defined: (edit.is_user_defined !== null ? edit.is_user_defined : false),
                            user_id: edit.user_id,
                            dataLoaded:true,
                            selectedCity:edit['cityId'],
                            show_on_main_page: (edit['show_on_main_page']!==undefined)?edit['show_on_main_page']:false,
                            city:edit['cityId']
                        })
                    } else {
                        toastr.danger('Server not response')
                    }
                })
        }
    }
    selectData = a => {
        let b = []
        for (var c = 0; c < a.length; c++) b.push({
            label: a[c].name, value: a[c]._id
        })
        return b
    }
    handleFormSubmit = (e) => {
        e.preventDefault()
        if (this.validator.allValid()) {
            const formData = new FormData()
            formData.append('id', this.props.match.params.id)
        //    formData.append('name', this.state.location)
            if(this.state.selectedCityText != '' && this.state.selectedCityText != undefined){
                formData.append('name', this.state.selectedCityText)
            }
            formData.append('cityId',this.state.selectedCity)
            var image = document.getElementById("image").files[0];
            if (image){
                formData.append('image', this.state.image)
            }
            formData.append('user_id', userSession.id)
            formData.append('is_user_defined', this.state.is_user_defined)
            formData.append('status', this.state.status)
            formData.append('ordering', this.state.ordering)
            formData.append('show_on_main_page', this.state.show_on_main_page)
            this.setState({submit: true})
            axios({
                method: "post", url: `${api_url}/update-location`, data: formData, headers: headers
            }).then(response => {
                if (response.status) {
                    this.setState({submit: false})
                    toastr.success('Location update successful.')
                    // eslint-disable-next-line react/prop-types
                    const {history} = this.props
                    // eslint-disable-next-line react/prop-types
                    const pageno = this.props.match.params.pId; // Get the pageno param from the URL
                    history.goBack();
                }
            }).catch(err => {
                toastr.error(err.message)
                this.setState({submit: false})
                alert(err.message)
            })
        } else {
            this.validator.showMessages()
            this.forceUpdate()
        }
    }
    handleCity = (e) => {
        var index = e.target.selectedIndex;
        var optionElement = e.target.childNodes[index]
        console.log(optionElement.getAttribute('data-id'));

        this.setState({selectedCity:e.target.value})
        this.setState({selectedCityText:optionElement.getAttribute('data-id')})
    }
    render() {
        const {submit,dataLoaded,cities,selectedCity} = this.state
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>
                            Location | {process.env.REACT_APP_PROJECTNAME}
                        </title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Location" path="/location/pageno/1" breadcrumbItem="Edit Location"/>
                        {dataLoaded === false && <Card><CardBody><div className="text-center px-4 font-size-18 text-primary">Please wait form initialize ...</div></CardBody></Card> }
                        {dataLoaded === true &&
                            <Row>
                                <Col xl="9">
                                    <Card>
                                        <CardBody>
                                            <form onSubmit={this.handleFormSubmit}>
                                                <Row>
                                                    {/*<Col md="3" className="mb-3">*/}
                                                    {/*    <Label htmlFor="location">Location Name</Label>*/}
                                                    {/*    <input name="location" placeholder="Type Location Name"*/}
                                                    {/*           defaultValue={this.state.location} type="text"*/}
                                                    {/*           className={"form-control"} onChange={this.handleInput}/>*/}
                                                    {/*    {this.validator.message('location', this.state.location, 'required')}*/}
                                                    {/*</Col>*/}
                                                    <Col md="3" className="mb-3">
                                                        <label className="control-label">
                                                            City
                                                        </label>
                                                        <select name="city" value={selectedCity} className={"form-select"} onChange={e => {this.handleInput(e);this.handleCity(e)}}>
                                                            <option>Select</option>
                                                            {this.selectData(cities).map((opt, key) => {
                                                                return <option data-id={opt.label} key={key} value={opt.value} >{opt.label}</option>;
                                                            })}
                                                        </select>
                                                        {this.validator.message('city', this.state.city, 'required')}
                                                    </Col>
                                                    <Col md="3" className="mb-3">
                                                        <Label htmlFor="image">Image</Label>
                                                        <input
                                                            id="image"
                                                            name="image"
                                                            placeholder="Image"
                                                            type="file"
                                                            accept="image/*"
                                                            onChange={this.handleFileChange}
                                                            className={"form-control"}
                                                        />
                                                        {/*{this.validator.message('image', this.state.image, 'required')}*/}
                                                    </Col>
                                                    <Col md={3} className="mb-3">
                                                        <label htmlFor="is_user_defined">Defined by ( CT/ User)</label>
                                                        <select className="form-control" defaultValue={this.state.is_user_defined} value={this.state.is_user_defined} onChange={this.handleInput} name="is_user_defined">
                                                            <option value="" selected disabled>Select a option</option>
                                                            <option value="true">User</option>
                                                            <option value="false">CT</option>
                                                        </select>
                                                        {this.validator.message('ordering', this.state.is_user_defined, 'required')}
                                                    </Col>
                                                    <Col md={3} className="mb-3">
                                                        <label htmlFor="ordering">Order No.</label>
                                                        <input name="ordering" placeholder="Type Ordering"
                                                               defaultValue={this.state.ordering} type="text"
                                                               className={"form-control"} onChange={this.handleInput}/>
                                                        {this.validator.message('ordering', this.state.ordering, 'required|numeric')}
                                                    </Col>
                                                    <Col md="auto" className="mb-3">
                                                        <Label htmlFor="">Show on main page</Label>
                                                        <div className="square-switch mt-2">
                                                            <input type="checkbox" id="square-show_on_main_page" switch="none" name="show_on_main_page"
                                                                checked={this.state.show_on_main_page} onChange={() =>
                                                                this.setState({ show_on_main_page: !this.state.show_on_main_page })
                                                            }
                                                            />
                                                            <label
                                                                htmlFor="square-show_on_main_page"
                                                                data-on-label="On"
                                                                data-off-label="Off"
                                                            />
                                                        </div>
                                                        {this.validator.message("show_on_main_page", this.state.show_on_main_page, "boolean")}
                                                    </Col>
                                                    <Col md="auto" className="mb-3">
                                                        <Label htmlFor="">Status</Label>
                                                        <div className="square-switch mt-2">
                                                            <input type="checkbox" id="square-switch-status"
                                                                   switch="none" name="status"
                                                                   checked={this.state.status}
                                                                   onChange={() => this.setState({status: !this.state.status})}/>
                                                            <label
                                                                htmlFor="square-switch-status"
                                                                data-on-label="On"
                                                                data-off-label="Off"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <div className="d-flex flex-wrap gap-2 justify-content-end">
                                                    <Button color="primary" type="submit" disabled={submit}>
                                                        {submit === true ? 'Please wait...' : 'Submit Data'}
                                                    </Button>
                                                </div>
                                            </form>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col xl="3">
                                    <Card>
                                        <CardBody>
                                            <h6>Old Image</h6>
                                            {!this.state.old_image && <h5 className="text-danger">No image</h5>}
                                            {this.state.old_image && <img src={this.state.old_image} className="img-fluid" style={mystyle} alt="image"/>}
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        }
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

LocationEdit.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string
        })
    }),
    location: PropTypes.object
}

export default LocationEdit
