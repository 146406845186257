import React from "react"
import MetaTags from "react-meta-tags"
import {Button, Card, CardBody, Col, Container, Label, Row,} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {apiKey} from "../../helpers/url_helper"
import SimpleReactValidator from 'simple-react-validator'
import axios from "axios"
import toastr from "toastr"
import PropTypes from "prop-types"
import country_code from "../../common/data/countrycodes"
import Select from "react-select"
import notification from "../../components/Common/Notification";
let api_url = process.env.REACT_APP_API_URL
let api_key = apiKey()
const mystyle = {
    height: "110px",
    overflow: "hidden",
    width: "auto",
    objectFit:"cover"
}
const headers = {'Content-Type': 'application/json', "Authorization": 'Bearer ' + api_key}
class UsersEdit extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            name: "",
            email: "",
            mobile: "",
            email_verified: false,
            mobile_verified: false,
            social_youtube: "",
            social_website: "",
            social_facebook: "",
            social_instagram: "",
            photo: "",
            profile_pic: null,
            user_role: [],
            bio: "",
            roleId:"",
            selectedRole:[],
            status:"",
            designation: "",
            social_static_followers:0
        }
        this.validator = new SimpleReactValidator({autoForceUpdate: this})
    }

    componentDidMount() {

        const url = `${api_url}/user-roles?status=1`
        axios.get(url,{headers})
            .then(res => {
                res.status && this.setState({user_role:res.data.data});
            }).catch(err => notification(err,'error','toast-bottom-right'));
        const id = this.props.match.params.id
        this.setState({edit_id: id})
        const params = {user_id: id}
        axios.get(`${api_url}/get-user`, {
            params, headers
        })
            .then(res => {
                if (res.data.status) {
                    let edit = res.data.data
                    this.setState({
                        name: edit['name'],
                        email: edit['email'],
                        country_code: edit['country_code'],
                        mobile: edit['mobile'],
                        email_verified: !!edit['email_verified'],
                        mobile_verified: !!edit['mobile_verified'],
                        social_youtube: edit['social_youtube'],
                        social_website: edit['social_website'],
                        social_facebook: edit['social_facebook'],
                        social_instagram: edit['social_instagram'],
                        photo: edit['photo'],
                        bio: edit['bio'],
                        roleId: edit['roleId'],
                        status: edit['status'],
                        designation: edit['designation'],
                        social_static_followers:edit['social_static_followers']
                    })
                    this.country_code()
                    this.user_role()
                } else {
                    toastr.danger('Server not response')
                }
            })
    }

    handleInput = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    handleFileChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        if (file) {
        reader.readAsDataURL(file);
        
        this.setState({
            photo: file
        })
        
        reader.onloadend = () => {
            this.setState({
                profile_pic: reader.result
            });
        }
    }

    }
    country_code = () => {
        let country_code_master = []
        country_code.map((val,key) => {
            country_code_master.push({value:val.dial_code,label:val.name+' '+val.dial_code})
            if (this.state.country_code === val.dial_code){
                this.setState({country_code:{value:val.dial_code,label:val.name+' '+val.dial_code}})
            }
        })
        this.setState({phone_code:country_code_master})
    }
    user_role = () => {
        let user_role_master = [];
        this.state.user_role.map((val,key) => {
            user_role_master.push({value:val.id,label:val.name})
            if (this.state.roleId === val.id){
                this.setState({selectedRole:{value:val.name,label:val.name}})
            }
        })
        this.setState({roles:user_role_master})
    }


    handleFormSubmit = async (e) => {
        e.preventDefault()
        if (this.validator.allValid()) {
            const formData = new FormData()
            formData.append('name', this.state.name)
            formData.append('email', this.state.email)
            formData.append('country_code', this.state.country_code?.value)
            formData.append('mobile', this.state.mobile)
            formData.append('photo', this.state.photo)
            formData.append('email_verified', this.state.email_verified?1:0)
            formData.append('mobile_verified', this.state.mobile_verified?1:0)
            formData.append('social_youtube', this.state.social_youtube)
            formData.append('social_website', this.state.social_website)
            formData.append('social_facebook', this.state.social_facebook)
            formData.append('social_instagram', this.state.social_instagram)
            formData.append('bio', this.state.bio)
            formData.append('status', this.state.status ? 1 : 0)
            formData.append('designation', this.state.designation)
            formData.append('id', this.state.edit_id)
            formData.append('roleId', this.state.roleId)
            formData.append('social_static_followers', this.state.social_static_followers)
            this.setState({submit: true})
            const req2 = await fetch(`${api_url}/update-user`, {
                method: "POST",
                headers: {
                    "Authorization": 'Bearer '+api_key,
                },
                body: formData
            });
            const data2 = await req2.json();
            if (data2.status) {
                this.setState({ submit: false });
                toastr.success('User update successful.')
                // eslint-disable-next-line react/prop-types
                    const {history} = this.props
                    // eslint-disable-next-line react/prop-types
                    const pageno = this.props.match.params.pId; // Get the pageno param from the URL
                    history.goBack();
            } else {
                toastr.error(data2.message);
                this.setState({submit: false})
            }
            // axios({
            //     method: "post", url: `${api_url}/update-user`, data: formData, headers
            // }).then(response => {
            //     if (response.status) {
            //         this.setState({submit: false})
            //         toastr.success('User update successful.')
            //         // eslint-disable-next-line react/prop-types
            //         const {history} = this.props
            //         // eslint-disable-next-line react/prop-types
            //         history.push('/users')
            //     }
            // }).catch(err => {
            //     toastr.error(err.message)
            //     this.setState({submit: false})
            //     alert(err.message)
            // })
        } else {
            this.validator.showMessages()
            this.forceUpdate()
        }
    }

    render() {
        const {submit,selectedRole} = this.state
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>
                            Manage User | {process.env.REACT_APP_PROJECTNAME}
                        </title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Manage User" path="/users/pageno/1" breadcrumbItem="Edit User"/>
                        <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                        <form onSubmit={this.handleFormSubmit}>
                                            <Row>
                                                <Col md="3" className="mb-3">
                                                    <Label htmlFor="name">Full Name</Label>
                                                    <input name="name" placeholder="Type Full Name"
                                                           defaultValue={this.state.name} type="text"
                                                           className={"form-control"} onChange={this.handleInput}/>
                                                    {this.validator.message('name', this.state.name, 'required')}
                                                </Col>
                                                <Col md={3} className="mb-3">
                                                    <Label htmlFor="role">User Role</Label>
                                                    <Select
                                                        value={this.state.selectedRole}
                                                        isMulti={false}
                                                        onChange={e => {this.setState({selectedRole:e, roleId:e.value})}}
                                                        options={this.state.roles}
                                                        classNamePrefix="select2-selection"
                                                    />

                                                    {this.validator.message('role', this.state.roleId, 'required')}
                                                
                                                </Col>
                                                <Col md="3" className="mb-3">
                                                    <Label htmlFor="designation">Designation</Label>
                                                    <input name="designation" placeholder="Type Designation"
                                                            defaultValue={this.state.designation} type="text"
                                                            className={"form-control"} onChange={this.handleInput}/>
                                                    {/* {this.validator.message('designation', this.state.designation, 'required')} */}
                                                </Col>
                                                <Col md="3" className="mb-3">
                                                    <Label htmlFor="email">Email Address</Label>
                                                    <input name="email" placeholder="Type Email Address"
                                                           defaultValue={this.state.email} type="email"
                                                           className={"form-control"} onChange={this.handleInput}/>
                                                    {this.validator.message('email', this.state.email, 'required|email')}
                                                </Col>
                                                <Col md="2" className="mb-3">
                                                    <Label htmlFor="country_code">Country Code</Label>
                                                    <Select
                                                        value={this.state.country_code}
                                                        isMulti={false}
                                                        onChange={e => this.setState({country_code:e})}
                                                        options={this.state.phone_code}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                    {this.validator.message('country_code', this.state.country_code, 'required')}
                                                </Col>
                                                <Col md="auto" className="mb-3">
                                                    <Label htmlFor="mobile">Mobile Number</Label>
                                                    <input name="mobile" placeholder="Type Mobile Number"
                                                           defaultValue={this.state.mobile} type="text"
                                                           className={"form-control"} onChange={this.handleInput}/>
                                                    {this.validator.message('mobile', this.state.mobile, 'required|phone')}
                                                </Col>
                                                <Col md="auto" className="mb-3">
                                                    <Label htmlFor="">Email Verified</Label>
                                                    <div className="square-switch mt-2">
                                                        {/* eslint-disable-next-line react/no-unknown-property */}
                                                        <input type="checkbox" id="square-switch-email-verified" switch="none" name="status" checked={this.state.email_verified} onChange={() => this.setState({email_verified: !this.state.email_verified})}/>
                                                        <label htmlFor="square-switch-email-verified" data-on-label="Yes" data-off-label="No"/>
                                                    </div>
                                                </Col>
                                                <Col md="auto" className="mb-3">
                                                    <Label htmlFor="">Mobile Verified</Label>
                                                    <div className="square-switch mt-2">
                                                        {/* eslint-disable-next-line react/no-unknown-property */}
                                                        <input type="checkbox" id="square-switch-mobile-verified" switch="none" name="mobile_verified" checked={this.state.mobile_verified} onChange={() => this.setState({mobile_verified: !this.state.mobile_verified})}/>
                                                        <label htmlFor="square-switch-mobile-verified" data-on-label="Yes" data-off-label="No"/>
                                                    </div>
                                                </Col>
                                                <Col md="4" className="mb-3">
                                                    <Label htmlFor="social_youtube">Youtube</Label>
                                                    <input name="social_youtube" placeholder="Type Youtube Link" defaultValue={this.state.social_youtube} type="url" className={"form-control"} onChange={this.handleInput}/>
                                                    {/*{this.validator.message('social_youtube', this.state.social_youtube, 'required|url')}*/}
                                                </Col>
                                                <Col md="4" className="mb-3">
                                                    <Label htmlFor="social_website">Website</Label>
                                                    <input name="social_website" placeholder="Type Website Link" defaultValue={this.state.social_website} type="url" className={"form-control"} onChange={this.handleInput}/>
                                                    {/*{this.validator.message('social_website', this.state.social_website, 'required|url')}*/}
                                                </Col>
                                                <Col md="4" className="mb-3">
                                                    <Label htmlFor="social_facebook">Facebook</Label>
                                                    <input name="social_facebook" placeholder="Type Facebook Link" defaultValue={this.state.social_facebook} type="url" className={"form-control"} onChange={this.handleInput}/>
                                                    {/*{this.validator.message('social_facebook', this.state.social_facebook, 'required|url')}*/}
                                                </Col>
                                                <Col md="4" className="mb-3">
                                                    <Label htmlFor="social_instagram">Instagram</Label>
                                                    <input name="social_instagram" placeholder="Type Instagram Link" defaultValue={this.state.social_instagram} type="text" className={"form-control"} onChange={this.handleInput}/>
                                                    {/*{this.validator.message('social_instagram', this.state.social_instagram, 'required|url')}*/}
                                                </Col>
                                                <Col md="3" className="mb-3">
                                                    <Label htmlFor="social_instagram">Profile Picture</Label>
                                                    <input name="photo" placeholder="Select profile picture" accept="image/png,image/jpg,image.webp" type="file" className={"form-control"} onChange={this.handleFileChange}/>
                                                    {/*{this.validator.message('social_instagram', this.state.social_instagram, 'required|url')}*/}
                                                </Col>
                                                <Col md="3" className="mb-3">
                                                    <Label htmlFor="social_static_followers">Static Followers</Label>
                                                    <input name="social_static_followers" placeholder="Type Static followers" value={this.state.social_static_followers} type="number" min={0} className={"form-control"} onChange={this.handleInput}/>
                                                    {/*{this.validator.message('social_youtube', this.state.social_youtube, 'required|url')}*/}
                                                </Col>
                                                <Col md="6" className="mb-3">
                                                    <Label htmlFor="bio">Bio</Label>
                                                    <textarea name="bio" placeholder="Type Bio" className={"form-control"} value={this.state.bio} onChange={this.handleInput}>{this.state.bio}</textarea>
                                                    {/*{this.validator.message('bio', this.state.bio, 'required')}*/}
                                                </Col>
                                                <Col md="auto" className="mb-3">
                                                    <Label htmlFor="">Status</Label>
                                                    <div className="square-switch mt-2">
                                                        {/* eslint-disable-next-line react/no-unknown-property */}
                                                        <input type="checkbox" id="square-switch-Status" switch="none" name="status" checked={this.state.status} onChange={() => this.setState({status: !this.state.status})}/>
                                                        <label htmlFor="square-switch-Status" data-on-label="Yes" data-off-label="No"/>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <div className="d-flex flex-wrap gap-2 justify-content-end">
                                                <Button color="primary" type="submit" disabled={submit}>
                                                    {submit === true ? 'Please wait...' : 'Submit Data'}
                                                </Button>
                                            </div>
                                        </form>
                                    </CardBody>
                                </Card>
                            </Col>
                            {this.state.photo &&
                                <Col xl="2">
                                    <Card>
                                        <CardBody>
                                            <h6>Profile Picture</h6>
                                            <img src={this.state.profile_pic?this.state.profile_pic:this.state.photo} className="img-fluid" style={mystyle}
                                                 alt="cover image"/>
                                        </CardBody>
                                    </Card>
                                </Col>
                            }
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

UsersEdit.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string
        })
    }),
    location: PropTypes.object
}

export default UsersEdit
