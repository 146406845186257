import React, {useEffect, useMemo, useState} from "react"
import { useParams, useHistory, useLocation } from 'react-router-dom';
import {Card, CardBody, CardTitle, Col, Row} from "reactstrap"
import MetaTags from 'react-meta-tags'
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {TableHeader, Pagination, Search} from "../../Datatable/index"
import {apiKey} from "helpers/url_helper"


import axios from 'axios'
import {Link} from "react-router-dom"
import notification from "../../../components/Common/Notification"

let api_url = process.env.REACT_APP_API_URL
let api_key = apiKey()

const PageSize = 15

const useFullPageLoader = () => {
    const [loading, setLoading] = useState(false)

    return [
        loading ? 'Loading' : null,
        () => setLoading(true), //Show loader
        () => setLoading(false) //Hide Loader
    ]
}

const BlogsCategories = (props) => {
    const history = useHistory();
    const location = useLocation();
    const { pageno } = useParams();
    const [tableData, setTableData] = useState([])
    const [loader, showLoader, hideLoader] = useFullPageLoader()
    const [totalItems, setTotalItems] = useState(0)
    const [currentPage, setCurrentPage] = useState(() => {
        const paramPage = parseInt(pageno);
        if (!isNaN(paramPage)) {
            return paramPage;
          } else {
            return 1;
          }
      });
    const [search, setSearch] = useState("")
    const [sorting, setSorting] = useState({field: "", order: ""})

    const headers = [
        {name: "#", field: "id", sortable: false},
        {name: "Category Name", field: "name", sortable: false},
        // {name: "Image", field: "image", sortable: false},
        {name: "Interest", field: "interest_details", sortable: false},
        // {name: "Order No.", field: "ordering", sortable: false},
        {name: "Status", field: "status", sortable: false},
        // {name: "Action", field: "", sortable: false}
    ]
    
    const handleChange = (value) => {
        history.push(`/blogs/categories/${value}`)
    };
    // Handle any changes to the currentPage state
    const handlePageChange = () => {
        setCurrentPage(() => {
            const paramPage = parseInt(pageno);
            if (!isNaN(paramPage)) {
                return paramPage;
            } else {
                return 1;
            }
        })
    };
    useEffect(() => {
        handlePageChange();
    }, [pageno,location]);
    useEffect(() => {
        showLoader()
        let query = ''
        search && (query = `keyword=${search}&`)
        const url = `${api_url}/get-blog-categories?${query}limit=${PageSize}&pageNo=${currentPage}`
        axios.get(url, {
            headers: {
                "Authorization": 'Bearer ' + api_key,
            }
        })
            .then(res => {
                if (res.status) {
                    setTableData(res.data.data)
                    setTotalItems(res.data.total_results)
                }
                hideLoader()
            }).catch(
                err => {
                    notification(err, 'error', 'toast-bottom-right')
                    if(err.response.status===401 || err.response.status===400 ){
                        history.push('/logout')
                    }
                }
            );
    }, [currentPage, search])



    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Manage Scoops Category</title>
                </MetaTags>
                <div className="container-fluid">
                    <Breadcrumbs title="Dashboard" path="/" breadcrumbItem="Scoops Category"/>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <div className="row">
                                        <div className="col-md-6">

                                        </div>
                                        <div className="col-md-6 d-flex flex-row-reverse">
                                            {/*<div className="ms-2">*/}
                                            {/*    <a href="/blogs/categories/add"*/}
                                            {/*       className="btn btn-primary waves-effect waves-light"><i*/}
                                            {/*        className="bx bx-plus font-size-16 align-middle me-2"></i> Add</a>*/}
                                            {/*</div>*/}
                                            <Search
                                                onSearch={value => {
                                                    setSearch(value)
                                                    setCurrentPage(1)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <table className="table table-striped">
                                        <TableHeader headers={headers}/>
                                        <tbody className="">{loader && <tr>
                                            <th colSpan={headers.length}><h4 className="text-center">Loading...</h4>
                                            </th>
                                        </tr>}
                                        {(!loader && tableData.length === 0) && <tr>
                                            <th colSpan={headers.length}><h4 className="text-center">No data found</h4>
                                            </th>
                                        </tr>}
                                        {!loader && tableData.map((value, index) => (
                                            <tr key={++index}>
                                                <th scope="row">
                                                    {PageSize * (currentPage - 1) + (index + 1)}
                                                </th>
                                                <td>{value.name}</td>
                                                {/*<td><img src={value.image} className="img-thumbnail avatar-md"*/}
                                                {/*         width="200" alt={value.name}/></td>*/}
                                                <td>{value.interest_details !== undefined && value.interest_details?.[0]?.name}</td>
                                                {/*<td>{value.ordering}</td>*/}
                                                <td>{value.status === true ? <span
                                                        className="badge badge-pill badge-soft-success font-size-13">Active</span> :
                                                    <span
                                                        className="badge badge-pill badge-soft-error font-size-13">Inactive</span>}</td>
                                                {/*<td><Link to={`/blogs/categories/${currentPage}/edit/${value._id}`}><i*/}
                                                {/*    className="bx bxs-edit-alt"></i></Link></td>*/}
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                    <Pagination
                                        total={totalItems}
                                        itemsPerPage={PageSize}
                                        currentPage={currentPage}
                                        onPageChange={page => handleChange(page)}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

export default BlogsCategories
