import React, {Component} from "react"
import Dropzone from "react-dropzone"
import UploadService from "../helpers/upload-files-draft.service"
import PropTypes from "prop-types"

export default class UploadFileVideoDraft extends Component<{ id?: string,type?:string,allow_file?:string }> {
    constructor(props) {
        super(props)
        this.upload = this.upload.bind(this)
        this.uploadFiles = this.uploadFiles.bind(this)
        this.onDrop = this.onDrop.bind(this)
        this.recursiveUpload = this.recursiveUpload.bind(this)
        const { history } = this.props;
        this.state = {
            selectedFiles: undefined,
            progressInfos: [],
            message: [],
            fileInfos: [],
            id: this.props.id,
            type: this.props.type,
            allow_file: this.props.allow_file,
            isloaded: false
        }
    }

    componentDidMount() {
        // UploadService.getFiles(this.state.id, this.state.type).then((response) => {
        //     this.setState({
        //         fileInfos: response.data,
        //     },this.loadedData)
        // })        
    }

    loadedData = () => {
        this.setState({isloaded: true})
    }

    upload(idx, file,files)  {

        if(idx === files.length - 1){
            let _progressInfos = [...this.state.progressInfos]
            let id = this.props.id, type = this.props.type
            UploadService.upload(file, id, type, (event) => {
                _progressInfos[idx].percentage = Math.round(
                    (250 * event.loaded) / event.total
                )
                this.setState({
                    _progressInfos,
                })
            })
                .then((response) => {
                    this.setState((prev) => {
                        let nextMessage = [
                            ...prev.message,
                            "Uploaded the file successfully: " + file.name,
                        ]
                        return {
                            message: nextMessage,
                        }
                    })
                    console.log(response)
                    
                    return UploadService.getFiles(id, type)
                })
                .then((files) => {
                    
                    console.log(files)
                    this.setState({
                        fileInfos: files.data,
                    })
                    setTimeout(() => {
                        history.back();
                    }, 3000);
                })
                .catch(() => {
                    _progressInfos[idx].percentage = 0
                    this.setState((prev) => {
                        let nextMessage = [
                            ...prev.message,
                            "Could not upload the file: " + file.name,
                        ]
                        return {
                            progressInfos: _progressInfos,
                            message: nextMessage,
                        }
                    })
                })
        }else{
            let _progressInfos = [...this.state.progressInfos]
            let id = this.props.id, type = this.props.type
            UploadService.upload(file, id, type, (event) => {
                _progressInfos[idx].percentage = Math.round(
                    (250 * event.loaded) / event.total
                )
                this.setState({
                    _progressInfos,
                })
            })
                .then((response) => {
                    this.setState((prev) => {
                        let nextMessage = [
                            ...prev.message,
                            "Uploaded the file successfully: " + file.name,
                        ]
                        return {
                            message: nextMessage,
                        }
                    })
                    console.log(response)
                    this.upload(idx+1,files[idx+1],files)
                    return UploadService.getFiles(id, type)
                })
                .then((files) => {
                    
                    console.log(files)
                    this.setState({
                        fileInfos: files.data,
                    })
                })
                .catch(() => {
                    _progressInfos[idx].percentage = 0
                    this.setState((prev) => {
                        let nextMessage = [
                            ...prev.message,
                            "Could not upload the file: " + file.name,
                        ]
                        return {
                            progressInfos: _progressInfos,
                            message: nextMessage,
                        }
                    })
                })
        }       
    }

    uploadFiles() {
        const selectedFiles = this.state.selectedFiles

        let _progressInfos = []

        for (let i = 0; i < selectedFiles.length; i++) {
            _progressInfos.push({percentage: 0, fileName: selectedFiles[i].name})
        }

        this.setState(
            {
                progressInfos: _progressInfos,
                message: [],
            },
            () => {

                this.upload(0 ,selectedFiles[0],selectedFiles)
                // this.recursiveUpload(0,selectedFiles)
                // for (let i = 0; i < selectedFiles.length; i++) {
                //     let respo = this.upload(i, selectedFiles[i])
                //     console.log(respo)
                // }
            }
        )
    }

    recursiveUpload = (index,files) => {
        if(index === files.length -1){
            console.log("uploading file "+ index + " = " + files[index])
            //let uploadstatus = this.upload(index,files[index])
        }else{
            // let uploadstatus = this.upload(index,files[index])
            console.log("uploading file "+ index + " = " + files[index])
            let uploadstatus = true
            if(uploadstatus === true){
                this.recursiveUpload(index+1,files)
            }
        }
        
    }

    onDrop(files) {
        if (files?.length > 0 && files?.length <= 25) {
          this.setState({ selectedFiles: files, message: [] });
        } else if (files?.length > 25) {
          this.setState({ message: ['Only up to 25 files allowed at once.'] });
        } else {
          this.setState({ selectedFiles: files, message: [] });
        }
      }

    render() {
        const {selectedFiles, progressInfos, message, fileInfos} = this.state
        let api_url = process.env.REACT_APP_API_URL
        let downloadUrl = api_url + "/video-draft-csv"
        let clearURL = api_url + "/video-draft-clear"
        
        return (
            <div>
                {progressInfos &&
                    progressInfos.map((progressInfo, index) => (
                        <div className="mb-2" key={index}>
                            <span>{progressInfo.fileName}</span>
                            <div className="progress">
                                <div
                                    className="progress-bar progress-bar-info"
                                    role="progressbar"
                                    aria-valuenow={progressInfo.percentage}
                                    aria-valuemin="0"
                                    aria-valuemax="250"
                                    style={{width: progressInfo.percentage + "%"}}
                                >
                                    {progressInfo.percentage}%
                                </div>
                            </div>
                        </div>
                    ))}
                <div className="my-3">
                    <Dropzone onDrop={this.onDrop} accept={this.state.allow_file} maxSize={158334976}>
                        {({getRootProps, getInputProps}) => (
                            <section>
                                <div className="d-flex justify-content-between mb-">
                                    <a href={downloadUrl}
                                        target="_blank" rel="noreferrer"
                                        className="btn btn-primary waves-effect waves-light"><i
                                        className="bx bx-download font-size-16 align-middle me-2"></i> Download
                                    </a>
                                    <a href={clearURL}
                                        target="_blank" rel="noreferrer"
                                        className="btn btn-primary waves-effect waves-light">
                                            Clear
                                    </a>
                                </div>
                                <div {...getRootProps({className: "dropzone dz-message needsclick mt-2"})}>
                                    <input {...getInputProps()} />
                                    {selectedFiles &&
                                    Array.isArray(selectedFiles) &&
                                    selectedFiles.length ? (
                                        <div className="selected-file">
                                            {selectedFiles?.length > 3
                                                ? `${selectedFiles.length} files`
                                                : selectedFiles.map((file) => file.name).join(", ")}
                                        </div>
                                    ) : (
                                        <div>
                                            <div className="mb-3">
                                                <i className="display-4 text-muted bx bxs-cloud-upload"/>
                                            </div>
                                            <h4>Drop files here or click to upload.</h4>
                                            <p>Max file size allow 50MB</p>
                                            <p>Max 25 files are allowed at once.</p>
                                        </div>
                                    )}
                                </div>
                                <aside className="selected-file-wrapper text-center mt-4">
                                    <button
                                        className="btn btn-primary"
                                        disabled={!selectedFiles}
                                        onClick={this.uploadFiles}
                                    >
                                        Upload
                                    </button>
                                </aside>
                            </section>
                        )}
                    </Dropzone>
                </div>

                {message?.length > 0 && (
                    <div className="alert alert-secondary" role="alert">
                        <ul>
                            {message.map((item, i) => {
                                return <li key={i}>{item}</li>
                            })}
                        </ul>
                    </div>
                )}
            </div>
        )
    }
}
UploadFileVideoDraft.propTypes = {
    id: PropTypes.string,
    type: PropTypes.string,
    allow_file: PropTypes.string,
}
