import React, {useEffect, useMemo, useState} from "react";
import { useParams, useHistory, useLocation } from 'react-router-dom';
import {Card, CardBody, Col, Row} from "reactstrap"
import MetaTags from 'react-meta-tags'
import Pagination from 'components/Common/Pagination';
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {TableHeader, Search} from "../Datatable/index";
import {apiKey} from "helpers/url_helper"
import {Link} from "react-router-dom";
import axios from 'axios';
import notification from "../../components/Common/Notification"
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
let api_url = process.env.REACT_APP_API_URL;
let api_key = apiKey()
let exportURL = api_url + "/get-shows-csv"

const useFullPageLoader = () => {
    const [loading, setLoading] = useState(false);

    return [
        loading ? 'Loading' : null,
        () => setLoading(true), //Show loader
        () => setLoading(false) //Hide Loader
    ];
};
let PageSize = 15;
const Originals = (props) => {
    const history = useHistory();
    const location = useLocation();
    const { pId:pageno } = useParams();
    const [tableData, settableData] = useState([]);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(() => {
        const paramPage = parseInt(pageno);
        if (!isNaN(paramPage)) {
            return paramPage;
          } else {
            return 1;
          }
      });
      const { searchQuery } = useParams();
      const [search, setSearch] = useState(() => {
          if (searchQuery) {
              return searchQuery;
            } else {
              return '';
            }
        });
      const [getSearch, setGetSearch] = useState(search);
      const [getSearchPage, setGetSearchPage] = useState(currentPage)
      const [isFits, setisFits] = useState(false);
    const [main_img, setMainImg] = useState("");

    const CountryHeader = [
        {name: "#", field: "id", sortable: false},
        {name: "Originials", field: "name", sortable: false},
        {name: "About", field: "about", sortable: false},
        {name: "Logo", field: "cover_image", sortable: false},
        {name: "Thumbnail", field: "thumbnail", sortable: false},

        {name: "Tags", field: "tags", sortable: false},
        {name: "Popular", field: "is_popular", sortable: false},
        // {name: "Show on main page", field: "show_on_main_page", sortable: false},
        {name: "Ordering", field: "ordering", sortable: false},
        {name: "Total Spam Count", field: "spams_comment_count", sortable: false},
        {name: "Status", field: "status", sortable: false},
        {name: "Action", field: "", sortable: false}
    ];
    function handleSearch(){
        setSearch(getSearch);
        setCurrentPage(getSearchPage);
        if(getSearch){
            history.push(`/originals/pageno/${getSearchPage}/${getSearch}`)
        }
    }// Handle any changes to the currentPage state
    const handlePageChange = () => {
        setCurrentPage(() => {
            const paramPage = parseInt(pageno);
            if (!isNaN(paramPage)) {
                return paramPage;
              } else {
                return 1;
              }
          })
    };
    useEffect(() => {
        handlePageChange();
    }, [pageno,location]);

    useEffect(() => {
        showLoader()
        let query = ''
        search && ( query = `keyword=${search}`)
        const url = `${api_url}/get-shows?${query}&limit=${PageSize}&pageNo=${currentPage}`
        axios.get(url,{
            headers: {
                "Authorization": 'Bearer '+api_key,
            }
        })
            .then(res => {
                hideLoader();
                if(res.status) {
                    settableData(res.data.data);
                    setTotalItems(res.data.totalRecords);
                }
            }).catch(
                err => {
                    notification(err, 'error', 'toast-bottom-right')
                    if(err.response.status===401 || err.response.status===400 ){
                        history.push('/logout')
                    }
                }
            );
    }, [currentPage,search])  // pass `value` as a dependency

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Manage Originals</title>
                </MetaTags>
                <div className="container-fluid">
                    <Breadcrumbs title="Dashboard" path="/" breadcrumbItem="Originals"/>
                    {isFits ? (
                        <Lightbox
                            mainSrc={main_img}
                            enableZoom={false}
                            onCloseRequest={() => {
                                setisFits(!isFits);
                            }}
                        />
                    ) : null}
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <div className="d-flex justify-content-between mb-3 gap-3">
                                        <div>
                                            <a href={exportURL}
                                                target="_blank" rel="noreferrer"
                                               className="btn btn-primary waves-effect waves-light"><i
                                                className="bx bx-export font-size-16 align-middle me-2"></i> Export</a>
                                        </div>
                                        <div>
                                            <a href="/originals/add"
                                               className="btn btn-primary waves-effect waves-light"><i
                                                className="bx bx-plus font-size-16 align-middle me-2"></i> Add</a>
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-md-12 d-flex flex-row-reverse">
                                        <div onClick={handleSearch}
                                               className="btn btn-primary waves-effect waves-light ms-2">Search</div>
                                            <Search
                                                onSearch={value => {
                                                    setGetSearch(value)
                                                    setGetSearchPage(1)
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <table className="table table-striped">
                                        <TableHeader
                                            headers={CountryHeader}
                                        />
                                        <tbody className="">{loader && <tr><th colSpan={CountryHeader.length}><h4 className="text-center">Loading...</h4></th></tr>}
                                        {(!loader && tableData.length === 0) && <tr><th colSpan={CountryHeader.length}><h4 className="text-center">No data found</h4></th></tr>}
                                        {!loader && tableData.map((value,index) => (
                                            <tr key={++index}>
                                                <th scope="row">
                                                    {PageSize * (currentPage - 1) + (index + 1)}
                                                </th>
                                                <td>{value.name}</td>
                                                <td><div style={{maxWidth:'200px',wordWrap:'break-word',maxHeight:'40px',textOverflow:'ellipsis',overflow:'hidden'}}>{value.about}</div></td>
                                                <td>
                                                    <img onClick={() => {
                                                            setisFits(true);
                                                            setMainImg(value.cover_image)
                                                        }}
                                                        className="img-thumbnail avatar-sm"
                                                        alt={value.name}
                                                        src={value.cover_image}
                                                        width="200"
                                                    />
                                                </td>
                                                <td>{value.thumbnail?
                                                    <img onClick={() => {
                                                        setisFits(true);
                                                        setMainImg(value.thumbnail)
                                                    }}
                                                         className="img-thumbnail avatar-sm"
                                                         alt={value.name}
                                                         src={value.thumbnail}
                                                         width="200"
                                                    />:'-'}
                                                </td>
                                                <td>{value.tags.map((val, key) => (
                                                    <span className="font-size-12 badge bg-info rounded-pill"
                                                          key={key}>{val['name']}</span>
                                                ))}</td>
                                                <td>{value.is_popular?'Yes':'No'}</td>
                                                {/* <td>{(value.show_on_main_page!==undefined && value.show_on_main_page)?'Yes':'No'}</td> */}
                                                <td>{value.ordering}</td>
                                                <th>{value.original_comment_report_spams_counts>0? <Link  to={`/originals/spam_reports/${value._id}`} style={{color:"green"}}>{value.original_comment_report_spams_counts}</Link>:0}</th>
                                                <td>{value.status?'Active':'Inactive'}</td>
                                                <td><Link to={`/originals/${currentPage}/edit/${value._id}`}><i className="bx bxs-edit-alt"></i> </Link></td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                    <Row className="justify-content-center">
                                        <Col className="col-auto">
                                            <Pagination
                                                className="pagination-bar"
                                                currentPage={currentPage}
                                                totalCount={totalItems}
                                                pageSize={PageSize}
                                                onPageChange={page => history.push(`/originals/pageno/${page}/${search}`)}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Originals
