import React from "react";
import MetaTags from "react-meta-tags";
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { apiKey } from "../../helpers/url_helper";
import SimpleReactValidator from "simple-react-validator";
import axios from "axios";
import toastr from "toastr";
import PropTypes from "prop-types";
import { Editor } from "@tinymce/tinymce-react";

let api_url = process.env.REACT_APP_API_URL;
let api_key = apiKey();

class CMSPageAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: true,
      page_title: "",
      description: "",
      handleResponse: null,
      submit: false,
      // editorState: EditorState.createEmpty()
    };
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }
  handleInput = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onEditorStateChange = editorState => {
    this.setState({
      editorState,
    });
    this.setState({
      description: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    });
  };

  handleFormSubmit = e => {
    e.preventDefault();
    if (this.validator.allValid()) {
      const formData = JSON.stringify({
        page_title: this.state.page_title,
        description: this.state.description,
        status: this.state.status,
      });
      this.setState({ submit: true });
      axios({
        method: "post",
        url: `${api_url}/add-cms`,
        data: formData,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + api_key,
        },
      })
        .then(response => {
          if (response.status) {
            this.setState({ submit: false });
            toastr.success("CMS Page added successful.");
            // eslint-disable-next-line react/prop-types
            const { history } = this.props;
            // eslint-disable-next-line react/prop-types
            history.push("/cms-page");
          }
        })
        .catch(err => {
          toastr.error(err.message);
          this.setState({ submit: false });
          alert(err.message);
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleEditorChange = e => {
    this.setState({ description: e.target.getContent() });
  };

  render() {
    const { page_title, description, submit } = this.state;
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>CMS Page | {process.env.REACT_APP_PROJECTNAME}</title>
          </MetaTags>
          <Container fluid={true}>
            <Breadcrumbs
              title="CMS Page"
              path="/cms-page"
              breadcrumbItem="Add CMS Page"
            />
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <form onSubmit={this.handleFormSubmit}>
                      <Row>
                        <Col md="6" className="mb-3">
                          <Label htmlFor="page_title">Page Title</Label>
                          <input
                            name="page_title"
                            placeholder="Type Page Title"
                            defaultValue={page_title}
                            type="text"
                            className={"form-control"}
                            onChange={this.handleInput}
                          />
                          {this.validator.message(
                            "page_title",
                            this.state.page_title,
                            "required"
                          )}
                        </Col>
                        <Col md="auto" className="mb-3">
                          <Label htmlFor="">Status</Label>
                          <div className="square-switch mt-2">
                            <input
                              type="checkbox"
                              id="square-switch-status"
                              switch="none"
                              name="status"
                              checked={this.state.status}
                              onChange={() =>
                                this.setState({ status: !this.state.status })
                              }
                            />
                            <label
                              htmlFor="square-switch-status"
                              data-on-label="On"
                              data-off-label="Off"
                            />
                          </div>
                        </Col>
                        <Col md={12} className={"mb-3"}>
                          <Label htmlFor="description">Description</Label>
                          <Editor
                            apiKey={process.env.REACT_APP_TINY_EDITOR}
                            initialValue={this.state.blog_content}
                            init={{
                              height: 600,
                              menubar: true,
                              config: {},
                              plugins: [
                                "advlist autolink lists link image preview anchor",
                                "searchreplace code fullscreen",
                                "media paste",
                              ],
                              toolbar: `preview  fullscreen | undo redo| link image media mediaembed | formatselect | bold italic | \
        alignleft aligncenter alignright alignjustify | \
        bullist numlist outdent indent | removeformat | code`,
                              image_title: true,
                              extended_valid_elements:
                                "script[src|async|defer|type|charset]",
                              forced_root_block: "p",
                              keep_styles: true,
                              inline_boundaries: true,
                              branding: false,
                              visual: true,
                            }}
                            onChange={this.handleEditorChange}
                          />
                          {this.validator.message(
                            "description",
                            this.state.description,
                            "required"
                          )}
                        </Col>
                      </Row>

                      <div className="d-flex flex-wrap gap-2 justify-content-end">
                        <Button color="primary" type="submit" disabled={submit}>
                          {submit === true ? "Please wait..." : "Submit Data"}
                        </Button>
                      </div>
                    </form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

CMSPageAdd.propTypes = {
  location: PropTypes.object,
};

export default CMSPageAdd;
